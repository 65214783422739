<template lang="pug">
  BaseView
    Loading(
      v-if="!isReady")
    HandleInvitationFlow(
      v-else
      :invitation="invitation"
      :peopleAdapter="peopleAdapter"
      @join="performJoin")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { profileFollowers } from '@/mixins/resources/profile-followers'

import BaseView from '@/layouts/Base'
import HandleInvitationFlow from '@/components/flows/HandleInvitationFlow'
import Loading from '@/components/Shared/Loading'

import MyProfileAdapter from '@/adapt/my-profile-adapter'
import PeopleAdapter from '@/adapt/people-adapter'

export default {
  mixins: [profileFollowers], // follow

  components: {
    BaseView,
    HandleInvitationFlow,
    Loading,
    MyProfileAdapter,
    PeopleAdapter
  },

  props: {
    uid: String,
    username: String
  },

  data() {
    return {
      isReady: false,
      myProfileAdapter: new MyProfileAdapter(),
      peopleAdapter: new PeopleAdapter()
    }
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    }),

    invitation() {
      return this.uid && this.username ? { host: this.username, id: this.uid } : null
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      showMessage: 'ux_controls/showMessage'
    }),

    handleError(error) {
      switch (error.status) {
        case 422:
          this.showMessage(this.$t('join-error-already-joined'))
          break
        default:
          this.showMessage(this.$t('join-error'))
      }

      this.$router.push(`/p/${this.invitation.host}`)
    },

    async performJoin(selectedPeople) {
      try {
        await this.follow(this.invitation.host, this.invitation.id, selectedPeople)
        this.showMessage(this.$t('join-success'))
        this.$router.push(`/p/${this.invitation.host}`)
      } catch (error) {
        this.handleError(error)
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(async vm => {
      if (vm.isLoggedIn) {
        try {
          await vm.myProfileAdapter.load()
          if (vm.myProfileAdapter.data.user_name == vm.username) {
            // Redirect the user to its profile page if he's accessing its own invitation
            next({ name: 'profile', params: { userName: vm.username } })
          } else {
            await vm.peopleAdapter.reset()
            await vm.peopleAdapter.load({ params: 'filters=is_me,is_dependent' })
            vm.isReady = true
            next()
          }
        } catch (error) {
          // The user has no profile yet
          if (error.status == 404) {
            next(`/profile/new?i=${vm.uid}&p=${vm.username}`)
          }
        }
      } else {
        // next({ name: 'login' })
        vm.login(to.fullPath)
      }
    })
  }
}
</script>

<i18n>
  pt-BR:
    join-error: "Não foi possível ligar seu perfil a este acervo."
    join-error-already-joined: "Você já segue este acervo"
    join-success: "Você se ligou a este acervo com sucesso!"
</i18n>

<style lang="sass" scoped></style>
