<!-- This route is not being used for now. By clicking on a person within
PeopleGrid the user goes straight to /edit -->
<template lang="pug">
BaseView
  template(#navbar)
    Button(
      icon="edit"
      rounded
      :to="{ path: 'edit'}"
      append)
  div(v-if="personAdapter.loading")
    Loading
  div(v-else)
    div(v-if="!personAdapter.newRecord")
      .row.no-gutters
        .col.mx-3
          h1.title {{ personAdapter.data.name }}
    div(v-else)
      .text-center
        i.mt-5.text-danger.fas.fa-times-circle.fa-5x
        h2.p-4 {{ $t('person-not-found') }}
</template>

<script>
// import Avatar from '@/components/People/Avatar'
import BaseView from '@/layouts/Base'
import Button from '@/components/company/Button'
import Loading from '@/components/Shared/Loading'
import PersonAdapter from '@/adapt/person-adapter'

export default {
  components: {
    BaseView,
    Button,
    // Avatar,
    Loading
  },

  props: {
    uid: String
  },

  data() {
    return {
      personAdapter: new PersonAdapter()
    }
  },

  beforeMount() {
    this.personAdapter.load({ id: this.uid })
  }
}
</script>

<i18n>
  pt-BR:
    profile-not-found: "Pessoa não encontrada"
</i18n>
