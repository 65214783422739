<template lang="pug">
  .f-modal.container-fluid(v-if="show")
    header
      slot(name="header")
    slot
    footer.fixed-bottom
      slot(name="footer")
</template>

<script>
import Button from '../general/Button/ButtonNew'
import Icon from '../Icon'

export default {
  components: {
    Button,
    Icon
  },

  props: {
    show: Boolean
  }
}
</script>

<style lang="sass" scoped>
.f-modal
  padding: 0
  width: 100%
  height: 100%
  position: fixed
  top: 0
  left: 0
  background-color: black
  z-index: 500
</style>
