<template lang="pug">
  span
    ValidationProvider(
      v-if="full"
      :name="name"
      :rules="rules"
      v-slot="{ errors }")
      b-form-group#full-name-group(
        :label="label"
        label-for='full-name-input')
        b-form-input#full-name-input(
          v-model='value.fullName'
          @input="handleInput")
          //- :placeholder="$t('placeholder.full_name')"
        span.field-error(v-show='errors.length>0' class='is-invalid') {{ errors[0] }}.
    ValidationProvider(
      v-if="!full"
      :name="name ? name : nameFirst"
      :rules="rules"
      v-slot="{ errors }")
      b-form-group#first-name-group(
        :label="label ? label : labelFirst"
        label-for='first-name-input')
        b-form-input#first-name-input(
          v-model='value.firstName'
          @input="handleInput")
          //- :placeholder="$t('placeholder.first_name')"
        span.field-error(v-show='errors.length>0' class='is-invalid') {{ errors[0] }}.
    ValidationProvider(
      v-if="!full"
      :name="nameLast"
      :rules="rules"
      v-slot="{ errors }")
      b-form-group#last-name-group(
        :label="labelLast"
        label-for='last-name-input')
        b-form-input#last-name-input(
          v-model='value.lastName'
          @input="handleInput")
          //- :placeholder="$t('placeholder.last_name')"
        span.field-error(v-show='errors.length>0' class='is-invalid') {{ errors[0] }}.
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { forms } from '@/mixins/forms'

export default {
  mixins: [forms],

  components: {
    ValidationProvider
  },

  props: {
    full: {
      // Whether it should use full name or first and last name
      type: Boolean,
      default: false
    },
    label: {
      // 'label' prop for either full-name or first-name form group
      type: String,
      default() {
        return this.$t('fields.full-name')
      }
    },
    labelFirst: {
      type: String,
      default() {
        return this.$t('fields.first-name')
      }
    },
    labelLast: {
      type: String,
      default() {
        return this.$t('fields.last-name')
      }
    },
    name: {
      type: String, // 'name' prop for either full-name or first-name input
      default: 'full-name'
    },
    nameFirst: {
      type: String,
      default: 'first-name'
    },
    nameLast: {
      type: String,
      default: 'last-name'
    },
    rules: {
      type: String,
      default: 'required|alpha_spaces'
    },
    value: {
      firstName: String,
      lastName: String,
      fullName: String
    }
  },

  methods: {
    handleInput() {
      this.$emit('input', this.getNameInput(this.value, this.full))
    }
  }
}
</script>

<i18n>
  pt-BR:
    placeholder:
      first_name: 'Cecília'
      last_name: 'Meireles'
      full_name: 'Escola Projeto Vida'
</i18n>

<style lang="sass" scoped>
@import "../../assets/stylesheets/_variables.sass"

.field-error
  font-size: 13px
  color: $primary-color
</style>
