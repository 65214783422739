<!-- This component is based on Avatar, but it has loading state and delete action -->
<template lang="pug">
  span.avatar-wrapper
    Avatar(
      :badge-variant="badgeVariant"
      :size="size"
      :src="src"
      :variant="variant")
      b-icon(
        class="text-grey"
        v-if="loading"
        icon="person-fill"
        :font-scale="0.6*size"
        animation="throb")
      //- I'd like to use our Icon component to keep the standard, but I didn't
      //- manage to do so. If I use it, the loading spinner never disappears
      //- Icon(
        v-if="loading"
        icon-key="loading-icon"
        icon="loading"
        :font-scale="0.6 * size")
    Button.btn-remove(
      v-if="src && !loading && !noReset"
      icon="trash"
      rounded
      variant="light"
      @click="$emit('remove')")
</template>

<script>
import Vue from 'vue'
import { BIcon, BIconPersonFill } from 'bootstrap-vue'
import Component from 'vue-class-component'

import Avatar from '@/components/Avatar'
import Button from '@/components/company/Button'
import Icon from '@/components/Icon'

@Component({
  components: {
    Avatar,
    Button,
    BIcon,
    BIconPersonFill,
    Icon
  },

  props: {
    badgeVariant: {
      type: String,
      default: 'primary'
    },
    loading: {
      type: Boolean,
      default: false
    },
    noReset: {
      type: Boolean,
      default: false
    },
    size: String,
    src: String,
    variant: {
      type: String,
      default: 'light'
    }
  }
})
class AvatarUpload extends Vue {}

export default AvatarUpload
</script>

<style lang="sass" scoped>
.btn-remove
  position: absolute
  top: -50px
  right: 0px

.avatar-wrapper
  position: relative
</style>
