<template lang="pug">
  #follow-button(
    v-show="!loading && profilePeopleAdapter.count > 0")
    Button(
      variant="primary"
      :label="getLabel"
      styles="width: 120px"
      @click="handleClick")
    Modal(
      :id="this.PEOPLE_MODAL_ID"
      no-close-on-backdrop
      @cancel="handleCancel"
      @close="handleCancel"
      @ok="handleOk")
      //- TODO: the required rule was added to this form because for now it's not possible to remove
      //- a user as a member. So we avoid it with this rule. Once this action is available, there
      //- shouldn't be the required rule.
      PersonSelectForm(
        :people-adapter="myProfilePeopleAdapter"
        ref="personSelectForm"
        rules="required"
        v-model="selectedPeople"
        @submit="handleSubmit")
</template>

<script>
import Button from '@/components/company/Button'
import Modal from '@/components/general/Popup'
import PersonSelectForm from '@/components/flows/forms/PersonSelectForm'

export default {
  components: {
    Button,
    Modal,
    PersonSelectForm
  },

  props: {
    myProfilePeopleAdapter: Object, // MyProfileAdapter.people
    profileAdapter: Object, // ProfileAdapter
    label: String,
    loading: Boolean
  },

  data() {
    return {
      isReloading: false,
      isFollowing: false, // Help to set button's label
      selectedPeople: []
    }
  },

  computed: {
    getLabel() {
      return this.label
        ? this.label
        : this.loading
        ? '...'
        : this.isFollowing
        ? this.$t('following')
        : this.$t('follow')
    },

    profilePeopleAdapter() {
      return this.profileAdapter.people
    }
  },

  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault() // Prevent modal from closing
      this.$refs.personSelectForm.$refs.formWrapper.onSubmit()
    },

    handleCancel() {
      this.updateSelectedPeople()
    },

    handleClick() {
      this.$bvModal.show(this.PEOPLE_MODAL_ID)
    },

    async handleSubmit() {
      await this.profileAdapter.updateFollowers(this.selectedPeople) // member and people

      // Avoid checkbox blinking because of updateSelectedPeople during reset and reload
      this.isReloading = true
      // Update the store (maybe we could update arrays instead of reloading from the backend)
      await this.profilePeopleAdapter.reset()
      await this.profilePeopleAdapter.loadFromApi()
      this.isReloading = false
      // Enable form submit button again
      this.$refs.personSelectForm.$refs.formWrapper.isSubmitting = false

      this.$nextTick(() => {
        this.$bvModal.hide(this.PEOPLE_MODAL_ID)
      })
    },

    // Update checkboxes with people already following the profile
    updateSelectedPeople() {
      // Being reloading means that selectedPeople is already with the correct state because
      // there was no navigation to another profile
      if (!this.isReloading) {
        this.selectedPeople.splice(0, this.selectedPeople.length)

        this.profilePeopleAdapter.data.forEach(person => {
          this.selectedPeople.push(person.uid)
        })
      }
    }
  }, // methods

  watch: {
    'profilePeopleAdapter.data': function() {
      // Update isFollowing state in order to update button label if needed
      this.isFollowing =
        this.profilePeopleAdapter.count > 0 ? true : this.isReloading ? this.isFollowing : false
      this.updateSelectedPeople()
    }
  },

  created() {
    this.PEOPLE_MODAL_ID = 'people-modal-id'
  }
}
</script>

<i18n>
  pt-BR:
    follow: "Seguir"
    following: "Seguindo"
</i18n>

<style lang="sass" scoped>
// @import '@/assets/stylesheets/_variables.sass'

.btn-circle
  border-radius: 50%
  text-align: center
  &.regular-circle
    padding: 10px 0px
    width: 46px
    height: 46px
  &.sm-circle
    width: 34px
    height: 34px
    padding: 5px 0px
  &.lg-circle
    width: 62px
    height: 62px
    padding: 15px 0px
// a
//   color: $secondary-color
</style>
