<template lang="pug">
  b-link(
    class="text-decoration-none text-dark"
    @click="$emit('click')")
    Avatar(
      :diameter="getDiameter"
      :person="person")
    .text-center.mt-2
      small(v-if="size == 'sm'") {{ getLabel }}
      span(v-else) {{ getLabel }}
</template>

<script>
import { forms } from '@/mixins/forms'
import Avatar from '@/components/People/Avatar'

export default {
  mixins: [forms], // getDisplayedName

  components: {
    Avatar
  },

  props: {
    diameter: Number, // Precedence over size
    labelPattern: {
      type: String,
      default: 'first-last' // 'first-last', 'first-only'
    },
    person: Object,
    size: String // lg, sm
  },

  computed: {
    getDiameter() {
      return this.diameter ? this.diameter : this.size == 'lg' ? 180 : this.size == 'sm' ? 60 : 100 // Default diameter
    },

    getLabel() {
      return this.labelPattern == 'first-last'
        ? this.getFirstLastNames(this.person.name, this.MAX_NAME_LENGTH)
        : this.getFirstName(this.person.name)
    }
  },

  created() {
    this.MAX_NAME_LENGTH = 15
  }
}
</script>
