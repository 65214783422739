export const mutations = {
  setIconSource: (state, source) => {
    state.icon_source = source
  },
  setAppearance: (state, theme) => {
    state.appearance = theme
  },
  setGateway: (state, gateway) => {
    state.gateway = gateway
  }
}

export const actions = {
  setIconSource: (context, source) => {
    context.commit('setIconSource', source)
  },
  setAppearance: (context, theme) => {
    context.commit('setRightIcon', theme)
  },
  setGateway: (context, gateway) => {
    context.commit('setGateway', gateway)
  }
}

export default {
  namespaced: true,
  state: {
    // system
    iconSource: 'fontawesome', // bootstrap-vue, icoons
    imageFormat: 'jpeg', // 'png', or 'jpeg'; cannot be 'jpg'
    imageQuality: 0.8, // if format is jpeg, a number between 0 and 1
    maxMediaSize: 5120000, // 5000 * 1024 = 5 MB
    maxImageDimensions: 20000000, // 20 Megapixels
    // personal
    theme: 'light', // dark
    gateway: 'iugu'
  },
  mutations,
  actions
}
