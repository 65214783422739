<template lang="pug">
  i(
    :id="id"
    :class="getClasses"
    :style="getStyle")
</template>

<script>
import icons from '../assets/icons.json'
import { mapState } from 'vuex'

export default {
  props: {
    icon: String,
    id: String,
    classes: {
      type: String,
      default: ''
    },
    size: String, // lg, sm
    source: {
      type: String,
      default: 'fontawesome'
    }
  },

  computed: {
    ...mapState({
      iconSource: state => state.settings.iconSource
    }),

    fontawesome() {
      return this.iconSource == 'fontawesome' ? icons.fontawesome[this.icon] : ''
    },

    getSizeClasses() {
      return this.size ? `icon-basis ${this.size}-icon` : 'icon-basis regular-icon'
    },

    getClasses() {
      return `${this.getSizeClasses} ${this.classes} ${this.fontawesome}`
    },

    getStyle() {
      return this.fontScale ? `font-size: ${this.fontScale * 100}%;` : ''
    }
  }
}
</script>

<style lang="sass" scoped>
.icon-basis
  // vertical-align: text-bottom
  vertical-align: -0.2em
  width: 1em
  height: 1em
  &.regular-icon
    font-size: 1.25rem
  &.sm-icon
    font-size: 1.05rem
  &.lg-icon
    font-size: 1.55rem

.icon-sq-md
  width: 24px
  height: 24px

.icon-sq-lg
  width: 34px
  height: 34px

.icon-sq-xl
  width: 44px
  height: 44px

.black-shadow
  filter: drop-shadow(0px 0px 2px black)

.white-shadow
  filter: drop-shadow(0px 0px 2px white)
</style>
