export const objects = {
  methods: {
    // Simple version of cloning objects. For a more complete one see:
    // https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
    clone(obj) {
      return JSON.parse(JSON.stringify(obj))
    },

    objectsAreEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    },

    // Set all attributes of an object to 'val'
    setAll(obj, val) {
      let instance = this
      Object.keys(obj).forEach(function(index) {
        return obj[index] instanceof Object ? instance.setAll(obj[index], val) : (obj[index] = val)
      })
    },

    // Set all attributes of an object to null
    setNull(obj) {
      this.setAll(obj, null)
    }
  }
}
