<template lang="pug">
  .d-flex.flex-column.align-items-center.justify-content-center.vph-100
    Icon.icon-sq-xl.txt-secondary.my-3(
      icon="login")
    h1.my-3 {{ $t('title') }}
    Button.my-3(
      :label="$t('next')"
      classes="primary"
      @onClick="redirect")
    Footer
</template>

<script>
import { mapActions } from 'vuex'
import Icon from '../components/Icon'
import Button from '../components/Button'
import Footer from '../components/Footer'

export default {
  components: {
    Icon,
    Button,
    Footer
  },

  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    redirect() {
      // TODO: Get previous uri on route guard and use it to go back to that point
      this.login('/home') // login and redirect to home
    }
  }
}
</script>

<i18n>
  pt-BR:
    title: "Entre no Fottorama"
    next: "Fazer login"
</i18n>

<style lang="sass" scoped></style>
