import { arrays } from '@/mixins/arrays'

import BasePaginatedCollectionAdapter from '@/adapt/base-paginated-collection-adapter'
import Person from '@/models/person'
import Profile from '@/models/profile'

export default class ProfileFollowersAdapter extends BasePaginatedCollectionAdapter {
  static model = Person
  static targetModel = Person
  static parentModel = Profile // ProfilePeople retrieves people from a Profile (parent)
  static property = 'people'

  constructor(username, params = {}) {
    super(params)
    this.username = username
  }

  async create(params = {}) {
    try {
      let cls = this.constructor.model
      let config = { headers: cls.authHeaders, save: false }

      return (await cls.api().post(this.url, params, config)).response // post(url, data, config)
    } catch (error) {
      console.log('profile-people-adapter create error', error)
    }
  }

  async delete(personId) {
    try {
      let cls = this.constructor.model
      let url = `${this.url}/${personId}`
      let config = { headers: cls.authHeaders, save: false }

      return (await cls.api().delete(url, config)).response // delete(url, config)
    } catch (error) {
      console.log('profile-people-adapter delete error', error)
    }
  }

  // Called from ProfileAdapter in order to update the people of this profile
  // peopleArray: array with uid from user's close people that should be following this profile
  async updatePeople(peopleArray) {
    // console.log('profile-people-adapter updatePeople')
    await this._addPeople(peopleArray)
    await this._removePeople(peopleArray)
  }

  get url() {
    return `/api/v1/profiles/${this.username}/people`
  }

  // Look for people to be added as follower
  async _addPeople(peopleArray) {
    for (let i = 0; i < peopleArray.length; i++) {
      let personId = peopleArray[i]

      if (arrays.methods.indexOfByProp(this.data, 'uid', personId) < 0) {
        // console.log('FollowButton handleSubmit person to be added: ', personId)
        await this.create({ uid: personId })
      }
    }
  }

  // Look for people to be removed as follower
  async _removePeople(peopleArray) {
    for (let i = 0; i < this.data.length; i++) {
      let personId = this.data[i].uid

      if (peopleArray.indexOf(personId) < 0) {
        // console.log('FollowButton handleSubmit person to be removed: ', personId)
        await this.delete(personId)
      }
    }
  }

  get _pId() {
    return this.username
  }
}
