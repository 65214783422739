import xml from 'xml-js'
import Base from './base.js'
import BaseError from '../errors/base_error.js'
import S3Error from '../errors/s3-error.js'

export default class S3 {
  async create(filename, content, presignedPost, overwrite, callback) {
    try {
      let url = presignedPost.url

      let data = Object.assign({}, presignedPost.fields)
      data.key += overwrite ? '' : `/${filename}`
      data.file = content

      let formData = new FormData()
      Object.entries(data).forEach(([k, v]) => formData.append(k, v))

      let response = await Base.post(url, formData, {
        onUploadProgress: callback,
        withCredentials: false,
        timeout: 0
      })

      response = xml.xml2js(response, { compact: true })

      return response.PostResponse.Location._text
    } catch (error) {
      if (error instanceof BaseError) {
        throw new S3Error(error.status, true, 'Could not upload content to S3')
      } else {
        throw new S3Error(499, true, error.message)
      }
    }
  }
}
