<template lang="pug">
  b-table(
    class="text-center"
    :fields="fields"
    fixed
    foot-clone
    hover
    id="order-items-table"
    responsive
    table-variant="light"
    :items="items")
    template(
      #cell(description)="data") {{ data.item.data.description }}
    template(
      #cell(quantity)="data") {{ quantity(data.item) }}
    template(
      #cell(price)="data") {{ (data.value/100).toFixed(2).toString().replace('.', ',') }}
    template(
      #foot(description)="data")
      p Total
    template(
      #foot(price)="data")
      p {{ totalPrice }}
    template(
      #foot()="data") {{ '' }}
</template>

<script>
/* eslint-disable */
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      fields: [
        {
          key: 'description',
          label: this.$t('description')
        },
        {
          key: 'quantity',
          label: this.$t('quantity')
        },
        {
          key: 'price',
          label: this.$t('price')
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      getTotalPrice: 'cart/getTotalPrice'
    }),

    ...mapState({
      items: state => state.cart.items
    }),

    totalPrice() {
      return (this.getTotalPrice/100).toFixed(2).toString().replace('.', ',')
    }
  },

  methods: {
    quantity(item) {
      switch(item.type) {
        case 'digital_media':
          return item.media.length
          break
        case 'collectable':
          return 1
          // return this.$t('collectable')
          break
        default:
          return this.$t('general-item')
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    collectable: "Colecionável"
    description: "Descrição"
    digital-media: "Mídia digital"
    general-item: "Item"
    price: "Valor"
    quantity: "Quantidade"
</i18n>

<style lang="sass" scoped></style>
