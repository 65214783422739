<template lang="pug">
  Navbar(
    :fixed="fixed"
    :no-border="noBorder"
    :title="title"
    :color="background")
    //- Left
    template(
      #left
      v-if="showBack || brand || $slots.left")
      Button(
        v-if="showBack"
        icon="left"
        rounded
        @click="handleBack")
      b-link(
        v-else-if="brand"
        to="/home")
        img#brand-img(
          :style="brandStyle"
          :src="brandSrc")
      slot(name="left")
    //- Right
    template(
      #right
      v-if="search || !noProfile || $slots.right")
      Button(
        v-if="search"
        class="mr-2"
        icon="search"
        rounded
        @click="$emit('search')")
      slot(name="right")
      ProfileDropdown(
        v-if="!noProfile"
        :my-profile-adapter="myProfileAdapter"
        @profile="$emit('profile')")
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/company/Button'
import MyProfileAdapter from '@/adapt/my-profile-adapter'
import Navbar from '@/components/company/Navbar'
import ProfileDropdown from '@/components/Shared/Navbar/ProfileDropdown'

export default {
  components: {
    Button,
    Navbar,
    ProfileDropdown
  },

  props: {
    back: {
      type: Boolean,
      default: false
    },
    backTo: String,
    backClick: {
      // only send a 'back' event on click
      type: Boolean,
      default: false
    },
    brand: {
      type: Boolean,
      default: false
    },
    container: {
      // if a container-fluid class should be applied internally
      type: Boolean,
      default: false
    },
    color: String, // black, white, grey, blue, green etc.
    fixed: String,
    noBorder: Boolean,
    noProfile: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    title: String,
    variant: String // primary, secondary, light, dark
  },

  data() {
    return {
      myProfileAdapter: new MyProfileAdapter()
    }
  },

  computed: {
    ...mapGetters({
      isMobileDevice: 'ux_controls/isViewportWidthMaxMD'
    }),

    background() {
      return this.color ? this.color : this.variant
    },

    brandSrc() {
      let src = this.isMobileDevice ? 'logo_pequeno.png' : 'logo_fottorama_grey.png'
      return require(`@/assets/images/${src}`)
    },

    brandStyle() {
      return this.isMobileDevice
        ? 'max-width: 40px; margin-left: 10px'
        : 'max-width: 100px; margin-left: 10px'
    },

    showBack() {
      return this.back || this.backTo || this.backClick ? true : false
    }
  },

  methods: {
    handleBack() {
      if (this.backTo) {
        this.$router.push(this.backTo)
      } else {
        if (this.backClick) {
          this.$emit('back')
        } else {
          this.$router.go(-1) // Go to previous url
        }
      }
    }
  },

  async beforeMount() {
    await this.myProfileAdapter.loadFromStore()
  }
}
</script>

<style lang="sass" scoped>
// #brand-img
//   max-width: 100px
//   margin-left: 10px
</style>
