<template lang="pug">
  .progress
    .progress-bar(
      role='progressbar'
      :aria-valuenow='progress'
      aria-valuemin='0'
      aria-valuemax='100'
      :style="{ width: progress + '%' }")
      span(
        v-if="labeled") {{ Math.round(progress) }}%
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true
    },
    labeled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.bottom
  border-radius: 0rem 0rem 0.25rem 0.25rem
</style>
