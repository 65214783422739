import MediaAdapter from '@/adapt/media-adapter'
import AlbumMediumAdapter from '@/adapters/album_medium_adapter'

import Album from '@/models/album'
import AlbumMedium from '@/models/album_medium'

export default class AlbumMediaAdapter extends MediaAdapter {
  static model = AlbumMedium
  static parentModel = Album // AlbumPeople retrieves media from an Album (parent)
  static targetAdapter = AlbumMediumAdapter

  constructor(albumId, params = {}) {
    super({ id: albumId, kind: 'album' }, params)
  }
}
