import AlbumDeliveriesError from '../errors/album-deliveries-error.js'
import Base from './base.js'
import store from '../../store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL

export default class AlbumDeliveries {
  // api_v1_album_deliveries POST /api/v1/albums/:album_uid/deliveries, api/v1/albums/deliveries#create
  async create(albumId, params = {}) {
    let url = `${API_URL}/api/v1/albums/${albumId}/deliveries`
    let data = { album: params }
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      // eslint-disable-next-line
      throw new AlbumDeliveriesError(error.status, false, 'Could not create album deliveries')
    }
  }
}
