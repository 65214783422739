<template lang="pug">
  #orders
    OrdersMenu(
      @back="leave"
      @update="update")
    OrderList
    //- Footer
</template>

<script>
import { mapActions } from 'vuex'
// import Footer from '../components/Footer'
import OrderList from '../components/application/Order/OrderList'
import OrdersMenu from '../components/application/Order/OrdersMenu'

export default {
  name: 'Orders',

  components: {
    // Footer,
    OrderList,
    OrdersMenu
  },

  methods: {
    ...mapActions({
      loadOrders: 'order/loadOrders',
      setCurrentPage: 'order/setCurrentPage'
    }),

    update() {
      this.loadOrders()
    },

    leave() {
      this.setCurrentPage(1)
      this.$router.push('/')
    }
  },

  mounted() {
    this.loadOrders()
  }
}
</script>

<style lang="sass" scoped></style>
