import { fabric } from 'fabric-with-gestures'

import uuid from 'uuid'

export default class Layer {
  constructor(options) {
    this.name = options.name
    this.id = uuid.v1()
    this.visible = true
    this.objects = []

    this._isActive = false
  }

  // Return if a layer is active or not
  get isActive() {
    return this._isActive
  }

  // Return the objects array
  // get objects() {
  //   return this.objects;
  // }

  // Return the number of objects in this layer
  numberOfObjects() {
    return this.objects.length
  }

  addObject(object) {
    object.id = uuid.v1()

    if (!this._isActive) {
      object.set({ selectable: false, hoverCursor: 'arrow' })
    }

    this.objects.push(object)
  }

  removeObject(object) {
    let index = this.objects.findIndex(o => o.id == object.id)
    if (index != -1) {
      this.objects.splice(index, 1)
      return true
    }
    return false
  }

  // Remove all objects from the layer
  clear() {
    this.objects.splice(0, this.objects.length)
  }

  hide() {
    this.visible = false
    this.objects.forEach(o => o.set({ visible: false }))
  }

  show() {
    this.visible = true
    this.objects.forEach(o => o.set({ visible: true }))
  }

  // Edited: added isActive = true
  setActive() {
    this.objects.forEach(o => o.set({ selectable: true, hoverCursor: 'move' }))
    fabric.Group.prototype.hasRotatingPoint = true
    fabric.Group.prototype.lockScalingFlip = false
    fabric.Group.prototype.lockUniScaling = false
    fabric.Group.prototype.strokeWidth = 0

    this._isActive = true
  }

  // Edited: added isActive = false
  setInactive() {
    this.objects.forEach(o => o.set({ selectable: false, hoverCursor: 'arrow' }))
    this._isActive = false
  }

  beforeToImage() {
    this.show()
  }

  afterToImage() {
    this.hide()
  }
}
