import BaseModel from '@/models/base_model'

export default class Medium extends BaseModel {
  static name = 'medium'
  static entity = 'media'
  static primaryKey = 'uid'

  static fields() {
    return {
      uid: this.attr(null),
      filename: this.attr(''),
      thumb_url: this.attr(''),
      dims: this.attr({ w: null, h: null })
    }
  }

  get isProcessed() {
    return this.dims.w != null && this.dims.h != null
  }
}
