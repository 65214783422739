import BaseAdapter from '@/adapt/base-adapter'

import MyProfileStorageCredentials from '@/models/my_profile_storage_credentials'

export default class MyProfileStorageCredentialsAdapter extends BaseAdapter {
  static model = MyProfileStorageCredentials
  static persistBy = 'create'

  get url() {
    return '/api/v1/profile/storage_credentials'
  }
}
