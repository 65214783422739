<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  props: {
    showButtons: {
      type: Boolean,
      default: true
    }
  }
})
class BaseForm extends Vue {
  submitting = false

  async onSubmit() {
    if (await this.$refs.form.validate()) {
      if (!this.submitting) {
        this.$refs.form.reset()
        this.model.resetErrors()
        this.submitting = true
        this.$emit('submit')
      }
    }
  }

  showErrors() {
    this.submitting = false
    this.$refs.form.setErrors(this.model.errors)
  }
}
export default BaseForm
</script>
