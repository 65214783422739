<template lang="pug">
  section.d-flex.flex-column.align-items-center.justify-content-center.vph-80.text-center
    .row
      .col-10.offset-1
        h1.display-4.mb-4 {{ $t('title') }}
        p.lead.mb-4.txt-dark-grey  {{ $t('subtitle') }}
        #logged-in-cta(
          v-if="isLoggedIn")
          Button(
            :label="$t('cta-primary')"
            size="lg"
            variant="primary"
            :to="{ name: 'home' }")
        #not-logged-in-cta(
          v-else)
          Button(
            :label="$t('cta-primary-off')"
            variant="primary"
            @click="login('/home')")
            //- href="https://api.whatsapp.com/send?phone=5519983577883&text=Olá,%20escola!%20Vamos%20ajudá-la%20a%20revolucionar%20sua%20gestão%20de%20fotos.%20Clique%20no%20botão%20acima%20e%20vamos%20lhe%20explicar%20como.")
          Link.ml-4(
            :label="$t('cta-secondary')"
            link="https://www.notion.so/Gest-o-de-Fotos-para-Escolas-d6456f3bbd924f12986ba59983aa89d4"
            target="_blank")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Button from '@/components/company/Button'
import Link from '@/components/general/Link'

export default {
  components: {
    Button,
    Link
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    })
  },

  methods: {
    ...mapActions({
      login: 'auth/login'
    })
  }
}
</script>

<i18n>
  pt-BR:
    title: "Gestão de fotos moderna e inteligente para a sua comunidade escolar"
    subtitle: "Construa o acervo de sua escola em conjunto com pais, alunos, professores e fotógrafos. Ganhe produtividade em processos internos que lidem com mídias. Gere receita extra com foto escolar."
    cta-primary: "Acessar meu acervo"
    cta-primary-off: "Criar um acervo"
    cta-secondary: "Saiba mais"
</i18n>

<style lang="sass" scoped>
@import '@/assets/stylesheets/_variables.sass'
h1
  font-weight: 700

h1
  font-size: 2rem

@media (min-width: $screen-xs-sm)
  h1
    font-size: 2.5rem

@media (min-width: $screen-sm-md)
  h1
    font-size: 3rem

@media (min-width: $screen-md-lg)
  h1
    font-size: 3.5rem
</style>
