<template lang="pug">
  .text-center.p-5(
    v-if="isLoading && !isPreviewing")
    Icon(
      class="icon-sq-xl txt-secondary mb-3"
      icon="loading")
  span(
    v-else)
    #gallery(
      v-if="hasMedia")
      GalleryGrid
      PostPreviewModal(
        :show="isPreviewing"
        @close="clearMedium")
    .col-10.offset-1.col-lg-8.offset-lg-2.col-xl-6.offset-xl-3(v-else)
      InstructionsEmptyAlbum(
        @upload="$emit('upload')")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import keyCodes from '../../../consts/keyCodes.js'
import GalleryGrid from './GalleryGrid'
import Icon from '../../Icon'
import InstructionsEmptyAlbum from './InstructionsEmptyAlbum'
import PostPreviewModal from '../Post/PostPreviewModal'

export default {
  components: {
    GalleryGrid,
    Icon,
    InstructionsEmptyAlbum,
    PostPreviewModal
  },

  computed: {
    ...mapGetters({
      isPreviewing: 'gallery/isPreviewing'
    }),

    ...mapState({
      hasMedia: state => state.gallery.content.total,
      keydownEvent: state => state.ux_controls.keydownEvent,
      isLoading: state => state.gallery.isLoading,
      isSelecting: state => state.gallery.selection.active
    })
  },

  methods: {
    ...mapActions({
      clearMedium: 'gallery/clearMedium',
      resetSelection: 'gallery/resetSelection',
      selectAllMedia: 'gallery/selectAllMedia'
    })
  },

  watch: {
    keydownEvent() {
      switch (this.keydownEvent.keyCode) {
        case keyCodes.A:
          // eslint-disable-next-line
          if (window.navigator.platform.match('Mac') ? this.keydownEvent.metaKey : this.keydownEvent.ctrlKey) {
            // Cmd + A (mac) or Ctrl + A (win)
            this.keydownEvent.preventDefault()
            this.selectAllMedia()
          }
          break
        case keyCodes.ESC: {
          if (this.isPreviewing) {
            this.clearMedium()
          } else {
            if (this.isSelecting) {
              this.resetSelection()
            }
          }
        }
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    add-media: "Importar mídias"
    empty-album-title: "Álbum vazio"
    empty-album-description: "Não há fotos ou vídeos ainda. Adicione mídias arrastando-as aqui ou clicando no menu acima."
    empty-album-md-description: "Não há fotos ou vídeos ainda. Adicione novas mídias clicando no menu acima."
</i18n>

<style lang="sass" scoped></style>
