<template lang="pug">
  Step(
    :back="back"
    :back-click="backClick"
    :back-to="backTo"
    :heading="$t('heading')"
    :id="id"
    ok-only
    :paragraph="paragraph"
    :title="title"
    @back="$emit('back')"
    @ok="handleOk")
    PersonSelectForm(
      :people-adapter="peopleAdapter"
      ref="personSelectForm"
      rules="required"
      v-model="localSelectedPeople"
      @submit="handleSubmit")
</template>

<script>
import PersonSelectForm from '@/components/flows/forms/PersonSelectForm'
import Step from '@/layouts/Step'

export default {
  components: {
    PersonSelectForm,
    Step
  },

  props: {
    back: Boolean,
    backClick: Boolean,
    backTo: String,
    id: String,
    invitation: Object, // { host: String, id: String }
    peopleAdapter: Object,
    title: {
      type: String,
      default() {
        return this.$t('title')
      }
    },
    value: Array
  },

  computed: {
    paragraph() {
      return this.$t('paragraph').replace('%c', `@${this.invitation.host}`)
    },

    localSelectedPeople: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    handleOk() {
      this.$refs.personSelectForm.$refs.formWrapper.onSubmit()
    },

    handleSubmit() {
      this.$refs.personSelectForm.$refs.formWrapper.isSubmitting = false
      this.$emit('ok')
    }
  }
}
</script>

<i18n>
  pt-BR:
    title: "Participantes"
    heading: "Quem deve participar?"
    paragraph: "Além do seu acervo pessoal, você tem controle também sobre o acervo de alguns dependentes. No caso do acervo %c quem deve participar?"
</i18n>
