<template lang="pug">
  b-popover(
    :target="target"
    :triggers="triggers"
    :placement="placement"
    :container="container"
    @show="$emit('show')"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')")
    b-button-group(vertical)
      Button(
        btn-class="text-left"
        icon="add-person"
        icon-class="mr-2"
        :label="$t('add')"
        :uppercase="false"
        @click="$emit('add')")
      //- Button(
        btn-class="text-left"
        icon="search"
        icon-class="mr-2"
        :label="$t('search')"
        :uppercase="false"
        @click="$emit('filter')")
</template>

<script>
import Button from '@/components/company/Button'
import Icon from '@/components/Icon'

export default {
  components: {
    Button,
    Icon
  },

  props: {
    target: String,
    triggers: String,
    placement: {
      type: String,
      default: 'auto'
    },
    container: String
  }
}
</script>

<i18n>
  pt-BR:
    add: "Adicionar"
    search: "Filtrar"
</i18n>

<style lang="sass" scoped>
.popover
  padding: 0.25rem 0
</style>
