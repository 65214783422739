<template lang="pug">
  Dropdown(
    v-if="isLoggedIn"
    :options="options"
    @select="handleOption")
    template(
      v-slot:button-content)
      ProfileIcon
  div(v-else)
    Button#login-button(
      :label="$t('login')"
      variant="primary"
      @click="handleOption('login')")
    //- Button(
      :label="$t('signup')"
      variant="link"
      @click="handleOption('signup')")
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState, mapActions } from 'vuex'

import Button from '@/components/company/Button'
import Dropdown from '@/components/Dropdown'
import ProfileIcon from '@/components/MyProfile/Icon'

@Component({
  components: {
    Button,
    Dropdown,
    ProfileIcon
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    })
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      logout: 'auth/logout'
    })
  },

  props: {
    myProfileAdapter: Object
  }
})
class ProfileDropdown extends Vue {
  options = [
    { value: 'home', text: this.$t('home'), icon: 'home' },
    { value: 'profile', text: this.$t('profile'), icon: 'profile' },
    { value: 'people', text: this.$t('people'), icon: 'people' },
    { value: 'help', text: this.$t('help'), icon: 'help' },
    // { value: 'selections', text: this.$t('selections'), icon: 'selections' },
    // { value: 'orders', text: this.$t('orders'), icon: 'receipt' },
    { value: 'logout', text: this.$t('logout'), icon: 'logout' }
  ]

  async handleOption(value) {
    // All the 'catch' are placed in order to avoid 'NavigationDuplicated:
    // Avoided redundant navigation to current location' when the user is
    // pushing the router to the same route.
    switch (value) {
      case 'help':
        window.open('https://wa.me/5519981269894', '_blank')
        break
      case 'home':
        this.$router.push('/home').catch(() => {})
        break
      case 'profile':
        if (this.$route.name == 'profile') {
          // Send a signal so the root view can forces reloading the profile
          // page (e.g. if it's in a profile not found page, it reloads to
          // current user's profile)
          this.$emit('profile')
        }
        this.$router.push(`/p/${this.myProfileAdapter.data.user_name}`).catch(() => {})
        break
      case 'people':
        this.$router.push('/people').catch(() => {})
        break
      case 'selections':
        this.$router.push('/selections').catch(() => {})
        break
      case 'orders':
        this.$router.push('/orders').catch(() => {})
        break
      case 'logout':
        this.logout()
        break
      case 'login':
        this.login('/home')
        break
    }
  }
}
export default ProfileDropdown
</script>

<i18n>
  pt-BR:
    help: "Ajuda"
    home: "Início"
    login: "Entrar"
    logout: "Sair"
    orders: "Pedidos"
    people: "Pessoas"
    profile: "Perfil"
    selections: "Seleções"
    signup: "Cadastrar-se"
</i18n>

<style lang="sass" scoped>
#login-button
  // keep navbar's height when compared to logged in status
  margin: 4px 0
</style>
