<template lang="pug">
  span#add-dependent-flow
    PersonStep(
      :fields="['selfie', 'name']"
      id="person-step"
      is-dependent
      :my-profile-adapter="myProfileAdapter"
      :paragraph="$t('paragraph')"
      skip
      :title="$t('title')"
      v-show="currentStep == 1"
      @ok="$emit('ok')"
      @back="$emit('back')")
</template>

<script>
import PersonStep from '@/components/flows/steps/PersonStep'

export default {
  components: {
    PersonStep
  },

  props: {
    id: String,
    label: {
      type: String,
      default: function() {
        return this.$t('cta-label')
      }
    },
    myProfileAdapter: Object
  },

  data: () => ({
    currentStep: 1
  })
}
</script>

<i18n>
  pt-BR:
    paragraph: "Você é pai, mãe ou responsável legal de algum dependente? Se sim, complete seus dados abaixo para poder controlar seu acervo. Tendo mais de um dependente, adicione-o depois editando seu perfil."
    title: "Novo dependente"
</i18n>
<!-- privacy-policy: "Política de Privacidade" -->
