<template lang="pug">
  MyProfileForm(
    action="edit"
    :fields="['avatar', 'name', 'user_name', 'description']"
    :myProfile="myProfileAdapter.data"
    ok-only
    :personal="false"
    ref="myProfileForm"
    @change="handleChange"
    @submit="handleSubmit")
    //- :personal is true because for now we don't have the 'community' concept and
    //- name is always full name instead of first and last name
</template>

<script>
import { mapActions } from 'vuex'
import MyProfileForm from '@/components/flows/forms/MyProfileForm'
import MyProfileAdapter from '@/adapt/my-profile-adapter'

export default {
  components: {
    MyProfileForm
  },

  data() {
    return {
      isSubmitting: false,
      myProfileAdapter: new MyProfileAdapter()
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    }),

    handleChange(isSubmitting) {
      this.isSubmitting = isSubmitting
    },

    async handleSubmit() {
      if (await this.myProfileAdapter.save()) {
        this.$refs.myProfileForm.$refs.formWrapper.isSubmitting = false
        this.showMessage(this.$i18n.t('profile-saved'))
      } else {
        this.showMessage(this.$i18n.t('error'))
        this.$refs.myProfileForm.$refs.formWrapper.showErrors()
      }
    }
  },

  beforeMount() {
    this.myProfileAdapter.loadFromStore()
  }
}
</script>

<i18n>
  pt-BR:
    error: "Não foi possível salvar o perfil"
    profile-saved: "Perfil salvo com sucesso"
</i18n>
