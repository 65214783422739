<template lang="pug">
  .d-flex(:class="getClass")
    .d-flex.justify-content-center.align-items-center.box
      span.mr-auto
        slot(name="left")
    .d-flex.justify-content-center.align-items-center.box#navbar-title(
      v-if="$slots.center || title")
      span
        slot(
          v-if="$slots.center"
          name="center")
        strong(
          v-else) {{ title }}
    .d-flex.justify-content-center.align-items-center.box
      span.ml-auto
        slot(name="right")
</template>

<script>
export default {
  props: {
    container: {
      type: Boolean,
      default: false
    },
    fixed: String,
    title: String,
    noBorder: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getClass() {
      let classes = this.fixed ? `fixed-${this.fixed}` : ''

      if (this.container) {
        classes = `${classes} container-fluid`
      }

      return this.noBorder
        ? `${classes} py-2`
        : this.fixed
        ? `${classes} py-2 ${this.fixed}-menu`
        : `${classes} py-2 top-menu`
    }
  }
}
</script>

<style lang="sass" scoped>
#navbar-title
  text-transform: uppercase
  height: 46px
.box
  flex: 1
.top-menu
  border-bottom: 1px solid #f8f8f8
.bottom-menu
  border-top: 1px solid #f8f8f8
</style>
