import BasePaginatedCollectionAdapter from '@/adapt/base-paginated-collection-adapter'
import AlbumPersonAdapter from '@/adapt/album-person-adapter'

import Album from '@/models/album'
import AlbumPerson from '@/models/album_person'
import Face from '@/models/face'
import Medium from '@/models/medium'
import Person from '@/models/person'

export default class AlbumPeople extends BasePaginatedCollectionAdapter {
  static model = AlbumPerson
  static parentModel = Album // AlbumPeople retrieves people from an Album (parent)
  static targetModel = AlbumPerson
  static targetAdapter = AlbumPersonAdapter
  static property = 'people'

  constructor(albumId, params = {}) {
    super(params)
    this.albumId = albumId
  }
  // api_v1_album_people /api/v1/albums/:album_uid/people, api/v1/albums/people { GET: index }
  get url() {
    return `/api/v1/albums/${this.albumId}/people`
  }

  updateDeliveries() {
    this.data.forEach(albumPersonAdapter => {
      albumPersonAdapter.updateDeliveries()
    })
  }

  async _beforePersist(data) {
    let media = data.people.map(person => person.medium)
    let faces = data.people.map(person => ({
      ...person.face,
      ...{ medium_uid: person.medium.uid }
    }))
    let people = data.people
    people.forEach(person => (person.face_uid = person.face.uid))

    await Medium.insertOrUpdate({ data: media })
    await Face.insertOrUpdate({ data: faces })
    await Person.insertOrUpdate({ data: people })

    data.people.forEach(albumPerson => {
      albumPerson.album_uid = this.albumId
      albumPerson.person_uid = albumPerson.uid
    })
  }

  get _pId() {
    return this.albumId
  }
  // If BasePaginatedAdapter's _orderField method is used, it returns
  // 'album_order' and it doesn't work. So instead of changing the base class
  // I decided to rewrite this method for this adapter.
  // TODO: Study how base-paginated-adapter's _orderField method could be updated in order to be used by album-people-adapter
  get _orderField() {
    return 'order'
  }
}
