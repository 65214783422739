<template lang="pug">
  b-sidebar(
    :bg-variant="bgVariant"
    :id="id"
    no-header
    :shadow="shadow"
    :width="width"
    @hidden="hide"
    @shown="show")
    slot
</template>

<script>
export default {
  props: {
    bgVariant: {
      type: String,
      default: 'white'
    },
    id: String,
    shadow: Boolean,
    width: String
  },

  methods: {
    hide() {
      // Allow background scrolling once sidebar is closed
      document.body.style.overflow = ''
      this.$emit('hidden')
    },

    show() {
      // Avoid background scrolling while sidebar is open
      document.body.style.overflow = 'hidden'
      this.$emit('shown')
    }
  }
}
</script>

<style lang="sass" scoped></style>
