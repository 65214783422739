<template lang="pug">
  .row.top-menu.mb-4.no-gutters.align-items-center
    .col-2.text-left
      Button(@click="$emit('back')")
        Icon(icon="left")
    .col-8.text-center
      strong.title {{ $t('title') }}
    .col-2.text-right
      Button(@click="$emit('update')")
        Icon(icon="update")
</template>

<script>
import Button from '../../general/Button/ButtonNew'
import Icon from '../../Icon'
import Navbar from '../../company/Navbar'

export default {
  components: {
    Button,
    Icon,
    Navbar
  }
}
</script>

<i18n>
  pt-BR:
    title: 'Pedidos recebidos'
    update: 'Atualizar'
</i18n>

<style lang="sass" scoped>
.top-menu
  border-bottom: 1px solid #f8f8f8
  height: 50px
  .title
    text-transform: uppercase
</style>
