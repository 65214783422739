<template lang="pug">
  Popup(
    :id="modalId"
    hide-header
    @ok="handleOk")
    SelectionForm(
      :plan="newPlan"
      v-model="editedSelection"
      ref="formWrapper"
      @clear-plan="clearNewPlan")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { objects } from '../../../mixins/objects'
import { forms } from '../../../mixins/forms'
import { plans } from '../../../mixins/resources/plans'
import { selections } from '../../../mixins/resources/selections'
import Popup from '../../general/Popup'
import SelectionForm from './SelectionForm'
import ValidationError from '../../../plugins/vee-validate'

export default {
  mixins: [
    objects, // objectsAreEqual
    forms, // isFormValid
    plans, // createPlan
    selections // updateSelection
  ],

  components: {
    Popup,
    SelectionForm
  },

  props: {
    modalId: String
  },

  data() {
    return {
      editedSelection: null,
      newPlan: {
        name: '',
        capPrice: '',
        singlePrice: ''
      }
    }
  },

  computed: {
    ...mapState({
      selection: state => state.selection.current
    })
  },

  methods: {
    ...mapActions({
      loadSelection: 'selection/loadSelection',
      showMessage: 'ux_controls/showMessage'
    }),

    clearNewPlan() {
      this.newPlan = {
        name: '',
        capPrice: '',
        singlePrice: ''
      }
    },

    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault() // Prevent modal from closing

      try {
        await this.isFormValid(this.$refs.formWrapper.$refs.form)
        await this.update()

        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId)
        })
      } catch (error) {
        if (error instanceof ValidationError) {
          this.showMessage(this.$t('validation-error'))
        } else {
          this.showMessage(this.$t('general-error') + ': ' + error.name)
        }
      }
    },

    async update() {
      let createdPlan

      if (this.editedSelection.plan.id == 'create') {
        createdPlan = await this.createPlan(this.newPlan)
        if (createdPlan.uid) {
          this.editedSelection.plan.id = createdPlan.uid
        }
      }

      if (!this.objectsAreEqual(this.selection, this.editedSelection)) {
        await this.updateSelection(this.selection.id, this.editedSelection)
        // Load selection again in order to update it on the store
        await this.loadSelection(this.selection.id)
        this.$emit('update') // Update selection name in selection page
      }
    }
  }, // methods

  mounted() {
    // Listen to modal opening
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId == this.modalId) {
        this.editedSelection = { ...this.selection }
        // Without the clone below, the deep 'plan' object goes by reference
        // and edited plan also changes current plan
        this.editedSelection.plan = { ...this.selection.plan }
        this.newPlan = {
          name: '',
          capPrice: '',
          singlePrice: ''
        }
      }
    })
  }
}
</script>

<i18n>
  pt-BR:
    general-error: "Encontramos um problema"
    validation-error: "Há algum problema nas informações fornecidas. Verifique as mensagens de erro em cada campo."
</i18n>
