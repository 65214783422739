<template lang="pug">
  BaseView
    Navbar(
      brand
      @profile="loadProfile(myProfileAdapter.data.user_name)")
    div(v-if="profileAdapter.loading")
      Loading
    div(v-else)
      div(v-if="!profileAdapter.newRecord")
        .row.no-gutters.mb-3
          .col.pt-4.text-center
            Avatar(
              size="8"
              variant="dark"
              :src="displayedAvatar")
            h2.mt-3.font-weight-bold {{ displayedName }}
            p.pt-3 @{{ displayedUserName }}
            p.text-muted {{ displayedDescription }}
            Button(
              v-if="ownProfile"
              :label="$t('edit')"
              :uppercase="false"
              pill
              size="sm"
              @click="$router.push('/profile')")
            FollowButton(
              v-else
              :loading="isLoading"
              :my-profile-people-adapter="myProfileAdapter.people"
              :profile-adapter="profileAdapter")
              //- v-if="showFollowButton"
        div(v-if="profileAdapter.albums.loading")
          Loading
        div(v-else)
          .row.no-gutters.mx-2.mt-5
            .col-6.col-sm-4.col-md-3.col-lg-2(
              v-for="album in profileAdapter.albums.data"
              :key="album.uid")
              Thumbnail(:album='album')
          div(v-if="profileAdapter.albums.data.length == 0")
            Instructions(
              icon="library"
              :title="$t('empty-library')")
          div(v-else)
            Pagination(
              v-model="profileAdapter.albums.currentPage"
              :perPage="profileAdapter.albums.perPage"
              :totalRows="profileAdapter.albums.total")
        //- TODO: Delete the code below. It's for test purposes only
        //- br
        //- br
        //- br
        //- br
        //- br
        //- br
        //- br
        //- div
        //-   router-link(to="/p/bethania") bethania
        //- div
        //-   router-link(to="/p/escolaprojetovida") escolaprojetovida
        //- div
        //-   router-link(to="/p/rodrigocvb") rodrigocvb
        //- div
        //-   router-link(to="/p/barbosarodrigo") barbosarodrigo
        br
        br
      div(v-else)
        .text-center
          i.mt-4.text-danger.fas.fa-times-circle.fa-5x
          h2.p-4 {{ $t('profile-not-found') }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

import MyProfileAdapter from '@/adapt/my-profile-adapter'
import ProfileAdapter from '@/adapt/profile-adapter'

import Avatar from '@/components/Avatar'
import BaseView from '@/layouts/Base'
import Button from '@/components/company/Button'
import FollowButton from '@/components/Profiles/FollowButton'
import Navbar from '@/components/Shared/Navbar'
import Loading from '@/components/Shared/Loading'
import Thumbnail from '@/components/Albums/Thumbnail'
import Instructions from '@/components/application/Instructions'
import Pagination from '@/components/company/Pagination'

export default {
  components: {
    Avatar,
    BaseView,
    Button,
    FollowButton,
    Instructions,
    Loading,
    Navbar,
    Pagination,
    Thumbnail
  },

  props: {
    userName: String
  },

  data() {
    return {
      isLoading: true,
      profileAdapter: new ProfileAdapter(this.userName),
      myProfileAdapter: new MyProfileAdapter()
    }
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    }),

    ownProfile() {
      return this.myProfileAdapter.data.user_name == this.userName
    },

    // showFollowButton() {
    //   return !this.isLoading && this.profileAdapter.people.count > 0 && !this.ownProfile
    // },

    // The 'displayed' computed properties below were created in order to
    // reflect updates in my-profile if the user edits it.
    // TODO: Think if these fields could be avoided if on my profile saving
    // the profile could be updated with my profile info
    // 22-01-24: I started reloading myProfile when clicking back from editing to profile view.
    // Maybe now these displayed fields are not needed anymore.

    displayedAvatar() {
      return this.ownProfile
        ? this.myProfileAdapter.data.avatar.url
        : this.profileAdapter.data.picture_url
    },

    displayedDescription() {
      return this.ownProfile
        ? this.myProfileAdapter.data.description
        : this.profileAdapter.data.description
    },

    displayedName() {
      return this.ownProfile ? this.myProfileAdapter.data.name : this.profileAdapter.data.name
    },

    displayedUserName() {
      return this.ownProfile
        ? this.myProfileAdapter.data.user_name
        : this.profileAdapter.data.user_name
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    async loadProfile(username) {
      this.isLoading = true

      await this.profileAdapter.load({ id: username })
      await this.profileAdapter.albums.load()
      await this.profileAdapter.people.load()

      this.isLoading = false
    }
  },

  watch: {
    // This watcher forces the update of the profile data when the user navigates through different
    // profiles. Without it the data keeps the same.
    async userName() {
      await this.loadProfile(this.userName)
    }
  },

  async beforeMount() {
    // Load with the userName from the url
    await this.loadProfile(this.userName)
    await this.myProfileAdapter.load()
    // Load is_me and is_dependent from my-profile that follow the current profile
    await this.myProfileAdapter.people.load({ params: 'filters=is_dependent,is_me' })
  }
}
</script>

<i18n>
  pt-BR:
    edit: "Editar perfil"
    profile-not-found: "Perfil não encontrado"
    empty-library: "Acervo vazio"
</i18n>
