import Vue from 'vue'
import { arrays } from '@/mixins/arrays'
import BaseAdapter from '@/adapt/base-adapter'
import AlbumPerson from '@/models/album_person'

export default class AlbumPersonAdapter extends BaseAdapter {
  // Return the channels through which media have been delivered
  get sentTo() {
    let channels = []

    if (this.data.deliveries) {
      channels = this.data.deliveries.reduce(function(array, delivery) {
        if (delivery.value && delivery.value.length > 0) {
          array.push({ type: delivery.type, value: delivery.value })
        }
        return array
      }, [])
    }

    return channels
  }
  // Return the current channels that have not been used in a previous delivery
  // For example when a person's email is updated
  get sendTo() {
    let currentChannels = []

    if (this.data && this.data.person) {
      // Email-only for now
      if (this.data.person.email) {
        currentChannels.push({ type: 'email', value: this.data.person.email })
      }
    }
    // Difference between currentChannels and sentTo
    return arrays.methods.subtractArrayOfObjects(currentChannels, this.sentTo)
  }

  async updateDeliveries() {
    // No resending is performed by the backend for now. So if it has
    // deliveries, nothing is done
    if (!this.data.deliveries) {
      // The delivery process is email-only for now
      if (this.data.person && this.data.person.email) {
        await AlbumPerson.update({
          where: [this.data.album_uid, this.data.person_uid],
          data: {
            deliveries: [{ type: 'email', value: this.data.person.email }]
          }
        })
        Vue.set(this.data, 'deliveries', [
          {
            type: 'email',
            value: this.data.person.email
          }
        ])
      }
    }
  }
}
