<template lang="pug">
  span
    div(v-if="peopleAdapter.loading")
      Loading
    div(v-else)
      DependentList(
        :peopleAdapter="peopleAdapter")
      Button.float-right(
        rounded
        variant="primary"
        size="lg"
        icon="+"
        @click="handleNew")
    Modal(
      :id="this.CONFIRMATION_MODAL_ID"
      :message="$t('confirmation-message')"
      :ok-title="$t('confirmation-ok-title')"
      :title="$t('confirmation-title')"
      @ok="handleOk")
</template>

<script>
import Button from '@/components/company/Button'
import DependentList from '@/components/MyProfile/Dependents/DependentList'
import Loading from '@/components/Shared/Loading'
import Modal from '@/components/general/Popup'
import PeopleAdapter from '@/adapt/people-adapter'

export default {
  components: {
    Button,
    DependentList,
    Loading,
    Modal
  },

  data() {
    return {
      peopleAdapter: new PeopleAdapter()
    }
  },

  methods: {
    handleNew() {
      this.$bvModal.show(this.CONFIRMATION_MODAL_ID)
    },

    handleOk() {
      this.$router.push('/profile/dependents/new')
    }
  },

  async beforeMount() {
    await this.peopleAdapter.load({ params: 'filters=is_dependent' })
  },

  created() {
    this.CONFIRMATION_MODAL_ID = 'confirmation-modal-id'
  }
}
</script>

<i18n>
  pt-BR:
    confirmation-title: "Adicionar membro da família"
    confirmation-message: "Se você está prestes a cadastrar o rosto de uma criança, você confirma ser o pai, a mãe ou o responsável legal desta criança."
    confirmation-ok-title: "Concordar"
</i18n>
