<template lang="pug">
  Pagination(
    v-model="pageNumber",
    :perPage="perPage",
    :totalRows="totalRows")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Pagination from '../../company/Pagination'

export default {
  components: {
    Pagination
  },

  computed: {
    ...mapState({
      perPage: state => state.gallery.settings.mediaPerPage,
      totalRows: state => state.gallery.content.total
    }),

    pageNumber: {
      get() {
        return this.$store.state.gallery.pageNumber + 1
      },
      set(page) {
        this.changePage(page - 1)
      }
    }
  },

  methods: {
    ...mapActions({
      changePage: 'gallery/changePage',
      setPageNumber: 'gallery/setPageNumber'
    })
  }
}
</script>

<style lang="sass" scoped></style>
