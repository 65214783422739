<template lang="pug">
  Dropdown(
    :options="options"
    :width="250"
    @select="handleOption")
    template(
      v-slot:button-content)
      Button(
        icon="user-plus"
        rounded)
</template>

<script>
import Button from '@/components/company/Button'
import Dropdown from '@/components/Dropdown'

export default {
  components: {
    Button,
    Dropdown
  },

  props: {
    myProfileAdapter: Object
  },

  methods: {
    handleOption(value) {
      switch (value) {
        case 'link':
          navigator.clipboard.writeText(this.message) // Copy to the clipboard
          this.$emit('show-message', this.$t('copy-success'))
          break
        case 'whatsapp':
          window.open(this.url, '_blank')
          break
        case 'update':
          this.updateInvitation()
          break
      }
    },

    async updateInvitation() {
      try {
        await this.myProfileAdapter.updateInvitation()
        this.$emit('show-message', this.$t('update-success'))
      } catch (error) {
        this.$emit('show-message', this.$t('update-error'))
      }
    }
  },

  computed: {
    link() {
      return `${process.env.VUE_APP_URL}/${this.myProfile.user_name}/invites/${this.myProfile.invitation}`
    },

    message() {
      return this.$t('message')
        .replace('%c', this.myProfile.user_name)
        .replace('%l', this.link)
    },

    myProfile() {
      return this.myProfileAdapter.data
    },

    url() {
      return `https://wa.me/?text=${this.message.replace(/ /g, '%20')}`
    }
  },

  async beforeMount() {
    this.options = [
      { value: 'link', text: this.$t('label-link'), icon: 'link' },
      { value: 'whatsapp', text: this.$t('label-whatsapp'), icon: 'whatsapp' },
      { value: 'update', text: this.$t('label-update'), icon: 'update' }
    ]
  }
}
</script>

<i18n>
  pt-BR:
    label-link: "Copiar link de convite"
    label-update: "Gerar novo link"
    label-whatsapp: "Convidar por WhatsApp"
    update-error: "Não foi possível gerar um novo link."
    update-success: "Um novo link para convite foi gerado. O antigo não funcionará mais."
    copy-success: "Link de convite copiado. Cole em sua mensagem para as pessoas que deseja adicionar ao seu acervo."
    message: "Este é um convite para o acervo @%c. Clique no link e faça parte dessa história: %l"
</i18n>

<style lang="sass" scoped></style>
