<template lang="pug">
  section.container-fluid
    .row(
      v-if="total > 0 || isLoading")
      .col.col-md-10.offset-md-1
        b-table(
          bordered
          :busy="isLoading"
          fixed
          head-variant="light"
          hover
          id="selections-table"
          :per-page="perPage"
          responsive
          table-variant="light"
          :items="orders"
          :fields="visibleFields")
          template(#table-busy)
            div(class="text-center my-5")
              Icon(
                class="icon-sq-xl txt-secondary mt-5"
                icon="loading")
          template(
            #cell(details)="data")
            Link(
              @click="showOrderDetails(data.index)")
              Icon(
                icon="receipt")
          template(
            #cell(index)="data")
              Link(
                :label="((currentPage - 1) * perPage + data.index + 1).toString()"
                @click="showOrderDetails(data.index)")
          //- template(
          //-   #cell(uid)="data")
          //-   a(
          //-     :href="`#${data.value.replace(/[^a-z]+/i,'-').toLowerCase()}`"
          //-     @click="open(data.index)") {{ data.value }}
          template(
            #cell(created_at)="data") {{ getReadableDate(data.value) }}
          template(
            #cell(total_cents)="data") {{ (data.value/100).toFixed(2) }}
          template(
            #cell(paid_at)="data")
              Icon(
                v-if="data.value"
                icon="check")
          //- template(
          //-   #cell(link)="data")
          //-   Link(
          //-     :class="hasArchiveClass(data.item.archive)"
          //-     @click="showArchiveDetails(data.index)")
          //-     Icon(
          //-       icon="link")
          template(
            #cell(archive)="data")
              Icon(
                v-if="data.item.archive"
                icon="check")
        OrderPagination
    .row(v-else)
      .col-10.offset-1.col-lg-8.offset-lg-2.col-xl-6.offset-xl-3
        Instructions(
          icon="receipt"
          :title="$t('no-order-title')"
          :description="$t('no-order-description')")
    //- TODO: access current order from the store instead of passing it as prop
    //- OrderArchiveModal(
    //-   :modalId="ORDER_ARCHIVE_MODAL_ID"
    //-   :order="orders[index]"
    //-   @ok="index = -1"
    //-   @update-archive="updateArchive")
    OrderDetailsModal(
      :modalId="ORDER_DETAILS_MODAL_ID"
      :order="orders[index]"
      @ok="index = -1")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { datetime } from '../../../mixins/datetime.js'
import { orders } from '../../../mixins/resources/orders.js'
import Icon from '../../Icon'
import Instructions from '../Instructions'
import Link from '../../general/Link'
// import OrderArchiveModal from './OrderArchiveModal'
import OrderDetailsModal from './OrderDetailsModal'
import OrderPagination from './OrderPagination'

export default {
  mixins: [
    datetime, // getReadableDate
    orders
  ],

  components: {
    Icon,
    Instructions,
    Link,
    // OrderArchiveModal,
    OrderDetailsModal,
    OrderPagination
  },

  data() {
    return {
      index: -1,
      fields: [
        {
          key: 'index',
          label: '#',
          thStyle: {
            width: '60px'
          },
          class: 'text-center'
        },
        // {
        //   key: 'uid',
        //   label: 'Código'
        // },
        {
          key: 'created_at',
          label: this.$t('column-created-at')
          // sortable: true
        },
        {
          key: 'name',
          label: this.$t('column-name')
        },
        {
          key: 'email',
          label: this.$t('column-email')
        },
        // {
        //   key: 'num_media',
        //   label: 'Nº de fotos',
        //   class: 'text-center'
        // },
        {
          key: 'total_cents',
          label: this.$t('column-total-cents'),
          // class: 'text-center'
          thStyle: {
            width: '90px'
          }
        },
        {
          key: 'paid_at',
          label: this.$t('column-paid-at'),
          class: 'text-center',
          thStyle: {
            width: '90px'
          }
        },
        // {
        //   key: 'link',
        //   label: 'Link',
        //   thStyle: {
        //     width: '60px'
        //   },
        //   class: 'text-center'
        // }
        {
          key: 'archive',
          label: this.$t('column-archive'),
          class: 'text-center',
          thStyle: {
            width: '90px'
          }
        }
      ],
      fieldsMobile: [
        {
          key: 'details',
          label: '',
          thStyle: {
            width: '60px'
          },
          class: 'text-center'
        },
        {
          key: 'name',
          label: this.$t('column-name')
        },
        {
          key: 'paid_at',
          label: this.$t('column-paid-at'),
          class: 'text-center',
          thStyle: {
            width: '90px'
          }
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      hideColumns: 'ux_controls/isViewportWidthMaxMD'
    }),

    ...mapState({
      currentPage: state => state.order.currentPage,
      isLoading: state => state.order.isLoading,
      perPage: state => state.order.settings.perPage,
      orders: state => state.order.orders,
      total: state => state.order.total
    }),

    visibleFields() {
      if (this.hideColumns) {
        return this.fieldsMobile
      }

      return this.fields
    }
  },

  methods: {
    // If the order already had its archive generated, the link appears in
    // black, otherwise it appears in blue
    // hasArchiveClass(archive) {
    //   if (archive) {
    //     return 'has-archive'
    //   }

    //   return ''
    // },

    // showArchiveDetails(index) {
    //   this.index = index
    //   this.$bvModal.show(this.ORDER_ARCHIVE_MODAL_ID)
    // },

    // updateArchive(archive) {
    //   this.orders[this.index].archive = archive
    // },

    showOrderDetails(index) {
      this.index = index
      this.$bvModal.show(this.ORDER_DETAILS_MODAL_ID)
    }
  },

  created() {
    this.ORDER_ARCHIVE_MODAL_ID = 'order-archive-modal'
    this.ORDER_DETAILS_MODAL_ID = 'order-details-modal'
  }
}
</script>

<i18n>
  pt-BR:
    no-order-description: "Você ainda não recebeu nenhum pedido."
    no-order-title: "Não há pedidos"
    column-archive: "Zip"
    column-created-at: "Feito em"
    column-email: "E-mail"
    column-paid-at: "Entregue"
    column-name: "Nome"
    column-total-cents: "Valor"
</i18n>

<style lang="sass" scoped>
a.has-archive
  color: black
</style>
