<!-- This component is a replacement for GalleryNavbar -->
<template lang="pug">
  #gallery-nav
    .chipset
      span
        Button(
          id="gallery-filter-button"
          class="mr-2"
          icon="search"
          rounded
          @click="$bvModal.show(FILTER_MODAL_ID)")
      span(
        v-for="(item, index) in items"
        :key="index")
        Chip.mr-2(
          :active="item.active"
          :close="item.close"
          :index="index"
          :src="item.src"
          :text="item.text"
          @click="$emit('click', $event)"
          @close="$emit('close', $event)")
    GalleryFilterModal(
      :album-people-adapter="albumPeopleAdapter"
      :id="FILTER_MODAL_ID"
      @filter="$emit('filter', $event)")
</template>

<script>
import Button from '@/components/company/Button'
import Chip from '@/components/general/Chips/Chip'
import GalleryFilterModal from '@/components/Shared/Gallery/GalleryFilterModal'

export default {
  components: {
    Button,
    Chip,
    GalleryFilterModal
  },

  props: {
    albumPeopleAdapter: Object,
    items: Array
  },

  created() {
    this.FILTER_MODAL_ID = 'gallery-filter-modal-id'
  }
}
</script>

<style lang="sass" scoped>
.chipset
  overflow: auto
  white-space: nowrap

#gallery-filter-button
  margin-bottom: 4px
</style>
