/* eslint-disable */
export default class BaseCollectionAdapter {
  constructor({ parent = null } = {}) {
    this.parent = parent
    this.loading = false
    this.data = []
  }

  loadFromStore() {
    let entries = this._loadEntries()
    let cls = this.constructor
    if (entries) {
      if (cls.targetAdapter) {
        this.data = entries.map(entry => new cls.targetAdapter({ data: entry, parent: this }))
      } else {
        this.data = entries
      }
    }
  }

  async loadFromApi() {
    let cls = this.constructor
    this.loading = true
    let response = await cls.targetModel.apiList({ url: this._url, save: false })
    if (response && response.data) {
      await this._persist(response.data)
    }
    this.loadFromStore()
    this.loading = false
  }

  async load() {
    if (this._shouldLoadFromApi()) {
      await this.loadFromApi()
    } else {
      this.loadFromStore()
    }
  }

  async reset() {
    this._delete()
    this.data = []
  }

  _shouldLoadFromApi() {
    let pageCount = this.parent ? this._queryHasMany.count() : this._queryBase.count()
    return pageCount == 0
  }

  // persist

  async _persist(data) {
    let cls = this.constructor
    this._beforePersist(data)
    cls.targetModel.insertOrUpdate({ data: data })
    this._afterPersist(data)
  }

  _beforePersist(data) {
    let cls = this.constructor
    let count = 0
    if (!this.parent) {
      data.forEach((entry, i) => entry[this._orderField] = count + i)
    }
    if (this.parent && cls.model == cls.targetModel) {
      data.forEach((entry, i) => {
        entry[this._pForeignKey] = this._pId
        entry[this._orderField] = count + i
      })
    }
  }

  _afterPersist(data) {
    let cls = this.constructor
    if (this.parent && cls.model != cls.targetModel) {
      let count = 0
      let inputData = data.map((entry, i) => {
        return {
          [this._tForeignKey]: entry[this._tPrimaryKey],
          [this._pForeignKey]: this._pId,
          [this._orderField]: count + i
        }
      })
      cls.model.insertOrUpdate({ data: inputData })
    }
  }

  // delete

  _delete() {
    let cls = this.constructor
    if (this.parent) {
      cls.model.delete(entry => entry[this._pForeignKey] == this._pId)
    } else {
      cls.model.deleteAll()
    }
  }

  // query

  _loadEntries() {
    return this._query.get()
  }

  get _query() {
    let cls = this.constructor
    if (this.parent) {
      return cls.model == cls.targetModel ? this._queryHasMany : this._queryHasManyThrough
    } else {
      return this._queryBase
    }
  }

  get _queryHasMany() {
    return this._queryBase.where(this._pForeignKey, (value) => value == this._pId)
  }

  get _queryHasManyThrough() {
    let relEntries = this._queryHasMany.get()
    return this.constructor.targetModel.query().whereId(relEntries.map(i => i[this._tForeignKey]))
  }

  get _queryBase() {
    return this.constructor.model.query().orderBy(this._orderField)
  }

  // helpers

  get _orderField() {
    let cls = this.constructor
    if (this.parent && cls.model == cls.targetModel) {
      return `${this.parent.constructor.model.name}_order`
    }
    return 'order'
  }

  get _url() {
    return `${this.url}`
  }

  get _pId() {
    return this.parent.data.$id
  }

  get _pForeignKey() {
    return this.parent.constructor.model.foreignKey
  }

  get _tPrimaryKey() {
    return this.constructor.targetModel.primaryKey
  }

  get _tForeignKey() {
    return this.constructor.targetModel.foreignKey
  }

}
