<template lang="pug">
  #gallery-status-bar
    StatusBar(
      :isOpen="selection.active")
      //- Left
      .d-flex.align-items-center.flex-even
        Button(
          v-if="canRemove"
          icon="delete"
          @onClick="remove")
        AddToSelectionButton(
          v-if="canAddToSelection"
          :modalId="SELECTION_CREATE_MODAL_ID"
          @ok="addMediaToSelection")
      //- Center
      .d-flex.align-items-center.justify-content-center.flex-even
        span {{ selection.total }} {{ $t('selected')}}
      //- Right
      .d-flex.align-items-center.justify-content-end.flex-even
        AddToMyCollectionButton(
          v-if="canAddToCollection"
          :id="ADD_TO_COLLECTION_SIDEBAR")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { selections } from '../../../mixins/resources/selections.js'
import AddToMyCollectionButton from '../../flows/AddToMyCollection'
import AddToSelectionButton from '../Selection/AddToSelectionModal'
import Button from '../../Button'
import StatusBar from '../../general/StatusBar'

export default {
  mixins: [selections], // removeMediaFromSelection

  components: {
    AddToMyCollectionButton,
    AddToSelectionButton,
    Button,
    StatusBar
  },

  computed: {
    ...mapGetters({
      canRemoveSelectionMedium: 'selection/canRemoveMedium'
    }),

    ...mapState({
      isLoggedIn: state => state.auth.accessToken,
      resource: state => state.gallery.resource,
      selection: state => state.gallery.selection
    }),

    canAddToCollection() {
      return this.resource.kind == 'selection'
    },

    canAddToSelection() {
      return this.selection.total > 0 && this.resource.kind == 'album' && this.isLoggedIn
    },

    canRemove() {
      return this.resource.kind == 'selection' && this.canRemoveSelectionMedium
    }
  },

  methods: {
    ...mapActions({
      resetSelection: 'gallery/resetSelection',
      showMessage: 'ux_controls/showMessage'
    }),

    async addMediaToSelection(selection) {
      await this.addMediaById(selection.uid, this.selection.media)
      this.resetSelection()
      this.showMessage(this.$t('added') + ' ' + selection.name)
    },

    remove() {
      this.removeMediaFromSelection(this.resource.id, this.selection.media)
    }
  },

  created() {
    this.SELECTION_CREATE_MODAL_ID = 'selection-create-modal'
    this.ADD_TO_COLLECTION_SIDEBAR = 'add-to-collection-sidebar'
  }
}
</script>

<i18n>
  pt-BR:
    added: "As mídias foram adicionadas à seleção"
    add-to-selection: "Salvar seleção"
    add-to-collection-label: "Eu quero"
    selected: "foto(s)"
</i18n>

<style lang="sass" scoped>
.flex-even
  flex: 1
</style>
