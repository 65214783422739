<template lang="pug">
  b-row.my-2(
    align-v="center")
    b-col(cols="3")
      Avatar(
        :diameter="75"
        :face-coords="albumPersonAdapter.data.person.face.coords"
        :medium-dims="albumPersonAdapter.data.person.face.medium.dims"
        :medium-src="albumPersonAdapter.data.person.face.medium.thumb_url")
    b-col(cols="7")
      div
        strong {{ albumPersonAdapter.data.person.name }}
      div
        small.text-muted {{ sentToText }}
      div
        small {{ sendToText }}
    b-col(cols="2")
      Icon(
        v-if="showIcon"
        classes="txt-green"
        icon="check")
</template>

<script>
import Avatar from '@/components/People/Avatar'
import Icon from '@/components/Icon'

export default {
  components: {
    Avatar,
    Icon
  },

  props: {
    albumPersonAdapter: Object
  },

  computed: {
    sentToText() {
      if (this.sentTo && this.sentTo.length > 0) {
        return `${this.$t('sent-to')}: ${this.sentTo.map(channel => channel.value).join(',')}`
      }

      return ''
    },

    sendToText() {
      if (this.sendTo && this.sendTo.length > 0) {
        return `${this.$t('send-to')}: ${this.sendTo.map(channel => channel.value).join(',')}`
      }

      return ''
    },

    sendTo() {
      return this.sentTo.length == 0 ? this.albumPersonAdapter.sendTo : []
    },

    sentTo() {
      return this.albumPersonAdapter.sentTo
    },

    showIcon() {
      return this.albumPersonAdapter.data.deliveries
    }
  }
}
</script>

<i18n>
  pt-BR:
    send-to: "Enviar para"
    sent-to: "Enviado para"
</i18n>
