<template lang="pug">
  #choose-selection
    span(
      v-if="isLoading && pages == 1")
      //- There's no selection displayed yet
      p.m-0 {{ $t('loading-selections') }}...
      Icon(
        icon="loading")
    div(
      v-else)
      RadioInput(
        id="selection-radio-group"
        :label="label"
        name="selection-radio-name"
        :options="options"
        v-model="selectionId"
        v-slot="{ ariaDescribedby }"
        stacked)
      #load-more(
        v-if="!allLoaded")
        span(
          v-if="isLoading")
          Icon(
            icon="loading")
        Link(
          v-else
          :label="$t('load-more')"
          @click="loadMore")
</template>

<script>
import { selections } from '../../../mixins/resources/selections.js'
import Icon from '../../Icon'
import Link from '../../general/Link'
import RadioInput from '../../inputs/RadioInput'

export default {
  mixins: [selections],

  components: {
    Icon,
    Link,
    RadioInput
  },

  props: {
    label: {
      type: String,
      default: function() {
        return this.$t('choose-selection')
      }
    },
    value: Object
  },

  data() {
    return {
      isLoading: false,
      options: [],
      pages: 1, // number of loaded pages
      selectionId: '',
      total: 0
    }
  },

  computed: {
    allLoaded() {
      return this.pages * this.PER_PAGE >= this.total
    }
  },

  methods: {
    // Add current selections as radio options
    async addSelections(page) {
      this.isLoading = true
      let response = await this.getSelections({
        page: page,
        per_page: this.PER_PAGE
      })

      this.total = response.total
      let selections = response.selections

      selections.forEach(selection => {
        this.options.push({ text: selection.name, value: selection.uid })
      })
      this.isLoading = false
    },

    loadMore() {
      this.pages++
      this.addSelections(this.pages)
    }
  },

  watch: {
    selectionId() {
      this.$emit('input', {
        name: this.getNameFromId(this.options, this.selectionId),
        uid: this.selectionId
      })
    }
  },

  created() {
    this.PER_PAGE = 5 // Loads 5 selections at a time
  },

  mounted() {
    this.addSelections(1) // add first page of selections
  }
}
</script>

<i18n>
  pt-BR:
    choose-selection: 'Escolha uma seleção existente'
    loading-selections: 'Aguarde enquanto carregamos suas seleções'
    load-more: 'Carregar mais'
</i18n>

<style lang="sass"></style>
