<template lang="pug">
  .flow
    CollectableSelectionStep(
      v-show="currentStep == 1"
      @back="back"
      @cancel="cancel"
      @ok="next"
      @skip="skip")
    CollectableMediaStep(
      v-show="currentStep == 2"
      @back="previous"
      @cancel="cancel"
      @ok="done"
      @skip="skip")
    //- CollectableSettingsStep(
    //-   v-show="currentStep == 3"
    //-   @ok="done")
</template>

<script>
import { mapGetters } from 'vuex'
import CollectableMediaStep from './CollectableMediaStep'
import CollectableSelectionStep from './CollectableSelectionStep'
// import CollectableSettingsStep from './CollectableSettingsStep'

export default {
  components: {
    CollectableMediaStep,
    CollectableSelectionStep
    // CollectableSettingsStep
  },

  data() {
    return {
      currentStep: 1
    }
  },

  computed: {
    ...mapGetters({
      totalPrice: 'cart/getTotalPrice'
    })
  },

  methods: {
    back() {
      this.$emit('back')
    },

    cancel() {
      this.$emit('cancel')
      this.currentStep = 1
    },

    done() {
      this.$emit('ok')
    },

    next() {
      this.currentStep++
    },

    previous() {
      this.currentStep--
    },

    skip() {
      this.$emit('skip')
    }
  }
}
</script>

<style lang="sass" scoped></style>
