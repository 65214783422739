import api from '../../api'
export const getters = {
  // Calculate price based on media number and selection plan
  getPrice: state => mediaNumber => {
    let price = 0

    if (state.current.plan && state.current.plan.id) {
      price = state.current.plan.singlePrice * mediaNumber

      if (price > state.current.plan.capPrice) {
        price = state.current.plan.capPrice
      }
    }

    return price
  },
  canEditCurrentSelection: state => {
    if (state.current.permissions && state.current.permissions.indexOf('all') >= 0) {
      return true
    }

    return false
  },
  canRemoveMedium: state => {
    if (state.current.permissions && state.current.permissions.indexOf('all') >= 0) {
      return true
    }

    return false
  }
}

export const mutations = {
  clearLibrary: state => {
    state.library.data.splice(0, state.library.data.length)
    state.library.total = 0
  },
  setCurrentId: (state, id) => {
    state.current.id = id
  },
  setCurrentName: (state, name) => {
    state.current.name = name
  },
  setCurrentPermissions: (state, permissions) => {
    state.current.permissions = permissions
  },
  setCurrentPage: (state, page) => {
    state.currentPage = page
  },
  // response matches backend notation
  setCurrentSelection: (state, response) => {
    state.current.id = response.uid
    state.current.name = response.name
    state.current.permissions = response.permissions
    state.current.plan = response.plan
      ? {
          capPrice: response.plan.cap_price_cents,
          packs: response.plan.packs,
          singlePrice: response.plan.single_price_cents,
          id: response.plan.uid
        }
      : { id: null }
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading
  },
  // response = backend response
  setLibrary: (state, response) => {
    state.library.data = response.selections
    state.library.total = response.total
  },
  setLibraryData: (state, data) => {
    state.library.data = data
  },
  setLibraryTotal: (state, total) => {
    state.library.total = total
  }
}

export const actions = {
  clearCurrentSelection: context => {
    context.commit('setCurrentId', null)
    context.commit('setCurrentName', null)
  },
  clearLibrary: context => {
    context.commit('clearLibrary')
  },
  // Get selections from the backend and set it on the store
  loadLibrary: (context, page = context.state.currentPage) => {
    context.commit('setIsLoading', true)

    return new Promise((resolve, reject) => {
      api.selections
        .getSelections({
          page: page,
          per_page: context.state.settings.perPage
        })
        .then(response => {
          context.commit('setLibrary', response)
          context.commit('setIsLoading', false)
          resolve()
        })
        .catch(error => {
          context.commit('clearLibrary')
          context.commit('setIsLoading', false)
          reject(error)
        })
    })
  },
  // Get a selection from the backend and set it on the store as the current
  loadSelection: ({ commit, dispatch }, selectionId) => {
    commit('setIsLoading', true)

    return new Promise((resolve, reject) => {
      api.selections
        .getSelection(selectionId)
        .then(response => {
          commit('setCurrentSelection', response)
          commit('setIsLoading', false)
          resolve()
        })
        .catch(error => {
          dispatch('clearCurrentSelection').then(() => {
            commit('setIsLoading', false)
          })
          reject(error)
        })
    })
  },
  // response = backend response
  setCurrentSelection: (context, response) => {
    context.commit('setCurrentSelection', response)
  },
  setCurrentId: (context, id) => {
    context.commit('setCurrentId', id)
  },
  setCurrentName: (context, name) => {
    context.commit('setCurrentName', name)
  },
  setCurrentPage: (context, page) => {
    context.commit('setCurrentPage', page)
  },
  setIsLoading: (context, isLoading) => {
    context.commit('setIsLoading', isLoading)
  },
  // response = backend response
  setLibrary: (context, response) => {
    context.commit('setLibrary', response)
  }
}

export default {
  namespaced: true,
  state: {
    library: {
      data: [],
      total: 0
    },
    current: {
      id: null,
      name: null,
      permissions: [],
      plan: null
    },
    currentPage: 1,
    isLoading: false,
    settings: {
      perPage: 50
    }
  },
  getters,
  mutations,
  actions
}
