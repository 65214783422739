<template lang="pug">
  #selection
    SelectionMenu(
      @select="handleMenuOption")
    section.container-fluid.my-5
      .row
        .col
          h1.title(
            :class="getClasses"
            :contenteditable="canEditName"
            v-text="editedName"
            @blur="editName"
            @keydown.enter.prevent="editName")
    GallerySection
    SelectionEditModal(
      :modalId="EDIT_MODAL_ID"
      @update="editedName = selection.name")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { selections } from '../mixins/resources/selections.js'
import GallerySection from '../components/application/Gallery/GallerySection'
import SelectionEditModal from '../components/application/Selection/SelectionEditModal'
import SelectionMenu from '../components/application/Selection/SelectionMenu'

export default {
  name: 'Selection',

  mixins: [selections], // updateSelection

  components: {
    GallerySection,
    SelectionEditModal,
    SelectionMenu
  },

  data() {
    return {
      editedName: ''
    }
  },

  computed: {
    ...mapGetters({
      canEditName: 'selection/canEditCurrentSelection'
    }),

    ...mapState({
      collectables: state => state.collectables.items,
      selection: state => state.selection.current
    }),

    getClasses() {
      return this.canEdit ? 'editable-title' : ''
    }
  },

  methods: {
    ...mapActions({
      setResource: 'gallery/setResource',
      setSelectionName: 'selection/setCurrentName',
      leaveSelection: 'selection/clearCurrentSelection',
      loadCollectables: 'collectables/loadItems',
      resetCollectables: 'collectables/reset',
      resetGallery: 'gallery/resetGallery'
    }),

    async editName(evt) {
      // Binding
      let src = evt.target.innerText
      this.editedName = src

      if (this.editedName != this.selection.name) {
        let params = { name: this.editedName }
        await this.updateSelection(this.selection.id, params)
        this.setSelectionName(this.editedName)
      }
    },

    handleMenuOption(value) {
      switch (value) {
        case 'back':
          this.back()
          break
        case 'edit':
          this.$bvModal.show(this.EDIT_MODAL_ID)
          break
      }
    },

    back() {
      this.resetGallery()
      this.leaveSelection()
      this.resetCollectables()
      this.$router.push('/selections')
    }
  }, // methods

  created() {
    this.EDIT_MODAL_ID = 'edit-selection-modal'
    this.setResource({
      id: this.$route.params.id,
      kind: 'selection'
    })
    this.editedName = this.selection.name
  },

  async mounted() {
    await this.loadCollectables({
      id: this.selection.id,
      kind: 'selection'
    })
  }
}
</script>

<i18n>
  pt-BR:
    template-title: 'Molduras disponíveis'
</i18n>

<style lang="sass" scoped>
@import '../assets/stylesheets/_variables.sass'
.title
  border: 1px solid transparent

.title:hover
  cursor: default

.editable-title:hover
  border-bottom: 1px solid #dadce0
  cursor: auto

.editable-title:focus
  outline: none
  border-bottom: 1px solid $secondary-color
</style>
