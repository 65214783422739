<template lang="pug">
  .container-fluid.text-center
    .row
      .col-10.offset-1.col-sm-8.offset-sm-2.col-md-6.offset-md-3.col-lg-4.offset-lg-4
        Icon.icon-sq-xl.txt-secondary.mb-3(
          :icon="icon")
        h1.instruction-title.mb-3 {{ title }}
        p.instruction-description.mb-3 {{ description }}
        #call-to-action(
          v-if="ctaLabel")
          Button(
            classes="primary"
            :label="ctaLabel"
            @onClick="$emit('click')")
        slot
</template>

<script>
import Button from '../Button'
import Icon from '../Icon.vue'

export default {
  components: {
    Button,
    Icon
  },

  props: {
    icon: String,
    title: String,
    description: String,
    ctaLabel: String
  }
}
</script>

<style lang="sass" scoped></style>
