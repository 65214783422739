import BaseModel from '@/models/base_model'
// import Delivery from '@/models/delivery'
import Person from '@/models/person'

export default class AlbumPerson extends BaseModel {
  static entity = 'albumPerson'
  static primaryKey = ['album_uid', 'person_uid']

  static fields() {
    return {
      album_uid: this.attr(null),
      person_uid: this.attr(null),
      order: this.attr(null),
      // has many media
      medium_count: this.attr(null),
      deliveries: this.attr(null),
      person: this.belongsTo(Person, 'person_uid')
    }
  }
}
