<template lang="pug">
  BaseView
    Step(
      :ok-disabled="isSubmitting"
      back-to="/home"
      card
      ok-only
      :title="$t('title')"
      @ok="handleOk")
      AlbumForm(
        action="new"
        :album="albumAdapter.data"
        :fields="fields"
        no-button
        ref="albumForm"
        @change="handleChange"
        @submit="handleSubmit")
</template>

<script>
import AlbumAdapter from '@/adapters/album_adapter'
import AlbumForm from '@/components/flows/forms/AlbumForm'
import BaseView from '@/layouts/Base'
import Step from '@/layouts/Step'

export default {
  components: {
    AlbumForm,
    BaseView,
    Step
  },

  props: {
    visibility: {
      type: String,
      default: 'private'
    }
  },

  data() {
    return {
      albumAdapter: new AlbumAdapter(),
      fields: ['visibility', 'name', 'description', 'starts_at', 'media_visibility'], // 'visibility', 'name', 'description', 'starts_at', 'media_visibility', 'watermark', 'recognition'
      isSubmitting: false
    }
  },

  methods: {
    handleChange(isSubmitting) {
      this.isSubmitting = isSubmitting
    },

    handleOk() {
      // Activate the submit action of the form
      this.$refs.albumForm.$refs.formWrapper.onSubmit()
    },

    async handleSubmit() {
      if (await this.albumAdapter.create()) {
        this.$router.replace(`/albums/${this.albumAdapter.data.uid}`)
      } else {
        this.$refs.albumForm.$refs.formWrapper.showErrors()
      }
    }
  }, // methods

  beforeMount() {
    this.albumAdapter.data.visibility = this.visibility
    // The face rec field was removed because as of now (dec 2021) we're
    // performing tests only with schools and they need it necessarily
    this.albumAdapter.data.recognition = true
  }
}
</script>

<i18n>
  pt-BR:
    title: "Criar álbum"
</i18n>
