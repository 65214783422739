import { cookies } from '../mixins/cookies.js'
import store from '../store'
import storageValues from '../consts/storage.js'
import uri from '../consts/uri.js'

import MyProfileAdapter from '@/adapt/my-profile-adapter'

export async function beforeEach(to, from, next) {
  let profile = new MyProfileAdapter()
  if (store.state.auth.isLoggedIn) {
    try {
      await profile.load()
    } catch (error) {
      // The user has no profile yet
      if (!to.path.includes('/invites') && !to.path.includes('/profile') && profile.newRecord) {
        next({ name: 'my-profile-new' })
      }
    }
  }

  if (
    to.path != uri.LOGIN_REDIRECT_URI && // If it's not redirecting from the login
    cookies.methods.getCookie(storageValues.IS_LOGGED_IN) && // User was logged in
    !store.state.auth.isLoggedIn
  ) {
    // But the token is gone from the memory
    store.dispatch('auth/login', to.fullPath)
  } else {
    next()
  }
}

// TODO: Handle isLoggedIn before enter in beforeEach hook

export function homeBeforeEnter(to, from, next) {
  if (store.state.auth.isLoggedIn) {
    next()
  } else {
    next({ name: 'login' })
  }
}

export async function orderBeforeEnter(to, from, next) {
  store.dispatch('ux_controls/setLoadingScreenDisplay', true)
  try {
    await store.dispatch('order/loadOrder', to.params.id)
    store.dispatch('ux_controls/setLoadingScreenDisplay', false)
    next()
  } catch (error) {
    store.dispatch('ux_controls/setLoadingScreenDisplay', false)
    next({ name: 'notFound' })
  }
}

export async function selectionBeforeEnter(to, from, next) {
  store.dispatch('ux_controls/setLoadingScreenDisplay', true)
  try {
    await store.dispatch('selection/loadSelection', to.params.id)
    store.dispatch('ux_controls/setLoadingScreenDisplay', false)
    next()
  } catch (error) {
    store.dispatch('ux_controls/setLoadingScreenDisplay', false)
    next({ name: 'notFound' })
  }
}
// After performing login on the server
export async function loginBeforeEnter(to, from, next) {
  try {
    await store.dispatch('auth/handleLoginRedirect')
    // If a redirect uri has been set
    if (localStorage.getItem(storageValues.LOGIN_REDIRECT_URI)) {
      next(localStorage.getItem(storageValues.LOGIN_REDIRECT_URI))
    } else {
      next({ name: 'home' })
    }

    localStorage.removeItem(storageValues.LOGIN_REDIRECT_URI)
  } catch (error) {
    console.log(error)
    next('/')
  }
}

export function logoutBeforeEnter(to, from, next) {
  store
    .dispatch('auth/handleLogoutRedirect')
    .then(() => {
      next('/')
    })
    .catch(error => {
      console.log(error)
      next('/')
    })
}
