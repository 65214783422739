import api from '../../api'

export const mutations = {
  clearAlbums: state => {
    state.albums.splice(0, state.albums.length)
    state.albums.total = 0
  },
  setAlbums: (state, albums) => {
    state.albums = albums
  },
  setCurrentPage: (state, page) => {
    state.currentPage = page
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading
  },
  setTotal: (state, total) => {
    state.total = total
  }
}

export const actions = {
  loadAlbums: (context, page = context.state.currentPage) => {
    context.commit('setIsLoading', true)

    return new Promise((resolve, reject) => {
      api.albums
        .getUserAlbums({
          page: page,
          per_page: context.state.settings.perPage
        })
        .then(response => {
          context.commit('setAlbums', response.albums)
          context.commit('setTotal', response.total)
          context.commit('setIsLoading', false)
          resolve()
        })
        .catch(error => {
          context.commit('clearAlbums')
          context.commit('setIsLoading', false)
          reject(error)
        })
    })
  },
  loadProfileAlbums: (context, userName, page = context.state.currentPage) => {
    context.commit('setIsLoading', true)

    return new Promise((resolve, reject) => {
      api.profiles
        .indexAlbums(userName, {
          page: page,
          per_page: context.state.settings.perPage
        })
        .then(response => {
          context.commit('setAlbums', response.albums)
          context.commit('setTotal', response.total)
          context.commit('setIsLoading', false)
          resolve()
        })
        .catch(error => {
          context.commit('clearAlbums')
          context.commit('setIsLoading', false)
          reject(error)
        })
    })
  },
  setCurrentPage: ({ commit }, page) => {
    commit('setCurrentPage', page)
  }
}

export default {
  namespaced: true,
  state: {
    albums: [],
    currentPage: 1,
    total: 0,
    isLoading: false,
    settings: {
      perPage: 24
    }
  },
  mutations,
  actions
}
