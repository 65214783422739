<template lang="pug">
  Popup(
    bodyClass="p-0"
    class="body-no-padding"
    :id="id"
    okOnly
    :title="collectable.name"
    @hidden="close")
    Carousel(
      :id="'carousel-' + id"
      :media="collectable.preview.media")
    .p-3
      small.detail-title {{ $t('offered-by') }}
      ProfileAvatarUsername(
        class="mb-3"
        :src="collectable.provider_profile.avatar"
        :username="collectable.provider_profile.username")
      small.detail-title {{ $t('description') }}
      p {{ collectable.description }}
      small.detail-title {{ $t('price') }}
      p {{ collectable.price.toFixed(2).toString().replace('.', ',') }}
</template>

<script>
// import { mapActions } from 'vuex'
import Carousel from '../../company/Carousel'
import Popup from '../../general/Popup'
import ProfileAvatarUsername from '../Profile/ProfileAvatarUsername'

export default {
  components: {
    Carousel,
    Popup,
    ProfileAvatarUsername
  },

  props: {
    collectable: Object,
    id: String
  },

  methods: {
    // ...mapActions({
    //   showMessage: 'ux_controls/showMessage'
    // }),

    cancel() {
      console.log('cancel')
    },

    close() {
      this.$emit('ok')
    },

    hide(text) {
      console.log('hide ' + text)
    }
  }
}
</script>

<i18n>
  pt-BR:
    description: "Descrição"
    offered-by: "Oferecido por"
    price: "Preço (R$)"
</i18n>

<style lang="sass" scoped>
.detail-title
  text-transform: uppercase
  color: grey
</style>
