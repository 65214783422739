<template lang="pug">
  Dropdown(
    :options="options"
    :width="250"
    @select="handleOption")
    template(
      v-slot:button-content)
      Button(
        icon="+"
        rounded)
</template>

<script>
import Button from '@/components/company/Button'
import Dropdown from '@/components/Dropdown'

export default {
  components: {
    Button,
    Dropdown
  },

  methods: {
    handleOption(value) {
      switch (value) {
        case 'private':
          this.$router.push({ name: 'albums-new' })
          break
        case 'public':
          this.$router.push({ name: 'albums-new', query: { v: 'public' } })
          break
        case 'social':
          this.$router.push({ name: 'albums-new', query: { v: 'community' } })
          break
      }
    }
  },

  async beforeMount() {
    this.options = [
      { value: 'private', text: this.$t('create-private-album'), icon: 'private' },
      { value: 'social', text: this.$t('create-social-album'), icon: 'users' },
      { value: 'public', text: this.$t('create-public-album'), icon: 'public' }
    ]
  }
}
</script>

<i18n>
  pt-BR:
    create-private-album: "Privado"
    create-public-album: "Público"
    create-social-album: "Social"
</i18n>

<style lang="sass" scoped></style>
