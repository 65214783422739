<template lang="pug">
  span
    Navbar(
      bordered
      :title="title")
      template(#right
        v-if="canDownload")
        Button(
          @click="download")
          label {{ $t('download') }}
    OrderArchiveModal(
      :modalId="ORDER_ARCHIVE_MODAL_ID"
      :order="order")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Button from '../../general/Button/ButtonNew'
import Icon from '../../Icon'
import Navbar from '../../company/Navbar'
import OrderArchiveModal from './OrderArchiveModal'

export default {
  components: {
    Button,
    Icon,
    Navbar,
    OrderArchiveModal
  },

  computed: {
    ...mapGetters({
      isMobileDevice: 'ux_controls/isViewportWidthMaxMD'
    }),

    ...mapState({
      order: state => state.order.current
    }),

    canDownload() {
      return this.isPaid && !this.isMobileDevice
    },

    isPaid() {
      return this.order.paidAt
    },

    title() {
      return this.isPaid ? this.$t('title-paid') : this.$t('title-not-paid')
    }
  },

  methods: {
    download() {
      this.$bvModal.show(this.ORDER_ARCHIVE_MODAL_ID)
    }
  },

  created() {
    this.ORDER_ARCHIVE_MODAL_ID = 'order-archive-modal'
  }
}
</script>

<i18n>
  pt-BR:
    download: "Baixar"
    title-paid: "Pedido concluído"
    title-not-paid: "Aguardando pagamento"
</i18n>

<style lang="sass" scoped>
.top-menu
  border-bottom: 1px solid #f8f8f8
</style>
