import Layer from './layer.js'

export default class LayerManager {
  constructor() {
    this.backgroundLayer = new Layer({ name: 'background' })
    this.photoLayer = new Layer({ name: 'photos' })
    this.overlayLayer = new Layer({ name: 'overlay' })
    this.watermarkLayer = new Layer({ name: 'watermark' })

    this._layers = []
    this._layers.push(this.backgroundLayer)
    this._layers.push(this.photoLayer)
    this._layers.push(this.overlayLayer)
    this._layers.push(this.watermarkLayer)
    // Start with no layer as current
    this.current = null
  }

  get layers() {
    return this._layers.slice().reverse()
  }

  // Return the layer object with name = layerName
  layerByName(layerName) {
    switch (layerName) {
      case 'background':
        return this.backgroundLayer
      case 'photos':
        return this.photoLayer
      case 'overlay':
        return this.overlayLayer
      case 'watermark':
        return this.watermarkLayer
    }

    return null
  }

  // Return the id of layerName
  layerId(layerName) {
    let layer = this._layers.find(layer => layer.name == layerName)

    if (layer) {
      return layer.id
    }

    return null
  }

  addObject(object) {
    this.current.addObject(object)
    return this._objectZIndex()
  }

  // Add an object to the top of the layer with name layerName
  addObjectToLayer(object, layerName) {
    let layer = this.layerByName(layerName)

    if (layer) {
      layer.addObject(object)

      return this._zIndexInLayer(layer.id)
    }

    return null
  }

  // Clear all objects of a layer
  clearLayer(layerName) {
    let layer = this.layerByName(layerName)

    if (layer) {
      layer.clear()

      return true
    }

    return false
  }

  setActiveLayer(layerId) {
    this._layers.forEach(layer => {
      if (layer.id == layerId) {
        this.current = layer
        layer.setActive()
      } else {
        layer.setInactive()
      }
    })
  }

  // Return the current active layer
  getActiveLayer() {
    this._layers.forEach(layer => {
      if (layer.isActive) {
        return layer
      }
    })

    return null
  }

  // Make layerName the current one
  changedToLayer(layerName) {
    let layerId = this.layerId(layerName)
    // If the layer with name layerName has been found
    if (layerId) {
      // If there's no current selected layer and its name is not layerName
      if (!this.current || !this.isLayerCurrent(layerId)) {
        this.setActiveLayer(layerId)
      }
      // Check again just to be sure it has changed
      return this.isLayerCurrent(layerId)
    }

    return false
  }

  isLayerCurrent(layerId) {
    return this.current.id == layerId
  }

  // Return the current z-index in the layer, i.e. the z-index of the last added object
  _zIndexInLayer(layerId) {
    let objectCount = 0

    for (let layer of this._layers) {
      objectCount += layer.objects.length

      if (layer.id == layerId) {
        break
      }
    }

    return objectCount - 1
  }

  // Description: Return the z-index for the current layer
  _objectZIndex() {
    let objectCount = 0
    for (let layer of this._layers) {
      objectCount += layer.objects.length
      if (layer.id == this.current.id) {
        break
      }
    }
    return objectCount - 1
  }
}
