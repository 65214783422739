/* eslint-disable */
export default class Base {
  static persistBy = 'insertOrUpdate'

  constructor({ data = null } = {}) {
    this.loading = false
    this.newRecord = data ? false : true
    this.data = data ?? new this.constructor.model()
  }

  loadFromStore({ id = null } = {}) {
    let cls = this.constructor
    let data = id
      ? cls.model.query().whereId(id).withAllRecursive().first()
      : cls.model.query().withAllRecursive().first()

    if (data) {
      this.data = data
      this.newRecord = false
    } else {
      // This is needed because the loading process can be happening after an
      // already existing record (e.g. from /p/william to /p/rodrigo (my profile))
      this.newRecord = true
    }
  }

  async loadFromApi({ id = null } = {}) {
    let cls = this.constructor
    this.loading = true
    this.data[cls.model.primaryKey] = id // set primaryKey value before calling url getter

    try {
      let response = await cls.model.apiFetch({ url: this.url, save: false })

      if (response && response.data) {
        await this._persist(response.data)
      }

      this.loadFromStore({ id: id })
      this.loading = false
    } catch (error) {
      // This error handling is needed in order to stop the loading state
      this.loading = false
      throw error // Forward the error
    }
  }

  async load({ id = null, includes = [] } = {}) {
    if (this._beforeLoad != undefined) {
      this._beforeLoad(id)
    }

    this.loadFromStore({ id: id })

    if (this.newRecord) {
      await this.loadFromApi({ id: id })
    }

    await this._loadRelationships(includes)

    if (this._afterLoad != undefined) {
      this._afterLoad(id)
    }
  }

  async create() {
    let response = await this.data.apiCreate({ url: this.url })
    if (response) {
      if (this._afterCreate != undefined) {
        await this._afterCreate(response)
      }
      return true
    }
    return false
  }

  async update() {
    if (this._beforeUpdate != undefined) {
      await this._beforeUpdate()
    }

    let saved = await this.data.apiUpdate({ url: this.url })

    if (saved) {
      if (this._afterUpdate != undefined) {
        await this._afterUpdate()
      }
      return true
    }
    return false
  }

  async _loadRelationships(includes) {
    let cls = this.constructor
    if (!this.newRecord) {
      for (let rel of includes) {
        if (cls.relationships.includes(rel)) {
          await eval(`this.${rel}.load()`)
        }
      }
    }
  }

  // persist

  async _persist(data) {
    let cls = this.constructor
    if (this._beforePersist != undefined) {
      await this._beforePersist(data)
    }
    cls.model.insertOrUpdate({ data: data })
  }
}
