import BaseModel from '@/models/base_model'
import Face from '@/models/face'

export default class Person extends BaseModel {
  static name = 'person'
  static entity = 'people'
  static primaryKey = 'uid'

  static fields() {
    return {
      uid: this.attr(''),
      belongs_to_me: this.attr(true),
      email: this.attr(''),
      name: this.attr(''),
      phone: this.attr(''),
      allow_image_usage: this.attr(false),
      medium_url: this.attr(''),
      is_being_processed: this.attr(false),
      is_dependent: this.attr(false),
      is_me: this.attr(false),
      // belongs to profile
      profile_user_name: this.attr(null),
      profile_order: this.attr(null),
      // belongs to face
      face_uid: this.attr(''),
      face: this.belongsTo(Face, 'face_uid')
    }
  }

  constructor(record) {
    super(record)
    this.selfie = { url: this.medium_url, blob: null }
  }
}
