<template lang="pug">
  PeopleGrid(
    col-class="col-6"
    :peopleAdapter="peopleAdapter")
</template>

<script>
import PeopleGrid from '@/components/People/PeopleGrid'

export default {
  components: {
    PeopleGrid
  },

  props: {
    peopleAdapter: Object
  }
}
</script>
