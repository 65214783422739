<!-- This component is a wrapper for NameInput because our backend uses a
notation (name) different from the one used by that component (firstName,
lastName, fullName) -->
<template lang="pug">
  NameInput(
    :full="full"
    :label="label"
    :name="name"
    v-model="profileName")
</template>

<script>
import NameInput from '@/components/inputs/NameInput'

export default {
  components: {
    NameInput
  },

  props: {
    full: Boolean,
    label: String,
    name: String,
    value: String // full name
  },

  data() {
    return {
      profileName: {
        firstName: '',
        lastName: '',
        fullName: this.value
      }
    }
  },

  watch: {
    profileName() {
      this.$emit('input', this.profileName.fullName)
    }
  }
}
</script>
