<template lang="pug">
  .drag-container
    .upload
      UploadBox(
        :files="files"
        :errors="errors"
        :currentFile="currentFile"
        :progress="progress"
        :success="success"
        @close="resetData(false)"
        @retry="retry"
        @stop="abort")

      slot
      //- label(for="file" class="btn btn-lg btn-primary") Select Files

      div(v-show="$refs.upload && $refs.upload.dropActive" class="drop-active")
        h3 {{ $t('drag-and-drop-message') }}

      div
        FileUpload(
          v-show='false'
          :input-id='inputId'
          :extensions="ALLOWED_EXTENSIONS"
          class="btn btn-primary"
          :custom-action="customAction"
          :multiple="multiple"
          :drop="drop"
          :size="maxMediaSize"
          :thread="NUMBER_OF_THREADS"
          v-model="files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          ref="upload") Select files
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

import errors from '@/consts/errors'
import { albums } from '@/mixins/resources/albums.js'
import { images } from '@/mixins/images.js'

import FileUpload from 'vue-upload-component'
import UploadBox from '@/components/application/UploadBox'

export default {
  mixins: [
    albums, // createMediumFromFile
    images // loadImageFromURL
  ],

  components: {
    FileUpload,
    UploadBox
  },

  data() {
    return {
      currentFile: 0,
      errors: [],
      files: [],
      loaded: 0,
      success: 0,
      total: 0,
      totalRetry: 0
    }
  },

  props: {
    albumId: String,
    drop: Boolean,
    inputId: String,
    multiple: Boolean
  },

  computed: {
    ...mapState({
      maxMediaSize: state => state.settings.maxMediaSize,
      maxImageDimensions: state => state.settings.maxImageDimensions
    }),

    progress() {
      return this.total > 0 ? this.loaded / this.total : 0
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    }),

    abort(message) {
      if (this.$refs.upload.active) {
        this.$refs.upload.active = false // Stop upload
        this.interruptUnsentFiles() // Add 'interruped' error to unsent files

        if (message) {
          this.showMessage(message)
        }
      }
    },

    async customAction(newFile) {
      try {
        let image = await this.loadImageFromURL(newFile.blob)
        // This dimensions verification could not be performed within inputFilter
        // because it's async and that pretreatment could not be async. The flow
        // doesn't wait until it's done to start customAction
        if (image.width * image.height > this.maxImageDimensions) {
          this.throwError(newFile, this.$t('dimensions'), false)
        } else {
          await this.create(newFile)
        }
      } catch (error) {
        console.log('error loading image?')
      }
    },

    async create(newFile) {
      try {
        let urlS3 = newFile.urlS3

        if (!urlS3) {
          // Store medium on S3
          urlS3 = await this.storeAlbumMedium(this.albumId, newFile.file, e => {
            this.$refs.upload.update(newFile, {
              loaded: e.loaded,
              total: e.total
            })
          })
          // Avoid duplication on S3 in case of backend error
          this.$refs.upload.update(newFile, { urlS3: urlS3 })
        }

        // Store medium on the backend
        await this.createAlbumMedium(this.albumId, {
          filename: newFile.name,
          kind: 'photo',
          url: urlS3
        })
        // The 'success' property is automatically set by vue-upload-component,
        // but it doesn't happen in case of interruption.
        this.$refs.upload.update(newFile, { success: true })
      } catch (error) {
        this.handleErrors(error, newFile)
      }
    },

    // Handle errors received during the request process to create a medium
    handleErrors(error, newFile) {
      switch (error.status) {
        case errors.UNAUTHORIZED:
          this.throwError(newFile, this.$t('permission'), false)
          this.abort(this.$t('error-unauthorized'))
          break
        case errors.NETWORK:
          this.throwError(newFile, this.$t('network'), true)
          break
        default: {
          let description = error.status ? error.status : this.$t('unknown')
          this.throwError(newFile, description, true)
        }
      }
    },

    // Add 'interrupted' error to unsent files
    interruptUnsentFiles() {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].loaded == 0) {
          // It's not possible to retry in case of interruption, because even
          // the successfully uploaded files get the 'abort' error and they
          // would be retried too. So the retrial is forbidden in this case.
          this.throwError(this.files[i], this.$t('interruped'), false)
          // Once this file has been removed from the files array, 'i' goes
          // back to its previous value so no file is ignored in the for loop
          i--
        }
      }
    },

    // Errors that prevent upfront a file from being uploaded, such as size,
    // extension, corrupted file. The dimension vetification had to be placed
    // in customAction because it's async and inputFilter doesn't wait for it
    hasError(newFile) {
      // Filter non-image file
      if (!this.ALLOWED_EXTENSIONS.test(newFile.name)) {
        this.reportError(newFile.name, this.$t('extension'))
        return true
      }

      if (newFile.size > this.maxMediaSize) {
        this.reportError(newFile.name, this.$t('size'))
        return true
      }

      if (newFile.size == 0) {
        this.reportError(newFile.name, this.$t('corrupted'))
        return true
      }

      return false
    },

    // Handle file events: add, error, remove, update, success etc.
    // newFile is the current state of the file and oldFile is the previous one
    // https://lian-yue.github.io/vue-upload-component/#/en/documents#options-events-input-file
    inputFile: function(newFile, oldFile) {
      // File is being updated
      if (newFile && oldFile) {
        // File is successfully uploaded
        if (newFile.success && !oldFile.success) {
          this.success++
          this.currentFile++
        }

        // An error has occurred
        // The error is being handled within handleErrors()
        // if (newFile.error && !oldFile.error) {
        //   console.log('error ' + newFile.name)
        // }

        // Sum up header size to the total, so progress goes to 100%
        if (newFile.total && !oldFile.total) {
          this.total += newFile.total - newFile.size
        }
        // Update upload progress. Check if it's not a retry
        if (newFile.loaded !== oldFile.loaded && newFile.loaded > oldFile.loaded) {
          this.loaded += newFile.loaded - oldFile.loaded
        }
      } else {
        // File is being added
        if (newFile && !oldFile) {
          if (!this.$refs.upload.active) {
            this.$refs.upload.active = true // Auto upload
          }
        }
      }
    },

    // Pretreatment
    inputFilter: function(newFile, oldFile, prevent) {
      // Executes this condition for all files before starting their upload
      if (newFile && !oldFile) {
        if (this.hasError(newFile)) {
          return prevent()
        }

        // Thumbnails
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }

        // Add bytes to the total size
        this.total += newFile.size
        // Create the loaded property for a file to control progress
        newFile.loaded = 0
      }
    },

    reportError(filename, reason) {
      let error = { filename: filename, reason: reason }
      Vue.set(this.errors, this.errors.length, error)
    },

    resetData(isRetrying) {
      this.currentFile = 0
      this.errors.splice(0, this.errors.length)
      this.loaded = 0

      if (isRetrying) {
        this.total = this.totalRetry
      } else {
        this.success = 0
        this.totalRetry = 0
        this.total = 0
        this.$refs.upload.clear()
      }
    },

    // Try again to upload all the files that are in the files array
    retry() {
      // Reset component data but files array
      this.resetData(true)

      for (let i = 0; i < this.files.length; i++) {
        if (!this.files[i].success) {
          this.$refs.upload.update(this.files[i], {
            active: true,
            error: '',
            loaded: 0,
            progress: '0.00'
          })
        } else {
          this.currentFile++
        }
      }
    },

    // Update a file with error
    throwError(newFile, errorDescription, canRetry) {
      this.reportError(newFile.name, errorDescription)

      if (canRetry) {
        this.$refs.upload.update(newFile, { error: errorDescription })
        this.totalRetry += newFile.size // Calculate the total size if the user retries
        this.currentFile++
      } else {
        this.$refs.upload.remove(newFile)
        // Once the file size counted to the total, it's subtracted
        this.total -= newFile.size
      }
    }
  },

  created() {
    this.ALLOWED_EXTENSIONS = /\.(jpe?g|png|webp)$/i
    this.NUMBER_OF_THREADS = 5
  }
}
</script>

<i18n>
  pt-BR:
    corrupted: "corrompido"
    dimensions: "dimensões"
    drag-and-drop-message: "Arraste e solte aqui suas fotos"
    error-unauthorized: "Erro de permissão. O upload foi interrompido. Refaça seu login e tente novamente."
    extension: "tipo do arquivo"
    image-format: "Apenas imagens podem ser carregadas."
    image-size: "Carregue imagens de no máximo 5 MB."
    interruped: "interrompido"
    network: "Rede"
    permission: "permissão"
    size: "tamanho"
    unknown: "desconhecido"
</i18n>

<style lang="sass" scoped>

.drag-container label.btn
  margin-bottom: 0
  margin-right: 1rem

.drag-container .drop-active
  top: 0
  bottom: 0
  right: 0
  left: 0
  position: fixed
  z-index: 9999
  opacity: .6
  text-align: center
  background: #000

.drag-container .drop-active h3
  margin: -.5em 0 0
  position: absolute
  top: 50%
  left: 0
  right: 0
  -webkit-transform: translateY(-50%)
  -ms-transform: translateY(-50%)
  transform: translateY(-50%)
  font-size: 40px
  color: #fff
  padding: 0

.progress-box
  background-color: white
  padding: 10px
  box-shadow: 0 1px 3px 0 rgba(60,64,67,.30), 0 4px 8px 3px rgba(60,64,67,.15)
  position: fixed
  bottom: 20px
  left: 20px
  border: 0px
  z-index: 1031 // greater than menu 'fixed-top' 1030
  border-radius: 6px
</style>
