<!-- Change to Sidebar.vue -->
<template lang="pug">
  .status-bar
    transition(name='appear')
      .status-bar-panel.d-flex.justify-content-between.p-3(
        v-if='isOpen')
        slot
</template>

<script>
export default {
  props: {
    isOpen: Boolean
  }
}
</script>

<style lang="sass" scoped>
@import '../../assets/stylesheets/_variables.sass'

.appear-enter-active,
.appear-leave-active
  transition: transform 0.2s ease

.appear-enter,
.appear-leave-to
  transform: translateY(100%)
  transition: all 150ms ease-in 0s

.menu-status-bar .status-bar-panel
  padding: 3rem 20px 2rem 20px

.status-bar-panel
  overflow-y: auto
  background-color: white
  position: fixed
  left: 0px
  bottom: 0px
  height: 100px
  width: 100%
  border-radius: 20px 20px 0 0
  box-shadow: 0 15px 30px 0 rgba(46, 54, 78, .6)
  z-index: 5 // To be placed over pagination bar's current page (z-index 3)
</style>
