<template lang="pug">
  ValidationProvider(
    :name="name"
    :rules="rules"
    v-slot="{ errors }")
    b-form-group(
      :id="inputGroupName"
      :description="description"
      :label="label"
      :label-for="inputName")
      b-form-select(
        :name="inputName"
        v-model="inputValue"
        :options="options")
      span.field-error(v-show="errors.length>0" class="is-invalid") {{ errors[0] }}.
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },

  props: {
    description: String,
    name: String,
    options: Array,
    value: String,
    rules: String,
    label: String
  },

  computed: {
    inputName() {
      return this.name + '-input'
    },

    inputGroupName() {
      return this.name + '-group'
    },

    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/stylesheets/_variables.sass"

.field-error
  font-size: 13px
  color: $primary-color
</style>
