import BasePaginatedCollectionAdapter from '@/adapt/base-paginated-collection-adapter'
import Medium from '@/models/medium'

export default class MediaAdapter extends BasePaginatedCollectionAdapter {
  static property = 'media'
  static targetModel = Medium // The query result is an array of media

  // resource: { id, kind }, where kind in ['album', 'order', 'selection']
  constructor(resource, params = {}) {
    super(params)
    this.resource = resource
  }

  get url() {
    return `/api/v1/${this.resource.kind}s/${this.resource.id}/media`
  }

  get _pId() {
    return this.resource.id
  }
}
