<template lang="pug">
  Navbar(
    fixed="top")
    template(
      v-slot:left)
      //- medium publisher
    template(
      v-slot:right)
      Button.ml-2(
        v-if="canSelectMedium"
        :key="selectMediumIconKey"
        @click="handleSelect")
        Icon.text-white.black-shadow(
          :icon="selectMediumIcon")
      Button.ml-2(
        v-if="canFilterByFace"
        :key="'btn-' + showFacesIcon"
        @click="showFaces")
        Icon.text-white.black-shadow(
          :icon="showFacesIcon")
      Button.ml-2(
        v-if="canDownload"
        @click="download")
        Icon.text-white.black-shadow(
          icon="download")
      Button.ml-2(
        @click="$emit('close')")
        Icon.text-white.black-shadow(
          icon="x")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { albums } from '../../../mixins/resources/albums.js'
import { files } from '../../../mixins/files.js'
import { orders } from '../../../mixins/resources/orders.js'
import Button from '../../general/Button/ButtonNew'
import Icon from '../../Icon'
import Navbar from '../../company/Navbar'

export default {
  mixins: [
    albums, // downloadAlbumMedium
    files, // downloadFile
    orders // downloadOrderMedium
  ],

  components: {
    Button,
    Icon,
    Navbar
  },

  computed: {
    ...mapGetters({
      canDownloadAlbumMedium: 'album/canDownloadMedium',
      isMobileDevice: 'ux_controls/isViewportWidthMaxSM',
      isMediumSelected: 'gallery/isMediumSelected'
    }),

    ...mapState({
      albumId: state => state.album.id,
      hasFaceRec: state => state.album.faceRec,
      isOrderPaid: state => state.order.current.paidAt,
      medium: state => state.gallery.medium,
      orderId: state => state.order.current.id,
      resource: state => state.gallery.resource.kind
    }),

    selectMediumIcon() {
      return this.isMediumSelected ? 'medium-selected' : 'medium-unselected'
    },

    selectMediumIconKey() {
      return this.isMediumSelected
        ? 'add-' + this.medium.index + '-selected'
        : 'add-' + this.medium.index + '-unselected'
    },

    canSelectMedium() {
      return this.resource != 'order'
    },

    canDownload() {
      return (
        (this.resource == 'album' && this.canDownloadAlbumMedium) ||
        (this.resource == 'order' && this.isOrderPaid)
      )
    },

    canFilterByFace() {
      // In a selection it's not possible to filter by faces yet
      return this.hasDims && this.resource == 'album' && this.hasFaceRec
    },
    // If medium has dimensions
    hasDims() {
      return this.medium.height && this.medium.width
    },

    showFacesIcon() {
      return this.medium.showFaces ? 'user-slash' : 'user'
    }
  },

  methods: {
    ...mapActions({
      toggleMediumShowFaces: 'gallery/toggleMediumShowFaces',
      toggleSelectionItem: 'gallery/toggleSelectionItem',
      showMessage: 'ux_controls/showMessage'
    }),

    async getResourceMedium() {
      switch (this.resource) {
        case 'album':
          return await this.downloadAlbumMedium(this.albumId, this.medium.uid)
        case 'order':
          return await this.downloadOrderMedium(this.orderId, this.medium.uid)
        default:
          return null
      }
    },

    handleSelect() {
      this.toggleSelectionItem(this.medium.index) // Add to or remove from selection
    },

    async download() {
      if (this.resource == 'order') {
        this.$gtm.trackEvent({
          action: 'image-download',
          target: `orders/${this.orderId}/medium/${this.medium.uid}`
        })
      }

      try {
        let medium = await this.getResourceMedium()

        if (medium) {
          // In a mobile device, the original image is opened in a new tab, so
          // the user can tap and hold and select save to camera roll.
          if (this.isMobileDevice) {
            let route = this.$router.resolve({ name: 'download', query: { url: medium.url } })
            window.open(route.href, '_blank')
          } else {
            this.downloadFile(medium.url, medium.filename)
          }
        }
      } catch (error) {
        this.showMessage(this.$t('download-error') + ' (' + error.name + ')')
      }
    },

    showFaces() {
      if (this.medium.height && this.medium.width) {
        this.toggleMediumShowFaces()
      } else {
        // Medium dims are not ready yet
        this.showMessage(this.$t('faces-error'))
      }
    }
  } // methods
}
</script>

<i18n>
  pt-BR:
    download-error: "Error ao baixar mídia. "
    faces-error: "As faces desta mídia estão sendo processadas."
    mobile-download: "Clique na mídia e segure, selecionando a opção para salvar no rolo de câmera."
</i18n>

<style lang="sass" scoped></style>
