import api from '../api'
import FileSaver from 'file-saver'
import uuid from 'uuid'

export const files = {
  methods: {
    // Download file with axios
    downloadFile(url, filename) {
      return new Promise((resolve, reject) => {
        let config = { responseType: 'arraybuffer' }
        api.other
          .get(url, config)
          .then(response => {
            FileSaver.saveAs(new Blob([response]), filename)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getExtension(filename, withDot = false) {
      return withDot
        ? filename.substring(filename.lastIndexOf('.'))
        : filename.substring(filename.lastIndexOf('.') + 1)
    },

    getUuidName(file) {
      return uuid.v4() + this.getExtension(file.name, true)
    },

    // Load file as base64
    loadFileReader(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = function() {
          resolve(reader.result)
        }
        reader.onerror = function(error) {
          reject(error)
        }
      })
    }
  }
}
