<template lang="pug">
  b-button(
    :id="id"
    :append="append"
    :block="block"
    :class="getButtonClasses"
    :disabled="disabled"
    :exact="exact"
    :exact-active-class="exactActiveClass"
    :href="href"
    :pill="pill"
    :replace="replace"
    :size="size"
    :squared="squared"
    :style="getButtonStyle"
    :to="to"
    :type="type"
    :variant="variant"
    @click="$emit('click')")
    Icon(
      v-if="icon"
      :classes="iconClass"
      :size="size"
      :source="iconSource"
      :icon="icon")
    slot
    |  {{ label }}
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

import Icon from '@/components/Icon'

@Component({
  components: { Icon },
  props: {
    append: Boolean,
    block: Boolean, // expand to the full width of a parent
    btnClass: String,
    disabled: Boolean,
    exact: String,
    exactActiveClass: String,
    href: String,
    icon: String,
    iconClass: String,
    iconSource: String,
    id: String,
    label: String,
    pill: Boolean, // rounded pill-style
    replace: Boolean,
    rounded: Boolean,
    size: String, // lg, sm
    styles: String,
    squared: Boolean,
    to: Object,
    uppercase: {
      type: Boolean,
      default: true
    },
    type: String, // button (default), submit, reset
    variant: {
      type: String,
      default: 'light' // primary, secondary, light, dark, link
    }
  },

  computed: {
    getRoundClasses() {
      if (this.rounded) {
        return this.size ? `btn-circle ${this.size}-circle` : 'btn-circle regular-circle'
      }

      return ''
    },

    getButtonStyle() {
      return this.uppercase ? `text-transform: uppercase; ${this.styles}` : this.styles
    },

    getButtonClasses() {
      return this.btnClass ? `${this.getRoundClasses} ${this.btnClass}` : `${this.getRoundClasses}`
    }
  }
})
class Button extends Vue {}
export default Button
</script>

<style lang="sass" scoped>
// @import '@/assets/stylesheets/_variables.sass'

.btn-circle
  border-radius: 50%
  text-align: center
  &.regular-circle
    padding: 10px 0px
    width: 46px
    height: 46px
  &.sm-circle
    width: 34px
    height: 34px
    padding: 5px 0px
  &.lg-circle
    width: 62px
    height: 62px
    padding: 15px 0px
// a
//   color: $secondary-color
</style>
