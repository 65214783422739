import { fabric } from 'fabric-with-gestures'
import LayerManager from './layer_manager.js'
import Canvas from './canvas.js'

export default class Application {
  // Note: canvasElement is the HTML <canvas> tag.
  constructor(canvasElement, multiplier) {
    this.layerManager = new LayerManager()
    this.canvas = new Canvas(canvasElement, multiplier)
  }

  // Add image to a specific layer (background, overlay etc.)
  addImageToLayer(image, layerName) {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        image,
        object => {
          if (object._element != null) {
            object.scale(1 / this.canvas.multiplier)
            let zIndex = this._insertObject(object, layerName)

            if (zIndex != null) {
              resolve(object)
            } else {
              reject('Failed to save to layer manager and canvas')
            }
          } else {
            reject('Image not found')
          }
        },
        {
          crossOrigin: 'anonymous'
        }
      )
    })
  }

  addWatermark() {
    let rect = new fabric.Rect({
      left: 0,
      top: 150,
      fill: 'grey',
      width: 155,
      height: 43,
      opacity: 0.5
    })

    this._insertObject(rect, 'watermark')

    let text = new fabric.Text('fottorama', {
      left: 10,
      top: 155,
      fontFamily: 'Lato',
      fontSize: 30,
      fontWeight: 'bold',
      fill: 'white'
    })

    this._insertObject(text, 'watermark')
  }

  // Insert object in the layerManager and in the canvas
  _insertObject(object, layerName) {
    let zIndex = this.layerManager.addObjectToLayer(object, layerName)

    if (zIndex != null) {
      this.canvas.insertAt(object, zIndex)
    }

    return zIndex
  }

  // Remove objects from a specific layer in layer manager and canvas
  clearLayer(layerName) {
    let layer = this.layerManager.layerByName(layerName)

    if (layer) {
      while (layer.objects.length > 0) {
        let object = layer.objects[0]

        if (layer.removeObject(object)) {
          this.canvas.remove(object)
        }
      }

      return true
    }

    return false
  }

  // Clear the layers in layer manager and the canvas
  clearCanvas() {
    this.layerManager.clearLayer(this.layerManager.backgroundLayer.name)
    this.layerManager.clearLayer(this.layerManager.photoLayer.name)
    this.layerManager.clearLayer(this.layerManager.overlayLayer.name)
    this.layerManager.clearLayer(this.layerManager.watermarkLayer.name)

    this.canvas.reset()
  }

  // Create an image merging objects from background, overlay and photos layers,
  // hiding the watermark layer
  generateImage(imageFormat, imageQuality) {
    this.layerManager.watermarkLayer.hide()
    let image = this.canvas.toImage({
      format: imageFormat,
      quality: imageQuality,
      transparent: true
    })
    this.layerManager.watermarkLayer.show()

    return image
  }

  // Description: select active layer by id
  setActiveLayer(layerId) {
    this.layerManager.setActiveLayer(layerId)
    // this.canvas.setShouldHandleBoundaries(this.layerManager.isPoseLayerCurrent());
    // this.canvas.clearSelection()
  }

  // Set as active the layer with name layerName
  setActiveLayerByName(layerName) {
    let layerId = this.layerManager.layerId(layerName)

    if (layerId != null) {
      this.setActiveLayer(layerId)
    }
  }
}
