<template lang="pug">
  Step(
    :back="back"
    :back-click="backClick"
    :back-to="backTo"
    :heading="$t('heading')"
    :id="id"
    ok-only
    :paragraph="$t('paragraph')"
    :title="title"
    @back="$emit('back')"
    @ok="handleOk")
    ProfileTypeForm(
      ref="profileTypeForm"
      v-model="localValue"
      @submit="handleSubmit")
</template>

<script>
import ProfileTypeForm from '@/components/flows/forms/ProfileTypeForm'
import Step from '@/layouts/Step'

export default {
  components: {
    ProfileTypeForm,
    Step
  },

  props: {
    back: Boolean,
    backTo: String,
    backClick: Boolean,
    id: String,
    title: {
      type: String,
      default() {
        return this.$t('title')
      }
    },
    value: Boolean
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    handleOk() {
      this.$refs.profileTypeForm.$refs.formWrapper.onSubmit()
    },

    handleSubmit() {
      this.$refs.profileTypeForm.$refs.formWrapper.isSubmitting = false
      this.$emit('ok')
    }
  }
}
</script>

<i18n>
  pt-BR:
    title: "Vamos começar"
    heading: "Que tipo de acervo está criando?"
    paragraph: "Escolha se esta conta será para gerir um acervo pessoal e familiar ou de uma comunidade (escolas, empresas etc.)"
</i18n>
