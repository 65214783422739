<template lang="pug">
  #account-step
    Navbar(
      bordered
      :title="$t('navbar-title')")
      template(#left)
        Button(
          icon="left"
          @onClick="$emit('back')")
    section.container-fluid
      .row
        .col-10.offset-1.col-sm-8.offset-sm-2.col-md-6.offset-md-3.col-lg-4.offset-lg-4
          Icon.icon-sq-xl.txt-secondary.mb-3(
            icon="user")
          h1.instruction-title.mb-3 {{ $t('title') }}
          p.instruction-description.mb-3 {{ $t('description') }}
          .row
            .col
              ValidationObserver(ref="form" v-slot="{ invalid }")
                form
                  AccountFields(v-model="profile")
    br
    br
    br
    Navbar(
      bordered
      color="white"
      fixed="bottom")
      template(#right)
        Button(
          :label="$t('next')"
          type="submit"
          classes="primary"
          @onClick="next")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { forms } from '../../mixins/forms'
import AccountFields from '../forms/AccountFields'
import Button from '../Button'
import Icon from '../Icon'
import Navbar from '../company/Navbar'

export default {
  mixins: [forms], //  isFormValid

  components: {
    ValidationObserver,
    AccountFields,
    Button,
    Icon,
    Navbar
  },

  computed: {
    ...mapGetters({
      totalPrice: 'cart/getTotalPrice'
    }),

    ...mapState({
      hasCollectables: state => state.collectables.total
    }),

    profile: {
      get() {
        return this.$store.state.cart.profile
      },
      set(profile) {
        this.setProfile(profile)
      }
    }
  },

  methods: {
    ...mapActions({
      addSelectedMediaToCart: 'addSelectedMediaToCart',
      setIsFinished: 'cart/setIsFinished',
      setProfile: 'cart/setProfile',
      showMessage: 'ux_controls/showMessage'
    }),

    async next() {
      try {
        await this.isFormValid(this.$refs.form)
        this.addSelectedMediaToCart()
        this.$emit('ok')
      } catch (error) {
        this.showMessage(this.$i18n.t('validation-error'))
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    navbar-title: "Contato"
    title: "Seu contato"
    description: "Usaremos esta informação apenas para garantir que tenha acesso às suas fotos de maneira segura."
    next: "Avançar"
    validation-error: "Há algum problema nas informações fornecidas. Verifique as mensagens de erro em cada campo."
</i18n>

<style lang="sass" scoped></style>
