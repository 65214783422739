import axios from 'axios'
import BaseError from '../errors/base_error.js'

// axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.timeout = 15000 // 15 seconds

export default class Base {
  static async get(url, config = {}) {
    return await Base.perform('get', url, {}, config)
  }

  static async post(url, data = {}, config = {}) {
    return await Base.perform('post', url, data, config)
  }

  static async patch(url, data = {}, config = {}) {
    return await Base.perform('patch', url, data, config)
  }

  static async delete(url, config = {}) {
    return await Base.perform('delete', url, {}, config)
  }

  // https://github.com/axios/axios#request-method-aliases
  static async perform(method, url, data, config) {
    try {
      let response
      switch (method) {
        case 'get':
          response = await axios.get(url, config)
          break
        case 'post':
          response = await axios.post(url, data, config)
          break
        case 'patch':
          response = await axios.patch(url, data, config)
          break
        case 'delete':
          response = await axios.delete(url, config)
          break
      }

      return response.data
    } catch (error) {
      Base._handleErrors(error)
    }
  }

  // Based on https://github.com/axios/axios#handling-errors
  static _handleErrors(error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw new BaseError(error.response.status, true, error.statusText)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      let message = error.code ? error.message + ' (' + error.code + ')' : error.message

      throw new BaseError(499, true, message) // 499: Client Closed Request
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new BaseError(500, true, error.message)
      // 500 Internal Server Error, a generic error message, given when an
      // unexpected condition was encountered and no more specific message is suitable.
    }
  }
}
