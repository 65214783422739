<template lang="pug">
  #people-grid
    .row.align-items-center.mt-4
      .my-3(
        :class="colClass"
        v-for="(personAdapter, index) in peopleAdapter.data"
        :key="personAdapter.data.uid")
        PersonCard(
          :person="personAdapter.data"
          @click="show(personAdapter.data.uid)")
    Pagination(
      v-model="peopleAdapter.currentPage"
      :perPage="peopleAdapter.perPage"
      :totalRows="peopleAdapter.count")
</template>

<script>
import Pagination from '@/components/company/Pagination'
import PersonCard from '@/components/People/PersonCard'

export default {
  components: {
    Pagination,
    PersonCard
  },

  props: {
    colClass: String,
    peopleAdapter: Object
  },

  methods: {
    async show(personId) {
      this.$router.push(`/people/${personId}/edit`)
    }
  }
}
</script>
