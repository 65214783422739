<template lang="pug">
  PeopleGrid(
    col-class="col-6 col-sm-4 col-md-3 col-lg-2"
    :peopleAdapter="peopleAdapter")
</template>

<script>
import PeopleAdapter from '@/adapt/people-adapter'
import PeopleGrid from '@/components/People/PeopleGrid'

export default {
  components: {
    PeopleAdapter,
    PeopleGrid
  },

  data() {
    return {
      peopleAdapter: new PeopleAdapter()
    }
  },

  async beforeMount() {
    await this.peopleAdapter.reset()
    await this.peopleAdapter.load()
  }
}
</script>
