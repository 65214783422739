<template lang="pug">
  span
    input(
      v-show="false"
      :accept="extensions"
      :id="inputId"
      ref="fileInput"
      type="file"
      @change="onFileSelected"
      @click='clear')
    //- If you want to send a custom template to launch the input you have to
    //- give a ref to this (e.g parent) and at @click event you add
    //- $refs.parent.$refs.fileInput.click() to trigger the input click
    slot(
      v-if="hasSlot")
    b-button(
      v-else
      :disabled="disabled"
      :size="size"
      :variant="variant"
      @click="$refs.fileInput.click()") {{ label }}...
</template>

<script>
// import Button from '../Button/ButtonNew'
// import { files } from '../../mixins/files.js'
// import { images } from '../../mixins/images.js'

export default {
  // mixins: [
  //   files,
  //   images
  // ],

  // components: {
  //   Button
  // },

  props: {
    // classes: {
    //   type: String,
    //   default: 'primary'
    // },
    disabled: {
      type: Boolean,
      default: false
    },
    drop: {
      type: Boolean,
      default: false
    },
    extensions: {
      type: String,
      default: 'image/x-png, image/jpeg'
    },
    inputId: String,
    label: {
      type: String,
      default: function() {
        return this.$t('label')
      }
    },
    // multiple: {
    //   type: Boolean,
    //   default: false
    // },
    size: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'primary'
    }
    // size: {
    //   type: Number,
    //   default: 500 * 1024
    // },
    // showProgress: {
    //   type: Boolean,
    //   default: false
    // }
  },

  computed: {
    hasSlot() {
      return this.$slots.default
    }
  },

  methods: {
    async onFileSelected(event) {
      if (event.target.files.length > 0) {
        this.$emit('success', event.target.files[0])

        // let file = new Object()

        // file.file = event.target.files[0]
        // file.url = URL.createObjectURL(file.file)

        // try {
        //   // file.base64 = await this.loadFileReader(file.object)
        //   // If image
        //   // file.image = await this.loadImageFromURL(file.url)
        //   this.$emit('success', file)
        // } catch (err) {
        //   console.log(err)
        //   this.$emit('error')
        // }
      } else {
        this.$emit('error')
      }
    },

    clear() {
      this.$refs.fileInput.value = ''
    }
  }
}
</script>

<i18n>
  pt-BR:
    label: 'Selecionar arquivo'
</i18n>

<style lang="sass" scoped></style>
