import { InMemoryWebStorage, UserManager, WebStorageStateStore } from 'oidc-client'
import uri from '../consts/uri.js'

const settings = {
  // Where the tokens will be stored
  // userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
  // URL to the authentication server (including realm)
  // authority: 'http://localhost:3000/api/oauth/authorize',
  authority: process.env.VUE_APP_FOTTORAMA_ACCOUNTS,
  // The name of the client in Keycloak setup for this service
  metadata: {
    authorization_endpoint: process.env.VUE_APP_FOTTORAMA_ACCOUNTS + '/api/oauth/authorize',
    end_session_endpoint: process.env.VUE_APP_FOTTORAMA_ACCOUNTS + '/users/sign_out', // eslint-disable-next-line
    issuer:
      process.env.VUE_APP_FOTTORAMA_ACCOUNTS, // eslint-disable-next-line
    token_endpoint:
      process.env.VUE_APP_FOTTORAMA_ACCOUNTS + '/api/oauth/token',
    userinfo_endpoint: process.env.VUE_APP_FOTTORAMA_ACCOUNTS + '/api/oauth/userinfo'
  },
  client_id: process.env.VUE_APP_FOTTORAMA_ALBUMS_CLIENT_ID,
  // Where to redirect the user to after successful authentication
  // redirect_uri: 'http://localhost:8080/user/auth/fottorama/callback',
  redirect_uri: process.env.VUE_APP_URL + uri.LOGIN_REDIRECT_URI,
  // Where to redirect the user to after logging the user out
  post_logout_redirect_uri: process.env.VUE_APP_URL + uri.LOGOUT_REDIRECT_URI,
  // Indicate the the authorization code flow should be used
  response_type: 'code'
  // "openid" tells the server that this client uses oidc for authentication
  // scope: 'other', // default: 'openid'
  // silent_redirect_uri: 'http://localhost:8080/settings',
  // Enable automatic (silent) renewal of the access token
  // automaticSilentRenew: true
}

export const userManager = new UserManager(settings)
