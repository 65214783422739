import { userManager } from '../../plugins/oidc-client.js'

// import api from '../../api'
import { cookies } from '../../mixins/cookies.js'
import storageValues from '../../consts/storage.js'

export const getters = {
  getAxiosHeader: state => {
    return {
      headers: {
        Authorization: 'Bearer ' + state.accessToken
      }
    }
  }
}

export const mutations = {
  setAccessToken: (state, accessToken) => {
    state.accessToken = accessToken
  },
  setIsLoggedIn: (state, isLoggedIn) => {
    state.isLoggedIn = isLoggedIn
  },
  setProfile: (state, profile) => {
    state.profile.email = profile.email
    state.profile.id = profile.sub // 'sub' is the user_id on the backend
  },
  setStorageCredentialAlbum: (state, presignedPost) => {
    state.storageCredential.album = presignedPost
  },
  setStorageCredentialSelfie: (state, presignedPost) => {
    state.storageCredential.selfie = presignedPost
  }
}

export const actions = {
  // getStorageCredentialSelfie: context => {
  //   // context.commit('setStorageCredentialSelfie', presignedPost)
  // },
  login: (context, redirectURI) => {
    localStorage.setItem(storageValues.LOGIN_REDIRECT_URI, redirectURI)
    context.state.userManager.signinRedirect().catch(error => console.log(error))
  },
  logout: context => {
    // Delete the cookie
    cookies.methods.setCookie(storageValues.IS_LOGGED_IN, false, 0)
    context.state.userManager.signoutRedirect().catch(error => console.log(error))
  },
  handleLoginRedirect: context => {
    return new Promise((resolve, reject) => {
      context.state.userManager
        .signinRedirectCallback()
        .then(user => {
          if (user.access_token) {
            context.commit('setAccessToken', user.access_token)
            context.commit('setIsLoggedIn', true)
            context.commit('setProfile', user.profile)
            cookies.methods.setCookie(storageValues.IS_LOGGED_IN, true)
            resolve()
          } else {
            context.commit('setIsLoggedIn', null)
            reject()
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  handleLogoutRedirect: context => {
    return new Promise((resolve, reject) => {
      context.state.userManager
        .signoutRedirectCallback()
        .then(() => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },
  setAccessToken: (context, accessToken) => {
    context.commit('setAccessToken', accessToken)
  },
  setIsLoggedIn: (context, isLoggedIn) => {
    context.commit('setIsLoggedIn', isLoggedIn)
  },
  setStorageCredentialAlbum: (context, presignedPost) => {
    context.commit('setStorageCredentialAlbum', presignedPost)
  },
  setStorageCredentialSelfie: (context, presignedPost) => {
    context.commit('setStorageCredentialSelfie', presignedPost)
  }
}

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    userManager: userManager,
    accessToken: null,
    profile: {
      email: null,
      id: null
    },
    storageCredential: {
      album: null,
      selfie: null
    }
  },
  getters,
  mutations,
  actions
}
