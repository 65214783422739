<template lang="pug">
  .container-fluid
    .row.align-items-center
      .gallery-col.col-4.col-md-3.col-lg-2(
        v-for="(medium, index) in media[pageNumber]"
        :key="'d-' + pageNumber + '-' + index + '-' + medium.uid")
        GalleryThumbnail(
          :key="'m-' + pageNumber + '-' + index + '-' + medium.uid"
          :index="index"
          :medium="medium"
          @click="handleClick"
          @zoom="handleZoom")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GalleryThumbnail from './GalleryThumbnail'

export default {
  components: {
    GalleryThumbnail
  },

  computed: {
    ...mapState({
      media: state => state.gallery.content.media,
      pageNumber: state => state.gallery.pageNumber,
      isSelecting: state => state.gallery.selection.active,
      isShiftPressed: state => state.ux_controls.isShiftPressed
    })
  },

  methods: {
    ...mapActions({
      selectRangeFromLastTo: 'gallery/selectRangeFromLastTo',
      updateMediumByIndex: 'gallery/updateMediumByIndex',
      toggleSelectionItem: 'gallery/toggleSelectionItem'
    }),

    handleClick(index) {
      if (this.isSelecting) {
        if (!this.isShiftPressed) {
          this.toggleSelectionItem(index) // Add to or remove from selection
        } else {
          this.selectRangeFromLastTo(index)
        }
      } else {
        this.updateMediumByIndex(index) // Preview medium
      }
    },

    // Allow the user to preview a medium while selecting
    handleZoom(index) {
      this.updateMediumByIndex(index)
    }
  }
}
</script>

<style lang="sass" scoped>
.gallery-col
  padding: 2px
</style>
