import api from '../../api'

export const mutations = {
  clearOrders: state => {
    state.orders.splice(0, state.orders.length)
    state.total = 0
  },
  setCurrentOrder: (state, response) => {
    state.current = {}
    state.current.archive = response.archive
    state.current.createdAt = response.created_at
    state.current.email = response.email
    state.current.items = response.items
    state.current.name = response.name
    state.current.paidAt = response.paid_at
    state.current.totalCents = response.total_cents
    state.current.id = response.uid
  },
  setCurrentPage: (state, page) => {
    state.currentPage = page
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading
  },
  setOrders: (state, orders) => {
    state.orders = orders
  },
  setTotal: (state, total) => {
    state.total = total
  }
}

export const actions = {
  loadOrder: (context, orderId) => {
    context.commit('setIsLoading', true)

    return new Promise((resolve, reject) => {
      api.orders
        .show(orderId)
        .then(response => {
          context.commit('setCurrentOrder', response)
          resolve()
        })
        .catch(error => {
          context.commit('setIsLoading', false)
          reject(error)
        })
    })
  },
  loadOrders: (context, page = context.state.currentPage) => {
    context.commit('setIsLoading', true)

    return new Promise((resolve, reject) => {
      api.orders
        .get({
          page: page,
          per_page: context.state.settings.perPage
        })
        .then(response => {
          context.commit('setOrders', response.orders)
          context.commit('setTotal', response.total)
          context.commit('setIsLoading', false)
          resolve()
        })
        .catch(error => {
          context.commit('clearOrders')
          context.commit('setIsLoading', false)
          reject(error)
        })
    })
  },
  setCurrentPage: (context, page) => {
    context.commit('setCurrentPage', page)
  }
}

export default {
  namespaced: true,
  state: {
    orders: [], // orders of the current page
    total: 0,
    current: null,
    currentPage: 1,
    isLoading: false,
    settings: {
      perPage: 48
    }
  },
  mutations,
  actions
}
