import api from '../../api'
import { files } from '../files.js'
import { images } from '../images.js'
import store from '../../store'

export const albums = {
  methods: {
    async createAlbum(album) {
      return await api.albums.create(this.getAlbumParams(album))
    },

    // params: { filename, kind: 'photo', url }
    async createAlbumMedium(albumId, params) {
      return await api.albums.createAlbumMedium(albumId, params)
    },

    // Get { url, filename } to download the medium
    async downloadAlbumMedium(albumId, mediumId) {
      return await api.albums.mediumUrlShow(albumId, mediumId)
    },

    async deleteAlbum(uid) {
      await api.albums.delete(uid)
      this.$router.push('/')
    },

    // Translate from frontend object to backend object
    getAlbumParams(album) {
      return album
        ? {
            description: album.description ? album.description : null,
            name: album.name,
            recognition: album.faceRec,
            starts_at: album.startsAt ? album.startsAt : null,
            visibility: album.visibility,
            watermark: album.watermark
          }
        : {}
    },

    async getAlbumMedium(albumId, mediumId) {
      return await api.albums.mediumShow(albumId, mediumId)
    },

    // Store image on Amazon S3
    async storeAlbumMedium(albumId, file, callback) {
      let base64 = await files.methods.loadFileReader(file)
      let filename = files.methods.getUuidName(file)
      let overwrite = false // Do not overwrite previous album medium

      try {
        return await api.s3.create(
          filename,
          images.methods.imageBlob(base64),
          store.state.auth.storageCredential.album,
          overwrite,
          callback
        )
      } catch (error) {
        // In case of a S3 error, ask for a new storage credential
        await this.getStorageCredentialAlbum(albumId)

        return await api.s3.create(
          filename,
          images.methods.imageBlob(base64),
          store.state.auth.storageCredential.album,
          overwrite,
          callback
        )
      }
    },

    async getStorageCredentialAlbum(albumId) {
      try {
        let presignedPost = await api.storage_credentials.getForAlbum(albumId)
        store.dispatch('auth/setStorageCredentialAlbum', presignedPost)
      } catch (err) {
        store.dispatch('auth/setStorageCredentialAlbum', null)
      }
    },

    async updateAlbum(albumId, params) {
      // Recognition and watermark cannot be changed after creation
      store.dispatch('album/setDescription', params.description)
      store.dispatch('album/setName', params.name)
      // TODO: update to visibility
      store.dispatch('album/setPublicRead', params.publicRead)
      store.dispatch('album/setStartsAt', params.startsAt)

      return await api.albums.update(albumId, this.getAlbumParams(params))
    }
  }
}
