<!-- The navbar was removed and replaced by GalleryNav. The 'select' and
'filter' options were removed for now because they are not essential to the
school use case. The 'select' option is working properly but the filter is not
working. So even if returns, it should be reviewed and implemented according to
the current stage of the code -->
<template lang="pug">
  #gallery
    //- Navbar.top-menu.mb-1
      template(v-slot:left)
        span.text-muted.ml-3 {{ mediaAdapter.count }} {{ $tc('media', mediaAdapter.count) }}
      template(v-slot:right)
        Button(
          v-if="canSelect"
          @click="toggleIsSelecting")
          div(v-if="!isSelecting")
            label {{ $t('select') }}
          div(v-else)
            label {{ $t('cancel') }}
        Button(
          v-if="canFilter"
          @click="$bvModal.show('filter-modal')")
          label {{ $t('filter') }}
    //- FilterModal(modalId="filter-modal")
    PreviewModal(
      modalId="preview-modal"
      :mediumAdapter="mediumAdapter"
      :canSelect="canSelect"
      :canFilter="canFilter"
      :canDownload="canDownload"
      :current-tab-data="currentTabData"
      :isFirstMedium="isFirstMedium"
      :isLastMedium="isLastMedium"
      :resource="mediaAdapter.resource"
      @select="selectMediumFromPreview"
      @filter="filterByFace"
      @toNextMedium="toNextMedium"
      @toPreviousMedium="toPreviousMedium")
    .row.no-gutters.align-items-center.mx-1
      .col-4.col-md-3.col-lg-2(
        v-for="mediumAdapter in mediaAdapter.data"
        :key="mediumAdapter.data.uid")
        Thumbnail.p-1(
          :mediumAdapter="mediumAdapter"
          :isSelecting="isSelecting"
          @select="selectMediumFromThumbnail"
          @preview="previewMedium")
    Pagination.mb-5(
      v-model="mediaAdapter.currentPage"
      :perPage="mediaAdapter.perPage"
      :totalRows="mediaAdapter.count")
    br
    //- StatusBar(:isOpen="isSelecting")
      .d-flex.align-items-center.flex-even
        slot(name="statusBarLeft")
      .d-flex.align-items-center.justify-content-center.flex-even
        span {{ $tc('selected', selection.length, { count: selection.length }) }}
      .d-flex.align-items-center.justify-content-end.flex-even
        slot(name="statusBarRigh")
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import keyCodes from '@/consts/keyCodes.js'
import Thumbnail from '@/components/Shared/Gallery/Thumbnail'
import PreviewModal from '@/components/Shared/Gallery/PreviewModal'

// import Button from '@/components/general/Button/ButtonNew'
// import FilterModal from '@/components/application/Gallery/GalleryFilterModal'
// import Navbar from '@/components/application/Navbar'
import Pagination from '@/components/company/Pagination'
// import StatusBar from '@/components/general/StatusBar'

@Component({
  components: {
    Thumbnail,
    PreviewModal,
    Pagination,
    // StatusBar,
    // FilterModal,
    // Button,
    // Navbar
  },
  props: {
    canSelect: Boolean,
    canFilter: Boolean,
    canDownload: Boolean,
    currentTabData: Object,
    mediaAdapter: Object,
    selection: Object,
  },
  watch: {
    keydownEvent() {
      this.onKeydownEvent()
    },
    media() {
      this.onMediaLoaded()
    }
  },
  computed: {
    ...mapState({
      isShiftPressed: state => state.ux_controls.isShiftPressed,
      keydownEvent: state => state.ux_controls.keydownEvent
    })
  }
})
class Gallery extends Vue {
  mediumIndex = 0
  isSelecting = false
  isLoadingPreviousMedium = false
  isLoadingNextMedium = false

  get media() {
    return this.mediaAdapter.data
  }
  // Returns an albumMediumAdapter
  get mediumAdapter() {
    return this.mediaAdapter.data.length > 0
      ? this.mediaAdapter.data[this.mediumIndex]
      : null
  }

  get isFirstMedium() {
    return this.mediaAdapter.currentPage == 1 && this.mediumIndex == 0
  }

  get isLastMedium() {
    return (
      this.mediaAdapter.currentPage * this.mediaAdapter.perPage >= this.mediaAdapter.count &&
      this.mediaAdapter.data.length - 1 == this.mediumIndex
    )
  }

  beforeMount() {
    this.onMediaLoaded()
  }

  onKeydownEvent() {
    if (this.keydownEvent.keyCode == keyCodes.ESC && this.isSelecting) {
      this.toggleIsSelecting()
    }
    if (this.keydownEvent.keyCode == keyCodes.A) {
      let isMac = window.navigator.platform.match('Mac')
      let meta = isMac ? this.keydownEvent.metaKey : this.keydownEvent.ctrlKey
      if (this.isSelecting && meta) {
        this.keydownEvent.preventDefault()
        this.selectRange(0, this.mediaAdapter.data.length - 1)
      }
    }
  }

  onMediaLoaded() {
    this.mediaAdapter.data.forEach(ma => {
      let selected = this.selection.contains(ma.data.uid)
      this.$set(ma, 'selected', selected) // make selected attribute reactive
    })
    if (this.isLoadingNextMedium) {
      this.isLoadingNextMedium = false
      this.mediumIndex = 0
    }
    if (this.isLoadingPreviousMedium) {
      this.isLoadingPreviousMedium = false
      this.mediumIndex = this.mediaAdapter.perPage - 1
    }
  }

  toggleIsSelecting() {
    this.isSelecting = !this.isSelecting
    if (!this.isSelecting) {
      this.clearSelection()
    }
  }

  selectMediumFromThumbnail(mediumAdapter) {
    if (this.isSelecting) {
      if (!this.isShiftPressed) {
        let mediumUid = mediumAdapter.data.uid
        mediumAdapter.selected = !mediumAdapter.selected
        mediumAdapter.selected
          ? this.selection.add(mediumUid)
          : this.selection.delete(mediumUid)
      } else {
        let indexes = []
        indexes.push(this.mediaAdapter.data.indexOf(mediumAdapter))
        indexes.push(this.mediaAdapter.data.findIndex(ma => ma.selected))
        indexes.push(this.mediaAdapter.data.map(ma => ma.selected).lastIndexOf(true))
        this.selectRange(Math.min(...indexes), Math.max(...indexes))
      }
    }
  }

  selectMediumFromPreview(mediumAdapter) {
    this.isSelecting = true
    this.selectMediumFromThumbnail(mediumAdapter)
  }

  previewMedium(mediumAdapter) {
    this.mediumIndex = this.mediaAdapter.data.findIndex(ma => ma == mediumAdapter)
    this.$bvModal.show('preview-modal')
  }

  filterByFace(face) {
    this.$emit('filterByFace', face)
  }

  toNextMedium() {
    if (this.mediumIndex == this.mediaAdapter.data.length - 1) {
      let count = this.mediaAdapter.perPage * this.mediaAdapter.currentPage + this.mediumIndex - 1
      if (count != this.mediaAdapter.count) {
        this.mediaAdapter.currentPage += 1
        this.isLoadingNextMedium = true
      }
    } else {
      this.mediumIndex += 1
    }
  }

  toPreviousMedium() {
    if (this.mediumIndex == 0) {
      if (this.mediaAdapter.currentPage != 1) {
        this.mediaAdapter.currentPage -= 1
        this.isLoadingPreviousMedium = true
      }
    } else {
      this.mediumIndex -= 1
    }
  }

  clearSelection() {
    this.isSelecting = false
    this.selectRange(-1, -1) // clear current page
    this.selection.clear()
  }

  selectRange(min, max) {
    this.mediaAdapter.data.forEach((ma, i) => {
      if (i >= min && i <= max) {
        ma.selected = true
        this.selection.add(ma.data.uid)
      } else {
        ma.selected = false
        this.selection.delete(ma.data.uid)
      }
    })
  }
}
export default Gallery
</script>

<i18n>
  pt-BR:
    cancel: "Cancelar"
    filter: "Filtrar"
    media: "foto | fotos"
    select: "Selecionar"
    selected: "Nenhum selecionado | 1 selecionado | {count} selecionados"
</i18n>

<style lang="sass" scoped>
#gallery
  // Cancel BaseView paddings
  margin: 0 -15px 0 -15px

.flex-even
  flex: 1
</style>
