/* eslint-disable */
const apiClassMethods = {
  async apiFetch({ url = null, persistBy = 'insertOrUpdate', save = true } = {}) {
    try {
      let config = { headers: this.authHeaders, persistBy: persistBy, save: save }
      return (await this.api().get(url, config)).response
    } catch (error) {
      this._handleErrors(error) // From errorsClassMethods
    }
  },

  async apiList({ url = null, persistBy = 'insertOrUpdate', save = true } = {}) {
    try {
      let config = { headers: this.authHeaders, persistBy: persistBy, save: save }
      return (await this.api().get(url, config)).response
    } catch (error) {
      this._handleErrors(error) // From errorsClassMethods
    }
  }
}

const apiInstanceMethods = {
  async apiCreate({ url = null } = {}) {
    try {
      let cls = this.constructor
      let data = { [cls.name]: this.$toJson() }
      let config = { headers: cls.authHeaders, save: false }
      return (await cls.api().post(url, data, config)).response
    } catch (error) {
      this._handleErrors(error) // From errorsInstanceMethods
      return false
    }
  },

  async apiUpdate({ url = null } = {}) {
    try {
      let cls = this.constructor
      let data = { [cls.name]: this.$toJson() }
      let config = { headers: cls.authHeaders, save: false }
      await cls.api().patch(url, data, config)
      return true
    } catch (error) {
      this._handleErrors(error) // From errorsInstanceMethods
      return false
    }
  }
}

export { apiClassMethods, apiInstanceMethods }
