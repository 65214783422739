<template lang="pug">
  Modal(
    :show="show")
    template(v-slot:header)
      //- PostMenu emits 'close' when clicking on 'x'
      PostMenu(
        @close="close")
    //- PostPreview emits 'close' when filtering by face
    PostPreview(
      @close="close")
    //- template(v-slot:footer)
    //-   .blank-footer.bg-primary
    //-     span teste footer
</template>

<script>
import Modal from '../../general/Modal'
import PostMenu from './PostMenu'
import PostPreview from './PostPreview'

export default {
  components: {
    Modal,
    PostMenu,
    PostPreview
  },

  props: {
    show: Boolean
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="sass" scoped>
.blank-footer
  height: 46px
</style>
