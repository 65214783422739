<template lang="pug">
  span#profile-fields
    AvatarInput(
      :action="action"
      :name="value.nameFor('avatar')"
      :personal="personal"
      :rules="action == 'new' ? 'required' : null"
      v-model="value.avatar")
    PersonNameInput(
      v-if="fields.indexOf('name') > -1"
      :label="value.labelFor('name')"
      :name="value.nameFor('name')"
      v-model="value.name"
      :full="!personal")
    UsernameInput(
      v-if="fields.indexOf('user_name') > -1"
      :description="$t('username-description')"
      :label="value.labelFor('user_name')"
      :name="value.nameFor('user_name')"
      rules="required|alpha_num"
      v-model="value.user_name")
    PhoneInput(
      v-if="fields.indexOf('phone') > -1"
      :label="value.labelFor('phone')"
      :name="value.nameFor('phone')"
      rules="required"
      v-model="value.phone")
    TextArea(
      v-if="fields.indexOf('description') > -1"
      :name="value.nameFor('description')"
      :label="value.labelFor('description')"
      v-model='value.description')
</template>

<script>
import AvatarInput from '@/components/inputs/AvatarInput'
import PersonNameInput from '@/components/flows/inputs/PersonNameInput'
import PhoneInput from '@/components/inputs/PhoneInput'
import TextArea from '@/components/inputs/TextArea'
import UsernameInput from '@/components/inputs/UsernameInput'

export default {
  components: {
    AvatarInput,
    PersonNameInput,
    PhoneInput,
    TextArea,
    UsernameInput
  },

  props: {
    action: String, // 'edit', 'new'
    fields: Array,
    personal: Boolean,
    value: Object // MyProfileAdapter.data
  }
}
</script>

<i18n>
  pt-BR:
    username-description: "Insira apenas letras e números, sem espaços ou símbolos."
</i18n>
