import BaseAdapter from '@/adapt/base-adapter'

import PersonStorageCredentials from '@/models/person_storage_credentials'

export default class PersonStorageCredentialsAdapter extends BaseAdapter {
  static model = PersonStorageCredentials
  static persistBy = 'create'

  get url() {
    return '/api/v1/people/storage_credentials'
  }
}
