<template lang="pug">
  #selections
    SelectionsHomeMenu(
      @back="leave"
      @select="handleMenuOption")
    SelectionList
    //- Footer
</template>

<script>
import { mapActions } from 'vuex'
import SelectionList from '../components/application/Selection/SelectionList'
import SelectionsHomeMenu from '../components/application/Selection/SelectionsHomeMenu'

export default {
  name: 'SelectionsHome',

  components: {
    SelectionsHomeMenu,
    SelectionList
  },

  methods: {
    ...mapActions({
      loadSelections: 'selection/loadLibrary',
      setCurrentPage: 'selection/setCurrentPage'
    }),

    handleMenuOption(value) {
      switch (value) {
        case 'create':
          console.log('create')
          break
        case 'delete':
          console.log('delete')
          break
      }
    },

    leave() {
      this.setCurrentPage(1)
      this.$router.push('/')
    }
  },

  mounted() {
    this.loadSelections()
  }
}
</script>

<style lang="sass" scoped></style>
