<template lang="pug">
  button.btn(
    @click.stop="click")
    .label-box
      slot
</template>

<script>
export default {
  methods: {
    click: function() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../../assets/stylesheets/_variables.sass'

.btn
  text-transform: uppercase
  padding: 0
  opacity: 0.9

.btn:hover,
.btn:focus
  opacity: 1

.label-box
  display: flex
  margin: 10px

.primary
  background-color: $primary-color !important // Because of Bootstrap
  color: white

.primary:hover
  color: white

label
  margin: 0 7px
  cursor: pointer
  font-weight: bolder

// .dark-circle
//   background-color: rgba(0, 0, 0, 0.2)
//   border-radius: 50%
</style>
