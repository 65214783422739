import { fabric } from 'fabric-with-gestures'

const Canvas = fabric.util.createClass(fabric.Canvas, {
  // Edited: added multiplier in order to fit the canvas for desktops and mobile devices.
  initialize(canvasElement, multiplier) {
    this.callSuper('initialize', canvasElement)
    this.multiplier = multiplier
    this.backgroundColor = '#ffffff'
    this.canvasElement = canvasElement

    this.canvasElement.style.cssText = 'box-shadow: 0 0 10px #bbb'
    // So the watermark stays on top even when the photo is selected
    this.preserveObjectStacking = true
    this.setBackgroundColor(this.backgroundColor)
  },

  // Set width and height of the canvas
  setCanvasDimensions(width, height) {
    this.setWidth(width)
    this.setHeight(height)

    this.canvasElement.width = width
    this.canvasElement.height = height

    this.dimensions = { width: width, height: height }

    this.requestRenderAll()
  },

  // Edited: removed focusing and added perPixelTargetFind = true
  // TODO: create an object class with perPixelTargetFind = true
  insertAt(object, index) {
    object.perPixelTargetFind = true
    this.callSuper('insertAt', object, index)
    // this.trigger('object:inserted', { target: object, kind: 'inserted' })
    // this.setActiveObject(object)
  },

  remove(object) {
    this.callSuper('remove', object)
    this.trigger('object:deleted', { target: object, kind: 'deleted' })
  },

  clearSelection() {
    this.discardActiveObject()
    this.requestRenderAll()
  },

  // Get the canvas back to its initial state
  reset() {
    this.clear()
    this.requestRenderAll()
  },

  // Returns true if the pose is totally contained in the canvas boundaries
  // The pose is based on real canvas witdth and height
  isPoseValid(pose) {
    let realWidth = this.width * this.multiplier
    let realHeight = this.height * this.multiplier

    return (
      pose.x > 0 &&
      pose.x < realWidth &&
      pose.y > 0 &&
      pose.y < realHeight &&
      pose.x + pose.width <= realWidth &&
      pose.y + pose.height <= realHeight
    )
  },

  // Centralizes the object according to rect's center and expand it untill it
  // reaches the rectangle's boundaries
  fitObjectToRectangle(object, rect) {
    if (object && rect) {
      let objectAspectRatio = object.width / object.height
      let rectAspectRatio = rect.width / rect.height

      // Fitted object hits the vertical borders (left and right)
      if (objectAspectRatio > rectAspectRatio) {
        object.scaleToWidth(rect.width / this.multiplier)
        // eslint-disable-next-line
        let top = (rect.height - object.getScaledHeight() * this.multiplier) / 2 + rect.y

        object.set({
          top: top / this.multiplier,
          left: rect.x / this.multiplier
        })
      }
      // Fitted object hits the horizontal borders (top and bottom)
      else {
        object.scaleToHeight(rect.height / this.multiplier)
        // eslint-disable-next-line
        let left = (rect.width - (object.getScaledWidth() * this.multiplier))/2 + rect.x

        object.set({
          top: rect.y / this.multiplier,
          left: left / this.multiplier
        })
      }
    }
  },

  // Edited: removed guidelines
  toImage(options) {
    this._beforeToImage(options)

    let data = this.toDataURL({
      format: options.format,
      quality: options.quality, // only used for jpeg
      multiplier: options.multiplier || this.multiplier,
      width: this.dimensions.width,
      height: this.dimensions.height
    })

    this._afterToImage()
    return data
  },

  _beforeToImage(options) {
    if (options.transparent) {
      this.setBackgroundColor(null)
    }
    this.requestRenderAll()
  },

  _afterToImage() {
    this.setBackgroundColor(this.backgroundColor)
    this.requestRenderAll()
  }
})

export default Canvas
