<template lang="pug">
  Link(:link="link")
    .container
      .cover(:style="cover")
      .visibility-icon
        Icon.text-white.black-shadow(
          :icon="visibilityIcon")
      p {{ album.name }}
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import Icon from '@/components/Icon'
import Link from '@/components/general/Link'

@Component({
  components: {
    Icon,
    Link
  },
  props: {
    album: Object // AlbumAdapter.data
  }
})
class Thumbnail extends Vue {
  get cover() {
    if (this.album.cover) {
      return `background-image: url(${this.album.cover})`
    } else {
      return 'background-color: grey'
    }
  }

  get link() {
    return `/albums/${this.album.uid}`
  }

  get visibilityIcon() {
    switch (this.album.visibility) {
      case 'private':
        return 'private'
      case 'public':
        return 'public'
      case 'community':
        return 'users'
      default:
        return 'question'
    }
  }
}

export default Thumbnail
</script>

<style lang="sass" scoped>
.container
  position: relative
  .cover
    width: 100%
    border-radius: 8px
    padding-bottom: 100%
    background-size: cover
    background-position: center
.visibility-icon
  position: absolute
  top: 5px
  left: 25px
</style>
