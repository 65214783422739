<template lang="pug">
  button.btn(v-if='!loading'
    @click="onClick()"
    ref='originalButton'
    :class="classes")
    .label-box(:class="labelClasses")
      //- Icon(
        v-if="icon"
        :icon="icon"
        :classes="iconClasses")
      strong.label-text(v-show="label") {{ label }}
      Icon(
        v-if="icon"
        :icon="icon"
        :classes="iconClasses")
  button.btn(v-else
    :class="classes"
    :style='loadingButtonWidth')
    .label-box(:class="labelClasses")
      span.mx-auto
        i(class='loading-icon fas fa-spinner fa-spin')
</template>

<script>
import Icon from './Icon.vue'

export default {
  components: {
    Icon
  },

  data() {
    return {
      width: Number
    }
  },

  props: {
    classes: String,
    icon: String,
    iconClasses: String,
    label: String,
    labelClasses: String,
    loading: Boolean
  },

  computed: {
    loadingButtonWidth() {
      if (this.$refs.originalButton != undefined) {
        // 2 px are added in order to get the exact original button width
        return 'width: ' + (this.$refs.originalButton.clientWidth + 2) + 'px'
      }

      return 0
    }
  },

  methods: {
    onClick: function() {
      this.$emit('onClick')
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/stylesheets/_variables.sass'

.btn
  text-transform: uppercase
  padding: 0
  z-index: 1 // click priority
  opacity: 0.9

.btn:hover,
.btn:focus
  opacity: 1

.label-box
  display: flex
  margin: 10px

// For left menu button
.no-left-margin
  margin: 10px 10px 10px 0

.label-text
  margin: 0 10px

.primary
  background-color: $primary-color !important // Because of Bootstrap
  color: white

.primary:hover
  color: white

.btn-size-lg
  padding: 5px

.btn-size-xl
  padding: 10px

.loading-icon
  font-size: 20px
</style>
