import Vue from 'vue'
import VueI18n from 'vue-i18n'

import Base from '@/i18n/dictionaries/base.pt-BR.json'
import ModelAlbum from '@/i18n/dictionaries/model.album.pt-BR.json'
import ModelOrder from '@/i18n/dictionaries/model.order.pt-BR.json'
import ModelPerson from '@/i18n/dictionaries/model.person.pt-BR.json'
import ModelProfile from '@/i18n/dictionaries/model.profile.pt-BR.json'

Vue.use(VueI18n)

const pt_BR = Base
pt_BR['model'] = {
  ...ModelAlbum,
  ...ModelOrder,
  ...ModelPerson,
  ...ModelProfile
}

export default new VueI18n({
  locale: 'pt-BR',
  messages: {
    'pt-BR': pt_BR
  },
  silentFallbackWarn: true
})
