/* eslint-disable */
export default class BasePaginatedCollectionAdapter {
  constructor({ parent = null, page = 1, perPage = 30, params = '' } = {}) {
    this.parent = parent
    this.page = page
    this.perPage = perPage
    this.params = params
    this.loading = false
    this.data = []
    this.count = 0
    this.loadedFromApi = false
  }

  loadFromStore() {
    let entries = this._loadEntries()
    let cls = this.constructor
    if (entries) {
      if (cls.targetAdapter) {
        this.data = entries.map(entry => new cls.targetAdapter({ data: entry, parent: this }))
      } else {
        this.data = entries
      }
    }
    this.loadedFromApi = false
  }

  async loadFromApi({ params = null } = {}) {
    let cls = this.constructor
    this.loading = true
    this.loadedFromApi = true
    await this._setParams(params)
    let response = await cls.targetModel.apiList({ url: this._url, save: false })
    if (response && response.data) {
      await this._persist(response.data)
    }
    this.loadFromStore()
    this.loading = false
  }

  async load({ params = null } = {}) {
    if (this._shouldLoadFromApi(params)) {
      await this.loadFromApi({ params: params })
    } else {
      this.loadFromStore()
    }
  }

  async reset() {
    this._delete()
    await this._setCount(null)
    this.data = []
    this.page = 1
  }

  get currentPage() {
    return this.page
  }

  set currentPage(value) {
    if (this.page != value) {
      this.page = value
      this.load()
    }
  }

  _shouldLoadFromApi(params) {
    if (this._hasParamsChanged(params)) {
      return true
    }

    if (this._count == null) {
      return true
    }

    let pageCount = this.parent ? this._queryHasMany.count() : this._queryBase.count()
    return pageCount < this.perPage && (this.page - 1) * this.perPage + pageCount < this._count
  }

  // params

  _hasParamsChanged(params) {
    return params && this.params != params
  }

  async _setParams(params) {
    if (this._hasParamsChanged(params)) {
      this.params = params
      this.reset()
    }
  }

  // count

  async _setCount(value) {
    if (this.parent) {
      this.parent.data = await this.parent.constructor.model.update({
        where: this._pId, data: { [this._countField]: value }
      })
    }
    this.count = value ?? 0
  }

  get _count() {
    if (this.parent) {
      return this.parent.data[this._countField]
    } else {
      return this.data == [] ? null : this.count
    }
  }

  // persist

  async _persist(data) {
    let cls = this.constructor
    if (this._beforePersist != undefined) {
      await this._beforePersist(data)
    }
    this._beforePersistInner(data)
    cls.targetModel.insertOrUpdate({ data: data[cls.property] })
    await this._setCount(data.total)
    this._afterPersistInner(data)
    if (this._afterPersist != undefined) {
      await this._afterPersist(data)
    }
  }

  _beforePersistInner(data) {
    let cls = this.constructor
    let count = (this.page - 1) * this.perPage
    if (!this.parent) {
      data[cls.property].forEach((entry, i) => entry[this._orderField] = count + i)
    }
    if (this.parent && cls.model == cls.targetModel) {
      data[cls.property].forEach((entry, i) => {
        entry[this._pForeignKey] = this._pId
        entry[this._orderField] = count + i
      })
    }
  }

  _afterPersistInner(data) {
    let cls = this.constructor
    if (this.parent && cls.model != cls.targetModel) {
      let count = (this.page - 1) * this.perPage
      let inputData = data[cls.property].map((entry, i) => {
        let obj = {}
        // If the parent has a composite primary key
        if (this._pId.constructor === Array) {
          this._pPrimaryKey.forEach((item, j) => {
            obj[item] = this._pId[j]
          })
        } else {
          obj[this._pForeignKey] = this._pId
        }
        obj[this._tForeignKey] = entry[this._tPrimaryKey]
        obj[this._orderField] = count + i
        return obj
      })
      cls.model.insertOrUpdate({ data: inputData })
    }
  }

  // delete

  _delete() {
    let cls = this.constructor
    if (this.parent) {
      cls.model.delete(entry => entry[this._pForeignKey] == this._pId)
    } else {
      cls.model.deleteAll()
    }
  }

  // query

  _loadEntries() {
    if (!this.loadedFromApi) {
      this.count = this._count
    }
    return this._query.get()
  }

  get _query() {
    let cls = this.constructor
    if (this.parent) {
      return cls.model == cls.targetModel
        ? this._queryHasMany // if the key is within the same table (targetModel == model)
        : this._queryHasManyThrough // if there's a table for the relationship (targetModel != model)
    } else {
      return this._queryBase
    }
  }

  get _queryHasMany() {
    // If the parent has a composite primary key
    if (this._pId.constructor === Array) {
      return this._whereAllRecursive(this._pPrimaryKey, this._pId, 0)
    }
    return this._queryBase.where(this._pForeignKey, (value) => value == this._pId)
  }
  // Apply 'where' clause to all keys within a composite key
  _whereAllRecursive(labels, values, i) {
    if (i == labels.length) {
      return this._queryBase
    }

    return this._whereAllRecursive(labels, values, i + 1).where(labels[i], values[i])
  }

  get _queryHasManyThrough() {
    let relEntries = this._queryHasMany.get()
    return this.constructor.targetModel.query().withAllRecursive().whereId(relEntries.map(i => i[this._tForeignKey]))
  }

  get _queryBase() {
    let query = this.constructor.model.query().withAllRecursive().orderBy(this._orderField)
    return this._paginate(query)
  }

  _paginate(query) {
    let start = (this.page - 1) * this.perPage
    let end = start + this.perPage - 1
    return query.where(this._orderField, (order) => order >= start && order <= end)
  }

  // helpers

  get _orderField() {
    let cls = this.constructor
    if (this.parent && cls.model == cls.targetModel) {
      return `${this.parent.constructor.model.name}_order`
    }
    return 'order'
  }

  get _url() {
    return `${this.url}?page=${this.page}&per_page=${this.perPage}&${this.params}`
  }

  get _countField() {
    return `${this.constructor.targetModel.name}_count`
  }

  get _pId() {
    let id = this.parent.data.$id
    try {
      id = JSON.parse(id)
    } catch(err) {}

    return id
  }

  get _pPrimaryKey() {
    return this.parent.constructor.model.primaryKey
  }

  get _pForeignKey() {
    return this.parent.constructor.model.foreignKey
  }

  get _tPrimaryKey() {
    return this.constructor.targetModel.primaryKey
  }

  get _tForeignKey() {
    return this.constructor.targetModel.foreignKey
  }

}
