<template lang="pug">
  fieldset
    TextInput#selection-name-field(
      :label="label"
      name="selection-name"
      type="text"
      v-model="value.name")
    Icon(
      v-if="isCreating"
      icon="loading")
</template>

<script>
import { selections } from '../../mixins/resources/selections.js'
import Icon from '../Icon'
import TextInput from '../inputs/TextInput'

export default {
  mixins: [selections],

  components: {
    Icon,
    TextInput
  },

  props: {
    isCreating: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: function() {
        return this.$t('create-selection')
      }
    },
    value: {
      name: '',
      uid: ''
    }
  },

  watch: {
    async isCreating() {
      if (this.isCreating) {
        let response = await this.createSelectionByName(this.value.name)
        this.$emit('done', response.uid)
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    create-selection: 'Nome da seleção'
</i18n>

<style lang="sass"></style>
