/* eslint-disable */
import Base from './base.js'
import SelectionError from '../errors/selection-error.js'
import store from '../../store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL

export default class Media {
  // POST /api/v1/selections/:selection_uid/media(.:format), api/v1/selections/media#create {:format=>:json}
  // data: { uid }
  async createMedium(selectionId, data = {}) {
    let url = API_URL + '/api/v1/selections/' + selectionId + '/media'
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      throw new SelectionError(error.status, false, 'Could not create selection medium')
    }
  }

  // POST /api/v1/selections
  async create(data = {}) {
    let url = API_URL + '/api/v1/selections'
    // let crsf_param = this._csrf_param()
    // let crsf_token = this._csrf_token()
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      throw new SelectionError(error.status, false, 'Could not create selection')
    }
  }

  // DELETE /api/v1/selections/:selection_uid/media/:uid
  async removeMedium(selectionId, mediumId) {
    let url = API_URL + '/api/v1/selections/' + selectionId + '/media/' + mediumId
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.delete(url, config)
    } catch (error) {
      throw new SelectionError(error.status, false, 'Could not remove selection medium')
    }
  }

  // GET    /api/v1/selection/:uid
  // parameters:
  //  uid: selection uid
  // response:
  //   object: { name: string, uid: string }
  async getSelection(selectionId) {
    let url = API_URL + '/api/v1/selections/' + selectionId
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new SelectionError(error.status, false, 'Could not get a selection')
    }
  }

  // GET    /api/v1/selections
  // response:
  //   object: { total: number, selections: array }
  async getSelections(params = {}) {
    let url = API_URL + '/api/v1/selections'
    let config = {
      headers: store.getters['auth/getAxiosHeader'].headers,
      params: params
    }

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new SelectionError(error.status, false, 'Could not get user selections')
    }
  }

  // GET    /api/v1/selections/:selection_uid/media
  // params:
  //  page: page number for pagination
  //  per_page: number of media per page
  // response:
  //   media array
  async getMedia(selectionId, params = {}) {
    let url = API_URL + '/api/v1/selections/' + selectionId + '/media'
    let config = { params: params } // No authentication for now. It's public

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new SelectionError(error.status, false, 'Could not get selection media')
    }
  }

  // PATCH  /api/v1/selections/:uid
  // data:
  //  name: string
  // response:
  //  204 No Content on success
  async update(selectionId, params) {
    let url = API_URL + '/api/v1/selections/' + selectionId
    let data = { selection: params }
    let config = {
      headers: store.getters['auth/getAxiosHeader'].headers
    }

    try {
      return await Base.patch(url, data, config)
    } catch (error) {
      throw new SelectionError(error.status, false, 'Could not update a selection')
    }
  }
}
