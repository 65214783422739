import BaseAdapter from '@/adapters/base_adapter'
import MediumFacesAdapter from '@/adapters/medium_faces_adapter'

import Medium from '@/models/medium'

export default class MediumAdapter extends BaseAdapter {
  static model = Medium
  static relationships = ['faces']

  constructor(params = {}) {
    super(params)
    this.faces = new MediumFacesAdapter({ parent: this })
  }

  get url() {
    return `/api/v1/media/${this.data.uid}`
  }
}
