import BaseModel from '@/models/base_model'

export default class Profile extends BaseModel {
  static name = 'profile'
  static entity = 'profiles'
  static primaryKey = 'user_name'

  static fields() {
    return {
      user_name: this.attr(''),
      name: this.attr(''),
      description: this.attr(''),
      picture_url: this.attr(''),
      // has many albums
      album_count: this.attr(null)
    }
  }
}
