<template lang="pug">
  div
    b-avatar(
      class="mr-2"
      :src="src")
    span(
      class="mr-auto") @{{ username }}
</template>

<script>
export default {
  props: {
    src: String,
    username: String
  }
}
</script>

<style lang="sass" scoped>
.b-avatar
  // When using b-avatar, it's adding badge-secondary class and the
  // background-color property is grey. I didn't figured out why.
  background-color: transparent
</style>
