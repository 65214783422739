import Application from '../../graphic/application.js'

export const mutations = {
  initialize: (state, { canvasElement, multiplier }) => {
    state.application = new Application(canvasElement, multiplier)
  },
  setCanvasDimensions: (state, { width, height }) => {
    state.application.canvas.setCanvasDimensions(width, height)
  },
  setCurrentLayerByName: (state, layerName) => {
    state.application.setActiveLayerByName(layerName)
  },
  addImageToLayer: async (state, { imageURL, layerName }) => {
    await state.application.addImageToLayer(imageURL, layerName)
  },
  addWatermark: state => {
    state.application.addWatermark()
  },
  addPhotoToRect: async (state, { image, rect }) => {
    state.isLoading = true
    let photo = await state.application.addImageToLayer(image, 'photos')
    state.application.canvas.fitObjectToRectangle(photo, rect)
    state.isLoading = false
  },
  clearLayer: (state, layerName) => {
    state.application.clearLayer(layerName)
  },
  clearCanvas: state => {
    state.application.clearCanvas()
  },
  generateImage: (state, { imageFormat, imageQuality }) => {
    state.blobImage = state.application.generateImage(imageFormat, imageQuality)
  }
}

export const actions = {
  initialize: (context, { canvasElement, multiplier }) => {
    context.commit('initialize', { canvasElement, multiplier })
  },
  setCanvasDimensions: (context, { width, height }) => {
    context.commit('setCanvasDimensions', { width, height })
  },
  setCurrentLayerByName: (context, layerName) => {
    context.commit('setCurrentLayerByName', layerName)
  },
  addTemplate: (context, template) => {
    if (template.backgroundURL) {
      context.commit('addImageToLayer', {
        imageURL: template.backgroundURL,
        layerName: 'background'
      })
    }

    if (template.overlayURL) {
      context.commit('addImageToLayer', {
        imageURL: template.overlayURL,
        layerName: 'overlay'
      })
    }
  },
  addWatermark: context => {
    context.commit('addWatermark')
  },
  addImageToLayer: (context, { imageURL, layerName }) => {
    context.commit('addImageToLayer', { imageURL, layerName })
  },
  addPhotoToRect: (context, { image, rect }) => {
    // context.state.application.addPhotoToRect(image, rect)
    context.commit('addPhotoToRect', { image, rect })
  },
  clearLayer: (context, layerName) => {
    context.commit('clearLayer', layerName)
  },
  clearCanvas: context => {
    context.commit('clearCanvas')
  },
  // generateImage(context) {
  generateImage: context => {
    context.commit('generateImage', {
      imageFormat: context.rootState.settings.imageFormat,
      imageQuality: context.rootState.settings.imageQuality
    })
  }
}

export default {
  namespaced: true,
  state: {
    application: null,
    isLoading: false,
    blobImage: null
  },
  mutations,
  actions
}
