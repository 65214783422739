<template lang="pug">
  BaseView
    PersonStep(
      close
      :fields="['selfie', 'name']"
      is-dependent
      :my-profile-adapter="myProfileAdapter"
      :title="$t('title')"
      @cancel="back"
      @ok="back")
</template>

<script>
import { mapActions } from 'vuex'
import BaseView from '@/layouts/Base'
import MyProfileAdapter from '@/adapt/my-profile-adapter'
import PersonStep from '@/components/flows/steps/PersonStep'

export default {
  components: {
    BaseView,
    PersonStep
  },

  data() {
    return {
      myProfileAdapter: new MyProfileAdapter()
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    }),

    back() {
      this.$router.push({ name: 'my-profile-dependents' })
    }
  },

  beforeMount() {
    this.myProfileAdapter.load()
  }
}
</script>

<i18n>
  pt-BR:
    title: "Novo dependente"
</i18n>
