<template lang="pug">
  Pagination(
    v-show="!isLoading"
    v-model="pageNumber",
    :perPage="perPage",
    :totalRows="totalRows")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Pagination from '../../company/Pagination'

export default {
  components: {
    Pagination
  },

  computed: {
    ...mapState({
      isLoading: state => state.selection.isLoading,
      perPage: state => state.selection.settings.perPage,
      totalRows: state => state.selection.library.total
    }),

    pageNumber: {
      get() {
        return this.$store.state.selection.currentPage
      },
      set(page) {
        this.setCurrentPage(page)
        this.loadSelections()
      }
    }
  },

  methods: {
    ...mapActions({
      loadSelections: 'selection/loadLibrary',
      setCurrentPage: 'selection/setCurrentPage'
    })
  }
}
</script>

<style lang="sass" scoped></style>
