<template lang="pug">
  #app
    Alert
    Loading
    router-view
</template>

<script>
import { mapActions } from 'vuex'
import Alert from '@/components/Alert'
import Loading from '@/components/Loading'

export default {
  components: {
    Alert,
    Loading
  },

  methods: {
    ...mapActions({
      setKeydownEvent: 'ux_controls/setKeydownEvent',
      setKeyupEvent: 'ux_controls/setKeyupEvent',
      setViewportDimensions: 'ux_controls/setViewportDimensions'
    }),

    handleKeydown(e) {
      this.setKeydownEvent(e)
    },

    handleKeyup(e) {
      this.setKeyupEvent(e)
    },

    handleResize() {
      // Viewport height in pixels
      let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      // Viewport width in pixels
      let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

      this.setViewportDimensions({
        height: vh,
        width: vw
      })
    }
  },

  created() {
    window.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('keyup', this.handleKeyup)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed() {
    window.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener('keyup', this.handleKeyup)
    window.removeEventListener('resize', this.handleResize)
  },

  metaInfo() {
    return {
      title:
        'Fottorama - Preserve o acervo de fotos seu, de sua família ou de sua comunidade sem esforço', // max 65 cha
      meta: [
        {
          name: 'description',
          content:
            'Fottorama preserva o acervo de pessoas, famílias e comunidades, garantindo que imagens importantes cheguem facilmente ao acervo de cada uma.' // max 155 char
        },
        {
          property: 'og:title',
          content: 'Fottorama - Preserve suas memórias sem esforço' // max 35 char
        },
        // {
        //   property: 'og:url',
        //   content: // Full link to the current webpage address
        // },
        {
          property: 'og:description',
          content: 'Receba fotos de amigos ou comunidades de que participa' // max 65 char
        },
        // {
        //   property: 'og:image',
        //   content: // Image(JPG or PNG) with a size less than 300KB and minimum dimensions of 300 x 200
        // },
        {
          property: 'og:site_name',
          content: 'Fottorama'
        }
      ]
    }
  } // metaInfo
}
</script>
