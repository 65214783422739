<template lang="pug">
  #add-to-collection
    slot(
      v-if="$slots.default")
    Button(
      v-else
      :label="label"
      classes="primary"
      @onClick="click")
    Sidebar(
      :id="id"
      width="100%"
      @hidden="currentStep = 1")
      AccountStep(
        id="account-step"
        v-show="currentStep == 1"
        @ok="next"
        @back="toggle")
      CollectableBuyingFlow(
        v-show="currentStep == 2"
        @back="back"
        @cancel="cancel"
        @ok="next"
        @skip="next")
      ReviewStep(
        id="review-step"
        v-show="currentStep == 3"
        @back="back"
        @cancel="cancel"
        @keep="keep"
        @ok="next")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AccountStep from '../steps/AccountStep'
import Button from '../Button'
import CollectableBuyingFlow from '../application/Collectable/CollectableBuyingFlow'
import ReviewStep from './add-to-my-collection/ReviewStep'
import Sidebar from '../company/Sidebar'

export default {
  components: {
    AccountStep,
    Button,
    CollectableBuyingFlow,
    ReviewStep,
    Sidebar
  },

  props: {
    id: String,
    label: {
      type: String,
      default: function() {
        return this.$t('cta-label')
      }
    }
  },

  data: () => ({
    currentStep: 1
  }),

  computed: {
    ...mapGetters({
      getTotalPrice: 'cart/getTotalPrice'
    }),

    ...mapState({
      hasCollectables: state => state.collectables.total,
      mediaNumber: state => state.gallery.selection.total
    })
  },

  methods: {
    ...mapActions({
      createOrder: 'cart/createOrder',
      emptyCart: 'cart/emptyCart',
      clearSelection: 'gallery/clearSelection',
      setIsLoading: 'ux_controls/setLoadingScreenDisplay',
      showMessage: 'ux_controls/showMessage'
    }),

    back() {
      switch (this.currentStep) {
        case this.REVIEW:
          this.currentStep = this.hasCollectables ? this.currentStep - 1 : this.ACCOUNT // Jump to review step
          break
        default:
          this.currentStep--
      }
    },

    cancel() {
      this.emptyCart()
      this.clearSelection()
      this.toggle()
    },

    keep() {
      this.clearSelection()
      this.toggle()
    },

    click() {
      if (this.mediaNumber == 0) {
        this.showMessage(this.$t('media-number-error'))
      } else {
        this.toggle()
      }
    },
    // Go to the order page so the user can downlad the media
    async download() {
      this.setIsLoading(true)
      this.toggle()
      document.body.style.overflow = ''
      try {
        let orderId = await this.createOrder()
        if (orderId) {
          this.$router.push('/orders/' + orderId)
          // this.setIsLoading(false)
        }
      } catch (error) {
        this.setIsLoading(false)
        this.showMessage(this.$t('order-creation-error') + ' (' + error + ')')
      }
    },

    next() {
      switch (this.currentStep) {
        case this.ACCOUNT:
          if (this.hasCollectables) {
            this.currentStep++
          } else {
            if (this.getTotalPrice == 0) {
              this.download()
            } else {
              this.currentStep = this.REVIEW
            }
          }
          break
        case this.COLLECTABLE:
          if (this.getTotalPrice > 0) {
            this.currentStep++
          } else {
            this.download()
          }
          break
        case this.REVIEW:
          this.download()
          break
        default:
          this.currentStep++
      }
    },
    // Open and close the Sidebar
    toggle() {
      this.$root.$emit('bv::toggle::collapse', this.id)
    }
  }, // methods

  created() {
    this.ACCOUNT = 1
    this.COLLECTABLE = 2
    this.REVIEW = 3
  }
}
</script>

<i18n>
  pt-BR:
    cta-label: "Eu quero"
    order-creation-error: "Houve um erro no processamento do seu pedido. Tente novamente ou fale com o suporte."
    media-number-error: "Selecione ao menos uma mídia para adicioná-la ao seu acervo."
</i18n>

<style lang="sass" scoped></style>
