<template lang="pug">
  Step(
    :back="back"
    :back-click="backClick"
    :back-to="backTo"
    :close="close"
    :foot-note="getFootNote"
    :id="id"
    ok-only
    :ok-label="okLabel"
    :paragraph="paragraph"
    :skip="skip"
    :title="title"
    @back="$emit('back')"
    @cancel="$emit('cancel')"
    @ok="handleOk"
    @skip="$emit('ok')")
    PersonForm(
      :fields="fields"
      :person="personAdapter.data"
      ref="personForm"
      @submit="handleSubmit")
    template(#footer)
      small
        Link(
          :label="$t('privacy-policy')"
          link="https://drive.google.com/file/d/15UmtuTyueczYaDHSqCiEl_4a1ovaUnEk/view")
</template>

<script>
import Link from '@/components/general/Link'
import PersonAdapter from '@/adapt/person-adapter'
import PersonForm from '@/components/flows/forms/PersonForm'
import Step from '@/layouts/Step'

export default {
  components: {
    Link,
    PersonAdapter,
    PersonForm,
    Step
  },

  props: {
    back: Boolean,
    backClick: Boolean,
    backTo: String,
    close: Boolean,
    fields: Array, // ['selfie', 'name', 'email', 'phone', 'allow_image_usage']
    footNote: String,
    id: String,
    isDependent: {
      type: Boolean,
      default: false
    },
    myProfileAdapter: Object,
    okLabel: String,
    paragraph: String,
    skip: Boolean,
    title: String
  },

  data() {
    return {
      personAdapter: new PersonAdapter()
    }
  },

  computed: {
    getFootNote() {
      return this.footNote ? this.footNote : this.$t('foot-note')
    }
  },

  methods: {
    handleOk() {
      // Activate the submit action of the form
      this.$refs.personForm.$refs.formWrapper.onSubmit()
    },

    async handleSubmit() {
      this.personAdapter.data.is_dependent = this.isDependent

      if (this.isDependent) {
        // Dependent's e-mail is the same of the person responsible (current user)
        this.personAdapter.data.email = this.myProfileAdapter.data.email
        this.personAdapter.data.phone = this.myProfileAdapter.data.phone
      }

      if (await this.personAdapter.save()) {
        this.$emit('ok')
      } else {
        this.$refs.myProfileForm.$refs.formWrapper.showErrors()
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    foot-note: "Se você está prestes a cadastrar o rosto de uma criança, você confirma ser seu pai, mãe ou o responsável legal. Se não for, você confirma que tem autorização de responsável legal para fazê-lo."
    privacy-policy: "Política de Privacidade"
</i18n>
