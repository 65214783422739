import Base from './base.js'
import store from '../../store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL

export default class Faces {
  // GET    /api/v1/media/:medium_uid/faces
  // params:
  //  medium_uid
  // response:
  //   faces array
  async get(mediumId, params = {}) {
    let url = API_URL + '/api/v1/media/' + mediumId + '/faces'
    let config = {
      headers: store.getters['auth/getAxiosHeader'].headers,
      params: params
    }
    return await Base.get(url, config)
  }
}
