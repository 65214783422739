<template lang="pug">
  Navbar.top-menu.mb-4
    template(
      v-if="isLoggedIn"
      v-slot:left)
      Button(
        @click="$emit('select', 'back')")
        Icon(
          icon="left")
    template(v-slot:right)
      Button.mr-2(
        @click="copy")
        Icon(
          icon="share")
      Button.mr-2(
        v-if="canEditSelection"
        @click="$emit('select', 'edit')")
        Icon(
          icon="edit")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Button from '../../general/Button/ButtonNew'
import Icon from '../../Icon'
import Navbar from '../Navbar'

export default {
  components: {
    Button,
    Icon,
    Navbar
  },

  computed: {
    ...mapGetters({
      canEditSelection: 'selection/canEditCurrentSelection'
    }),

    ...mapState({
      isLoggedIn: state => state.auth.accessToken
    })
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    }),

    copy() {
      navigator.clipboard.writeText(window.location.href)
      this.showMessage(this.$t('copied'))
    }
  }
}
</script>

<i18n>
  pt-BR:
    copied: 'O link da seleção foi copiado para a área de transferência.'
</i18n>

<style lang="sass" scoped>
.top-menu
  border-bottom: 1px solid #f8f8f8
  height: 50px
</style>
