<template lang="pug">
  Dropdown(
    v-if="show"
    :options="options"
    @select="handleOption")
    template(
      v-slot:button-content)
      Button(
        class="mr-2"
        icon="dots-v"
        rounded)
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

import Button from '@/components/company/Button'
import Dropdown from '@/components/Dropdown'

@Component({
  components: {
    Button,
    Dropdown
  },

  props: {
    permissions: Object
  }
})
class NavbarDropdown extends Vue {
  options = []
  show = false

  beforeMount() {
    if (this.permissions.canEdit) {
      this.options.push({
        value: 'edit',
        text: this.$t('edit'),
        icon: 'edit'
      })
    }

    if (this.permissions.canUpload) {
      this.options.push({
        value: 'upload',
        text: this.$t('upload'),
        icon: 'upload'
      })
    }

    if (this.permissions.canDeliver) {
      this.options.push({
        value: 'deliver',
        text: this.$t('deliver'),
        icon: 'plane'
      })
    }

    if (this.options.length > 0) {
      this.show = true
    }
  }

  handleOption(value) {
    this.$emit('select', value)
  }
}
export default NavbarDropdown
</script>

<i18n>
  pt-BR:
    edit: 'Editar álbum'
    upload: 'Adicionar fotos'
    deliver: 'Entregar fotos'
</i18n>
