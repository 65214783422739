export const arrays = {
  methods: {
    cloneOnlyProp(original, property) {
      let clone = []

      for (let i = 0; i < original.length; i++) {
        // clone[i] = {}
        clone[i] = original[i][property]
      }

      return clone
    },
    // Filter array of objects, which property matches value, returns array:
    // Based on https://stackoverflow.com/questions/13964155/get-javascript-object-from-array-of-objects-by-value-of-property
    filterByProp(array, property, value) {
      return array.filter(object => {
        return object[property] === value
      })
    },
    // Find the value of the first element/object in the array, otherwise undefined is returned.
    // Based on https://stackoverflow.com/questions/13964155/get-javascript-object-from-array-of-objects-by-value-of-property
    findByProp(array, property, value) {
      return array.find(object => {
        return object[property] === value
      })
    },
    // Based on 'findByProp', but with two properties
    findByProps(array, prop1, value1, prop2, value2) {
      return array.find(object => {
        return object[prop1] === value1 && object[prop2] === value2
      })
    },
    // Return a string with one of the array properties separated by comma
    getPropAsString(array, property) {
      let string = ''

      for (let i = 0; i < array.length; i++) {
        // clone[i] = {}
        string += array[i][property] + ', '
      }

      return string.slice(0, string.length - 2) // Remove ', ' at the end'
    },
    // Find the index of the first element/object in the array, otherwise -1 is returned
    // Based on https://stackoverflow.com/questions/8668174/indexof-method-in-an-object-array
    indexOfByProp(array, property, value) {
      return array
        .map(function(object) {
          return object[property]
        })
        .indexOf(value)
    },
    // Based on 'indexOfByProp', but with two properties
    indexOfByProps(array, prop1, value1, prop2, value2) {
      let object = this.findByProps(array, prop1, value1, prop2, value2)

      return array.indexOf(object)
    },
    // Remove the first occurrence of a value from an array
    // From: https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array
    removeItemOnce(array, value) {
      var index = array.indexOf(value)

      if (index > -1) {
        array.splice(index, 1)
      }

      return array
    },
    // Remove all occurrences of a value from an array
    // Based on https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array
    removeItemAll(array, value) {
      var index = 0

      while (index < array.length) {
        if (array[index] === value) {
          array.splice(index, 1)
        } else {
          ++index
        }
      }

      return array
    },
    // array: array of objects
    // TODO: use filter instead
    removeObjectWithKey(array, property, value) {
      let removed = false
      let index = array.findIndex(o => o[property] === value)

      if (index > -1) {
        array.splice(index, 1)
        removed = true
      }

      return {
        array: array,
        removed: removed
      }
    },
    // Remove 'toRemove' elements from 'array'
    // Based on https://stackoverflow.com/questions/19957348/remove-all-elements-contained-in-another-array
    subtract(array, toRemove) {
      return array.filter(function(el) {
        return toRemove.indexOf(el) < 0
      })
    },
    // Remove 'toRemove' elements from 'array', handling arrays of objects
    // Based on https://stackoverflow.com/questions/21987909/how-to-get-the-difference-between-two-arrays-of-objects-in-javascript
    subtractArrayOfObjects(array, toRemove) {
      return array.filter(this.comparer(toRemove))
    },
    // Auxiliar method for 'subtractArrayOfObjects'
    comparer(otherArray) {
      return function(current) {
        return (
          otherArray.filter(function(other) {
            return other.value == current.value && other.display == current.display
          }).length == 0
        )
      }
    },
    // Auxiliar method for 'unique'
    _onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
    // Remove duplicate elements from 'array'
    // Based on https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
    unique(array) {
      return array.filter(this._onlyUnique)
    }
  }
}
