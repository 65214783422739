<template lang="pug">
  a.link(
    v-if="!link"
    @click.stop="onClick()"
    :class="classes") {{ label }}
    slot
  a(
    v-else-if="link.includes('http')"
    :href="link"
    target="_blank"
    @click="onClick()"
    :class="classes") {{ label }}
    slot
  router-link(
    v-else=""
    :to="getInternalPath(link)"
    :target="target"
    @click="onClick()"
    :class="classes") {{ label }}
    slot
</template>

<script>
import { path } from '../../mixins/path.js'

export default {
  mixins: [path], // getInternalPath

  props: {
    label: String,
    link: String,
    target: String,
    classes: String
  },

  methods: {
    onClick: function() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../assets/stylesheets/_variables.sass'

a
  color: $secondary-color

a:hover
  color: $dark-color

a:not([href])
  color: $secondary-color
  cursor: pointer

a:not([href]):hover
  color: $dark-color
</style>
