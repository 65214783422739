import api from '@/api'

export const profileFollowers = {
  methods: {
    // profileUsername: the profile to be followed
    // invitationId: the invitation id from the profile to be followed
    // people: current user's people that will be following th profile (uid)
    async follow(profileUsername, invitationId, people) {
      try {
        await api.profileFollowers.createMember(profileUsername, { invitation: invitationId })
      } catch (error) {
        /* do nothing */
      }

      // Even if there's an error above (e.g. the user is already a member), the person is added.
      // If the person can't be added for some reason the error will be thrown inside the for
      // TODO: No error is thrown here either, because I'm waiting for backend updated to better
      // understand this implementation
      for (let i = 0; i < people.length; i++) {
        try {
          await api.profileFollowers.createPerson(profileUsername, { uid: people[i] })
        } catch (error) {
          /* do nothing */
        }
      }
    }
  }
}
