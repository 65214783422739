<template lang="pug">
  Modal(
    :id="id"
    v-model="localValue"
    @ok="handleOk")
    Avatar.mx-auto.mt-3(
      :face-coords="faceCoords"
      :medium-dims="medium.data.dims"
      :medium-src="medium.data.thumb_url")
    Form(
      action="new"
      :person="personAdapter.data"
      ref="formWrapper"
      :show-buttons="false"
      @submit="submit")
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

import Avatar from '@/components/People/Avatar'
import Form from '@/components/People/Form'
import Modal from '@/components/general/Popup'
import PersonAdapter from '@/adapt/person-adapter'

@Component({
  components: {
    Avatar,
    Form,
    Modal
  },
  props: {
    face: Object,
    medium: Object, // mediumAdapter
    id: String,
    value: Boolean // visible
  },
  watch: {
    face() {
      this.personAdapter.data.face_uid = this.face.$id
    }
  }
})
class PeopleModal extends Vue {
  personAdapter = new PersonAdapter()

  get faceCoords() {
    return this.face ? this.face.coords : null
  }

  get localValue() {
    return this.value
  }

  set localValue(newValue) {
    this.$emit('input', newValue)
  }

  handleOk(bvModalEvt) {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    // Trigger submit handler
    this.handleSubmit()
  }

  handleSubmit() {
    this.$refs.formWrapper.onSubmit()
  }

  async submit() {
    if (await this.personAdapter.create()) {
      this.$bvModal.hide(this.id)
    } else {
      this.$refs.form.showErrors()
    }
  }
}
export default PeopleModal
</script>
