<template lang="pug">
  div
    b-form-checkbox(
      :disabled="disabled"
      :id="name + '-switch'"
      name="check-button"
      :size="size"
      switch
      v-model="inputValue") {{ label }}
    small.text-muted(
      v-if="description") {{ description }}
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },

  props: {
    checked: Boolean,
    description: String,
    disabled: Boolean,
    label: String,
    name: String,
    size: String
  },

  computed: {
    inputValue: {
      get() {
        return this.checked
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  }
}
</script>

<style lang="sass" scoped></style>
