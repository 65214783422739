<template lang="pug">
  ValidationObserver(ref="form" v-slot="{ invalid }")
    form
      //- @submit.stop.prevent="handleSubmit"
      fieldset
        TextInput#selection-name-field(
          name='selection-name'
          type='text'
          rules='required'
          v-model='value.name')
        PlanSelectInput#plan-select-field(
          name="plan-select"
          v-model="value.plan.id")
        #plan-create-fields(
          v-if="value.plan.id == 'create'")
          TextInput#plan-name-field(
            name="plan-name"
            placeholder="Standard, Summer 2021 etc."
            rules="required"
            type="text"
            v-model="plan.name")
          TextInput#plan-single-price-field(
            :description="$t('description-single-price')"
            name="plan-single-price"
            rules="numeric|required"
            type="text"
            v-model="plan.singlePrice")
            //- rules="double:2,comma|required"
          TextInput#plan-cap-price-field(
            :description="$t('description-cap-price')"
            name="plan-cap-price"
            rules="numeric"
            type="text"
            v-model="plan.capPrice")

        //- Submit button slot
        slot
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import PlanSelectInput from '../Plan/PlanSelectInput'
import TextInput from '../../inputs/TextInput'

export default {
  components: {
    PlanSelectInput,
    ValidationObserver,
    TextInput
  },

  props: {
    plan: {
      name: String,
      capPrice: String,
      singlePrice: String
    },
    value: {
      name: String,
      plan: Object
    }
  },

  watch: {
    'value.plan': function(newValue, oldValue) {
      if (oldValue == 'create') {
        this.$emit('clear-plan')
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    description-cap-price: "Se o valor total ultrapassar esse teto, cobrar o teto."
    description-single-price: "Preço de cada mídia quando comprada individualmente."
</i18n>

<style lang="sass" scoped></style>
