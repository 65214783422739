import PlanError from '../errors/plan-error.js'
import Base from './base.js'
import store from '../../store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL

export default class Profiles {
  // POST /api/v1/profile, api/v1/profiles#create {:format=>:json}
  // params:
  //  :user_name, String, required: true, blank: false
  //  :name, String, required: true, blank: false
  //  :description, String
  //  :picture_url, String
  // response:
  //
  async create(params) {
    let url = API_URL + '/api/v1/plans'
    let data = { plan: params }
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      throw new PlanError(error.status, false, 'Could not create plan')
    }
  }

  // GET /api/v1/profiles/:user_name, api/v1/profiles#show {:format=>:json}
  // async show(username) {
  //   let url = API_URL + '/api/v1/profiles/' + username
  //   // let config = store.getters['auth/getAxiosHeader']

  //   try {
  //     return await Base.get(url)
  //   } catch (error) {
  //     throw new PlanError(error.status, false, 'Could not get profile')
  //   }
  // }

  // GET /api/v1/profile, api/v1/profiles#show {:format=>:json}
  // async showCurrent() {
  //   let url = API_URL + '/api/v1/profile'
  //   let config = store.getters['auth/getAxiosHeader']

  //   try {
  //     return await Base.get(url, config)
  //   } catch (error) {
  //     throw new PlanError(error.status, false, 'Could not get current profile')
  //   }
  // }

  // GET /api/v1/plans(.:format), api/v1/plans#index {:format=>:json}
  async index() {
    let url = API_URL + '/api/v1/plans/'
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new PlanError(error.status, false, 'Could not get plans')
    }
  }

  // GET /api/v1/profile/storage_credentials, api/v1/profiles/storage_credentials#show {:format=>:json}
  // async showStorageCredential() {
  //   let url = API_URL + '/api/v1/profile/storage_credentials'
  //   let config = store.getters['auth/getAxiosHeader']

  //   try {
  //     return await Base.get(url, config)
  //   } catch (error) {
  //     throw new PlanError(error.status, false, 'Could not get profile storage credential')
  //   }
  // }

  // PATCH /api/v1/profile, api/v1/profiles#update {:format=>:json}
  // PUT /api/v1/profile, api/v1/profiles#update {:format=>:json}
  // params:
  //    :user_name, String, blank: false
  //    :name, String, blank: false
  //    :description, String
  //    :picture_url, String
  // response:
  //
  // async update(params) {
  //   let url = API_URL + '/api/v1/profile'
  //   let data = { profile: params }
  //   let config = store.getters['auth/getAxiosHeader']

  //   try {
  //     return await Base.patch(url, data, config)
  //   } catch (error) {
  //     throw new PlanError(error.status, false, 'Could not update profile')
  //   }
  // }
}
