<template lang="pug">
  #collection-selection-step
    Navbar(
      bordered
      :title="$t('collectable')")
      template(#left)
        Button(
          icon="left"
          @onClick="$emit('back')")
      template(#right)
        Button(
          :label="$t('skip')"
          @onClick="$emit('skip')")
    Instructions(
      class="p-3 text-center"
      :description="$t('description')"
      :title="$t('title')")
    CollectableSelect(
      class="mb-5"
      :options="collectables"
      v-model="selected")
    br
    Navbar(
      bordered
      color="white"
      fixed="bottom")
      template(#right)
        Button(
          :label="$t('next')"
          class="primary"
          @onClick="process")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { arrays } from '../../../mixins/arrays'
import CollectableSelect from '../../application/Collectable/CollectableSelect'
import Icon from '../../Icon'
import Instructions from '../Instructions'
import Navbar from '../../company/Navbar'
import Button from '../../Button'

export default {
  mixins: [arrays], // findByProp

  components: {
    Button,
    CollectableSelect,
    Icon,
    Instructions,
    Navbar
  },

  data() {
    return {
      selected: []
    }
  },

  computed: {
    ...mapState({
      collectables: state => state.collectables.items,
      hasCollectables: state => state.collectables.total,
      resourceId: state => state.gallery.resource.id
    })
  },

  methods: {
    ...mapActions({
      addItem: 'cart/addItem',
      showMessage: 'ux_controls/showMessage'
    }),

    process() {
      if (this.selected.length == 0) {
        this.showMessage(this.$t('no-collectable-selected-error'))
      } else {
        this.selected.forEach(collectableId => {
          let current = this.findByProp(this.collectables, 'uid', collectableId)

          this.addItem({
            data: {
              description: current.name,
              collectableId: collectableId
            },
            media: [],
            price: current.price * 100,
            resourceId: this.resourceId,
            type: 'collectable'
          })
        })
        this.$emit('ok')
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    collectable: "Colecionáveis"
    description: "Selecione um dos itens colecionáveis abaixo ou pule esta etapa clicando acima."
    next: "Avançar"
    no-collectable-selected-error: "Você não selecionou nenhum item colecionável. Selecione um para avançar ou pule esta etapa."
    skip: "Pular"
    title: "Vamos eternizar suas fotos?"
</i18n>

<style lang="sass" scoped></style>
