<template lang="pug">
  Form(
    ref="formWrapper"
    :model="myProfile"
    :no-button="noButton"
    :ok-only="okOnly"
    @change="$emit('change', $event)"
    @error="$emit('error')"
    @submit="$emit('submit')")
    ProfileFields(
      :action="action"
      :fields="fields"
      :personal="personal"
      v-model="myProfile")
</template>

<script>
import Form from '@/components/Shared/Form'
import ProfileFields from '@/components/flows/fields/ProfileFields'

export default {
  components: {
    Form,
    ProfileFields
  },

  props: {
    action: String, // 'edit', 'new'
    fields: Array, // ['avatar', 'username', 'name', 'description']
    myProfile: Object, // MyProfileAdapter.data
    noButton: Boolean,
    okOnly: Boolean,
    personal: Boolean
  }
}
</script>
