<template lang="pug">
  #selection
    OrderMenu
    section.container-fluid.my-5(
      v-if="!isPaid")
      OrderPayment
    GallerySection(
      v-else)
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GallerySection from '../components/application/Gallery/GallerySection'
import OrderMenu from '../components/application/Order/OrderMenu'
import OrderPayment from '../components/application/Order/OrderPayment'

export default {
  name: 'Order',

  components: {
    GallerySection,
    OrderMenu,
    OrderPayment
  },

  computed: {
    ...mapState({
      order: state => state.order.current
    }),

    isPaid() {
      return this.order.paidAt
    }
  },

  methods: {
    ...mapActions({
      resetSelection: 'gallery/resetSelection',
      setResource: 'gallery/setResource'
    })
  },

  created() {
    this.setResource({
      id: this.$route.params.id,
      kind: 'order'
    })
  },

  beforeMount() {
    // When comming from AddToMyCollection flow, the gallery selection may be
    // active
    this.resetSelection()
  }
}
</script>

<style lang="sass" scoped></style>
