import BaseAdapter from '@/adapt/base-adapter'
import AlbumMediaAdapter from '@/adapt/album-media-adapter'

import Album from '@/models/album'
import MyProfile from '@/models/my_profile'

export default class AlbumAdapter extends BaseAdapter {
  static model = Album
  static relationships = ['media']

  constructor(albumId, params = {}) {
    super(params)
    this.media = new AlbumMediaAdapter(albumId)
  }
  // api_v1_album, /api/v1/albums/:uid, api/v1/albums, { GET: show }
  get url() {
    return this.data.uid ? `/api/v1/albums/${this.data.uid}` : 'api/v1/albums'
  }

  get canEdit() {
    return this.data && this.data.permissions.includes('all')
  }

  get canUpload() {
    return this.data && this.data.permissions.includes('all')
  }

  get canDeliver() {
    return this.data && this.data.permissions.includes('all')
  }

  async _afterUpdate() {
    await Album.insertOrUpdate({ data: this.data.$toJson() })
  }

  async _afterCreate(response) {
    let profile = MyProfile.query().first()
    this.data.profile_user_name = profile.user_name
    this.data.uid = response.data.uid
    await Album.insertOrUpdate({ data: this.data.$toJson() })
    await MyProfile.update({
      where: profile.user_name,
      data: { album_count: profile.album_count + 1 }
    })
  }
}
