import PaymentError from '../errors/payment-error.js'
import Base from './base.js'
import store from '../../store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL

export default class Payments {
  // POST /api/v1/payments(.:format), api/v1/payments#create {:format=>:json}
  // params:
  //  :order_uid, String, required: true, blank: false
  // response:
  //  pix: { qrcode, qrcode_text, ? }
  async create(params) {
    let url = API_URL + '/api/v1/payments'
    let data = { payment: params }
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      throw new PaymentError(error.status, false, 'Could not create payment')
    }
  }
}
