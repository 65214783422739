import BaseCollectionAdapter from '@/adapters/base_collection_adapter'

import Face from '@/models/face'

export default class MediumFacesAdapter extends BaseCollectionAdapter {
  static model = Face
  static targetModel = Face

  get url() {
    return `${this.parent.url}/faces`
  }
}
