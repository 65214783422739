import BaseAdapter from '@/adapters/base_adapter'
import MyProfileStorageCredentialsAdapter from '@/adapters/my_profile_storage_credentials_adapter'

import MyProfile from '@/models/my_profile'
import api from '@/api'

export default class MyProfileAdapter extends BaseAdapter {
  static model = MyProfile
  static persistBy = 'create'

  constructor(params = {}) {
    super(params)
    this.credentials = new MyProfileStorageCredentialsAdapter({ parent: this })
  }

  get url() {
    return '/api/v1/profile'
  }

  async save() {
    if (this.data.avatar.blob && !(await this._uploadAvatar())) {
      return false
    }
    this.data.picture_url = this.data.avatar.url
    return MyProfile.exists() ? await this.update() : await this.create()
  }

  async _afterCreate() {
    MyProfile.create({ data: this.data.$toJson() })
  }

  async _afterUpdate() {
    MyProfile.create({ data: this.data.$toJson() })
  }

  async _uploadAvatar() {
    try {
      await this.credentials.loadFromApi()
      // TODO: improve external api communication
      this.data.avatar.url = await api.s3.create(
        '',
        this.data.avatar.blob,
        this.credentials.data,
        true,
        null
      )
      this.data.avatar.url += `?${Date.now()}`
      return true
    } catch (err) {
      this.data.addError('avatar', ['could_not_be_saved'])
      return false
    }
  }
}
