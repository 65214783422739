import BaseModel from '@/models/base_model'

export default class AlbumMedium extends BaseModel {
  static entity = 'albumMedium'
  static primaryKey = ['album_uid', 'medium_uid']

  static fields() {
    return {
      album_uid: this.attr(null),
      medium_uid: this.attr(null),
      order: this.attr(null)
    }
  }
}
