<template lang="pug">
  .d-flex.justify-content-between

    //- Left arrow
    .nav-arrow.d-flex.flex-column.justify-content-center(
      ref="arrowBox")
      Button(
        v-show="!isFirstPost"
        @click="previous")
        Icon.text-white(
          icon="left")

    //- Medium container
    #post-preview-column.d-flex.align-items-center.vph-100
      div.text-center(
        v-if="error")
        p.txt-light {{ $t('being-processed') }}
        Link(
          classes="try-again"
          :label="$t('try-again')"
          @click="updateDimensions")
      .medium-wrapper.vph-100(
        v-else
        ref="mediumBox"
        :style="maxHeight")
        img(
          v-if="medium.src"
          ref="medium"
          :src="medium.src")
        a.face-frame(
          v-for="(face, index) in medium.faces"
          v-if="medium.showFaces"
          :key="face.uid"
          :style="getFacePosition(face.coords)"
          @click="filterByFace(face)")
      .loading-block.d-flex.flex-column.align-items-center.justify-content-center(
        :style="displayLoading")
        Icon(
          class="icon-sq-xl text-secondary mb-3"
          icon="loading")

    //- Right arrow
    .nav-arrow.d-flex.flex-column.justify-content-center
      Button(
        v-show="!isLastPost"
        @click="next")
        Icon.text-white(
          icon="right")
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from 'vuex'
import { images } from '../../../mixins/images'
import keyCodes from '../../../consts/keyCodes'
// import { albums } from '../../../mixins/resources/albums'
import Button from '../../general/Button/ButtonNew'
import Icon from '../../Icon'
import Link from '../../general/Link'

export default {
  mixins: [
    images // loadImageFromURL
    // albums // getAlbumMedium
  ],

  components: {
    Button,
    Icon,
    Link
  },

  data() {
    return {
      error: false,
      imgH: null,
      imgW: null,
      isLoading: false,
      maxH: null
    }
  },

  computed: {
    ...mapGetters({
      isFirstPost: 'gallery/isFirstPost',
      isLastPost: 'gallery/isLastPost'
    }),

    ...mapState({
      // albumId: state => state.album.id,
      keydownEvent: state => state.ux_controls.keydownEvent,
      medium: state => state.gallery.medium,
      viewportHeight: state => state.ux_controls.viewportHeight,
      viewportWidth: state => state.ux_controls.viewportWidth
    }),

    maxHeight() {
      if (this.maxH) {
        return 'max-height:' + this.maxH + 'px'
      }

      return ''
    },

    displayLoading() {
      if (!this.isLoading) {
        return 'display: none!important;'
      }

      return ''
    }
  },

  methods: {
    ...mapActions({
      applyFilterByFace: 'gallery/applyFilterByFace',
      next: 'gallery/previewNext',
      previous: 'gallery/previewPrevious',
      showMessage: 'ux_controls/showMessage',
      toggleSelectionItem: 'gallery/toggleSelectionItem'
    }),

    // Get current medium's width and height
    async updateDimensions() {
      this.error = false
      try {
        await this.loadMedium()
        this.setDimensions()
      } catch (err) {
        console.log('PostPreview: error loading medium')
        this.error = true
        this.isLoading = false
      }
    },
    // coords: face coords according to thumbnail height and width (that are
    // available in this.medium)
    getFacePosition(coords) {
      let left = (coords.x / this.medium.width) * 100
      let top = (coords.y / this.medium.height) * 100

      let height = (this.imgH / this.medium.height) * coords.h
      let width = (this.imgW / this.medium.width) * coords.w

      return (
        'width: '  + width  + 'px; ' +
        'height: ' + height + 'px; ' +
        'top: '    + top    + '%; '  +
        'left: '   + left   + '%;'
      )
    },

    async loadMedium() {
      // When it's loading, a black block is displayed in order to hide the
      // max-height change. Without this, the image goes up before updating
      // to the new one.
      this.isLoading = true
      this.maxH = null
      // let medium = await this.getAlbumMedium(this.albumId, this.medium.uid)
      // Used to know when the image is fully loaded on the screen
      await this.loadImageFromURL(this.medium.src)
      this.isLoading = false
    },

    filterByFace(face) {
      if (face.persisted) { // If it's already persisted in the DB for searching
        this.applyFilterByFace(face)
        this.$emit('close') // Close preview
      } else {
        this.showMessage(this.$t('face-not-persisted'))
      }
    },

    handleResize() {
      if (this.$refs.medium && this.$refs.arrowBox) {
        this.setDimensions()
      }
    },

    // We had a previous implementation where we didn't use max-height in order
    // to centralize the medium horizontally and vertically. This function had
    // only the imgH and imgW attibutions. It loaded media faster (so we could)
    // try a different approach in the future, but it didn't displayed for
    // example an entire portrait image in a hotizontal mobile screen. It
    // overflowed up and down.
    setDimensions() {
      // Navigation arrows width
      let abW = this.$refs.arrowBox.clientWidth
      // Available width for the medium to grow (viewport width minus both arrows)
      let avW = this.viewportWidth - abW * 2
      // Medium displayed height
      this.imgH = this.$refs.medium.clientHeight
      // Medium displayed width
      this.imgW = this.$refs.medium.clientWidth
      // If the medium thumbnail is displayed in its original dimensions
      if (this.imgH == this.THUMB_MAX_SIDE || this.imgW == this.THUMB_MAX_SIDE) {
        this.maxH = this.imgH
      } else { // If the medium thumbnail is reduced and could be expanded
        if (this.imgH == this.viewportHeight) { // If it can expand vertically
          this.maxH = null
        } else { // if it can expand horizontally
          this.maxH = (this.imgH * avW) / this.imgW
        }
      }
    }
  },

  watch: {
    'medium.src': function() {
      this.updateDimensions()
    },

    keydownEvent() {
      switch (this.keydownEvent.keyCode) {
        case keyCodes.SPACE:
          // If preventDeafult() is not called, the medium index can change
          // sometimes. I didn't figure out why, but preventDefault fixed it.
          this.keydownEvent.preventDefault()
          this.toggleSelectionItem(this.medium.index) // Add to or remove from selection
          break
        case keyCodes.ARROW_RIGHT:
          this.next()
          break
        case keyCodes.ARROW_LEFT:
          this.previous()
          break
      }
    },

    // Resizing the height
    viewportHeight() {
      this.handleResize()
    },
    // Resizing the width
    viewportWidth() {
      this.handleResize()
    }
  },

  created() {
    // The medium thumbnail has 640 pixels at most in its biggest side
    this.THUMB_MAX_SIDE = 640
  },

  mounted() {
    this.updateDimensions()
  }
}
</script>

<i18n>
  pt-BR:
    being-processed: "Em processamento..."
    face-not-persisted: "As faces estão em processamento. Por favor aguarde."
    try-again: "Tentar novamente"
</i18n>

<style lang="sass" scoped>
@import '../../../assets/stylesheets/_variables.sass'

img
  border-radius: 4px
  max-height: 100%
  max-width: 100%

.nav-arrow
  flex: 0 0 2.5em

.btn.focus, .btn:focus
  box-shadow: none

a.face-frame:hover
  cursor: pointer

.face-frame
  position: absolute
  border: 2px solid rgba(255, 255, 255, 0.6)
  box-shadow: 0 0 15px black
  border-radius: 5%
// important for showing faces correctly
.medium-wrapper
  position: relative

#post-preview-column
  position: relative
// It covers the image before the transition to another one
.loading-block
  background-color: black
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0

.try-again:hover
  color: $secondary-color !important
</style>
