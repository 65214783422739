import Vue from 'vue'
import api from '../../api'
import { arrays } from '../../mixins/arrays.js'
import { objects } from '../../mixins/objects.js'

export const getters = {
  getParams: state => {
    let params = {}

    params.page = state.pageNumber + 1
    params.per_page = state.settings.mediaPerPage
    params.selfie_id = state.filters.selfie.id
    params.face_uid = state.filters.face.uid

    return params
  },
  getSelectionIds: state => {
    return arrays.methods.cloneOnlyProp(state.selection.media, 'uid')
  },
  isFiltering: state => {
    return state.filters.face.uid != null || state.filters.selfie.id != null
  },
  isFirstPost: state => {
    return state.medium.index == 0 && state.pageNumber == 0
  },
  isLastPost: (state, getters) => {
    if (state.content.media[state.pageNumber]) {
      return (
        state.medium.index == state.content.media[state.pageNumber].length - 1 &&
        state.pageNumber == getters.pageCount - 1
      )
    }

    return true
  },
  isMediumSelected: state => {
    // Important verification for page changes while previewing
    if (
      state.content.media[state.pageNumber] &&
      state.content.media[state.pageNumber][state.medium.index]
    ) {
      return state.content.media[state.pageNumber][state.medium.index].selected
    }

    return false
  },
  isPreviewing: state => {
    return state.medium.index != null
  },
  isValidIndex: state => index => {
    return index >= 0 && index < state.content.media[state.content.pageNumber].length
  },
  isValidPage: (state, getters) => pageNumber => {
    return pageNumber >= 0 && pageNumber < getters.pageCount
  },
  pageCount: state => {
    return Math.ceil(state.content.total / state.settings.mediaPerPage)
  }
}

export const mutations = {
  addMediumToSelection: (state, medium) => {
    state.selection.media.push(medium)
    state.selection.total++
  },
  clearContent: state => {
    state.content.media.splice(0, state.content.media.length)
    state.content.total = 0
  },
  clearFilters: state => {
    objects.methods.setNull(state.filters.face)
    objects.methods.setNull(state.filters.selfie)
  },
  clearFiltersFace: state => {
    objects.methods.setNull(state.filters.face)
  },
  clearFiltersSelfie: state => {
    objects.methods.setNull(state.filters.selfie)
  },
  clearMedium: state => {
    state.medium.index = null
    state.medium.height = null
    state.medium.src = null
    state.medium.uid = null
    state.medium.width = null
    state.medium.faces.splice(0, state.medium.faces.length)
    state.medium.showFaces = false
  },
  clearMediumFaces: state => {
    state.medium.faces.splice(0, state.medium.faces.length)
  },
  clearResource: state => {
    objects.methods.setNull(state.resource)
  },
  clearSelection: state => {
    state.selection.media.splice(0, state.selection.media.length)
    state.selection.total = 0
  },
  removeMediumFromContent: (state, { mediumId, page }) => {
    let removed = arrays.methods.removeObjectWithKey(state.content.media[page], 'uid', mediumId)
      .removed

    if (removed) {
      state.content.total--
    }
  },
  removeMediumFromSelection: (state, mediumId) => {
    let removed = arrays.methods.removeObjectWithKey(state.selection.media, 'uid', mediumId).removed

    if (removed) {
      state.selection.total--
    }
  },
  resetSelection: state => {
    state.selection.active = false
    state.selection.media.splice(0, state.selection.media.length)
    state.selection.total = 0
  },
  setContent: (state, { response, page }) => {
    Vue.set(state.content.media, page, response.media)
    state.content.total = response.total
  },
  setContentItemSelected: (state, { index, page, selected }) => {
    if (state.content.media[page][index]) {
      Vue.set(state.content.media[page][index], 'selected', selected)
    }
  },
  setFilters: (state, filters) => {
    state.filters = objects.methods.clone(filters)
  },
  setFiltersFace: (state, face) => {
    state.filters.face = objects.methods.clone(face)
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading
  },
  setMediumFaces: (state, faces) => {
    state.medium.faces = faces
  },
  setMediumHeight: (state, height) => {
    state.medium.height = height
  },
  setMediumIndex: (state, index) => {
    state.medium.index = index
  },
  setMediumId: (state, id) => {
    state.medium.uid = id
  },
  setMediumShowFaces: (state, show) => {
    state.medium.showFaces = show
  },
  setMediumSrc: (state, src) => {
    state.medium.src = src
  },
  setMediumWidth: (state, width) => {
    state.medium.width = width
  },
  setPageNumber: (state, pageNumber) => {
    state.pageNumber = pageNumber
  },
  setParams: (state, params) => {
    state.params = params
  },
  setResource: (state, resource) => {
    state.resource = resource
  },
  setSelection: (state, selection) => {
    state.selection = selection
  },
  setSelectionActive: (state, active) => {
    state.selection.active = active
  },
  setSelectionTotal: (state, total) => {
    state.selection.total = total
  },
  toggleSelection: state => {
    state.selection.active = !state.selection.active
  },
  toggleMediumShowFaces: state => {
    state.medium.showFaces = !state.medium.showFaces
  },
  updateMediumByIndex: (state, index) => {
    state.medium.index = index
    state.medium.src = state.content.media[state.pageNumber][index].thumb_url
    state.medium.uid = state.content.media[state.pageNumber][index].uid
    // Backend doesn't return 'dims' for selections media and filter by face
    // is not allowed yet
    if (state.resource.kind == 'album') {
      state.medium.height = state.content.media[state.pageNumber][index].dims.h
      state.medium.width = state.content.media[state.pageNumber][index].dims.w
      // Hide faces when medium changes
      state.medium.faces.splice(0, state.medium.faces.length)
      state.medium.showFaces = false
    }
  }
}

export const actions = {
  applyFilterByFace: ({ state, commit, dispatch }, face) => {
    face.medium = {
      height: state.medium.height,
      src: state.medium.src,
      width: state.medium.width
    }

    commit('clearFiltersSelfie')
    commit('setFiltersFace', face)
    dispatch('applyFilters')
  },
  applyFilters: ({ commit }, filters) => {
    commit('resetSelection')
    commit('setPageNumber', 0)
    commit('clearContent')
    // If no filters are passed, 'loadMedia' uses state.filters
    if (filters) {
      commit('setFilters', filters)
    }
    // dispatch('loadMedia')
  },
  changePage: ({ state, commit, dispatch, getters }, nextPage) => {
    let currentPage = state.pageNumber
    // If it's a valid page and not the same as the current one
    if (getters.isValidPage(nextPage) && currentPage != nextPage) {
      commit('setPageNumber', nextPage)
      // If the next page is not loaded yet from the backend
      if (!state.content.media[nextPage]) {
        dispatch('loadMedia').then(() => {
          dispatch('updatePreviewOnPageChange', { currentPage, nextPage })
        })
      } else {
        dispatch('updatePreviewOnPageChange', { currentPage, nextPage })
      }
    }
  },
  clearContent: context => {
    context.commit('clearContent')
  },
  clearFiltersSelfie: context => {
    context.commit('clearFiltersSelfie')
  },
  clearMedium: context => {
    context.commit('clearMedium')
  },
  clearSelection: ({ state, commit }, reset = false) => {
    if (state.selection.active) {
      state.selection.media.forEach(medium => {
        commit('setContentItemSelected', {
          index: medium.index,
          page: medium.page,
          selected: false
        })
      })
      commit('clearSelection')

      if (reset) {
        commit('setSelectionActive', false)
      }
    }
  },
  getMediumFaces: context => {
    return new Promise((resolve, reject) => {
      api.faces
        .get(context.state.medium.uid)
        .then(response => {
          context.commit('setMediumFaces', response)
          resolve()
        })
        .catch(error => {
          context.commit('clearMediumFaces')
          reject(error)
        })
    })
  },
  loadMedia: (context, params = getters.getParams(context.state)) => {
    let resource
    context.commit('setIsLoading', true)

    switch (context.state.resource.kind) {
      case 'album':
        resource = api.albums
        break
      case 'order':
        resource = api.orders
        break
      case 'selection':
        resource = api.selections
        break
    }

    return new Promise((resolve, reject) => {
      resource
        .getMedia(context.state.resource.id, params)
        .then(response => {
          context.commit('setContent', {
            response: response,
            page: params.page - 1
          })
          context.commit('setIsLoading', false)
          resolve()
        })
        .catch(error => {
          context.commit('clearContent')
          context.commit('setIsLoading', false)
          reject(error)
        })
    })
  },
  previewNext: ({ state, dispatch }) => {
    // If the move keeps the user in the same page
    if (state.medium.index < state.content.media[state.pageNumber].length - 1) {
      dispatch('updateMediumByIndex', state.medium.index + 1)
    } else {
      dispatch('changePage', state.pageNumber + 1)
    }
  },
  previewPrevious: ({ state, dispatch }) => {
    // If the move keeps the user in the same page
    if (state.medium.index > 0) {
      dispatch('updateMediumByIndex', state.medium.index - 1)
    } else {
      dispatch('changePage', state.pageNumber - 1)
    }
  },
  removeMediumFromContent: (context, { mediumId, page }) => {
    context.commit('removeMediumFromContent', { mediumId, page })
  },
  removeMediumFromSelection: (context, mediumId) => {
    context.commit('removeMediumFromSelection', mediumId)
  },
  resetGallery: ({ commit }) => {
    commit('clearContent')
    commit('clearFilters')
    commit('clearMedium')
    commit('clearResource')
    commit('resetSelection')
    commit('setPageNumber', 0)
    commit('setIsLoading', false)
  },
  resetSelection: ({ dispatch }) => {
    // When reset is called from the outside, it should make sure that the
    // 'selected' property is set to false. That's why it not only calls
    // the 'resetSelection' mutation.
    dispatch('clearSelection', true)
  },
  selectAllMedia: ({ state, commit, dispatch }) => {
    // Active selection mode if it's not active
    if (!state.selection.active) {
      commit('toggleSelection')
    }
    // Select all media from the current page
    for (let i = 0; i < state.content.media[state.pageNumber].length; i++) {
      if (!state.content.media[state.pageNumber][i].selected) {
        dispatch('selectMedium', i)
      }
    }
  },
  selectMedium: ({ state, commit }, index) => {
    if (!state.content.media[state.pageNumber][index].selected) {
      let medium = {
        index: index,
        page: state.pageNumber,
        thumb_url: state.content.media[state.pageNumber][index].thumb_url,
        uid: state.content.media[state.pageNumber][index].uid
      }

      commit('addMediumToSelection', medium)
      commit('setContentItemSelected', {
        index: index,
        page: state.pageNumber,
        selected: true
      }) // To reflect in the UI
    }
  },
  selectRange: ({ dispatch }, { fromIndex, toIndex }) => {
    if (fromIndex < toIndex) {
      for (let i = fromIndex; i <= toIndex; i++) {
        dispatch('selectMedium', i)
      }
    }
  },
  // Select a range from last select to passed index
  selectRangeFromLastTo: ({ state, dispatch }, toIndex) => {
    // Index of the last selected medium
    let fromIndex = state.selection.media[state.selection.total - 1].index

    if (toIndex > fromIndex) {
      dispatch('selectRange', { fromIndex: fromIndex, toIndex: toIndex })
    } else {
      if (toIndex < fromIndex) {
        dispatch('selectRange', { fromIndex: toIndex, toIndex: fromIndex })
      } else {
        // If the range is one single medium, it's unselected
        dispatch('unselectMedium', { index: toIndex })
      }
    }
  },
  setContent: (context, { content, page = 0 }) => {
    context.commit('setContent', { content, page })
  },
  setFilters: (context, filters) => {
    context.commit('setFilters', filters)
  },
  setFiltersFace: (context, face) => {
    context.commit('setFiltersFace', face)
  },
  setMediumIndex: (context, index) => {
    context.commit('setMediumIndex', index)
  },
  setMediumShowFaces: (context, show) => {
    context.commit('setMediumShowFaces', show)
  },
  setMediumSrc: (context, src) => {
    context.commit('setMediumSrc', src)
  },
  setPageNumber: (context, page) => {
    context.commit('setPageNumber', page)
  },
  setParams: (context, params) => {
    context.commit('setParams', params)
  },
  setResource: (context, resource) => {
    context.commit('setResource', resource)
  },
  setSelection: (context, selection) => {
    context.commit('setSelection', selection)
  },
  toggleSelection: ({ commit, dispatch }) => {
    // If it's selecting, clear selection before toggling
    dispatch('clearSelection').then(() => {
      commit('toggleSelection')
    })
  },
  toggleSelectionItem: ({ state, commit, dispatch }, index) => {
    if (!state.selection.active) {
      // Start selecting while previewing
      commit('toggleSelection')
    }

    if (state.content.media[state.pageNumber][index].selected) {
      dispatch('unselectMedium', { index: index })
    } else {
      dispatch('selectMedium', index)
    }
  },
  toggleMediumShowFaces: ({ state, commit, dispatch }) => {
    // Avoid to make another call if the medium has not changed
    if (!state.medium.showFaces && state.medium.faces.length == 0) {
      dispatch('getMediumFaces')
    }
    commit('toggleMediumShowFaces')
  },
  updateMediumByIndex: ({ commit, getters }, index) => {
    if (getters.isValidIndex) {
      commit('updateMediumByIndex', index)
    }
  },
  unselectMedium: ({ state, commit }, { index, page = state.pageNumber }) => {
    let medium = state.content.media[state.pageNumber][index]

    if (medium.selected) {
      commit('setContentItemSelected', {
        index: index,
        page: page,
        selected: false
      }) // To reflect in the UI
      commit('removeMediumFromSelection', medium.uid)
    }
  },
  // eslint-disable-next-line
  updatePreviewOnPageChange: ({ state, dispatch, getters }, { currentPage, nextPage }) => {
    if (getters.isPreviewing) {
      let index = 0
      // If navigating backwards, goes to the last medium of previous page
      if (nextPage < currentPage) {
        index = state.content.media[nextPage].length - 1
      }

      dispatch('updateMediumByIndex', index)
    }
  }
}

export default {
  namespaced: true,
  state: {
    content: {
      media: [],
      total: 0
    },
    filters: {
      face: {
        coords: null, // face coords
        medium: {
          // medium the face has been taken from
          height: null,
          src: null,
          width: null
        },
        uid: null // face uid
      },
      selfie: {
        src: null,
        id: null
      }
    },
    isLoading: false, // if it's loading media from the backend
    medium: {
      // post being previewed
      index: null,
      height: null,
      src: null,
      uid: null,
      width: null,
      faces: [], // array
      showFaces: false
    },
    pageNumber: 0, // On the backend, page starts at 1
    resource: {
      id: null,
      kind: null // 'album', 'selection'
    },
    selection: {
      active: false,
      media: [], // medium: { index, page, uid }
      total: 0
    },
    settings: {
      mediaPerPage: 48
    }
  },
  getters,
  mutations,
  actions
}
