import Base from './base.js'

export default class Charges {
  async create(params = {}) {
    let url = process.env.VUE_APP_ALBUMS_API_URL + '/api/v1/charges'
    let data = { charge: params }
    // let crsf_param = this._csrf_param()
    // let crsf_token = this._csrf_token()
    return await Base.post(url, data)
  }
}
