export const datetime = {
  methods: {
    getReadableDate(isoDate) {
      let response = isoDate.substring(0, 10) + ', ' + isoDate.substring(11, 16)
      return response
      // Other approaches
      // let date = new Date(isoDate)
      // date.toISOString().substring(0, 10)
      // date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate()
    }
  }
}
