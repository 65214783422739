<template lang="pug">
  .text-center
    .crop-container.mx-auto(
      v-if="src"
      :style="containerStyle")
      b-img(
        v-if="src"
        :src="src"
        :style="imageStyle")
    .position-relative(
      v-else)
      b-avatar(
        :size="diameter"
        variant="light"
        badge-top)
        template(
          v-if="isBeingProcessed || !hasFace"
          #badge)
          Icon(
            v-if="isBeingProcessed"
            class="badge-icon"
            icon="loading")
          span(
            v-else-if="!hasFace") !
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import Icon from '@/components/Icon'

@Component({
  components: {
    Icon
  },

  props: {
    diameter: {
      type: Number,
      default: 120 // pixels
    },
    person: Object, // If coords, dims and src are not passed in, person should be passed in
    faceCoords: Object, // { w, h, x, y }
    mediumDims: Object, // { w, h }
    mediumSrc: String
  }
})
class PeopleAvatar extends Vue {
  get containerStyle() {
    return `max-width: ${this.diameter}px; max-height: ${this.diameter}px`
  }

  get coords() {
    return this.faceCoords
      ? this.faceCoords
      : this.person && this.person.face
      ? this.person.face.coords
      : null
  }

  get dims() {
    return this.mediumDims
      ? this.mediumDims
      : this.person && this.person.face
      ? this.person.face.medium.dims
      : null
  }

  get src() {
    return this.mediumSrc
      ? this.mediumSrc
      : this.person && this.person.face
      ? this.person.face.medium.thumb_url
      : null
  }

  get isBeingProcessed() {
    return this.person.is_being_processed
  }

  get hasFace() {
    return this.person.face
  }

  get imageStyle() {
    if (this.coords) {
      // Math based on face's height being the reference
      let scaleFactor = this.diameter / this.coords.h
      let mt = this.coords.y * scaleFactor
      let ml = (this.coords.x - (this.coords.h - this.coords.w) / 2) * scaleFactor

      return `height: ${this.dims.h * scaleFactor}px;
        margin-top: -${mt}px;
        margin-left: -${ml}px;`
    }

    return ''
  }
}
export default PeopleAvatar
</script>

<style lang="sass" scoped>
.badge-icon
  // Center icon vertically
  vertical-align: 1px

.crop-container
  overflow: hidden
  border-radius: 50%
</style>
