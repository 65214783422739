import BaseAdapter from '@/adapt/base-adapter'
import MyProfileStorageCredentialsAdapter from '@/adapt/my-profile-storage-credentials-adapter'
import PeopleAdapter from '@/adapt/people-adapter'

import BaseModel from '@/models/base_model'
import MyProfile from '@/models/my_profile'

import api from '@/api'
import store from '@/store'

export default class MyProfileAdapter extends BaseAdapter {
  static model = MyProfile
  static persistBy = 'create'

  constructor(params = {}) {
    super(params)
    this.credentials = new MyProfileStorageCredentialsAdapter()
    // There's no static relationships = ['people'] in this class because for now there's no need
    // to load relationships at the same time of my-profile loading. Also, the way loadRelationships
    // is implemented doesn't allow parameters to be passed (e.g. 'is_me,is_dependent')
    this.people = new PeopleAdapter()
    // Get user's email to save this info when adding 'me' as a person. It's saved here (so on
    // profile creation we have the email) and also _afterLoad, because on loading is erases the
    // email field.
    this.data.email = store.state.auth.profile.email
  }

  get url() {
    return '/api/v1/profile'
  }

  async save() {
    if (this.data.avatar.blob && !(await this._uploadAvatar())) {
      return false
    }
    this.data.picture_url = this.data.avatar.url
    return MyProfile.exists() ? await this.update() : await this.create()
  }

  async _afterCreate() {
    MyProfile.create({ data: this.data.$toJson() })
  }

  _afterLoad() {
    // Get user's email to save this info when adding 'me' as a person
    this.data.email = store.state.auth.profile.email
  }

  async _afterUpdate() {
    MyProfile.create({ data: this.data.$toJson() })
  }

  async loadInvitation() {
    if (!this.data.invitation) {
      await this.loadInvitationFromApi()
    }
  }
  // update: if an update of invitation hash should be performed. Everytime
  // updateInvitation is called, it has to call loadInvitationFromApi in
  // order to get the new hash. So if this method is being called from update
  // instead of load it shouldn't call update again in case of error.
  async loadInvitationFromApi(update = true) {
    try {
      let response = await BaseModel.apiFetch({
        url: `${this.url}/invitation`,
        save: false
      })

      if (response && response.data) {
        this.data.invitation = response.data.invitation // adapter persistence
        MyProfile.insertOrUpdate({ data: this.data }) // vuex persistence
      }
    } catch (error) {
      // If the profile has no invitation on the backend yet (not found) this
      // invitation is created through update method
      if (update) {
        await this.updateInvitation()
      }
    }
  }

  async updateInvitation() {
    await this.data.apiUpdate({ url: `${this.url}/invitation` })
    // 'false' by parameter says it shouldn't call updateInvitation again in
    // case of error
    this.loadInvitationFromApi(false)
  }

  async _uploadAvatar() {
    try {
      await this.credentials.loadFromApi()
      // TODO: improve external api communication (william)
      // eslint-disable-next-line
      this.data.avatar.url = await api.s3.create('', this.data.avatar.blob, this.credentials.data, true, null)
      this.data.avatar.url += `?${Date.now()}`
      return true
    } catch (err) {
      this.data.addError('avatar', ['could_not_be_saved'])
      return false
    }
  }
}
