<template lang="pug">
  Instructions(
    :ctaLabel="label"
    icon="photo-video"
    :title="$t('empty-album-title')"
    :description="description"
    @click="$emit('upload')")
</template>

<script>
import { mapGetters } from 'vuex'
import Instructions from '../Instructions'

export default {
  components: {
    Instructions
  },

  computed: {
    ...mapGetters({
      canUpload: 'album/canUpload',
      isMobileDevice: 'ux_controls/isViewportWidthMaxMD'
    }),

    description() {
      if (this.canUpload) {
        if (this.isMobileDevice) {
          return this.$t('empty-album-md-description')
        }

        return this.$t('empty-album-description')
      }

      return this.$t('empty-album-cant-upload')
    },

    label() {
      if (this.canUpload) {
        return this.$t('add-media')
      }

      return null
    }
  }
}
</script>

<i18n>
  pt-BR:
    add-media: "Importar mídias"
    empty-album-title: "Álbum vazio"
    empty-album-cant-upload: "Não há fotos ou vídeos ainda."
    empty-album-description: "Não há fotos ou vídeos ainda. Adicione mídias arrastando-as aqui ou clicando no menu acima."
    empty-album-md-description: "Não há fotos ou vídeos ainda. Adicione novas mídias clicando no menu acima."
</i18n>

<style lang="sass" scoped></style>
