import BaseAdapter from '@/adapt/base-adapter'
import PersonStorageCredentialsAdapter from '@/adapt/person-storage-credentials-adapter'

import Face from '@/models/face'
import Person from '@/models/person'
import Medium from '@/models/medium'
import MyProfile from '@/models/my_profile'

import api from '@/api'
import uuid from 'uuid'

export default class PersonAdapter extends BaseAdapter {
  static model = Person

  constructor(params = {}) {
    super(params)
    this.credentials = new PersonStorageCredentialsAdapter()
  }

  get canEdit() {
    return this.data // && this.data.permissions.includes('all')
  }

  async save() {
    if (this.data.selfie.blob && !(await this._uploadSelfie())) {
      return false
    }
    this.data.medium_url = this.data.selfie.url
    return await this.create()
  }

  get url() {
    return this.data.uid ? `/api/v1/people/${this.data.uid}` : 'api/v1/people'
  }

  _beforeUpdate() {
    // medium_url is required on the backend update action, but not required
    // on the frontend if
    if (this.data.medium_url.length == 0) {
      delete this.data.medium_url
    }

    if (this.data.face_uid.length == 0) {
      delete this.data.face_uid
    }
  }
  // Verify if medium_url or face_uid were removed before updating because they
  // were empty
  async _afterUpdate() {
    if (!this.data.medium_url) {
      this.data.medium_url = ''
    }
    if (!this.data.face_uid) {
      this.data.face_uid = ''
    }
    await Person.insertOrUpdate({ data: this.data.$toJson() })
  }

  async _afterCreate(response) {
    let profile = MyProfile.query().first()
    this.data.profile_user_name = profile.user_name
    this.data.uid = response.data.uid
    await Person.insertOrUpdate({ data: this.data.$toJson() })
    await MyProfile.update({
      where: profile.user_name,
      data: { person_count: profile.person_count + 1 }
    })
  }

  async _beforePersist(data) {
    if (!data.is_being_processed) {
      await Medium.insertOrUpdate({ data: data.medium })
      data.face.medium_uid = data.medium.uid
      await Face.insertOrUpdate({ data: data.face })
      data.face_uid = data.face.uid
    }
  }

  async _uploadSelfie() {
    try {
      await this.credentials.loadFromApi()
      let filename = `${uuid.v4()}.jpg` // always jpg so far
      // TODO: improve external api communication (william, from my-profile-adapter)
      this.data.selfie.url = await api.s3.create(
        filename,
        this.data.selfie.blob,
        this.credentials.data,
        false,
        null
      )
      return true
    } catch (err) {
      this.data.addError('selfie', ['could_not_be_saved'])
      return false
    }
  }
}
