<template lang="pug">
  .collectable-box
    b-card(
      :class="cardClasses"
      :title="collectable.name"
      :img-src="cover"
      img-alt="Image"
      img-top
      tag="article"
      class="mb-2"
      @click="click")
      ProfileAvatarUsername(
        class="mt-3"
        :src="collectable.provider_profile.avatar"
        :username="collectable.provider_profile.username")
      .price-details.d-flex.mt-2
        .price
          div
            small {{ $t('from') }}
          strong R$ {{ collectable.price.toFixed(2).toString().replace('.', ',') }}
        .ml-auto.align-self-end
          Link(
            @click="preview"
            :label="$t('details')")
    Button(
      class="select-icon"
      :key="'select-button-' + selected"
      @click="select")
      Icon(
        :key="'select-icon-' + selected"
        :class="iconClasses"
        :icon="icon")
    CollectablePreviewModal(
      :collectable="collectable"
      :id="COLLECTABLE_PREVIEW_MODAL")
</template>

<script>
import Button from '../../general/Button/ButtonNew'
import CollectablePreviewModal from './CollectablePreviewModal'
import Icon from '../../Icon'
import Link from '../../general/Link'
import ProfileAvatarUsername from '../Profile/ProfileAvatarUsername'

export default {
  components: {
    Button,
    CollectablePreviewModal,
    Icon,
    Link,
    ProfileAvatarUsername
  },

  props: {
    collectable: Object,
    index: Number,
    selected: Boolean
  },

  computed: {
    cardClasses() {
      return this.selected ? 'selected' : ''
    },

    cover() {
      let coverIndex = this.collectable.preview.cover_index

      return this.collectable.preview.media[coverIndex]
        ? this.collectable.preview.media[coverIndex]
        : null
    },

    icon() {
      return this.selected ? 'select-active' : 'circle'
    },

    iconClasses() {
      return this.selected ? 'icon-selected' : 'icon-not-selected black-shadow'
    }
  },

  methods: {
    click() {
      this.$emit('click', this.index, this.collectable.uid)
    },

    preview() {
      this.$bvModal.show(this.COLLECTABLE_PREVIEW_MODAL)
    },

    select() {
      this.click()
    }
  },

  created() {
    this.COLLECTABLE_PREVIEW_MODAL = 'collectable-preview-modal-' + this.index
  }
}
</script>

<i18n>
  pt-BR:
    details: "Ver detalhes"
    from: "A partir de"
</i18n>

<style lang="sass" scoped>
@import '../../../assets/stylesheets/_variables.sass'

.collectable-box
  -webkit-box-sizing: border-box /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box    /* Firefox, other Gecko */
  box-sizing: border-box         /* Opera/IE 8+ */
  margin: 15px 0 !important
  cursor: pointer
  position: relative // To position price tag
  background-color: $primary-color
  border-radius: 8px

.card
  transition: transform .135s cubic-bezier(0.0,0.0,0.2,1)

.card.selected
  transform: translateZ(0px) scale3d(0.95, 0.96, 1)
  transition: transform .135s cubic-bezier(0.0,0.0,0.2,1)

.card-title
  font-size: 1.20rem
  margin-bottom: 0
  text-transform: uppercase

.icon-not-selected
  color: white
  opacity: .5

.icon-selected
  color: $primary-color

.select-icon
  position: absolute
  top: 10px
  left: 10px
</style>
