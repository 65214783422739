// Alerts, tips and processing messages/screens
import keyCodes from '../../consts/keyCodes'

const ALERT_DURATION = 5
const BOOTSTRAP_SM = 576
const BOOTSTRAP_MD = 768

export const getters = {
  isViewportWidthMaxMD: state => {
    if (state.viewportWidth < BOOTSTRAP_MD) {
      return true
    }

    return false
  },
  isViewportWidthMaxSM: state => {
    if (state.viewportWidth < BOOTSTRAP_SM) {
      return true
    }

    return false
  }
}

export const mutations = {
  setAlertCountdown: (state, number) => {
    state.alertCountdown = number
  },
  setAlertMessage: (state, message) => {
    state.alertMessage = message
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading
  },
  setIsShiftPressed: (state, isShiftPressed) => {
    state.isShiftPressed = isShiftPressed
  },
  setKeydownEvent: (state, event) => {
    state.keydownEvent = event
  },
  setKeyupEvent: (state, event) => {
    state.keyupEvent = event
  },
  setLoadingScreenDisplay: (state, isLoading) => {
    state.loadingScreen.isLoading = isLoading
  },
  setLoadingScreenMessage: (state, message) => {
    state.loadingScreen.message = message
  },
  setLoadingScreenOpacity: (state, opacity) => {
    state.loadingScreen.opacity = opacity
  },
  setLoadingScreenType: (state, showProgress) => {
    state.loadingScreen.showProgress = showProgress
  },
  setLoadingScreenProgress: (state, progress) => {
    state.loadingScreen.progress = progress
  },
  setViewportDimensions: (state, { height, width }) => {
    state.viewportHeight = height
    state.viewportWidth = width
  },
  showLoadingScreen: state => {
    state.loadingScreen.isLoading = true
  },
  hideLoadingScreen: state => {
    state.loadingScreen.isLoading = false
  }
}

export const actions = {
  setAlertCountdown: (context, number) => {
    context.commit('setAlertCountdown', number)
  },
  setAlertMessage: (context, message) => {
    context.commit('setAlertMessage', message)
  },
  setIsLoading: (context, isLoading) => {
    context.commit('setIsLoading', isLoading)
  },
  alertCountdownChanged: (context, dismissCountdown) => {
    context.commit('setAlertCountdown', dismissCountdown)
  },
  showMessage: (context, message) => {
    context.commit('setAlertMessage', message)
    context.commit('setAlertCountdown', ALERT_DURATION)
  },
  showLoadingSpinner: context => {
    context.commit('setLoadingScreenMessage', '')
    context.commit('setLoadingScreenOpacity', 1)
    context.commit('setLoadingScreenType', false)
    context.commit('showLoadingScreen')
  },
  hideLoadingSpinner: context => {
    context.commit('hideLoadingScreen')
  },
  setKeydownEvent: ({ commit }, event) => {
    if (event.keyCode == keyCodes.SHIFT) {
      commit('setIsShiftPressed', true)
    }

    commit('setKeydownEvent', event)
  },
  setKeyupEvent: ({ commit }, event) => {
    if (event.keyCode == keyCodes.SHIFT) {
      commit('setIsShiftPressed', false)
    }

    commit('setKeyupEvent', event)
  },
  setLoadingScreenDisplay: (context, isLoading) => {
    context.commit('setLoadingScreenDisplay', isLoading)
  },
  setLoadingScreenMessage: (context, message) => {
    context.commit('setLoadingScreenMessage', message)
  },
  setViewportDimensions: (context, { height, width }) => {
    context.commit('setViewportDimensions', { height, width })
  },
  showProgressBar: (context, message) => {
    context.commit('setLoadingScreenMessage', message)
    context.commit('setLoadingScreenOpacity', 1)
    context.commit('setLoadingScreenType', true)
    context.commit('setLoadingScreenProgress', 0)
    context.commit('showLoadingScreen')
  },
  hideProgressBar: context => {
    context.commit('hideLoadingScreen')
  },
  setLoadingScreen: (context, { message, opacity, showProgress }) => {
    context.commit('setLoadingScreenMessage', message)
    context.commit('setLoadingScreenOpacity', opacity)
    context.commit('setLoadingScreenType', showProgress)
    context.commit('setLoadingScreenProgress', 0)
  },
  updateLoadingProgress: (context, progress) => {
    context.commit('setLoadingScreenProgress', progress)
  },
  restoreLoadingScreen: context => {
    context.commit('setLoadingScreenDisplay', false)
    context.commit('setLoadingScreenOpacity', 1)
    context.commit('setLoadingScreenMessage', '')
    context.commit('setLoadingScreenType', false)
    context.commit('setLoadingScreenProgress', 0)
  }
}

export default {
  namespaced: true,
  state: {
    alertCountdown: 0,
    alertMessage: '',
    isLoading: false,
    isShiftPressed: false,
    keydownEvent: null,
    loadingScreen: {
      isLoading: false,
      opacity: 1,
      message: '',
      showProgress: false,
      progress: 0
    },
    viewportHeight: 0,
    viewportWidth: 0
  },
  getters,
  mutations,
  actions
}
