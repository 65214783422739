<template lang="pug">
  b-modal(
    :id="id"
    :body-class="bodyClass"
    centered
    :hide-header="false"
    :title="title"
    :cancel-disabled="cancelDisabled"
    :cancel-title="cancelTitle"
    :ok-disabled="okDisabled"
    :ok-only="okOnly"
    :ok-title="okTitle"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :scrollable="scrollable"
    :size="size"
    v-model="localValue"
    @ok="$emit('ok', $event)"
    @cancel="$emit('cancel', $event)"
    @close="$emit('close', $event)"
    @change="$emit('change', $event)"
    @hidden="$emit('hidden', $event)")
    .d-block
      p(
        v-if="message") {{ message }}
      slot
</template>

<script>
export default {
  name: 'Popup',

  props: {
    bodyClass: String,
    cancelDisabled: Boolean,
    cancelTitle: {
      type: String,
      default() {
        return this.$t('cancel-title')
      }
    },
    id: String,
    message: String,
    okDisabled: Boolean,
    okOnly: Boolean,
    okTitle: String,
    noCloseOnBackdrop: Boolean,
    scrollable: {
      type: Boolean,
      default: false
    },
    size: String, // sm, lg, xl
    title: String,
    value: Boolean // visible
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    cancel-title: "Cancelar"
</i18n>

<style lang="sass" scoped>
.modal-body.body-no-padding
  padding: 0
</style>
