import BaseModel from '@/models/base_model'

export default class MyProfileStorageCredentials extends BaseModel {
  static entity = 'myProfileStorageCredentials'

  static fields() {
    return {
      url: this.attr(''),
      fields: this.attr('')
    }
  }
}
