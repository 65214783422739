export default class Api {
  constructor(options = {}) {
    if (options.resources != null) {
      Object.entries(options.resources).forEach(([name, klass]) => {
        Object.defineProperty(this, name, { value: new klass() })
      })
    }
  }

  static install(Vue) {
    Vue.mixin({
      beforeCreate: function() {
        if (this.$options.api !== undefined) {
          this.$api = this.$options.api
        } else {
          let api = this.$root.$options.api
          if (api !== undefined) {
            this.$api = api
          }
        }
      }
    })
  }
}
