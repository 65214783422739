import Base from './base.js'
import StorageCredentialError from '../errors/storage-credential-error.js'
import store from '../../store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL

export default class StorageCredentials {
  async getForAlbum(albumId) {
    let url = API_URL + '/api/v1/albums/' + albumId + '/storage_credentials'
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new StorageCredentialError(
        error.status,
        false,
        'Could not get album storage credentials'
      )
    }
  }

  async getForSelfie() {
    let url = API_URL + '/api/v1/storage_credentials'

    try {
      return await Base.get(url)
    } catch (error) {
      throw new StorageCredentialError(
        error.status,
        false,
        'Could not get selfie storage credentials'
      )
    }
  }
}
