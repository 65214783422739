import BasePaginatedCollectionAdapter from '@/adapters/base_paginated_collection_adapter'

import Medium from '@/models/medium'
import AlbumMedium from '@/models/album_medium'
import AlbumMediumAdapter from '@/adapters/album_medium_adapter'

export default class AlbumMediaAdapter extends BasePaginatedCollectionAdapter {
  static model = AlbumMedium
  static targetModel = Medium
  static targetAdapter = AlbumMediumAdapter
  static property = 'media'

  get url() {
    return `${this.parent.url}/media`
  }
}
