<template lang="pug">
b-nav(
  pills)
  b-nav-item(
    class="text-uppercase"
    exact='' exact-active-class='active'
    to="/people") {{ $t('all') }}
  b-nav-item(
    class="text-uppercase"
    disabled
    exact="" exact-active-class="active"
    v-b-tooltip.hover :title="$t('soon')"
    to="/people/groups") {{ $t('groups') }}
  Button(
    class="ml-auto"
    disabled
    icon="sliders"
    rounded
    size="sm"
    @click="create")
</template>

<script>
import Button from '@/components/company/Button'

export default {
  components: {
    Button
  },

  methods: {
    create() {
      this.$router.push('/people/new')
    }
  }
}
</script>

<i18n>
  pt-BR:
    all: "Todas"
    groups: "Grupos"
    soon: "Em breve"
</i18n>

<style lang="sass" scoped>
.nav
 box-shadow: inset 0 -3px 0 #f8f8f8

.nav-link
  border-bottom: 3px solid #f8f8f8 // avoid layout break when opening person modal
  color: grey

.nav-pills .nav-link.active, .nav-pills .show > .nav-link
  color: black
  background-color: white
  border-bottom: 3px solid #000000
  border-radius: 0
</style>
