<template lang="pug">
  Form(
    ref="formWrapper"
    :model="person"
    :no-button="noButton"
    @submit="$emit('submit')")
    PersonFields(
      :fields="fields"
      v-model="person")
</template>

<script>
import Form from '@/components/Shared/Form'
import PersonFields from '@/components/flows/fields/PersonFields'

export default {
  components: {
    Form,
    PersonFields
  },

  props: {
    fields: Array, // ['selfie', 'name', 'email', 'phone', 'allow_image_usage']
    person: Object,
    noButton: {
      type: Boolean,
      default: true
    }
  }
}
</script>
