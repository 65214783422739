import BaseAdapter from '@/adapters/base_adapter'

import MyProfileStorageCredentials from '@/models/my_profile_storage_credentials'

export default class MyProfileStorageCredentialsAdapter extends BaseAdapter {
  static model = MyProfileStorageCredentials
  static persistBy = 'create'

  get url() {
    return `${this.parent.url}/storage_credentials`
  }
}
