class _Set {
  constructor() {
    this._data = []
  }

  add(item) {
    if (this._data.indexOf(item) < 0) {
      this._data.push(item)
    }
  }

  delete(item) {
    let index = this._data.indexOf(item)
    if (index >= 0) {
      this._data.splice(index, 1)
    }
  }

  contains(item) {
    return this._data.indexOf(item) < 0 ? false : true
  }

  clear() {
    this._data.splice(0)
  }

  get length() {
    return this._data.length
  }

  get data() {
    return this._data
  }
}

export default _Set
