<template lang="pug">
  SelectInput(
    :label="$t('fields.' + name)"
    :name="name"
    :options="options"
    :rules="rules"
    v-model="inputValue")
</template>

<script>
import { plans } from '../../../mixins/resources/plans'
import SelectInput from '../../inputs/SelectInput'

export default {
  mixins: [plans], // getPlans

  components: {
    SelectInput
  },

  data() {
    return {
      options: []
    }
  },

  props: {
    name: String,
    rules: String,
    value: String
  },

  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    // Add 'free' option, user's plans as options and 'create' option
    async addOptions() {
      this.options.push({ value: null, text: this.$t('free') })

      let response = await this.getPlans() // [{ name, uid } ...]

      response.plans.forEach(plan => {
        this.options.push({ value: plan.uid, text: plan.name })
      })

      this.options.push({ value: 'create', text: this.$t('create') })
    }
  },

  mounted() {
    this.addOptions()
  }
}
</script>

<i18n>
  pt-BR:
    create: "Criar um novo plano..."
    free: "Grátis"
</i18n>

<style lang="sass" scoped></style>
