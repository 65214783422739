<template lang="pug">
  b-nav(
    pills)
    b-nav-item(
      v-for="(item, index) in items"
      :key="index"
      class="text-uppercase"
      exact=""
      exact-active-class="active"
      :to="item.to") {{ item.text }}
</template>

<script>
export default {
  props: {
    items: Array // [{ text, to }]
  }
}
</script>

<style lang="sass" scoped>
.nav
 box-shadow: inset 0 -3px 0 #f8f8f8

.nav-link
  border-bottom: 3px solid #f8f8f8 // avoid layout break when opening person modal
  color: grey

.nav-pills .nav-link.active, .nav-pills .show > .nav-link
  color: black
  background-color: white
  border-bottom: 3px solid #000000
  border-radius: 0
</style>
