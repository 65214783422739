<!-- This component was based on the old People/CollapsePaginated. That
  component had features to paginate and to adjust according to the screen
  size. Those features were kept here, but not touched so far. -->
<template lang="pug">
  Modal(
    :id="id"
    size="lg"
    :title="$t('title')"
    @ok="$emit('ok')"
    @cancel="$emit('cancel')")
    b-row(
      align-v="center"
      class="text-center")
      b-col(cols="1")
        Button(
          v-show="showPrevious"
          :disabled="disablePrevious"
          btn-class="float-left"
          icon="left"
          rounded
          @click="$emit('previous')")
      b-col(cols="10")
        .person-selfie(
          v-for="(albumPerson, index) in albumPeople"
          :key="albumPerson.data.person_uid")
          PersonCard(
            label-pattern="first-only"
            :person="albumPerson.data.person"
            @click="filterByPerson(albumPerson.data)")
      b-col(cols="1")
        Button(
          v-show="showNext"
          :disabled="disableNext"
          btn-class="float-right"
          icon="right"
          rounded
          @click="$emit('next')")
</template>

<script>
import { mapGetters } from 'vuex'

import Button from '@/components/company/Button'
import Modal from '@/components/general/Popup'
import PersonCard from '@/components/People/PersonCard'

export default {
  components: {
    Button,
    Modal,
    PersonCard
  },

  props: {
    albumPeopleAdapter: Object,
    id: String
  },

  computed: {
    ...mapGetters({
      isMobileDevice: 'ux_controls/isViewportWidthMaxSM'
    }),

    albumPeople() {
      return this.albumPeopleAdapter ? this.albumPeopleAdapter.data : null
    },

    getSize() {
      return this.isMobileDevice ? 'sm' : ''
    },

    disableNext() {
      return this.albumPeopleAdapter
        ? this.albumPeopleAdapter.page * this.albumPeopleAdapter.perPage >=
            this.albumPeopleAdapter.count
        : true
    },

    disablePrevious() {
      return this.albumPeopleAdapter ? this.albumPeopleAdapter.page == 1 : true
    },

    showNext() {
      return !this.disableNext
    },

    showPrevious() {
      return !this.disablePrevious
    }
  },

  methods: {
    filterByPerson(albumPerson) {
      this.$emit('filter', albumPerson)
      this.$bvModal.hide(this.id)
    }
  }
}
</script>

<i18n>
  pt-BR:
    title: 'Filtros'
</i18n>

<style lang="sass" scoped>
.person-selfie
  display: inline-block
  padding: 14px
</style>
