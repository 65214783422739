<template lang="pug">
  span#handle-invitation-flow
    ChooseMembersStep(
      v-show="currentStep == 1"
      id="choose-members-step"
      :invitation="invitation"
      :peopleAdapter="peopleAdapter"
      v-model="selectedPeople"
      @ok="next")
</template>

<script>
import ChooseMembersStep from '@/components/flows/steps/ChooseMembersStep'

export default {
  components: {
    ChooseMembersStep
  },

  props: {
    invitation: Object, // { host, id }
    peopleAdapter: Object
  },

  data: () => ({
    currentStep: 1,
    selectedPeople: []
  }),

  computed: {
    hasDependents() {
      return this.peopleAdapter.data.length > 1
    },

    meId() {
      return this.peopleAdapter.data[0] ? this.peopleAdapter.data[0].data.uid : ''
    }
  },

  methods: {
    next() {
      switch (this.currentStep) {
        case this.CHOOSE_MEMBERS:
          this.$emit('join', this.selectedPeople)
          this.currentStep++
          break
        default:
          this.currentStep++
      }
    }
  }, // methods

  beforeMount() {
    // If the user has no dependents, the CHOOSE_MEMBERS step is not needed. It
    // means that he's enrolling himself to the collection
    if (!this.hasDependents) {
      this.selectedPeople.push(this.meId)
      this.next()
    }
  },

  created() {
    this.CHOOSE_MEMBERS = 1
  }
}
</script>
