<template lang="pug">
  .text-center
    span#loading-icon(
      v-if="isLoading")
      i(class='loading-icon fas fa-spinner fa-spin')
    span(
      v-else)
      #order-payment(
        v-if="payment")
        p Leia o código abaixo e faça o pagamento via PIX para ter acesso ao seu pedido.
        img(
          class="qrcode"
          :src="qrcode")
        p Caso não consiga ler o QR Code, clique no botão abaixo para copiar o código PIX e efetuar o pagamento.
        b-button(
          variant="primary"
          @click="copyPix") Copiar código PIX
      #payment-error(
        v-else)
        p {{ $t('error') }}
        b-button(
          variant="primary"
          @click="generatePayment") {{ $t('label') }}
      //- Link(
        v-if="link"
        :link="link"
        label="Link para code")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Icon from '../../Icon'
// import Link from '../../general/Link'

export default {
  components: {
    Icon
    //   Link
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState({
      order: state => state.order.current,
      payment: state => state.cart.payment
    }),

    isPaid() {
      return this.order.paidAt
    },

    qrcode() {
      if (this.payment && this.payment.pix) {
        return this.payment.pix.qrcode
      }

      return null
    }
  },

  methods: {
    ...mapActions({
      createPayment: 'cart/createPayment',
      showMessage: 'ux_controls/showMessage'
    }),

    copyPix() {
      if (this.payment && this.payment.pix) {
        navigator.clipboard.writeText(this.payment.pix.qrcode_text)
        this.showMessage('O código PIX foi copiado para a área de transferência.')
      }
    },

    async generatePayment() {
      this.isLoading = true
      try {
        if (!this.isPaid) {
          await this.createPayment(this.order.id)
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }
  },

  mounted() {
    this.generatePayment()
  }
}
</script>

<i18n>
  pt-BR:
    label: "Pagar via PIX"
    error: "Não conseguimos gerar o pagamento. Verifique sua internet e clique no botão abaixo para tentar novamente."
</i18n>

<style lang="sass" scoped>
@import '../../../assets/stylesheets/_variables.sass'

.qrcode
  max-width: 250px

#loading-icon
  color: $secondary-color
  font-size: 50px
</style>
