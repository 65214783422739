<template lang="pug">
  BaseView
    Navbar(
      back-click
      :title="title"
      @back="handleBack")
    FormWrapper.py-3
      Nav.mb-4(
        :items="items")
      router-view
      //- router-view: ShowRootTab and ShowDependentTab
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapActions } from 'vuex'

import BaseView from '@/layouts/Base'
import FormWrapper from '@/layouts/FormWrapper'
import MyProfileAdapter from '@/adapt/my-profile-adapter'
import Nav from '@/components/general/Nav'
import Navbar from '@/components/Shared/Navbar'

@Component({
  components: {
    BaseView,
    FormWrapper,
    Nav,
    Navbar
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    })
  }
})
class Show extends Vue {
  items = [
    { text: this.$t('nav-my-profile'), to: '/profile' },
    { text: this.$t('nav-dependents'), to: '/profile/dependents' }
  ]
  myProfileAdapter = new MyProfileAdapter()

  async handleBack() {
    // Reload myProfile because it could have been edited
    await this.myProfileAdapter.loadFromStore()
    this.$router.push({
      name: 'profile',
      params: { userName: this.myProfileAdapter.data.user_name }
    })
  }

  beforeMount() {
    this.myProfileAdapter.loadFromStore()
  }

  get title() {
    return !this.myProfileAdapter.newRecord ? this.$t('update-title') : this.$t('create-title')
  }
}
export default Show
</script>

<i18n>
  pt-BR:
    create-title: "Criar perfil"
    update-title: "Editar perfil"
    nav-dependents: "Dependentes"
    nav-my-profile: "Meu perfil"
</i18n>
