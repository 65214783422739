<template lang="pug">
  b-form-group(
    :label="label"
    v-slot="{ ariaDescribedby }")
    ValidationProvider(
      :name="name"
      :rules="rules"
      v-slot="{ errors, classes }")
      div(
        :class="classes")
        b-form-radio(
          v-for="option in options"
          v-model="localValue"
          :inline="inline"
          :key="option.value"
          :value="option.value"
          :aria-describedby="ariaDescribedby"
          :size="size") {{ option.text }}
        .field-error(v-show='errors.length>0' class='is-invalid') {{ errors[0] }}.
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },

  // In case you want v-model to work with 'checked' instead of 'value' and
  // 'change' instead of 'input'
  // model: {
  //   prop: 'checked',
  //   event: 'change'
  // },

  props: {
    id: String,
    inline: Boolean,
    label: String,
    name: String,
    options: Array,
    rules: String,
    size: String,
    stacked: Boolean,
    value: {
      type: String,
      required: true
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/stylesheets/_variables.sass"

.field-error
  font-size: 13px
  color: $primary-color
</style>
