<template lang="pug">
  b-pagination.p-5(
    v-if="pageCount > 1"
    align="center"
    :aria-controls="ariaControls"
    :hide-goto-end-buttons="hideButtons"
    :per-page="perPage"
    size="lg"
    :total-rows="totalRows"
    v-model="pageNumber"
    @change="$emit('change')")
    template(#first-text)
      i(class="fas fa-angle-double-left")
    template(#prev-text)
      i(class="fas fa-angle-left")
    template(#next-text)
      i(class="fas fa-angle-right")
    template(#last-text)
      i(class="fas fa-angle-double-right")
</template>

<script>
export default {
  props: {
    ariaControls: String, // If it's controlling another component e.g. b-table
    value: Number,
    perPage: Number,
    totalRows: Number
  },

  data() {
    return {
      windowWidth: 0
    }
  },

  computed: {
    pageNumber: {
      get() {
        return this.value
      },
      set(page) {
        this.$emit('input', page)
      }
    },

    hideButtons() {
      if (this.windowWidth < this.SCREEN_SIZE_SM) {
        return true
      }

      return false
    },

    pageCount() {
      return Math.ceil(this.totalRows / this.perPage)
    }
  },

  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    }
  },

  created() {
    this.SCREEN_SIZE_SM = 540 // Based on Bootstrap grid

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="sass" scoped></style>
