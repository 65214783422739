<template lang="pug">
  #loading.fixed-top.d-flex.flex-column.align-items-center.justify-content-center.vph-100(
    v-if='isLoading')
    p.instruction-description.text-center.p-5 {{ message }}
    span#progress-bar(v-if='showProgressBar')
      ProgressBar(:progress='progress')
    span#loading-icon(v-else)
      i(class='loading-icon fas fa-spinner fa-spin'
  )
</template>

<script>
import { mapState } from 'vuex'
import ProgressBar from './ProgressBar.vue'

export default {
  components: {
    ProgressBar
  },
  computed: {
    ...mapState({
      isLoading: state => state.ux_controls.loadingScreen.isLoading,
      message: state => state.ux_controls.loadingScreen.message,
      progress: state => state.ux_controls.loadingScreen.progress,
      showProgressBar: state => state.ux_controls.loadingScreen.showProgress
    })
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/stylesheets/_variables.sass'

#loading
  background-color: white
  // opacity: .7

#loading-icon
  color: $secondary-color
  font-size: 50px

#progress-bar
  width: 150px
</style>
