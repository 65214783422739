<template lang="pug">
  CheckboxInput(
    :id="id"
    :label="label"
    :name="name"
    :options="options"
    :rules="rules"
    size="lg"
    v-model="localValue")
</template>

<script>
import CheckboxInput from '@/components/inputs/CheckboxInput'

export default {
  components: {
    CheckboxInput
  },

  props: {
    description: String,
    id: {
      type: String,
      default: 'people-checkbox-id'
    },
    label: String,
    name: {
      type: String,
      default: 'people-checkbox-name'
    },
    peopleAdapter: Object,
    rules: String, // 'required'
    value: Array
  },

  data() {
    return {
      options: []
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }, // computed

  mounted() {
    this.options = this.peopleAdapter.data.map(personAdapter => ({
      text: personAdapter.data.is_me
        ? `${personAdapter.data.name} (${this.$t('me')})`
        : personAdapter.data.name,
      value: personAdapter.data.uid
    }))
  }
}
</script>

<i18n>
  pt-BR:
    me: "eu"
</i18n>

<style lang="sass" scoped>
@import "@/assets/stylesheets/_variables.sass"

.field-error
  font-size: 13px
  color: $primary-color
</style>
