import Vue from 'vue'
import VueRouter from 'vue-router'

import uri from '../consts/uri.js'
import {
  beforeEach,
  homeBeforeEnter,
  loginBeforeEnter,
  logoutBeforeEnter,
  orderBeforeEnter,
  // postBeforeEnter,
  selectionBeforeEnter
} from './route_guards.js'

// Index - Public landing page
import Index from '@/views/Index'
// Albums
import AlbumsDeliveries from '@/views/Albums/Deliveries/Index'
import AlbumsEdit from '@/views/Albums/Edit'
import AlbumsIndex from '@/views/Albums/Index'
import AlbumsNew from '@/views/Albums/New'
import AlbumsShow from '@/views/Albums/Show'
// Invites
import InviteShow from '@/views/Invites/Show'
import InviteJoin from '@/views/Invites/Join'
// People
// import GroupList from '@/components/People/GroupList'
import PeopleEdit from '@/views/People/Edit'
import PeopleIndex from '@/views/People/Index'
import PeopleTab from '@/views/People/tabs/PeopleTab'
import PeopleGroupsTab from '@/views/People/tabs/PeopleGroupsTab'
import PeopleShow from '@/views/People/Show'
// My Profile
import MyProfileDependentsNew from '@/views/MyProfile/Dependents/New'
import MyProfileNew from '@/views/MyProfile/New'
import MyProfileShow from '@/views/MyProfile/Show'
import MyProfileShowDependentsTab from '@/views/MyProfile/tabs/ShowDependentsTab'
import MyProfileShowRootTab from '@/views/MyProfile/tabs/ShowRootTab'
// Profiles
import ProfilesShow from '@/views/Profiles/Show'
// Orders
import Order from '../views/Order'
import Orders from '../views/Orders'
// Selections
import Selection from '../views/Selection'
import SelectionsHome from '../views/SelectionsHome'
// Others
import Download from '../views/Download'
import Login from '../views/Login'
import NotFound from '../views/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: uri.LOGIN_REDIRECT_URI,
    beforeEnter: loginBeforeEnter
  },
  {
    path: uri.LOGOUT_REDIRECT_URI,
    beforeEnter: logoutBeforeEnter
  },
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/home',
    name: 'home',
    component: AlbumsIndex,
    beforeEnter: homeBeforeEnter
  },
  {
    path: '/albums/new',
    name: 'albums-new',
    component: AlbumsNew,
    props: route => ({ visibility: route.query.v })
  },
  {
    path: '/albums/:uid/deliveries',
    name: 'album-deliveries',
    component: AlbumsDeliveries,
    props: route => ({ uid: route.params.uid })
  },
  {
    path: '/albums/:uid/edit',
    name: 'album-edit',
    component: AlbumsEdit,
    props: route => ({ uid: route.params.uid })
  },
  {
    path: '/albums/:uid',
    name: 'album-show',
    component: AlbumsShow,
    props: route => ({
      uid: route.params.uid,
      personId: route.query.p
    })
  },
  {
    path: '/download',
    name: 'download',
    component: Download
  },
  {
    path: '/:username/invites/:uid',
    name: 'invite-show',
    component: InviteShow,
    props: route => ({
      uid: route.params.uid,
      username: route.params.username
    })
  },
  {
    path: '/:username/invites/:uid/join',
    name: 'invite-join',
    component: InviteJoin,
    props: route => ({
      uid: route.params.uid,
      username: route.params.username
    })
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders
  },
  {
    path: '/orders/:id',
    name: 'order',
    component: Order,
    beforeEnter: orderBeforeEnter
  },
  {
    path: '/people',
    component: PeopleIndex,
    children: [
      { path: 'groups', component: PeopleGroupsTab, name: 'people-groups' },
      { path: '', component: PeopleTab, name: 'people' }
    ]
  },
  {
    path: '/people/:uid',
    name: 'people-show',
    component: PeopleShow,
    props: true // route.params will be set as the component props
  },
  {
    path: '/people/:uid/edit',
    name: 'people-edit',
    component: PeopleEdit,
    props: true // route.params will be set as the component props
  },
  {
    path: '/profile',
    component: MyProfileShow,
    children: [
      { path: 'dependents', component: MyProfileShowDependentsTab, name: 'my-profile-dependents' },
      { path: '', component: MyProfileShowRootTab, name: 'my_profile' }
    ]
  },
  {
    path: '/profile/dependents/new',
    name: 'my-profile-dependents-new',
    component: MyProfileDependentsNew
  },
  {
    path: '/profile/new',
    name: 'my-profile-new',
    component: MyProfileNew,
    props: route => ({
      invitationId: route.query.i, // hash
      invitationHost: route.query.p // username
    })
  },
  {
    path: '/p/:userName',
    name: 'profile',
    component: ProfilesShow,
    props: route => ({ userName: route.params.userName })
  },
  {
    path: '/selections',
    name: 'selections',
    component: SelectionsHome
  },
  {
    path: '/s/:id',
    name: 'selection',
    component: Selection,
    beforeEnter: selectionBeforeEnter
    // children: [{
    //   path: 'p/:mediumId',
    //   component: Post,
    //   beforeEnter: postBeforeEnter,
    //   props: true,
    //   meta: {
    //     showModal: true
    //   }
    // }]
  },
  {
    path: '/404',
    alias: '*',
    name: 'notFound',
    component: NotFound
    // component: () =>
    // import(/* webpackChunkName: "NotFound" */ NotFound)
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => await beforeEach(to, from, next))

export default router
