<template lang="pug">
  b-dropdown(
    :menu-class="getMenuClass"
    :no-caret="noCaret"
    :right="right"
    :toggle-class="getToggleClass")
    template(v-slot:button-content)
      slot(name="button-content")
    b-dropdown-item(
      v-for="(option, index) in options"
      :key="index"
      :link-class="getLinkClass"
      :style="getLinkStyle"
      @click="select(option.value)")
      Icon(:icon="option.icon" classes="align-middle")
      strong.align-middle.pl-3 {{ option.text }}
</template>

<script>
import Icon from '@/components/Icon'

export default {
  components: {
    Icon
  },

  props: {
    linkClass: String,
    menuClass: String,
    noCaret: {
      type: Boolean,
      default: true
    },
    options: Array, // { value, text, icon }
    right: {
      type: Boolean,
      default: true
    },
    toggleClass: String,
    width: {
      type: Number,
      default: 200
    }
  },

  computed: {
    getLinkClass() {
      return `p-3 ${this.linkClass}`
    },

    getLinkStyle() {
      return `width: ${this.width}px`
    },

    getMenuClass() {
      return `shadow border-0 p-3 border-radius mt-2 ${this.menuClass}`
    },

    getToggleClass() {
      return `p-0 bg-transparent border-0 shadow-none ${this.toggleClass}`
    }
  },

  methods: {
    select(option) {
      this.$emit('select', option)
    }
  }
}
</script>
