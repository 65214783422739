import AlbumError from '../errors/album-error.js'
import Base from './base.js'
import store from '../../store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL

export default class Albums {
  async create(params = {}) {
    let url = API_URL + '/api/v1/albums'
    let data = { album: params }
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      throw new AlbumError(error.status, false, 'Could not create an album')
    }
  }

  async createAlbumMedium(albumId, params = {}) {
    let url = API_URL + '/api/v1/albums/' + albumId + '/media'
    let data = { medium: params }
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      throw new AlbumError(error.status, false, 'Could not create album medium')
    }
  }

  async delete(albumId) {
    let url = API_URL + '/api/v1/albums/' + albumId
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.delete(url, config)
    } catch (error) {
      throw new AlbumError(error.status, false, 'Could not create an album')
    }
  }

  async get(albumId) {
    let url = API_URL + '/api/v1/albums/' + albumId
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new AlbumError(error.status, false, 'Could not get an album')
    }
  }

  // GET    /api/v1/albums/:album_uid/media
  // params:
  //  selfie_id (optional): search for similarity if present
  //  face_id (optional): search for similarity if present
  //  min_score (float, optional): used only if selfie_id is present; backend sets a default
  //  page: page number for pagination
  //  per_page: number of media per page
  // response:
  //   media array
  async getMedia(albumId, params = {}) {
    let url = API_URL + '/api/v1/albums/' + albumId + '/media'
    let config = {
      headers: store.getters['auth/getAxiosHeader'].headers,
      params: params
    }

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new AlbumError(error.status, false, 'Could not get album media')
    }
  }

  // GET "/api/v1/albums/"
  // params:
  //  page (optional): page number for pagination
  //  per_page (optional): number of media per page
  // response:
  // object:
  //   total: number of albums
  //   albums: array of albums
  async getUserAlbums(params = {}) {
    let url = API_URL + '/api/v1/albums/'
    let config = {
      headers: store.getters['auth/getAxiosHeader'].headers,
      params: params
    }

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new AlbumError(error.status, false, 'Could not get user albums')
    }
  }

  async update(uid, params) {
    let url = API_URL + '/api/v1/albums/' + uid
    let data = { album: params }
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.patch(url, data, config)
    } catch (error) {
      throw new AlbumError(error.status, false, 'Could not update album')
    }
  }

  // GET /api/v1/albums/:album_uid/media/:medium_uid/url(.:format) api/v1/albums/media/url#show
  // get url and filename to download the medium
  async mediumUrlShow(albumId, mediumId) {
    // eslint-disable-next-line
    let url = API_URL + '/api/v1/albums/' + albumId + '/media/' + mediumId + '/url'
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new AlbumError(error.status, false, 'Could not download medium')
    }
  }

  // GET /api/v1/albums/:album_uid/media/:uid(.:format)
  // get temporary link to download original medium
  // Backend controller action: api/v1/albums/media#show {:format=>:json}
  async mediumShow(albumId, mediumId) {
    let url = API_URL + '/api/v1/albums/' + albumId + '/media/' + mediumId
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new AlbumError(error.status, false, 'Could not show medium')
    }
  }
}
