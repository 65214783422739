<template lang="pug">
  .container-fluid
    .row.no-gutters
      .col.col-sm-10.offset-sm-1.col-md-8.offset-md-2.col-lg-6.offset-lg-3
        .p-3.p-sm-4.p-md-5(:class="classes")
          slot
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  props: {
    noCard: {
      type: Boolean,
      default: false
    }
  }
})
class FormWrapper extends Vue {
  get classes() {
    return this.noCard ? '' : 'card'
  }
}
export default FormWrapper
</script>

<style lang="sass" scoped></style>
