<template lang="pug">
  section
    GalleryNavbar
    Gallery(
      @upload="$emit('upload')")
    GalleryPagination
    br
    br
    br
    br
    GalleryStatusBar
</template>

<script>
import { mapActions } from 'vuex'
import Gallery from './Gallery'
import GalleryNavbar from './GalleryNavbar'
import GalleryPagination from './GalleryPagination'
import GalleryStatusBar from './GalleryStatusBar'

export default {
  components: {
    Gallery,
    GalleryNavbar,
    GalleryPagination,
    GalleryStatusBar
  },

  methods: {
    ...mapActions({
      loadMedia: 'gallery/loadMedia'
    })
  },

  mounted() {
    this.loadMedia()
  }
}
</script>

<style lang="sass" scoped></style>
