<template lang="pug">
  ValidationObserver(ref="form" v-slot="{ handleSubmit, reset }")
    form(@submit.prevent="handleSubmit(onSubmit)")
      fieldset.my-3
        TextInput(
          :disabled="disabled"
          :name="person.nameFor('name')"
          :label="person.labelFor('name')"
          v-model="person.name"
          type="text"
          rules="required")
        EmailInput(
          :disabled="disabled"
          :name="person.nameFor('email')"
          :label="person.labelFor('email')"
          v-model="person.email")
        PhoneInput(
          :disabled="disabled"
          :name="person.nameFor('phone')"
          :label="person.labelFor('phone')"
          v-model="person.phone")
        ToggleButton(
          :disabled="disabled"
          :name="person.nameFor('allow_image_usage')"
          :label="person.labelFor('allow_image_usage')"
          v-model="person.allow_image_usage")
    .float-right.pt-3(v-if="showButtons")
      Button.mr-2(
        :label="$t('cancel')"
        @click="$emit('cancel')")
      Button(
        :disabled="submitting || disabled"
        :label="$t('save')"
        variant="primary"
        @click="onSubmit")
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import Component from 'vue-class-component'
import BaseForm from '@/components/Shared/BaseForm'

import Button from '@/components/company/Button'
import EmailInput from '@/components/inputs/EmailInput'
import PhoneInput from '@/components/inputs/PhoneInput'
import TextInput from '@/components/inputs/TextInput'
import ToggleButton from '@/components/inputs/ToggleButton'

@Component({
  components: {
    ValidationObserver,
    BaseForm,
    Button,
    EmailInput,
    PhoneInput,
    TextInput,
    ToggleButton
  },
  props: {
    canUploadSelfie: {
      type: Boolean,
      default: false
    },
    person: Object
  }
})
class Form extends BaseForm {
  model = this.person

  get disabled() {
    return !this.person.belongs_to_me
  }
}
export default Form
</script>

<i18n>
  pt-BR:
    cancel: "Cancelar"
    save: "Salvar"
</i18n>
