<template lang="pug">
  a.chip(
    :class="getLinkClass"
    @click="$emit('click', index)")
    img(
      v-if="src"
      :src="src" width="96" height="96")
    | {{ text }}
    span(
      v-if="close"
      class="closebtn"
      @click.stop="$emit('close', index)") &times
</template>

<script>
export default {
  props: {
    active: Boolean,
    close: Boolean,
    index: Number,
    src: String,
    text: String
  },

  computed: {
    getLinkClass() {
      return this.active ? 'active' : ''
    }
  }
}
</script>

<style lang="sass" scoped>
a
  color: grey
  text-decoration: none
  cursor: pointer

a:hover
  text-decoration: none
  color: grey

a.active
  background-color: grey
  color: white

a.active:hover
  color: white

a.active .closebtn
  color: white

.chip
  display: inline-block
  padding: 0 25px
  height: 46px
  font-size: 18px
  line-height: 46px
  border-radius: 25px
  background-color: #f1f1f1

.chip img
  float: left
  margin: 0 10px 0 -25px
  height: 46px
  width: 46px
  border-radius: 50%

.closebtn
  padding-left: 10px
  color: #888
  font-weight: bold
  float: right
  font-size: 20px
  cursor: pointer
</style>
