<template lang="pug">
  b-modal(
    centered
    :id="modalId"
    ok-only
    :title="$t('title')"
    @hidden="close")

    #order-details(
      v-if="order")
      .d-block.py-3
        .row
          .col-3
            strong {{ $t('code') }}:
          .col-9
            p.code-string {{ order.uid }}
        .row
          .col-3
            strong {{ $t('created-at') }}:
          .col-9
            p {{ getReadableDate(order.created_at) }}
        .row
          .col-3
            strong {{ $t('name') }}:
          .col-9
            p {{ order.name }}
        .row
          .col-3
            strong {{ $t('email') }}:
          .col-9
            p {{ order.email }}
        .row
          .col-3
            strong {{ $t('total-cents') }}:
          .col-9
            p {{ (order.total_cents/100).toFixed(2).toString().replace('.', ',') }}
        .row
          .col-3
            strong {{ $t('paid-at') }}:
          .col-9
            p {{ paidAtValue }}
        .row
          .col-3
            strong {{ $t('export-files') }}:
          .col-9
            b-button.mr-3(
              size="sm"
              @click="copyFilenames(LIGHTROOM)") Lightroom
            b-button.mr-3(
              size="sm"
              @click="copyFilenames(MAC)") Mac
            b-button(
              size="sm"
              @click="copyFilenames(WINDOWS)") Windows
</template>

<script>
import { mapActions } from 'vuex'
import { datetime } from '../../../mixins/datetime.js'
import { orders } from '../../../mixins/resources/orders.js'
import Link from '../../general/Link'

export default {
  mixins: [
    datetime, // getReadableDate
    orders // getMediaFilenames
  ],

  components: {
    Link
  },

  props: {
    modalId: {
      type: String,
      default: 'order-details-modal'
    },
    order: Object
  },

  computed: {
    paidAtValue() {
      if (this.order.total_cents > 0) {
        return this.order.paid_at ? this.getReadableDate(this.order.paid_at) : this.$t('pending')
      }

      return this.$t('not-applied')
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    }),

    close() {
      this.$emit('ok')
    },

    async copyFilenames(env) {
      try {
        let filenames = await this.getMediaFilenames(this.order.uid)

        if (env == this.LIGHTROOM) {
          navigator.clipboard.writeText(filenames.join(', ')) // Copy to the clipboard
        } else {
          navigator.clipboard.writeText(filenames.join(' OR ')) // Copy to the clipboard
        }

        this.showMessage(this.$t('order-media-copied'))
      } catch (error) {
        this.showMessage(error.name + ': ' + this.$t('order-media-error'))
      }
    }
  },

  created() {
    this.LIGHTROOM = 1
    this.MAC = 2
    this.WINDOWS = 3
  }
}
</script>

<i18n>
  pt-BR:
    code: "Código"
    created-at: "Data"
    email: "E-mail"
    export-files: "Ver no"
    name: "Nome"
    not-applied: "Não se aplica (gratuito)"
    order-media-copied: "Os nomes dos arquivos foram copiados para a área de transferência. Cole na busca para filtrá-los."
    order-media-error: "Houve um erro ao copiar os nome de arquivos deste pedido."
    paid-at: "Pagamento"
    pending: "Pendente"
    title: "Detalhes do pedido"
    total-cents: "Valor (R$)"
</i18n>

<style lang="sass" scoped>
p.code-string
  word-break: break-all
</style>
