<template lang="pug">
  BaseView
    div(v-if="personAdapter.loading")
      Loading
    div(v-else)
      Navbar(
        back
        :title="$t('title')")
      div(v-if="!personAdapter.newRecord && personAdapter.canEdit")
        FormWrapper.py-3
          Avatar.mx-auto.mb-3(
            :person="personAdapter.data")
          .mb-2.text-center(
            v-if="faceInformation")
            i.text-muted {{ faceInformation }}
          h5.text-center(
            v-if="personAdapter.data.is_me || personAdapter.data.is_dependent")
            b-badge {{ meOrDependentText }}
          Form(
            action="edit"
            :person="personAdapter.data"
            @cancel="back"
            @submit="submit")
      div(v-else)
        .text-center
          i.mt-5.text-danger.fas.fa-times-circle.fa-5x
          h2.p-4 {{ $t('person-not-found') }}
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

import Avatar from '@/components/People/Avatar'
import BaseView from '@/layouts/Base'
import PersonAdapter from '@/adapt/person-adapter'
import Form from '@/components/People/Form'
import FormWrapper from '@/layouts/FormWrapper'
import Loading from '@/components/Shared/Loading'
import Navbar from '@/components/Shared/Navbar'

@Component({
  components: {
    Avatar,
    BaseView,
    Form,
    FormWrapper,
    Loading,
    Navbar
  },

  props: {
    uid: String
  }
})
class Edit extends Vue {
  personAdapter = new PersonAdapter()

  get faceCoords() {
    return this.personAdapter.data.face ? this.personAdapter.data.face.coords : null
  }

  get faceInformation() {
    return this.personAdapter.data.is_being_processed
      ? this.$t('info-is-being-processed')
      : !this.personAdapter.data.face
      ? this.$t('info-no-face')
      : !this.personAdapter.data.belongs_to_me
      ? this.$t('info-do-not-belong-to-me')
      : null
  }

  get mediumDims() {
    return this.personAdapter.data.face && this.personAdapter.data.face.medium
      ? this.personAdapter.data.face.medium.dims
      : null
  }

  get mediumSrc() {
    return this.personAdapter.data.face && this.personAdapter.data.face.medium
      ? this.personAdapter.data.face.medium.thumb_url
      : ''
  }

  get meOrDependentText() {
    return this.personAdapter.data.is_me ? this.$t('is-me') : this.$t('is-dependent')
  }

  async beforeMount() {
    await this.personAdapter.load({ id: this.uid })
  }

  async submit() {
    if (await this.personAdapter.update()) {
      this.back()
    } else {
      this.$refs.form.showErrors()
    }
  }

  back() {
    this.$router.go(-1) // Go to previous url
  }
}
export default Edit
</script>

<i18n>
  pt-BR:
    person-not-found: "Pessoa não encontrada"
    info-do-not-belong-to-me: "Pessoa adicionada via convite"
    info-is-being-processed: "A imagem está sendo processada..."
    info-no-face: "Nenhum rosto foi encontrado na imagem."
    is-me: "Esta pessoa sou eu"
    is-dependent: "Esta pessoa é meu dependente"
    title: "Editar"
</i18n>
