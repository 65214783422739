<template lang="pug">
  span
    span(
      :class="mainWrapperClass")
      .d-flex.justify-content-center.navbar-title(
        v-if="fixed"
        :class="'fixed-' + fixed")
        strong.d-flex.align-items-center {{ title }}
      .navbar-action-wrapper.d-flex.navbar-height(
        :class="actionWrapperClass")
        .d-flex.justify-content-center.navbar-title.navbar-title-free(
          v-if="!fixed")
          strong.d-flex.align-items-center {{ title }}
        .d-flex.justify-content-center.align-items-center.box(
          v-if="$slots.left"
          :class="slotClass")
          span.mr-auto
            slot(name="left")
        .d-flex.justify-content-center.align-items-center.box(
          v-if="$slots.center"
          :class="slotClass")
          span
            slot(name="center")
        .d-flex.justify-content-center.align-items-center.box(
          v-if="$slots.right"
          :class="slotClass")
          span.ml-auto
            slot(name="right")
</template>

<script>
export default {
  props: {
    color: String,
    fixed: String,
    noBorder: {
      type: Boolean,
      default: false
    },
    title: String
  },

  computed: {
    actionWrapperClass() {
      let classes = ''

      if (this.fixed) {
        classes = `fixed-${this.fixed}`

        if (!this.noBorder) {
          classes += ` border-navbar-fixed-${this.fixed}`
        }
      } else {
        classes = 'position-relative mb-3'

        if (!this.noBorder) {
          classes += ' border-navbar-fixed-top'
        }
      }

      if (this.color) {
        classes += ` bg-${this.color}`
      }

      return classes
    },

    mainWrapperClass() {
      return this.fixed ? `d-flex fixed-${this.fixed}` : ''
    },

    slotClass() {
      return this.fixed ? '' : 'position-relative'
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../assets/stylesheets/_variables.sass'

.navbar-action-wrapper
  padding: .5rem 1rem // Based on Bootstrap-Vue navbar

.box
  flex: 1

.navbar-height
  height: $navbar-height

.navbar-title
  height: $navbar-height
  text-transform: uppercase

.navbar-title-free
  position: absolute
  left: 0
  top: 0
  right: 0
  bottom: 0

.border-navbar-fixed-bottom
  border-top: 1px solid #f8f8f8

.border-navbar-fixed-top
  border-bottom: 1px solid #f8f8f8
</style>
