import BaseModel from '@/models/base_model'

export default class PersonStorageCredentials extends BaseModel {
  static entity = 'personStorageCredentials'

  static fields() {
    return {
      url: this.attr(''),
      fields: this.attr('')
    }
  }
}
