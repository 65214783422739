<template lang="pug">
  BaseView(v-if="isReady")
    Navbar(
      back
      no-profile
      :title="$t('title')")
    FormWrapper.text-center(no-card)
      b-alert(show='' variant='light')
        Button(
          variant="primary"
          :label="$t('cta-label')"
          @click="handleDelivery")
        .mt-2
          b-link(v-b-toggle.collapse-1='') {{ $t('learn-more') }}
          b-collapse#collapse-1.mt-2
            p {{ $t('cta-description') }}
    FormWrapper.mb-5
      .person-list-item(
        v-for="(albumPersonAdapter, index) in people"
        :key="albumPersonAdapter.data.person_uid")
        DeliveryListItem(
          :album-person-adapter="albumPersonAdapter")
    Pagination(
      v-model="albumPeople.currentPage"
      :perPage="albumPeople.perPage"
      :totalRows="albumPeople.count")
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Component from 'vue-class-component'

import { albumDeliveries } from '@/mixins/resources/album-deliveries'
import AlbumPeople from '@/adapt/album-people-adapter'

import BaseView from '@/layouts/Base'
import Button from '@/components/company/Button'
import FormWrapper from '@/layouts/FormWrapper'
import Loading from '@/components/Shared/Loading'
import Navbar from '@/components/Shared/Navbar'
import Pagination from '@/components/company/Pagination'
import DeliveryListItem from '@/components/Albums/People/DeliveryListItem'

@Component({
  mixins: [albumDeliveries], // deliver

  components: {
    BaseView,
    Button,
    DeliveryListItem,
    FormWrapper,
    Loading,
    Navbar,
    Pagination
  },

  props: {
    uid: String
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.accessToken
    })
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    })
  },

  beforeRouteEnter(to, from, next) {
    // In this guard, there's no access to 'this' because it doesn't exist yet.
    // That's why there's is callback in the next call.
    // https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
    next(async vm => {
      if (vm.isLoggedIn) {
        vm.albumPeople = new AlbumPeople(vm.uid)
        try {
          await vm.albumPeople.load()
          vm.isReady = true
          next()
        } catch (error) {
          next({ name: 'notFound' })
        }
      } else {
        next({ name: 'login' })
      }
    })
  }
})
class Index extends Vue {
  albumPeople = null
  isReady = false

  get people() {
    return this.albumPeople ? this.albumPeople.data : null
  }

  async handleDelivery() {
    try {
      await this.deliver(this.uid)
      this.albumPeople.updateDeliveries()
    } catch (error) {
      this.showMessage(`${this.$i18n.t('delivery-error')} (${error.status})`)
    }
  }

  back() {
    this.$router.push(`/albums/${this.uid}`)
  }
}
export default Index
</script>

<i18n>
  pt-BR:
    cta-description: "Ao clicar no botão acima, o envio será feito apenas para pessoas que ainda não receberam suas mídias. Não é possível reenviá-las para uma mesma pessoa."
    cta-label: "Enviar fotos"
    delivery-error: "Erro ao entregas as mídias. Tente novamente mais tarde."
    learn-more: "Saiba mais"
    title: "Envios"
    album-not-found: "Álbum não encontrado"
</i18n>
