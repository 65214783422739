<template lang="pug">
  b-modal.modal(
    :id="modalId"
    :hide-header="true"
    :hide-footer="true"
    no-fade)
    Navbar(
      class="fixed-top"
      no-border)
      template(#right)
        Button.ml-2(
          v-if="canFilter"
          :icon="isShowingFacesIcon"
          :key="isShowingFacesIcon"
          rounded
          styles="border: none"
          variant="outline-light"
          @click="toggleIsShowingFaces")
        Button.ml-2(
          v-if="canDownload"
          icon="download"
          rounded
          styles="border: none"
          variant="outline-light"
          @click="download")
        Button.ml-2(
          icon="x"
          rounded
          styles="border: none"
          variant="outline-light"
          @click="$bvModal.hide(modalId)")
    .d-flex.justify-content-between
      //- Left arrow
      .nav-arrow.d-flex.flex-column.justify-content-center
        div(v-show="!isLoadingMedium && !isFirstMedium")
          Button(
            icon="left"
            rounded
            variant="outline-light"
            @click="toPreviousMedium")
      //- Medium container
      #post-preview-column.d-flex.align-items-center.vph-100
        div(v-if="isLoadingMedium || isLoadingImage")
          Loading
        div(v-else)
          div(v-if="isProcessing")
            p.text.center.text-light {{ $t('being-processed') }}
          div(v-else)
            .image-container
              img.img-fluid(
                ref="image"
                :src="mediumAdapter.data.thumb_url")
              template(v-if="isShowingFaces")
                span(
                  v-for="(face, index) in baseMediumAdapter.faces.data")
                  a.face-frame(
                    :id="`face-frame-${index}`"
                    :style="facePosition(face.coords)")
                  PostFacePopover(
                    :target="`face-frame-${index}`"
                    @add="addFaceToPeople(face)"
                    @filter="filter(face)")
      //- Right arrow
      .nav-arrow.d-flex.flex-column.justify-content-center.fixed-right
        div(v-show="!isLoadingMedium && !isLastMedium")
          Button(
            icon="right"
            rounded
            variant="outline-light"
            @click="toNextMedium")
    //- TODO: Fix tagging is not available on the backend. So the button is being removed for now
    //- .d-flex.fixed-bottom.justify-content-center(
      v-if="isFilteringByPerson")
      Button.mb-3(
        :label="`${currentTabData.name} ${$t('is-not-here')}`"
        :uppercase="false"
        variant="outline-light"
        @click="fixTagging")
    PeopleModal(
      :face="faceToBeAdded"
      :medium="mediumAdapter"
      :id="PEOPLE_MODAL_ID"
      v-model="isAddingPerson")
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import MediumAdapter from '@/adapters/medium_adapter'
import MediumUrlAdapter from '@/adapt/medium-url-adapter'

import { images } from '@/mixins/images.js'
import { files } from '@/mixins/files.js'

import Component from 'vue-class-component'
import keyCodes from '@/consts/keyCodes.js'
import Navb from '@/components/Shared/Navbar'
import Navbar from '@/components/application/Navbar'
import Loading from '@/components/Shared/Loading'
import Button from '@/components/company/Button'
import Icon from '@/components/Icon'
import PeopleModal from '@/components/People/Modal'
import PostFacePopover from '@/components/application/Post/PostFacePopover'

@Component({
  components: {
    Navb,
    Navbar,
    Loading,
    Button,
    Icon,
    PeopleModal,
    PostFacePopover
  },
  props: {
    modalId: String,
    mediumAdapter: Object, // albumMediumAdapter
    canSelect: Boolean,
    canFilter: Boolean,
    canDownload: Boolean,
    currentTabData: Object,
    isFirstMedium: Boolean,
    isLastMedium: Boolean,
    resource: Object
  },
  mixins: [images, files],
  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    })
  },
  computed: {
    ...mapGetters({
      isMobileDevice: 'ux_controls/isViewportWidthMaxSM'
    }),

    ...mapState({
      keydownEvent: state => state.ux_controls.keydownEvent,
      viewportHeight: state => state.ux_controls.viewportHeight,
      viewportWidth: state => state.ux_controls.viewportWidth
    })
  },
  watch: {
    mediumAdapter() {
      this.onMediumChanged()
    },
    keydownEvent() {
      this.onKeydownEvent()
    },
    viewportHeight() {
      this.onViewportChanged()
    },
    viewportWidth() {
      this.onViewportChanged()
    }
  }
})
class PreviewModal extends Vue {
  imageDisplayWidth = null
  imageDisplayHeight = null

  isAddingPerson = false
  isShowingFaces = false
  isLoadingMedium = true
  isLoadingImage = true
  isProcessing = false

  baseMediumAdapter = null

  faceToBeAdded = null

  get isSelected() {
    return this.mediumAdapter && this.mediumAdapter.selected
  }

  get selectMediumIcon() {
    return this.isSelected ? 'medium-selected' : 'medium-unselected'
  }

  get isFilteringByPerson() {
    return this.currentTabData && this.currentTabData.id ? true : false
  }

  get isShowingFacesIcon() {
    return this.isShowingFaces ? 'user-slash' : 'user'
  }

  addFaceToPeople(face) {
    this.faceToBeAdded = face
    this.$bvModal.show(this.PEOPLE_MODAL_ID)
  }

  fixTagging() {
    // console.log('PreviewModal fixTagging person, medium:', this.currentTabData.id, this.mediumAdapter.data.uid)
    this.showMessage(this.$i18n.t('remove-tagging-confirmation'))
  }

  onMediumChanged() {
    this.isShowingFaces = false
    this.isLoadingMedium = false
    this.loadImage()
  }

  onKeydownEvent() {
    if (!this.isAddingPerson) {
      // If PeopleModal is open, keyboard navigation has no effect
      switch (this.keydownEvent.keyCode) {
        case keyCodes.ARROW_LEFT:
          this.toPreviousMedium()
          break
        case keyCodes.ARROW_RIGHT:
          this.toNextMedium()
          break
      }
    }
  }

  onViewportChanged() {
    this.updateImageDisplaySize()
  }

  async loadImage() {
    this.isLoadingImage = true
    try {
      await this.loadImageFromURL(this.mediumAdapter.data.thumb_url)
      this.isProcessing = false
    } catch (err) {
      this.isProcessing = true
    }
    this.isLoadingImage = false
  }

  async download() {
    let mediumUrlAdapter = new MediumUrlAdapter(this.resource, this.mediumAdapter.data.uid)
    let response = await mediumUrlAdapter.loadFromApi()

    if (response && response.data) {
      // In a mobile device, the original image is opened in a new tab, so
      // the user can tap and hold and select save to camera roll.
      if (this.isMobileDevice) {
        this.showMessage(this.$i18n.t('download-mobile'))
        let route = this.$router.resolve({
          name: 'download',
          query: { url: response.data.url }
        })
        // Check if pop-up window failed due to a pop-up blocker
        if (!window.open(route.href, '_blank')) {
          this.$router.push(route.href)
        }
      } else {
        // Desktop device
        this.showMessage(this.$i18n.t('download-desktop'))
        try {
          await this.downloadFile(response.data.url, this.mediumAdapter.data.filename)
        } catch (error) {
          this.showMessage(this.$i18n.t('download-error'))
        }
      }
    } else {
      this.showMessage(this.$i18n.t('download-error'))
    }
  }

  toggleIsShowingFaces() {
    // only show faces if this.$refs.image is rendered
    this.isShowingFaces = !this.isShowingFaces && this.$refs.image
    if (this.isShowingFaces) {
      this.showFaces()
    }
  }

  showFaces() {
    if (!this.mediumAdapter.data.isProcessed) {
      // TODO: fetch medium again from api to update isProcessed status
      this.showMessage(this.$i18n.t('faces-being-processed'))
      this.isShowingFaces = false
    } else {
      this.baseMediumAdapter = new MediumAdapter({
        data: this.mediumAdapter.data
      })
      this.baseMediumAdapter.faces.load()
    }
  }

  facePosition(coords) {
    this.updateImageDisplaySize()
    let ratio = this.imageDisplayWidth / this.mediumAdapter.data.dims.w
    let left = coords.x * ratio
    let top = coords.y * ratio
    let height = coords.h * ratio
    let width = coords.w * ratio
    return `width: ${width}px; height: ${height}px; top: ${top}px; left: ${left}px;`
  }

  filter(face) {
    if (!face.persisted) {
      // TODO: fetch face again from api to updated persisted status
      this.showMessage(this.$i18n.t('face-not-persisted'))
    } else {
      this.$emit('filter', face)
      this.$bvModal.hide(this.modalId)
    }
  }

  updateImageDisplaySize() {
    if (this.$refs.image) {
      this.imageDisplayWidth = this.$refs.image.clientWidth
      this.imageDisplayHeight = this.$refs.image.clientHeight
    }
  }

  toPreviousMedium() {
    if (!this.isLoadingMedium && !this.isFirstMedium) {
      this.isLoadingMedium = true
      this.$emit('toPreviousMedium')
    }
  }

  toNextMedium() {
    if (!this.isLoadingMedium && !this.isLastMedium) {
      this.isLoadingMedium = true
      this.$emit('toNextMedium')
    }
  }

  beforeMount() {
    // This call is made so it doesn't get stuck on loading status, because
    // at the beginning the watcher doesn't detect a medium change
    this.onMediumChanged()
  }

  created() {
    this.PEOPLE_MODAL_ID = 'people-modal-id'
  }
}
export default PreviewModal
</script>

<i18n>
  pt-BR:
    being-processed: "Processando..."
    faces-being-processed: "As faces ainda estão sendo processadas..."
    face-not-persisted: "Esta face ainda não foi completamente processada..."
    download-desktop: "O arquivo está sendo baixado..."
    download-mobile: "Aguarde o carregamento total da foto para download..."
    download-error: "Erro ao baixar mídia. Verifique sua conexão."
    is-not-here: "não está na foto"
    remove-tagging-confirmation: "A marcação foi desfeita."
</i18n>

<style lang="sass" scoped>
@import '@/assets/stylesheets/_variables.sass'

/deep/ .modal-dialog
  max-width: 100%
  margin: 0
/deep/ .modal-content
  border: none
  border-radius: 0
  background-color: #000
  height: 100vh
  overflow: hidden
/deep/ .modal-body
  padding: 0
.image-container
  position: relative
img
  max-height: 100%
  max-width: 100%
.nav-arrow
  flex: 0 0 46px
a.face-frame
  display: block
  position: absolute
  border: 2px solid rgba(255, 255, 255, 0.6)
  box-shadow: 0 0 15px black
  border-radius: 5%
  &:hover
    cursor: pointer
    border: 2px solid rgba(250, 22, 63, 0.6) // primary color
</style>
