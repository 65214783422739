<!-- All things related to the selection and the old filter actions are not
  being used in this file. See Gallery file for more details. -->
<template lang="pug">
  BaseView
    div(v-if="albumAdapter.loading")
      Loading
    div(v-else)
      div(v-if="!albumAdapter.newRecord")
        Navbar(back)
          template(#right)
            Icon(
              id="visibility-icon"
              :icon="visibilityIcon"
              classes="align-middle txt-dark-grey mr-3")
            b-tooltip(
              target="visibility-icon"
              triggers="hover") I am tooltip
            ShowNavbarDropdown(
              :permissions="albumAdapter"
              @select="handleOption")
        .row.no-gutters
          .col.mx-3
            h1.title {{ albumAdapter.data.name }}
        .row.no-gutters
          .col.mx-3
            p.mb-4.description {{ albumAdapter.data.description }}
        GalleryNav(
          :album-people-adapter="albumPeopleAdapter"
          :items="tabs"
          @click="handleNavClick"
          @close="handleNavClose"
          @filter="filterByPerson")
        div(v-if="!mediaAdapter[currentTab] || mediaAdapter[currentTab].loading")
          Loading
        div(v-else)
          div(v-if="mediaAdapter[currentTab].count > 0")
            Gallery(
              ref="gallery"
              :can-select="albumAdapter.canEdit"
              :can-filter="albumAdapter.canEdit"
              :can-download="true"
              :media-adapter="mediaAdapter[currentTab]"
              :current-tab-data="currentTabData"
              :selection="selection"
              @filterByFace="filterByFace")
              //- template(v-slot:statusBarLeft)
                //- Displayed on mobile devices
                Button.d-block.d-md-none(@click="$bvModal.show('selection-modal')")
                  Icon(icon="+")
                //- Displayed on desktop devices, giving the drag and drop option
                Link.d-none.d-md-block(
                  :label="$t('add-to-selection')"
                  @click="$bvModal.show('selection-modal')")
            //- SelectionModal(
              modalId="selection-modal"
              :media="selection.data"
              @ok="$refs.gallery.clearSelection()")
          div(v-else)
            Instructions.mt-4(
              :ctaLabel="label"
              icon="photo-video"
              :title="$t('title')"
              :description="description"
              @click="upload")
      div(v-else)
        AlbumNotFound
        //- .text-center
          i.mt-5.text-danger.fas.fa-times-circle.fa-5x
          h2.p-4 {{ $t('album-not-found') }}
      ImageUpload(
        v-if="albumAdapter.canEdit"
        :albumId="albumAdapter.data.uid"
        :drop="true"
        inputId="uploader"
        :multiple="true")
</template>

<script>
import { mapState } from 'vuex'

import AlbumAdapter from '@/adapt/album-adapter'
import AlbumPeopleAdapter from '@/adapt/album-people-adapter'
import AlbumPersonMediaAdapter from '@/adapt/album-person-media-adapter'

import AlbumNotFound from '@/components/Albums/AlbumNotFound'
import BaseView from '@/layouts/Base'
import Gallery from '@/components/Shared/Gallery'
import GalleryNav from '@/components/Shared/Gallery/GalleryNav'
import ImageUpload from '@/components/Albums/ImageUpload'
import Loading from '@/components/Shared/Loading'
import Navbar from '@/components/Shared/Navbar'
import ShowNavbarDropdown from '@/components/Albums/ShowNavbarDropdown'

import _Set from '@/mixins/set'
import Instructions from '@/components/application/Instructions'
// import Button from '@/components/general/Button/ButtonNew'
import Link from '@/components/general/Link'
import Icon from '@/components/Icon'
// import SelectionModal from '@/components/application/Selection/AddToSelectionModal'

export default {
  components: {
    AlbumNotFound,
    BaseView,
    // Button,
    Icon,
    Gallery,
    GalleryNav,
    ImageUpload,
    Instructions,
    Link,
    Loading,
    Navbar,
    // SelectionModal,
    ShowNavbarDropdown
  },

  props: {
    personId: String,
    uid: String
  },

  data() {
    return {
      albumAdapter: new AlbumAdapter(this.uid),
      albumPeopleAdapter: new AlbumPeopleAdapter(this.uid),
      mediaAdapter: [], // Each media tab; array of AlbumPersonMediaAdapter
      currentTab: 0,
      selection: new _Set(),
      tabs: [{ text: this.$t('global.all-media-tab') }]
    }
  },

  computed: {
    ...mapState({
      filter: state => state.gallery.filters,
      viewportWidth: state => state.ux_controls.viewportWidth
    }),

    currentTabData() {
      return { name: this.tabs[this.currentTab].text, id: this.personId }
    },

    description() {
      return this.albumAdapter.canEdit ? this.$t('description-add-media') : this.$t('description')
    },

    label() {
      return this.albumAdapter.canEdit ? this.$t('add-media') : null
    },

    visibilityIcon() {
      switch (this.albumAdapter.data.visibility) {
        case 'private':
          return 'private'
        case 'public':
          return 'public'
        case 'community':
          return 'users'
        default:
          return 'question'
      }
    }
  },

  methods: {
    // Add tabs for 'me', dependents and eventual filters
    async addInitialTabs() {
      let startIndex = 0

      for (let i = 0; i < this.albumPeopleAdapter.data.length; i++) {
        let person = this.albumPeopleAdapter.data[i].data.person

        if (person.is_dependent || person.is_me || person.uid == this.personId) {
          // If current person is the user (me), a dependent or matches the filter, a tab is added
          this.tabs.push({
            close: !(person.is_dependent || person.is_me), // not a fixed tab; filter is closable
            text: person.name.split(' ')[0], // first name only
            src: person.face.medium.thumb_url,
            query: { p: person.uid }
          })

          if (person.uid == this.personId) {
            startIndex = this.tabs.length - 1
          }
        }
      }

      await this.handleNavClick(startIndex)
    },

    async filterByPerson(albumPerson) {
      // Check if that search had already been performed
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].query && this.tabs[i].query.p == albumPerson.person_uid) {
          this.handleNavClick(i)
          return
        }
      }
      // Create the tab for this new filter
      this.tabs.push({
        close: true,
        query: { p: albumPerson.person.uid },
        src: albumPerson.person.face.medium.thumb_url,
        text: albumPerson.person.name.split(' ')[0] // first name only
      })
      // Go to this new tab and load its media
      await this.handleNavClick(this.tabs.length - 1)
      // TODO: Fix the scroll below. It is not working properly. Sometimes the
      // scrollbar comes back to the start. Also, when the user clicks on
      // a filter that had already been applied, the bar doesn't move
      // because the 'return' in the first for avoids it.
      const el = document.querySelector('.chipset')
      el.scrollLeft = el.scrollWidth - this.viewportWidth + 30
    },

    async handleNavClick(index) {
      if (this.tabs[this.currentTab]) {
        this.$set(this.tabs[this.currentTab], 'active', false)
      }

      if (this.tabs[index]) {
        this.$set(this.tabs[index], 'active', true)
      }

      this.currentTab = index
      // 'await' is needed in order to give time to update personId prop
      await this.$router
        .replace({
          name: 'album-show',
          query: this.tabs[index].query
        })
        .catch(() => {}) // Catch for avoiding error NavigationDuplicated

      await this.loadTab(index)
    },

    async handleNavClose(index) {
      this.tabs.splice(index, 1)
      this.mediaAdapter.splice(index, 1)

      if (this.currentTab >= index) {
        await this.handleNavClick(this.currentTab - 1)
      }
    },

    handleOption(value) {
      switch (value) {
        case 'deliver':
          this.$router.push(`/albums/${this.uid}/deliveries`)
          break
        case 'edit':
          this.$router.replace(`/albums/${this.uid}/edit`)
          break
        case 'upload':
          this.upload()
          break
      }
    },

    async loadTab(index) {
      if (!this.mediaAdapter[index]) {
        if (index > 0) {
          // If it's not the 'all' tabs, it's a filtered tab
          this.$set(this.mediaAdapter, index, new AlbumPersonMediaAdapter(this.uid, this.personId))
        } else {
          // If it's the 'all' tabs
          this.$set(this.mediaAdapter, index, this.albumAdapter.media)
        }

        await this.mediaAdapter[index].load()
      }
    },

    upload() {
      document.getElementById('uploader').click()
    },

    // The methods below are not being used. See details in Gallery file.

    onFilterChanged() {
      if (this.filter.selfie) {
        this.filterBySelfie(this.filter.selfie)
      }
      // TODO: fix filter actions
    },

    filterBySelfie(selfie) {
      let params = `selfie_id=${selfie.id}`
      this.albumAdapter.media.load({ params: params })
    },

    filterByFace(face) {
      let params = `face_uid=${face.uid}`
      this.albumAdapter.media.load({ params: params })
    }
  }, // methods

  async beforeMount() {
    await this.albumAdapter.load({ id: this.uid })

    // Load all people found in this album
    try {
      await this.albumPeopleAdapter.load()
    } catch (error) {
      /* do nothing */
    }

    await this.addInitialTabs() // For me and dependents; not loaded yet
  },

  watch: {
    filter() {
      this.onFilterChanged()
    }
  },

  created() {
    this.MEDIA_DELIVERY_MODAL_ID = 'media-delivery-modal-id'
  }
}
</script>

<i18n>
  pt-BR:
    add-media: "Importar mídias"
    title: "Álbum vazio"
    description: "Não há fotos ou vídeos ainda."
    description-add-media: "@:description Adicione mídias arrastando-as aqui ou clicando no menu acima."
    add-to-selection: "Salvar seleção..."
    album-not-found: "Álbum não encontrado"
</i18n>

<style lang="sass" scoped>
.title
  font-weight: 600

.description
  text-align: justify
  text-justify: inter-word
</style>
