import Vue from 'vue'

import Api from './resources/index.js'
import Albums from './resources/albums.js'
import AlbumDeliveries from './resources/album-deliveries.js'
import Archives from './resources/archives.js'
import Charges from './resources/charges.js'
import Faces from './resources/faces.js'
import Orders from './resources/orders.js'
import Other from './resources/other.js'
import Payments from './resources/payments.js'
import Plans from './resources/plans.js'
import ProfileFollowers from './resources/profile-followers.js'
import S3 from './resources/s3.js'
import Selections from './resources/selections.js'
import Selfies from './resources/selfies.js'
import StorageCredentials from './resources/storage-credentials.js'

Vue.use(Api)

export default new Api({
  resources: {
    albums: Albums,
    albumDeliveries: AlbumDeliveries,
    archives: Archives,
    charges: Charges,
    faces: Faces,
    orders: Orders,
    other: Other,
    payments: Payments,
    plans: Plans,
    profileFollowers: ProfileFollowers,
    s3: S3,
    selections: Selections,
    selfies: Selfies,
    storage_credentials: StorageCredentials
  }
})
