<template lang="pug">
  .container-fluid
    .row.justify-content-center
      .col-sm-6.col-md-4.col-lg-3(
        v-for="(collectable, index) in options"
        :key="'c-' + collectable.uid")
        CollectableOption(
          :collectable="collectable"
          :index="index"
          :selected="isSelected(collectable.uid)"
          @click="toggleOption")
</template>

<script>
import CollectableOption from '../../application/Collectable/CollectableOption'

export default {
  components: {
    CollectableOption
  },

  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    options: Array,
    value: Array
  },

  methods: {
    isSelected(uid) {
      // TODO: see if there's a more efficient way to avoid searching the array
      return this.value.indexOf(uid) > -1
    },

    toggleOption(index, val) {
      let i = this.value.indexOf(val)

      if (i > -1) {
        // Remove the collectable without mutating the 'value' arrray
        this.$emit('input', [...this.value.slice(0, i), ...this.value.slice(i + 1)])
      } else {
        if (this.multiple) {
          this.$emit('input', this.value.concat(val))
        } else {
          this.$emit('input', [val])
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped></style>
