import Base from './base.js'
import SelfieError from '../errors/selfie-error.js'
import store from '../../store'

export default class Selfies {
  async create(data = {}) {
    let url = process.env.VUE_APP_ALBUMS_API_URL + '/api/v1/selfies'
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      throw new SelfieError(false, 'Could not create selfie')
    }
  }
}
