export const images = {
  methods: {
    loadImageFromURL(url) {
      return new Promise((resolve, reject) => {
        let image = new Image()
        image.src = url
        image.onload = () => resolve(image)
        image.onerror = err => reject(err)
      })
    },

    imageBlob(content) {
      if (content) {
        let parts = content.split(';base64,')
        let imageType = parts[0].split(':')[1]
        let decodedData = atob(parts[1])
        let uInt8Array = new Uint8Array(decodedData.length)
        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i)
        }
        return new Blob([uInt8Array], { type: imageType })
      }
      return null
    }
  }
}
