<template lang="pug">
  fieldset
    SelectionRadioInput(
      @input="$emit('input', $event)")
</template>

<script>
import SelectionRadioInput from '../application/Selection/SelectionRadioInput'

export default {
  components: {
    SelectionRadioInput
  },

  props: {
    value: Object
  }
}
</script>

<style lang="sass"></style>
