import Base from './base.js'
import OrderError from '../errors/order-error.js'
import store from '../../store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL

export default class Orders {
  // POST   /api/v1/orders
  // data:
  //  email:
  //  selection_uid:
  //  media: array of uids
  // response:
  //
  async create(params) {
    let url = API_URL + '/api/v1/orders'
    let data = { order: params }

    try {
      return await Base.post(url, data)
    } catch (error) {
      throw new OrderError(false, 'Could not create order')
    }
  }
  // POST /api/v1/orders/:order_uid/archives
  // Start service on Amazon to create zip file. Once each file part is
  // generated, it's sent to S3 and database is updated with the download url
  // data:
  //  email:
  //  selection_uid:
  //  media: array of uids
  // response:
  //
  async createArchive(orderId) {
    let url = API_URL + '/api/v1/orders/' + orderId + '/archives'
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, null, config) // data is null
    } catch (error) {
      throw new OrderError(false, 'Could not create order archive')
    }
  }

  // GET    /api/v1/orders
  // response:
  //   total: number of orders
  //   orders: array of orders, where each position has an order with:
  //    uid:
  //    email:
  //    num_media:
  //    selection:
  //    file: file to deliver the order media
  async get(params = {}) {
    let url = API_URL + '/api/v1/orders'
    let config = {
      headers: store.getters['auth/getAxiosHeader'].headers,
      params: params
    }

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new OrderError(false, 'Could not get user orders')
    }
  }

  // GET /api/v1/orders/:order_uid/media/filenames(.:format), api/v1/orders/media/filenames#index {:format=>:json}
  // GET /api/v1/orders/:order_uid/media(.:format), api/v1/orders/media#index {:format=>:json}
  // response: array of media pertaining to the order, where each position is an object:
  //   uid,
  //   thumbnail_url,
  //   filename: original filename
  async indexMediaFilenames(orderId) {
    let url = API_URL + '/api/v1/orders/' + orderId + '/media/filenames'
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new OrderError(false, 'Could not get order media filenames')
    }
  }

  // GET /api/v1/orders/:order_uid/media(.:format), api/v1/orders/media#index {:format=>:json}
  // response
  //  404: order is not paid
  async getMedia(orderId, params = {}) {
    let url = API_URL + '/api/v1/orders/' + orderId + '/media'
    let config = {
      params: params
    }

    try {
      return await Base.get(url, config)
    } catch (error) {
      throw new OrderError(error.status, false, 'Could not get order media')
    }
  }

  // GET /api/v1/orders/:uid(.:format), api/v1/orders#show {:format=>:json}
  async show(orderId) {
    let url = API_URL + '/api/v1/orders/' + orderId

    try {
      return await Base.get(url)
    } catch (error) {
      throw new OrderError(error.status, false, 'Could not get order')
    }
  }

  // GET /api/v1/orders/:order_uid/media/:medium_uid/url(.:format), api/v1/orders/media/url#show {:format=>:json}
  // get url and filename to download the medium
  async showMediumUrl(orderId, mediumId) {
    // eslint-disable-next-line
    let url = API_URL + '/api/v1/orders/' + orderId + '/media/' + mediumId + '/url'
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.get(url, config)
    } catch (error) {
      // eslint-disable-next-line
      throw new OrderError(error.status, false, 'Could not download order medium')
    }
  }
}
