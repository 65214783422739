<template lang="pug">
  BaseView
    Loading(
      v-if="!isReady")
    NewProfileFlow(
      v-else
      :invitation="invitation"
      :my-profile-adapter="myProfileAdapter"
      @show-message="showMessage($event)")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseView from '@/layouts/Base'
import Loading from '@/components/Shared/Loading'
import MyProfileAdapter from '@/adapt/my-profile-adapter'
import NewProfileFlow from '@/components/flows/NewProfileFlow'

export default {
  components: {
    BaseView,
    Loading,
    MyProfileAdapter,
    NewProfileFlow
  },

  props: {
    invitationHost: String,
    invitationId: String
  },

  data() {
    return {
      myProfileAdapter: new MyProfileAdapter(),
      isReady: false
    }
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    }),

    invitation() {
      return this.invitationHost && this.invitationId
        ? { host: this.invitationHost, id: this.invitationId }
        : null
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      showMessage: 'ux_controls/showMessage'
    })
  },

  beforeRouteEnter(to, from, next) {
    next(async vm => {
      if (vm.isLoggedIn) {
        try {
          await vm.myProfileAdapter.load()
          // The user already has a profile
          if (vm.invitationId && vm.invitationHost) {
            // If it's in the middle of an invitation flow
            if (vm.invitationHost != vm.myProfileAdapter.data.user_name) {
              // Continue the invitation flow
              next(`/${vm.invitationHost}/invites/${vm.invitationId}/join`)
            } else {
              // Redirect the user to its profile page if he's accessing its own invitation
              next({ name: 'profile', params: { userName: vm.invitationHost } })
            }
          } else {
            // Show his profile
            next('/profile')
          }
        } catch (error) {
          // The user has no profile yet
          vm.isReady = true
          next()
        }
      } else {
        // next({ name: 'login' })
        vm.login(to.fullPath)
      }
    })
  }
}
</script>
