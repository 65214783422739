import MediumAdapter from '@/adapters/medium_adapter'
import MediaAdapter from '@/adapt/media-adapter'

import AlbumPerson from '@/models/album_person'
import AlbumPersonMedium from '@/models/album_person_medium'
import Medium from '@/models/medium'

export default class AlbumPersonMediaAdapter extends MediaAdapter {
  static model = AlbumPersonMedium // This model keeps the relationship needed to find the media
  static parentModel = AlbumPerson // AlbumPersonMedia gets media from an AlbumPerson (parent)
  static targetAdapter = MediumAdapter // Encapsulates the targetModel (Medium)

  constructor(albumId, personId, params = {}) {
    super({ id: albumId, kind: 'album' }, params)
    this.albumId = albumId
    this.personId = personId
  }
  // api_v1_album_person_media /api/v1/albums/:album_uid/people/:person_uid/media { GET: index }
  get url() {
    return `/api/v1/albums/${this.resource.id}/people/${this.personId}/media`
  }

  async _beforePersist(data) {
    Medium.insertOrUpdate({ data: data.media })

    data.media.forEach(albumPersonMedium => {
      albumPersonMedium.album_uid = this.albumId
      albumPersonMedium.medium_uid = albumPersonMedium.uid
      albumPersonMedium.person_uid = this.personId
    })
  }

  get _pId() {
    return [this.albumId, this.personId]
  }
}
