<template lang="pug">
  ValidationProvider(
    :name="name"
    :rules="rules"
    v-slot="{ errors }")
    .form-group
      label(:for="name") {{ label }}
      input(
        :disabled="disabled"
        class="form-control"
        :id="name"
        v-model="inputValue"
        :type="type"
        :placeholder="placeholder")
      span.field-error(
        v-show="errors.length > 0"
        class="is-invalid") {{ errors[0] }}.
      small.form-text.text-muted(
        v-if="description") {{ description }}
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },

  props: {
    disabled: Boolean,
    description: String,
    label: {
      type: String,
      default: function() {
        return this.$t('fields.' + this.name)
      }
    },
    name: String,
    placeholder: String,
    rules: String,
    type: {
      type: String,
      default: 'text'
    },
    value: String
  },

  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/stylesheets/_variables.sass"

.field-error
  font-size: 13px
  color: $primary-color
</style>
