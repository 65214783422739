import i18n from '@/i18n'

const viewInstanceMethods = {
  nameFor(field) {
    return `${this.constructor.name}.${field}`
  },

  labelFor(field) {
    return i18n.t(`model.${this.constructor.name}.fields.${field}`)
  }
}

export { viewInstanceMethods }
