<template lang="pug">
  #upload-box(v-show="show")
    #uploading(
      v-if="isUploading")
      #box-header.d-flex.justify-content-between
        .box-header-title {{ $t('uploading-title') }}...
        Button(
          key='btn-abort'
          icon="x"
          @onClick="$emit('stop', $t('abort-message'))")
      div
        .thumbnail-container
          img(
            v-if="files[currentFile] && files[currentFile].blob"
            :src="files[currentFile].blob"
            alt="img"
            class="image-fit")
      ProgressBar(
        class="bottom w-100"
        labeled
        :progress="progress * 100")
    #report(
      v-else)
      #errors(
        v-if="showErrors")
        #box-header.d-flex.justify-content-between
          .box-header-title {{ $t('errors-occurred') }} ({{ errors.length }})
          Button(
            v-if="!isUploaded"
            key='btn-error-icon-close'
            icon="x"
            @onClick="close")
          Button(
            v-else
            key='btn-error-continue'
            icon="right"
            @onClick='viewErrors = false')
        #error-details.m-10px(v-if="viewErrorsDetails")
          .error-line(v-for="error in errors") {{ displayedFilename(error.filename) }} ({{ error.reason }})
        .m-10px
          Link(
            v-show="!viewErrorsDetails"
            :label="$t('view-skipped')"
            @click='viewErrorsDetails = true')
          Link(
            v-show="viewErrorsDetails && canRetry"
            :label="$t('try-again')"
            @click="retry")
      #uploaded(v-else)
        .box-header-title {{ success }} {{ $t('uploaded-items') }}
        Button(
          key="btn-error-label-close"
          :label="$t('close')"
          @onClick="close")
</template>

<script>
import Button from '../Button'
import Icon from '../Icon'
import Link from '../general/Link'
import ProgressBar from '../ProgressBar'

export default {
  components: {
    Button,
    Icon,
    Link,
    ProgressBar
  },

  data() {
    return {
      numberOfFiles: 0,
      isClosing: false,
      show: false,
      viewErrors: true,
      viewErrorsDetails: false // See error details
    }
  },

  props: {
    currentFile: Number,
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    files: {
      type: Array,
      default() {
        return []
      }
    },
    progress: Number,
    success: Number
  },

  computed: {
    canRetry() {
      // Successfully uploaded files and files not uploaded due to network
      // issues remain in the files array.
      return this.files.length > this.success
    },

    hasErrors() {
      return this.errors.length > 0
    },

    isUploading() {
      return this.currentFile < this.files.length
    },

    isUploaded() {
      return this.files.length > 0 && this.currentFile == this.files.length
    },

    showErrors() {
      return this.hasErrors && this.viewErrors
    }
  },

  methods: {
    close() {
      this.resetData()
      this.$emit('close')
    },

    // Make a too long filename to fit the available line space
    displayedFilename(original) {
      if (original.length > this.MAX_FILENAME_LENGTH) {
        return (
          original.substring(0, 10) +
          '...' +
          original.substring(original.length - 10, original.length)
        )
      }

      return original
    },

    resetData() {
      this.numberOfFiles = 0
      this.isClosing = false
      this.show = false
      this.viewErrors = true
      this.viewErrorsDetails = false
    },

    retry() {
      this.resetData
      this.$emit('retry')
    },

    stop() {
      console.log('stop uploading')
    }
  },

  watch: {
    isUploaded() {
      // Controls auto closing
      if (this.isUploaded && !this.hasErrors) {
        this.isClosing = true

        setTimeout(() => {
          if (this.isClosing) {
            // If a new upload didn't start during closing
            this.close()
          }
        }, 5000)
      }
    },

    files() {
      if (this.files.length > this.numberOfFiles) {
        // New files have been added during the upload process
        this.isClosing = false // In case the box is about to be closed
        this.show = true
        this.numberOfFiles = this.files.length
      }
    },

    errors() {
      // If an error has occurred, the box is not auto closed
      if (this.errors.length > 0) {
        this.isClosing = false
        this.show = true
        this.viewErrors = true
        this.viewErrorsDetails = false
      }
    }
  },

  created() {
    this.MAX_FILENAME_LENGTH = 20
  }
}
</script>

<i18n>
  pt-BR:
    abort-message: "O upload está sendo interrompido..."
    close: "Fechar"
    continue: "Continuar"
    errors-occurred: "Encontramos erros"
    stop: "Parar"
    try-again: "Tentar novamente"
    uploading-title: "Enviando mídias"
    uploaded-items: "mídia(s) enviada(s)"
    view-skipped: "Ver detalhes"
</i18n>

<style lang="sass" scoped>
#upload-box
  background-color: white
  box-shadow: 0 1px 3px 0 rgba(60,64,67,.30), 0 4px 8px 3px rgba(60,64,67,.15)
  bottom: 20px
  left: 20px
  border: 0px
  z-index: 1031 // greater than menu 'fixed-top' 1030
  border-radius: 6px
  position: fixed
  width: 290px

button
  float: right

.image-fit
  height: 100%
  width: 100%
  object-fit: cover

.thumbnail-container
  width: 70px
  height: 70px
  display: inline-block

#box-header
  border-bottom: 1px solid #f8f8f8

.box-header-title
  margin: 10px

#error-details
  overflow: scroll
  max-height: 400px

#error-details ul
  padding: 10px 0px 10px 20px

li.error-line
  word-break: break-all

//   opacity: 0
//   height: 0
//   transition: opacity 0.3s, height 0.3s 0.1s

// #upload-box .active
//   opacity: 1
//   height: 30px
//   transition: opacity 0.3s 0.1s, height 0.3s


// #uploaded
//   -moz-animation: cssAnimation 0s ease-in 5s forwards
//   /* Firefox */
//   -webkit-animation: cssAnimation 0s ease-in 5s forwards
//   /* Safari and Chrome */
//   -o-animation: cssAnimation 0s ease-in 5s forwards
//   /* Opera */
//   animation: cssAnimation 0s ease-in 5s forwards
//   -webkit-animation-fill-mode: forwards
//   animation-fill-mode: forwards

// @keyframes cssAnimation
//   to
//     width: 0
//     height: 0
//     overflow: hidden

// @-webkit-keyframes cssAnimation
//   to
//     width: 0
//     height: 0
//     visibility: hidden
</style>
