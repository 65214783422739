<template lang="pug">
  Popup(
    :id="modalId"
    :title="$t('title')"
    @ok="handleOk"
    @cancel="$emit('cancel')"
    @change="updateFilters")
    FilterOptions(
      :filters="editedFilters"
      @clear="clearFaceFilter"
      @filter-by-selfie="filterBySelfie")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { objects } from '../../../mixins/objects.js'
import Popup from '../../general/Popup'
import FilterOptions from './Filter/FilterOptions'

export default {
  mixins: [objects],

  components: {
    FilterOptions,
    Popup
  },

  props: {
    modalId: String
  },

  data() {
    return {
      editedFilters: {
        face: null,
        selfie: null
      }
    }
  },

  computed: {
    ...mapState({
      filters: state => state.gallery.filters
    })
  },

  methods: {
    ...mapActions({
      applyFilters: 'gallery/applyFilters'
    }),

    clearFaceFilter() {
      this.setNull(this.editedFilters.face)
      this.setNull(this.editedFilters.selfie)
    },

    filterBySelfie(selfie) {
      this.setNull(this.editedFilters.face)
      this.editedFilters.selfie.src = selfie.url // 'clone' cannot be used because of the src-url difference
      this.editedFilters.selfie.id = selfie.id
    },

    handleOk() {
      if (!this.objectsAreEqual(this.editedFilters, this.filters)) {
        this.applyFilters(this.editedFilters)
      }
    },
    // Modal is open
    updateFilters() {
      this.editedFilters.face = this.clone(this.filters.face)
      this.editedFilters.selfie = this.clone(this.filters.selfie)
    }
  }
}
</script>

<i18n>
  pt-BR:
    title: 'Filtros disponíveis'
</i18n>

<style lang="sass" scoped></style>
