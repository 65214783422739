<template lang="pug">
  Form(
    ref="formWrapper"
    :model="album"
    :no-button="noButton"
    :ok-only="okOnly"
    @change="$emit('change', $event)"
    @submit="$emit('submit')")
    AlbumFields(
      :action="action"
      :fields="fields"
      v-model="album")
</template>

<script>
import Form from '@/components/Shared/Form'
import AlbumFields from '@/components/flows/fields/AlbumFields'

export default {
  components: {
    Form,
    AlbumFields
  },

  props: {
    action: String, // 'edit', 'new'
    fields: Array, // 'name', 'description', 'media_visibility', 'starts_at', 'visibility', 'watermark', 'recognition'
    album: Object, // AlbumAdapter.data
    noButton: Boolean,
    okOnly: Boolean
  }
}
</script>
