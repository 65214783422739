<template lang="pug">
  .row.no-gutters.justify-content-center
    .col-auto
      br
      br
      .loading
        i.fas.fa-spinner.fa-spin
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component()
class Loading extends Vue {}
export default Loading
</script>

<style lang="sass" scoped>
@import '@/assets/stylesheets/_variables.sass'
.loading
  color: $secondary-color
  font-size: 50px
</style>
