<template lang="pug">
  span#album-fields
    .text-center.my-4(
      v-if="fields.indexOf('visibility') > -1")
      RadioInput(
        inline
        :name="value.nameFor('visibility')"
        :options="visibilityOptions"
        rules="required"
        v-model="value.visibility"
        v-slot="{ ariaDescribedby }")
    TextInput(
      v-if="fields.indexOf('name') > -1"
      :name="value.nameFor('name')"
      :label="value.labelFor('name')"
      v-model="value.name"
      type="text"
      rules="required")
    TextArea(
      v-if="fields.indexOf('description') > -1"
      :name="value.nameFor('description')"
      :label="value.labelFor('description')"
      v-model="value.description")
    DatetimePicker(
      v-if="fields.indexOf('starts_at') > -1"
      :name="value.nameFor('starts_at')"
      :label="value.labelFor('starts_at')"
      v-model="value.starts_at")
    AlbumMediaVisibilityToggleButton.pb-2(
      v-if="fields.indexOf('media_visibility') > -1 && value.visibility == 'community'"
      :name="value.nameFor('media_visibility')"
      :label="value.labelFor('media_visibility')"
      v-model="value.media_visibility")
    ToggleButton.pb-2(
      v-if="!isEditing && fields.indexOf('watermark') > -1"
      :name="value.nameFor('watermark')"
      :label="value.labelFor('watermark')"
      v-model="value.watermark")
    ToggleButton.pb-2(
      v-if="!isEditing && fields.indexOf('recognition') > -1"
      :name="value.nameFor('recognition')"
      :label="value.labelFor('recognition')"
      v-model="value.recognition")
</template>

<script>
import AlbumMediaVisibilityToggleButton from '@/components/flows/inputs/AlbumMediaVisibilityToggleButton'
import DatetimePicker from '@/components/inputs/DatetimePicker'
import RadioInput from '@/components/inputs/RadioInput'
import TextArea from '@/components/inputs/TextArea'
import TextInput from '@/components/inputs/TextInput'
import ToggleButton from '@/components/inputs/ToggleButton'

export default {
  components: {
    AlbumMediaVisibilityToggleButton,
    DatetimePicker,
    RadioInput,
    ToggleButton,
    TextArea,
    TextInput
  },

  props: {
    action: String, // 'edit', 'new'
    fields: Array, // 'visibility', 'name', 'description', 'starts_at', 'watermark', 'recognition'
    value: Object // AlbumAdapter.data
  },

  computed: {
    isEditing() {
      return this.action == 'edit'
    }
  },

  created() {
    this.visibilityOptions = [
      { text: this.$t('private'), value: 'private' },
      { text: this.$t('social'), value: 'community' },
      { text: this.$t('public'), value: 'public' }
    ]
  }
}
</script>

<i18n>
  pt-BR:
    private: "Privado"
    public: "Público"
    social: "Social"
</i18n>
