import api from '../../api'
import store from '../../store'

export const orders = {
  methods: {
    // Get { url, filename } to download the medium
    async downloadOrderMedium(orderId, mediumId) {
      return await api.orders.showMediumUrl(orderId, mediumId)
    },
    // Generate zip file for downloading order media
    async generateArchive(orderId) {
      return await api.orders.createArchive(orderId)
    },

    async getArchive(archiveId) {
      return await api.archives.get(archiveId)
    },
    // Returns the media pertaining to the order array of { uid, thumbnail_url, filename }
    async getMediaFilenames(orderId) {
      return await api.orders.indexMediaFilenames(orderId)
    },

    async loadOrders(params = {}) {
      if (!params.per_page) {
        params.per_page = store.state.order.settings.perPage
      }

      return await api.orders.get(params)
    }
  }
}
