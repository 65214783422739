import Iugu from '../helpers/payment/iugu.js'

export const getters = {
  performPayment: state => ({ user, items }) => {
    return new Promise((resolve, reject) => {
      state.gateway.performPayment(user, items).then(response => {
        if (response.error) {
          reject(response.error)
        } else {
          resolve(response.external_id)
        }
      })
    })
  }
}

export const mutations = {
  initialize: async (state, gatewayId) => {
    switch (gatewayId) {
      case 'stripe':
        break
      default:
        state.gateway = new Iugu()
        await state.gateway.loadScript()
    }
  }
}

export const actions = {
  initialize: context => {
    context.commit('initialize', context.rootState.settings.gateway)
  }
}

export default {
  namespaced: true,
  state: {
    gateway: null
  },
  getters,
  mutations,
  actions
}
