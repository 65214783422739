import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/index.js'
import api from './api/index.js'
import gtm from './plugins/vue-gtm.js'
import { BootstrapVue } from 'bootstrap-vue'
import VueMeta from 'vue-meta'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './assets/stylesheets/global.sass'
import '@/plugins/vee-validate.js'

Vue.use(BootstrapVue)
Vue.use(VueMeta)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  api,
  gtm,
  render: h => h(App)
}).$mount('#app')
