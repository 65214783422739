import api from '../../../api'
import Gateway from './gateway.js'
import { forms } from '../../../mixins/forms'

export default class Iugu extends Gateway {
  constructor() {
    super('iugu')
    this.token = null
    this.script = null
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      let iuguScript = document.createElement('script')
      // The Iugu object was not exposed in the code, so this workaround has been implemented.
      iuguScript.onload = () => {
        this.script = window.Iugu
        this._setParams()
        resolve(this.script)
      }

      iuguScript.onerror = err => {
        reject(err)
      }

      iuguScript.setAttribute('src', 'https://js.iugu.com/v2')
      document.head.appendChild(iuguScript)
    })
  }

  async performPayment(user, items) {
    let creditCard = this._getCreditCardObject(user.card)
    let response = {}

    try {
      response = await this._createToken(creditCard)
    } catch (error) {
      response.error = error
    }

    if (response && !response.error) {
      response = await this._createCharge(user.email, items)
    }

    return response
  }

  async _createCharge(email, items) {
    let response = {}
    let params = this._getChargeParams(email, items)

    if (params) {
      let charge = null

      try {
        charge = await api.charges.create(params)
      } catch (error) {
        response.error = 'charge-creation-error'
      }

      if (charge && charge.external_id) {
        response.external_id = charge.external_id
      }
    } else {
      response.error = 'charge-creation-error'
    }

    return response
  }

  _createToken(creditCard) {
    return new Promise((resolve, reject) => {
      try {
        this.script.createPaymentToken(creditCard, response => {
          if (response.errors) {
            reject('cc-error')
          } else {
            this.token = response.id
            resolve({})
          }
        })
      } catch (error) {
        reject('iugu-error')
      }
    })
  }

  _getChargeParams(email, items) {
    if (this.token && email && items) {
      return {
        token: this.token,
        email: email,
        items: items
      }
    }

    return null
  }

  _getCreditCardObject(card) {
    // User provided full name instead of first and last names
    if (card.name.fullName) {
      card.name.firstName = forms.methods.getFirstName(card.name.fullName)
      card.name.lastName = forms.methods.getLastName(card.name.fullName, false)
    }

    let expirationDate = forms.methods.splitExpirationDate(card.expirationDate)

    let creditCard = this.script.CreditCard(
      card.number,
      expirationDate.month,
      expirationDate.year,
      card.name.firstName,
      card.name.lastName,
      card.verificationCode
    )

    return creditCard
  }

  _setParams() {
    this.script.setAccountID(process.env.VUE_APP_IUGU_ACCOUNT_ID)

    let isTesting =
      process.env.VUE_APP_IUGU_TEST_MODE != undefined ? process.env.VUE_APP_IUGU_TEST_MODE : true

    this.script.setTestMode(isTesting)
  }
}
