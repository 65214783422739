<template lang="pug">
  BaseView
    Navbar(
      back-to="/home"
      :title="$t('title')")
      template(#right)
        InviteDropdown.mr-2(
          :my-profile-adapter="myProfileAdapter"
          @show-message="showMessage($event)")
    Nav
    router-view
</template>

<script>
import { mapActions } from 'vuex'
import BaseView from '@/layouts/Base'
import InviteDropdown from '@/components/People/InviteDropdown'
import Nav from '@/components/People/Nav'
import Navbar from '@/components/Shared/Navbar'
import MyProfileAdapter from '@/adapt/my-profile-adapter'

export default {
  components: {
    BaseView,
    InviteDropdown,
    Nav,
    Navbar
  },

  data() {
    return {
      myProfileAdapter: new MyProfileAdapter()
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    })
  },

  async beforeMount() {
    await this.myProfileAdapter.load()
    await this.myProfileAdapter.loadInvitation()
  }
}
</script>

<i18n>
  pt-BR:
    title: "Pessoas"
</i18n>
