import BasePaginatedCollectionAdapter from '@/adapt/base-paginated-collection-adapter'
import PersonAdapter from '@/adapt/person-adapter'

import Face from '@/models/face'
import Medium from '@/models/medium'
import Person from '@/models/person'
import MyProfile from '@/models/my_profile'

export default class PeopleAdapter extends BasePaginatedCollectionAdapter {
  static model = Person
  static parentModel = MyProfile
  static targetModel = Person
  static targetAdapter = PersonAdapter
  static property = 'people'

  constructor(params = {}) {
    super(params)
    this.myProfile = MyProfile.query().first()
  }

  get url() {
    return '/api/v1/people'
  }

  async _beforePersist(data) {
    let media = data.people.map(person => person.medium)
    let faces = data.people.map(person =>
      person.face
        ? {
            ...person.face,
            ...{ medium_uid: person.medium ? person.medium.uid : null }
          }
        : null
    )

    await Medium.insertOrUpdate({ data: media })
    await Face.insertOrUpdate({ data: faces })

    data.people.forEach(person => (person.face_uid = person.face ? person.face.uid : ''))
  }

  get _pId() {
    return this.myProfile[MyProfile.primaryKey]
  }

  async _setCount(value) {
    await MyProfile.update({
      where: this._pId,
      data: { [this._countField]: value }
    })
    this.count = value ?? 0
  }
}
