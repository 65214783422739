<template lang="pug">
  section.container-fluid
    .row.align-items-center
      .gallery-col.col-4.col-md-3.col-lg-2(
        v-for="(medium, index) in options"
        :key="'m-' + medium.uid")
        Thumbnail(
          :key="'m-'+ medium.uid"
          :index="index"
          :selected="isSelected(medium.uid)"
          :src="medium.thumb_url"
          @click="handleClick")
</template>

<script>
import Thumbnail from '../../company/Thumbnail'

export default {
  components: {
    Thumbnail
  },

  props: {
    min: Number,
    max: Number,
    options: Array,
    value: Array
  },

  methods: {
    isSelected(id) {
      // TODO: see if there's a more efficient way to avoid searching the array
      return this.value.indexOf(id) > -1
    },

    handleClick(index) {
      let val = this.options[index].uid

      let i = this.value.indexOf(val)

      if (i > -1) {
        // Remove the collectable without mutating the 'value' arrray
        this.$emit('input', [...this.value.slice(0, i), ...this.value.slice(i + 1)])
      } else {
        // if (this.multiple) {
        this.$emit('input', this.value.concat(val))
        // } else {
        // this.$emit('input', [val])
        // }
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    limit: "Você já selecionou o número limite de fotos para este item. Avance para a próxima etapa."
</i18n>

<style lang="sass" scoped>
.gallery-col
  padding: 2px
</style>
