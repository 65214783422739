<template lang="pug">
  section.container-fluid
    .row(
      v-if="selections.total > 0 || isLoading")
      .col.col-md-8.offset-md-2.col-lg-6.offset-lg-3
        b-table(
          bordered
          :busy="isLoading"
          fixed
          head-variant="light"
          hover
          id="selections-table"
          :per-page="perPage"
          responsive
          table-variant="light"
          :items="selections.data"
          :fields="fields")
          template(#table-busy)
            div(class="text-center my-5")
              Icon(
                class="icon-sq-xl txt-secondary mt-5"
                icon="loading")
          template(
            #cell(index)="data") {{ (currentPage - 1) * perPage + data.index + 1 }}
          template(
            #cell(name)="data")
            router-link(
              :to="{ name: 'selection', params: { id: data.item.uid }}") {{ data.value }}
          template(
            #cell(link)="data")
            Link(
              @click="copy(data.item.uid)")
              Icon(
                icon="link")
        SelectionPagination
    .row(v-else)
      .col-10.offset-1.col-lg-8.offset-lg-2.col-xl-6.offset-xl-3
        Instructions(
          icon="receipt"
          :title="$t('no-selection-title')"
          :description="$t('no-selection-description')")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Icon from '../../Icon'
import Instructions from '../Instructions'
import Link from '../../general/Link'
import SelectionPagination from './SelectionPagination'

export default {
  components: {
    Icon,
    Instructions,
    Link,
    SelectionPagination
  },

  data() {
    return {
      fields: [
        {
          key: 'index',
          label: '#',
          thStyle: {
            width: '60px'
          },
          class: 'text-center'
        },
        {
          key: 'name',
          label: 'Nome'
        },
        {
          key: 'link',
          label: 'Link',
          thStyle: {
            width: '60px'
          },
          class: 'text-center'
        }
      ]
    }
  },

  computed: {
    ...mapState({
      currentPage: state => state.selection.currentPage,
      isLoading: state => state.selection.isLoading,
      perPage: state => state.selection.settings.perPage,
      selections: state => state.selection.library
    })
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    }),
    // Copy selection url to the clipboard
    copy(selectionId) {
      let url = process.env.VUE_APP_URL + '/s/' + selectionId

      navigator.clipboard.writeText(url)
      this.showMessage(this.$t('copied'))
    }
  }
}
</script>

<i18n>
  pt-BR:
    copied: 'O link da seleção foi copiado para a área de transferência.'
    no-selection-description: 'Você ainda não criou nenhuma seleção.'
    no-selection-title: 'Não há seleções'
</i18n>

<style lang="sass" scoped></style>
