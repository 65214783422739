<template lang="pug">
  span#person-fields
    AvatarInput(
      :name="value.nameFor('selfie')"
      v-model="value.selfie"
      rules="required")
    PersonNameInput(
      v-if="fields.indexOf('name') > -1"
      :label="value.labelFor('name')"
      :name="value.nameFor('name')"
      v-model="value.name")
    PhoneInput(
      v-if="fields.indexOf('phone') > -1"
      :label="value.labelFor('phone')"
      :name="value.nameFor('phone')"
      v-model="value.phone")
</template>

<script>
import AvatarInput from '@/components/inputs/AvatarInput'
import PersonNameInput from '@/components/flows/inputs/PersonNameInput'
import PhoneInput from '@/components/inputs/PhoneInput'

export default {
  components: {
    AvatarInput,
    PersonNameInput,
    PhoneInput
  },

  props: {
    fields: Array, // ['selfie', 'name', 'email', 'phone', 'allow_image_usage']
    value: Object // PersonAdapter.data
  }
}
</script>
