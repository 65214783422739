import api from '../../api'
import { images } from '../images.js'
import { files } from '../files.js'

export const selfies = {
  methods: {
    // Creates a selfie on the backend and returns its id
    async getSelfieId(url) {
      return await api.selfies.create({
        selfie: {
          url: url
        }
      })
    },

    async storeSelfieOnS3(blobImage, filename, callback) {
      let presignedPost = await api.storage_credentials.getForSelfie()
      let file = {
        content: images.methods.imageBlob(blobImage),
        name: filename
      }

      return await api.s3.create(
        file.name,
        file.content,
        presignedPost,
        false, // Do not overwrite previous selfie
        callback
      )
    },

    async storeSelfie(selfieFile) {
      let storedSelfie = {}
      let filename = files.methods.getUuidName(selfieFile)
      let base64 = await files.methods.loadFileReader(selfieFile)

      storedSelfie.url = await this.storeSelfieOnS3(base64, filename)
      storedSelfie.id = (await this.getSelfieId(storedSelfie.url)).id

      localStorage.setItem('selfie', JSON.stringify(storedSelfie))

      return storedSelfie
    }
  }
}
