import ProfileFollowersError from '@/api/errors/profile-followers-error'
import Base from '@/api/resources/base.js'
import store from '@/store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL

export default class ProfileFollowers {
  // api_v1_profile_members POST   /api/v1/profiles/:profile_user_name/members(.:format) api/v1/profiles/members#create {:format=>:json, :profile_user_name=>/[^\/]+/}
  async createMember(profileUsername, params = {}) {
    let url = `${API_URL}/api/v1/profiles/${profileUsername}/members`
    let data = params
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      throw new ProfileFollowersError(
        error.status,
        false,
        'Could not add current user as a profile member'
      )
    }
  }

  // api_v1_profile_people POST /api/v1/profiles/:profile_user_name/people(.:format), api/v1/profiles/people#create {:format=>:json, :profile_user_name=>/[^\/]+/}
  async createPerson(profileUsername, params = {}) {
    let url = `${API_URL}/api/v1/profiles/${profileUsername}/people`
    let data = params
    let config = store.getters['auth/getAxiosHeader']

    try {
      return await Base.post(url, data, config)
    } catch (error) {
      throw new ProfileFollowersError(
        error.status,
        false,
        'Could not add a person as a profile follower'
      )
    }
  }
}
