<template lang="pug">
  //- .card
  .thumbnail-box(
    :class="isSelected")
    .image.square(
      :class="isSelected"
      :style="imageSource"
      @click="handleClick")
    .processing(
      v-if="error"
      @click="handleClick") {{ $t('processing') }}
    Button#btn-zoom(
      v-if="isSelecting"
      @click="$emit('zoom', index)")
      Icon.text-white.black-shadow(
        icon="zoom-in")
</template>

<script>
import { mapState } from 'vuex'
import { images } from '../../../mixins/images.js'
import Button from '../../general/Button/ButtonNew'
import Icon from '../../Icon'

export default {
  mixins: [images], // loadImageFromURL

  components: {
    Button,
    Icon
  },

  props: {
    index: Number,
    medium: Object
  },

  data() {
    return {
      error: false
    }
  },

  computed: {
    ...mapState({
      isSelecting: state => state.gallery.selection.active
    }),

    imageSource() {
      return this.error
        ? 'background-color: grey'
        : 'background-image: url(' + this.medium.thumb_url + ')'
    },

    isSelected() {
      if (this.medium.selected) {
        return 'selected'
      }

      return ''
    }
  },

  methods: {
    handleClick() {
      this.$emit('click', this.index)
    }
  },

  async mounted() {
    try {
      await this.loadImageFromURL(this.medium.thumb_url)
    } catch (error) {
      this.error = true
    }
  }
}
</script>

<i18n>
  pt-BR:
    processing: 'Processando...'
</i18n>

<style lang="sass" scoped>
@import '../../../assets/stylesheets/_variables.sass'

#btn-zoom
  position: absolute
  top: 0
  left: 0

.processing
  color: $white-grey
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.processing:hover
  cursor: default

.image.selected
  border-radius: 10px

.square
  width: 100%
  // border-radius: 8px
  padding-bottom: 100%
  background-size: cover
  background-position: center

.thumbnail-box
  position: relative // To position btn zoom

.thumbnail-box.selected
  padding: 10px
  background-color: $primary-color
</style>
