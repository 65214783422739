<template lang="pug">
  b-carousel(
    :id="id"
    :interval="4000"
    controls
    indicators
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333;")
    b-carousel-slide(
      v-for="(medium, index) in media"
      :key="index"
      :img-src="medium"
      img-height="200")
    //- background="#ababab"
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'carousel-1'
    },
    media: Array
  }
}
</script>
