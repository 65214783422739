<template lang="pug">
  ValidationProvider(
    :name="name"
    :rules="rules"
    v-slot="{ errors }")
    .form-group
      label(:for="name") {{ label }}
      textarea(
        class='form-control'
        :id="name"
        v-model='inputValue'
        :rows='rows')
      span.field-error(v-show='errors.length > 0' class='is-invalid') {{ errors[0] }}.
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },

  props: {
    name: String,
    value: String,
    rules: String,
    label: {
      type: String,
      default: function() {
        return this.$t('fields.' + this.name)
      }
    },
    placeholder: String,
    rows: {
      type: Number,
      default: 5
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/stylesheets/_variables.sass"

textarea
  resize: none

.field-error
  font-size: 13px
  color: $primary-color
</style>
