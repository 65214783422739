<template lang="pug">
  .d-flex.flex-column.align-items-center.justify-content-center.vph-100
    Instructions(
      :cta-label="label"
      :description="description"
      icon="attention"
      :title="$t('title')"
      @click="handleClick")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Instructions from '@/components/application/Instructions'

export default {
  components: {
    Instructions
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.accessToken
    }),

    description() {
      return this.isLoggedIn ? this.$t('description-in') : this.$t('description-out')
    },

    label() {
      return this.isLoggedIn ? this.$t('label-in') : this.$t('label-out')
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    handleClick() {
      if (this.isLoggedIn) {
        this.$router.push('/home')
      } else {
        this.login(this.$router.currentRoute.fullPath)
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    label-in: "Página inicial"
    label-out: "Entrar"
    description-in: "Este álbum tem acesso restrito ou não existe."
    description-out: "Este álbum tem acesso restrito ou não existe. Caso tenha acesso a ele, entre com sua conta"
    title: "Atenção"
</i18n>
