<template lang="pug">
  .row.border-light-grey.pb-2
    .col-7.col-sm-9
      .filter-title.pb-1 {{ $t('filter-face-title') }}
      .filter-description.small.pb-1 {{ $t('filter-face-description') }}
      FileInput(
        :disabled="isLoading"
        :label="$t('filter-face-upload')"
        size="sm"
        variant="light"
        @success="upload")
        //- @error="error"
      b-button.ml-2(
        v-if="mainProps.src"
        size="sm"
        :disabled="isLoading"
        variant="light"
        @click="clear") {{ $t('filter-face-clear') }}
      span.ml-2(
        v-if="isLoading")
        i(class='loading-icon fas fa-spinner fa-spin')
    .col-5.col-sm-3.pt-4.text-center
      b-img.face-thumbnail(
        v-bind="mainProps"
        rounded="circle"
        alt="Rounded image")
</template>

<script>
import { mapActions } from 'vuex'
import { selfies } from '../../../../mixins/resources/selfies.js'
import FileInput from '../../../inputs/FileInput'
import Icon from '../../../Icon'

export default {
  mixins: [selfies], // storeSelfie

  components: {
    FileInput,
    Icon
  },

  props: {
    face: Object,
    selfie: Object
  },

  data() {
    return {
      isLoading: false,
      editedSelfie: null
    }
  },

  computed: {
    mainProps() {
      if (this.editedSelfie && this.editedSelfie.url) {
        return {
          src: this.editedSelfie.url
        }
      }

      if (this.selfie && this.selfie.src) {
        return {
          src: this.selfie.src
        }
      }

      if (this.face && this.face.medium && this.face.medium.src) {
        return {
          src: this.face.medium.src
        }
      }

      return { blank: true, blankColor: '#777' }
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    }),

    clear() {
      this.editedSelfie = null
      this.$emit('clear') // update 'face' and 'selfie'
    },

    // error() {
    //   this.showMessage(
    //     this.$i18n.t('selfie-upload-error') + ' (' + error.name + ')'
    //   )
    // },

    async upload(file) {
      this.isLoading = true

      try {
        this.editedSelfie = await this.storeSelfie(file) // Trigger search
        this.isLoading = false
      } catch (error) {
        this.showMessage(this.$i18n.t('selfie-upload-error') + ' (' + error.name + ')')
        this.isLoading = false
      }
    }
  }, // methods

  watch: {
    editedSelfie() {
      this.$emit('filter-by-selfie', this.editedSelfie)
    }
  }
}
</script>

<i18n>
  pt-BR:
    filter-face-clear: 'Limpar'
    filter-face-description: 'Ao navegar pelas fotos deste álbum, clique em uma das faces estará filtrando as mídias em que aquele rosto aparece. Se preferir, carregue aqui uma selfie e também será feita uma busca por este rosto.'
    filter-face-title: 'Filtrar por uma face'
    filter-face-upload: 'Carregar'
    selfie-upload-error: 'Erro ao carregar selfie'
    title: 'Filtros disponíveis'
</i18n>

<style lang="sass" scoped>
.filter-description
  color: grey

.face-thumbnail
  width: 90px
  height: 90px
</style>
