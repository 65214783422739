<!-- This component should be replaced when we create post routes. So instaead
of opening this route the post will have its own download route -->
<template lang="pug">
  img(
    :src="src")
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      src: ''
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    })
  },

  mounted() {
    this.src = this.$route.query.url
    this.showMessage(this.$t('instructions'))
  }
}
</script>

<i18n>
  pt-BR:
    instructions: "Uma vez carregada, toque na imagem e segure. Escolha a opção de salvá-la em seu rolo de câmera."
</i18n>

<style lang="sass" scoped>
img
  max-width: 100%
</style>
