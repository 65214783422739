<template lang="pug">
  div(:id="id")
    Navbar(
      :back="back"
      :back-click="backClick"
      :back-to="backTo"
      :color="navbarColor"
      no-profile
      :title="title"
      @back="$emit('back')")
      template(
        v-if="skip || close"
        #right)
        Button(
          v-if="skip"
          :label="$t('skip')"
          @click="$emit('skip')")
        Button(
          v-if="close"
          icon="x"
          rounded
          @click="handleCancel")
    section.container-fluid
      .row(
        :class="rowClasses")
        .col(
          :class="colClasses")
          h2.font-weight-bold(
            v-if="heading") {{ heading }}
          p.text-muted.mb-4(
            v-if="paragraph") {{ paragraph }}
          slot
      .row.text-center.mt-3(
        v-if="footNote")
        .p-0.col-sm-10.offset-sm-1.col-md-8.offset-md-2.col-lg-6.offset-lg-3
          small.text-muted {{ footNote }}
          .mt-2(
            v-if="$slots.footer")
            slot(name="footer")
      br
      br
      br
    Navbar(
      :color="navbarColor"
      container
      fixed="bottom"
      no-border
      no-profile)
      template(#right)
        Button.mr-3(
          v-if="!okOnly"
          :label="cancelLabel"
          :size="buttonSize"
          type="submit"
          variant="light"
          @click="handleCancel")
        Button(
          :disabled="okDisabled"
          :label="okLabel"
          :size="buttonSize"
          type="submit"
          variant="primary"
          @click="handleOk")
    Modal(
      :cancel-title="$t('confirmation-cancel-label')"
      :id="CONFIRMATION_MODAL_ID"
      :ok-title="$t('confirmation-ok-label')"
      :title="$t('confirmation-title')"
      v-if="cancelConfirmation || okConfirmation"
      @cancel="handleConfirmation('cancel')"
      @ok="handleConfirmation('ok')")
      p {{ confirmationText }}
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/company/Button'
import Modal from '@/components/general/Popup'
import Navbar from '@/components/Shared/Navbar'

export default {
  components: {
    Button,
    Modal,
    Navbar
  },

  props: {
    back: Boolean,
    backTo: String,
    backClick: Boolean,
    cancelConfirmation: String,
    cancelLabel: {
      type: String,
      default() {
        return this.$t('cancel-label')
      }
    },
    card: {
      type: Boolean,
      default: false
    },
    close: {
      type: Boolean,
      default: false
    },
    footNote: String,
    heading: String,
    id: String,
    navbarColor: {
      type: String,
      default: 'white'
    },
    okConfirmation: String,
    okDisabled: Boolean,
    okLabel: {
      type: String,
      default() {
        return this.$t('ok-label')
      }
    },
    okOnly: {
      type: Boolean,
      default: false
    },
    paragraph: String,
    title: String,
    skip: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      confirmationText: ''
    }
  },

  computed: {
    ...mapGetters({
      isMobileDevice: 'ux_controls/isViewportWidthMaxSM'
    }),

    buttonSize() {
      return this.isMobileDevice ? '' : 'lg'
    },

    colClasses() {
      let defaultClasses = 'col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3'
      // Card is not applied on mobile devices
      if (this.isMobileDevice || !this.card) {
        return defaultClasses
      }

      return `card p-4 ${defaultClasses}`
    },

    rowClasses() {
      return !this.isMobileDevice && this.card ? 'mt-4' : ''
    }
  },

  methods: {
    handleCancel() {
      if (this.cancelConfirmation) {
        this.showModal(this.cancelConfirmation)
      } else {
        this.$emit('cancel')
      }
    },

    handleConfirmation(action) {
      if (this.confirmationText == this.okConfirmation && action == 'ok') {
        this.$emit('ok')
      } else {
        if (this.confirmationText == this.cancelConfirmation && action == 'ok') {
          this.$emit('cancel')
        }
      }
    },

    handleOk() {
      if (this.okConfirmation) {
        this.showModal(this.okConfirmation)
      } else {
        this.$emit('ok')
      }
    },

    showModal(message) {
      this.confirmationText = message
      this.$bvModal.show(this.CONFIRMATION_MODAL_ID)
    }
  },

  created() {
    this.CONFIRMATION_MODAL_ID = 'confirmation-modal'
  }
}
</script>

<i18n>
  pt-BR:
    cancel-label: "Cancelar"
    confirmation-ok-label: "Sim"
    confirmation-cancel-label: "Não"
    confirmation-title: "Confirmação"
    ok-label: "Confirmar"
    skip: "Pular"
</i18n>

<style lang="sass" scoped></style>
