<template lang="pug">
  BaseView
    Navbar(brand)
    Hero
</template>

<script>
import BaseView from '@/layouts/Base'
import Hero from '@/components/Index/Hero'
import Navbar from '@/components/Shared/Navbar'

export default {
  components: {
    BaseView,
    Hero,
    Navbar
  }
}
</script>
