<template lang="pug">
  ValidationProvider(
    :name="name"
    :rules="getRules"
    v-slot="{ errors }")
    b-form-group#email-group(
      :label="label"
      label-for='name')
      b-form-input(
        :disabled="disabled"
        :id="name"
        v-model='inputValue'
        type='email'
        :placeholder='placeholder')
      span.field-error(v-show='errors.length>0' class='is-invalid') {{ errors[0] }}.
</template>

<script>
/* eslint-disable */
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },
  props: {
    disabled: Boolean,
    label: String,
    name: String,
    confirm: String,
    rules: String, // 'required' only
    value: String,
    placeholder: String
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },

    getRules() {
      let required = this.rules != undefined && this.rules.includes('required')
        ? 'required|'
        : ''

      if (!this.confirm) {
        return `${required}email`
      } else {
        return `${required}email|email_confirmation:@${this.confirm}`
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/stylesheets/_variables.sass"

#input
    height: $input-height

.field-error
  font-size: 13px
  color: $primary-color
</style>
