import BaseModel from '@/models/base_model'
// import Medium from '@/models/medium'

export default class AlbumPersonMedium extends BaseModel {
  static entity = 'albumPersonMedium'
  static primaryKey = ['album_uid', 'person_uid', 'medium_uid']

  static fields() {
    return {
      album_uid: this.attr(null),
      person_uid: this.attr(null),
      medium_uid: this.attr(null),
      order: this.attr(null)
    }
  }
}
