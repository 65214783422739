<template lang="pug">
  ValidationObserver(ref="form" v-slot="{ handleSubmit }")
    form(@submit.prevent="handleSubmit(onSubmit)")
      fieldset
        slot
    .float-right.pt-3(v-if="showButtons")
      Button.mr-2(
        v-if="!okOnly"
        :label="$t('cancel')"
        @click="$emit('cancel')")
      Button(
        :disabled="isSubmitting"
        :label="$t('save')"
        type="submit"
        variant="primary"
        @click="onSubmit")
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import Button from '@/components/company/Button'

export default {
  components: {
    ValidationObserver,
    Button
  },

  props: {
    model: Object, // album, person etc.
    noButton: {
      type: Boolean,
      default: false
    },
    okOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isSubmitting: false
    }
  },

  computed: {
    showButtons() {
      return this.okOnly || !this.noButton
    }
  },

  methods: {
    async onSubmit() {
      if (await this.$refs.form.validate()) {
        if (!this.isSubmitting) {
          this.$refs.form.reset()

          if (this.model) {
            this.model.resetErrors()
          }

          this.isSubmitting = true
          this.$emit('submit')
        }
      } else {
        this.$emit('error')
      }
    },

    // Show backend errors
    showErrors() {
      this.isSubmitting = false
      if (this.model) {
        this.$refs.form.setErrors(this.model.errors)
      }
    }
  }, // methods

  watch: {
    isSubmitting() {
      this.$emit('change', this.isSubmitting)
    }
  }
}
</script>

<i18n>
  pt-BR:
    cancel: "Cancelar"
    save: "Salvar"
</i18n>
