import api from '../../api'

export const mutations = {
  clearItems: state => {
    state.items.splice(0, state.items.length)
    state.total = 0
  },
  clearResource: state => {
    state.resource.id = null
    state.resource.kind = null
  },
  setItems: (state, items) => {
    state.items = items
  },
  setResource: (state, resource) => {
    state.resource = resource
  },
  setTotal: (state, total) => {
    state.total = total
  }
}

export const actions = {
  reset: ({ commit }) => {
    commit('clearItems')
    commit('clearResource')
  },
  // resource = { id, kind }
  loadItems: ({ commit }, resource) => {
    switch (resource.id) {
      case '017971c3a49283f3f4e59b0eee53c635':
        // local: '30 primeiras fotos', collectables_default.json
        api.other
          .get(
            'https://fottorama-development.s3.amazonaws.com/rodrigo/collectables/collectables_default/collectables_default.json'
          )
          .then(response => {
            commit('setItems', response.collectables)
            commit('setTotal', response.total)
          })
        break
      case '01791e8cfe13df67c0f2becb91c05f4c':
        // local: 'Joana D'arc Nunes Castro', collectables_cibele.json
        api.other
          .get(
            'https://fottorama-development.s3.amazonaws.com/rodrigo/collectables/collectables_projetovida/collectables_cibele.json'
          )
          .then(response => {
            commit('setItems', response.collectables)
            commit('setTotal', response.total)
          })
        break
      case '017941e56e68e33aaf4b97098a8a5907':
        // staging: 'Bebê A', collectables_pv.json
        api.other
          .get(
            'https://fottorama-development.s3.amazonaws.com/rodrigo/collectables/collectables_pv/collectables_pv.json'
          )
          .then(response => {
            commit('setItems', response.collectables)
            commit('setTotal', response.total)
          })
        break
      case '017941e8342ee622aa312e07f0b737f3':
        // production: 'Bebê A', collectables_pv.json
        api.other
          .get(
            'https://fottorama-development.s3.amazonaws.com/rodrigo/collectables/collectables_pv/collectables_pv.json'
          )
          .then(response => {
            commit('setItems', response.collectables)
            commit('setTotal', response.total)
          })
        break
    }
  }
}

export default {
  namespaced: true,
  state: {
    items: [],
    resource: {
      id: null,
      kind: null // 'collection', 'album', 'selection'
    },
    total: 0
  },
  mutations,
  actions
}
