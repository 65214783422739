import i18n from '@/i18n'
import BaseError from '@/api/errors/base_error'

const errorsClassMethods = {
  _handleErrors(error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw new BaseError(error.response.status, true, error.statusText)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      let message = error.code ? error.message + ' (' + error.code + ')' : error.message

      throw new BaseError(499, true, message) // 499: Client Closed Request
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new BaseError(500, true, error.message)
      // 500 Internal Server Error, a generic error message, given when an
      // unexpected condition was encountered and no more specific message is suitable.
    }
  }
}

const errorsInstanceMethods = {
  resetErrors() {
    this.errors = {}
  },

  addError(field, errors) {
    this.errors[this.nameFor(field)] = errors.map(x => {
      let error = x.replace(new RegExp(' ', 'g'), () => '_')
      let message = i18n.t(`model.${this.constructor.name}.errors.${field}.${error}`)
      return `${this.labelFor(field)} ${message}`
    })
  },

  _handleErrors(err) {
    if (err.response && err.response.data) {
      let errors = err.response.data.error || {}
      for (let [k, v] of Object.entries(errors)) {
        this.addError(k, v)
      }
    }
  }
}

export { errorsClassMethods, errorsInstanceMethods }
