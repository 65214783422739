<template lang="pug">
  BaseView
    div(v-if="albumAdapter.loading")
      Loading
    Step(
      v-else
      :ok-disabled="isSubmitting"
      back-click
      card
      ok-only
      :ok-label="$t('save')"
      :title="$t('title')"
      @back="back"
      @ok="handleOk")
      AlbumForm(
        action="edit"
        :album="albumAdapter.data"
        :fields="fields"
        no-button
        ref="albumForm"
        @change="handleChange"
        @submit="handleSubmit")
</template>

<script>
import AlbumAdapter from '@/adapt/album-adapter'
import AlbumForm from '@/components/flows/forms/AlbumForm'
import BaseView from '@/layouts/Base'
import Loading from '@/components/Shared/Loading'
import Step from '@/layouts/Step'

export default {
  components: {
    AlbumForm,
    BaseView,
    Loading,
    Step
  },

  props: {
    uid: String
  },

  data() {
    return {
      albumAdapter: new AlbumAdapter(),
      fields: ['visibility', 'name', 'description', 'starts_at', 'watermark', 'media_visibility'],
      isSubmitting: false
    }
  },

  methods: {
    handleChange(isSubmitting) {
      this.isSubmitting = isSubmitting
    },

    handleOk() {
      // Activate the submit action of the form
      this.$refs.albumForm.$refs.formWrapper.onSubmit()
    },

    async handleSubmit() {
      if (this.albumAdapter.data.visibility != 'community') {
        // For now, only 'community' albums are allow to have 'media_visibility' == 'own'
        this.albumAdapter.data.media_visibility = 'all'
      }

      if (await this.albumAdapter.update()) {
        this.back()
      } else {
        this.$refs.albumForm.$refs.formWrapper.showErrors()
      }
    },

    back() {
      this.$router.replace(`/albums/${this.uid}`)
    }
  },

  beforeMount() {
    this.albumAdapter.load({ id: this.uid })
  }
}
</script>

<i18n>
  pt-BR:
    album-not-found: "Álbum não encontrado"
    save: "Salvar"
    title: "Editar álbum"
</i18n>
