<template lang="pug">
  #collection-media-step
    Navbar(
      bordered
      :title="title")
      template(#left)
        Button(
          icon="left"
          @onClick="back")
    Instructions(
      class="p-3 text-center"
      :description="description"
      :title="$t('title')")
    MediumSelect(
      :min="min"
      :max="max"
      :options="media"
      v-model="selected")
    Navbar(
      bordered
      color="white"
      fixed="bottom")
      template(#right)
        Button(
          :label="$t('next')"
          class="primary"
          @onClick="process")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Button from '../../Button'
import Instructions from '../Instructions'
import MediumSelect from '../Medium/MediumSelect'
import Navbar from '../../company/Navbar'

export default {
  components: {
    Button,
    Instructions,
    MediumSelect,
    Navbar
  },

  data() {
    return {
      selected: []
    }
  },

  computed: {
    ...mapGetters({
      findCollectable: 'cart/findCollectable',
      getCollectables: 'cart/getCollectables'
    }),

    ...mapState({
      collectables: state => state.collectables.items,
      media: state => state.gallery.selection.media
    }),

    collectable() {
      var result = this.collectables.filter(obj => {
        return obj.uid === this.getCollectables[0]
      })

      return result[0]
    },

    description() {
      if (this.collectable && this.collectable.design) {
        let description = this.$t('select')

        if (this.min && this.max) {
          if (this.min == this.max) {
            description += ` ${this.min} ${this.$t('media')}`
          } else {
            description += ` ${this.$t('between')} ${this.min} ${this.$t('and')} ${
              this.max
            } ${this.$t('media')}`
          }
        } else {
          if (!this.min) {
            description += ` ${this.$t('up-to')} ${this.max} ${this.$t('media')}`
          } else {
            // max == null
            description += ` ${this.$t('at-least')} ${this.min} ${this.$t('media')}`
          }
        }

        return description
      }

      return ''
    },

    isMediaNumberValid() {
      let mediaNumber = this.selected.length

      if (this.min && this.max) {
        return mediaNumber >= this.min && mediaNumber <= this.max
      } else {
        if (!this.min) {
          return mediaNumber <= this.max
        } else {
          // max == null
          return mediaNumber > 0
        }
      }
    },

    max() {
      if (this.collectable && this.collectable.design) {
        return this.collectable.design.media_number.max
      }

      return null
    },

    min() {
      if (this.collectable && this.collectable.design) {
        return this.collectable.design.media_number.min
      }

      return null
    },

    title() {
      if (this.collectable && this.collectable.name) {
        return this.collectable.name
      }

      return ''
    }
  },

  methods: {
    ...mapActions({
      addItemMedia: 'cart/addItemMedia',
      removeCollectables: 'cart/removeCollectables',
      showMessage: 'ux_controls/showMessage'
    }),

    back() {
      this.removeCollectables()
      this.selected.splice(0, this.selected.length)
      this.$emit('back')
    },

    process() {
      if (this.isMediaNumberValid) {
        let index = this.findCollectable(this.collectable.uid)
        this.addItemMedia({ index, media: this.selected })
        this.$emit('ok')
      } else {
        this.showMessage(this.$t('media-number-error'))
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    and: "e"
    at-least: "pelo menos"
    between: "entre"
    media: "foto(s)"
    media-number-error: "Selecione um número de mídias válido."
    next: "Avançar"
    select: "Selecione"
    skip: "Pular"
    title: "Quais fotos quer usar?"
    up-to: "até"
</i18n>

<style lang="sass" scoped></style>
