import BaseModel from '@/models/base_model'

export default class MyProfile extends BaseModel {
  static name = 'profile'
  static entity = 'myProfile'
  static primaryKey = 'user_name'

  // The fields below that are not on the backend yet are used on MyProfile creation flow in order
  // to pass those information to me and dependents when they are saved as a person
  static fields() {
    return {
      email: this.attr(''), // Not on the backend for now
      user_name: this.attr(''),
      name: this.attr(''),
      description: this.attr(''),
      invitation: this.attr(null),
      phone: this.attr(''), // Not on the backend for now
      picture_url: this.attr(''),
      // has many
      album_count: this.attr(null),
      person_count: this.attr(null)
    }
  }

  constructor(record) {
    super(record)
    this.avatar = { url: this.picture_url, blob: null }
  }
}
