<template lang="pug">
  Popup(
    scrollable
    :id="modalId"
    :title="$t('title')"
    :cancel-disabled="cancelDisabled"
    :ok-disabled="okDisabled"
    @ok="handleOk"
    @cancel="handleCancel")
    ValidationObserver(ref="form" v-slot="{ invalid }")
      form
        CreateSelectionFields(
          :isCreating="isCreating"
          :label="$t('create')"
          v-model="newSelection"
          @done="created")
        ChooseSelectionFields(
          :label="$t('choose')"
          v-model="existingSelection")
    span.field-error(
      v-show='errors.length>0'
      class='is-invalid') {{ errors[0] }}.
</template>

<script>
import { ValidationObserver } from 'vee-validate'

import { mapActions } from 'vuex'
import { selections } from '../../../mixins/resources/selections.js'

import ChooseSelectionFields from '../../forms/ChooseSelectionFields'
import Popup from '../../general/Popup'
import CreateSelectionFields from '../../forms/CreateSelectionFields'

export default {
  mixins: [selections],

  components: {
    ChooseSelectionFields,
    Popup,
    CreateSelectionFields,
    ValidationObserver
  },

  props: {
    modalId: {
      type: String,
      default: 'selection-create-modal'
    },
    media: Array
  },

  data() {
    return {
      existingSelection: {
        uid: '',
        name: ''
      },
      newSelection: {
        uid: '',
        name: ''
      },
      errors: [],
      isCreating: false,
      cancelDisabled: false,
      okDisabled: false
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    }),

    handleCancel() {
      this.$emit('cancel')
    },

    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      // Created a new selection
      if (this.newSelection.name.length > 0) {
        this.cancelDisabled = true
        this.okDisabled = true
        this.isCreating = true
        // In this case, the modal is hidden on created() method
      } else {
        // Added to an existing one
        if (this.existingSelection.uid.length > 0) {
          this.$nextTick(async () => {
            await this.addMediaById(this.existingSelection.uid, this.media)
            this.$bvModal.hide(this.modalId)
            this.showMessage(`${this.$t('added')} ${this.existingSelection.name}`)
            this.$emit('ok', this.existingSelection)
          })
        } else {
          this.showMessage(this.$t('error'))
        }
      }
    },

    async created(selectionId) {
      this.newSelection.uid = selectionId
      this.okDisabled = false
      this.cancelDisabled = false
      this.isCreating = false

      this.$nextTick(async () => {
        await this.addMediaById(this.newSelection.uid, this.media)
        this.$bvModal.hide(this.modalId)
        this.showMessage(`${this.$t('added')} ${this.newSelection.name}`)
        this.$emit('ok')
        this.newSelection = { name: '', uid: '' }
      })
    }
  },
  // If user updates one selection, it cleans up the other one.
  watch: {
    'existingSelection.name': function() {
      if (this.existingSelection.name.length > 0) {
        this.newSelection = { name: '', uid: '' }
      }
    },

    'newSelection.name': function() {
      // When the first char has been typed, cleans up existing selection
      if (this.newSelection.name.length == 1) {
        this.existingSelection = { name: '', uid: '' }
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    choose: 'Ou escolha uma seleção existente'
    create: 'Crie uma nova seleção'
    error: 'Escolha uma seleção ou cancele esta operação'
    title: 'Adicionar mídias a uma seleção'
    added: "As mídias foram adicionadas à seleção"
</i18n>

<style lang="sass" scoped>
@import "../../../assets/stylesheets/_variables.sass"
.field-error
  font-size: 13px
  color: $primary-color
</style>
