<template lang="pug">
  ValidationProvider(
    :name='name'
    :rules='rules'
    v-slot='{ errors }')
    .form-group
      label(for='startsAt' slot='before') {{ label }}
      span(v-if="inputValue" v-on:click="inputValue=null")
        small#clear-date {{ $t('clear') }}
      datetime(
        input-class="form-control"
        v-model='inputValue'
        type='datetime'
        input-id='startsAt'
        :minute-step='15')
      span.field-error(v-show='errors.length>0' class='is-invalid') {{ errors[0] }}.
</template>

<script>
import { Datetime } from 'vue-datetime'
import { ValidationProvider } from 'vee-validate'
import 'vue-datetime/dist/vue-datetime.css'
import { Settings } from 'luxon'

export default {
  components: {
    Datetime,
    ValidationProvider
  },

  props: {
    name: String,
    value: String,
    rules: String,
    label: String
  },

  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        if (newValue === '') {
          newValue = null
        }
        this.$emit('input', newValue)
      }
    }
  },

  created() {
    Settings.defaultLocale = this.$i18n.locale
  }
}
</script>

<i18n>
  pt-BR:
    clear: 'Limpar'
</i18n>

<style lang="sass">
@import "../../assets/stylesheets/_variables.sass"

.vdatetime-input // Attributes values copied from Bootstrap
  width: 100%
  border: 1px solid #ced4da
  border-radius: .25rem
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out

.vdatetime-popup__header
  background: $primary-color

.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span
  background: $primary-color

.vdatetime-time-picker__item--selected
  color: $primary-color

.vdatetime-popup__actions__button
  color: $secondary-color

#clear-date
  display: inline-block
  float: right
  margin: 5px 0 0 0
  color: lightgrey
  text-transform: uppercase
  cursor: pointer

.field-error
  font-size: 13px
  color: $primary-color
</style>
