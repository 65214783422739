import api from '../../api'

export const getters = {
  canDownloadMedium: state => {
    if (state.permissions && state.permissions.indexOf('all') >= 0) {
      return true
    }

    return false
  },
  canEdit: state => {
    if (state.permissions && state.permissions.indexOf('all') >= 0) {
      return true
    }

    return false
  },
  canUpload: state => {
    if (state.permissions && state.permissions.indexOf('all') >= 0) {
      return true
    }

    return false
  },
  getAlbum: state => {
    return {
      description: state.description,
      faceRec: state.faceRec,
      name: state.name,
      publicRead: state.publicRead,
      startsAt: state.startsAt,
      watermark: state.watermark
    }
  }
}

export const mutations = {
  addUploadedPhoto: (state, photoUrl) => {
    state.uploadedPhotos.push(photoUrl)
  },
  // clearMedia: state => {
  //   state.media.splice(0, state.media.length)
  // },
  clearUploadedPhotos: state => {
    state.uploadedPhotos.splice(0, state.uploadedPhotos.length)
  },
  clearTemplates: state => {
    state.templates.splice(0, state.templates.length)
  },
  setDescription: (state, description) => {
    state.description = description
  },
  setFaceRec: (state, enable) => {
    state.faceRec = enable
  },
  setId: (state, id) => {
    state.id = id
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading
  },
  setName: (state, name) => {
    state.name = name
  },
  setPermissions: (state, permissions) => {
    state.permissions = permissions
  },
  setPrice: (state, price) => {
    state.price = price
  },
  setPublicRead: (state, publicRead) => {
    state.publicRead = publicRead
  },
  setStartsAt: (state, datetime) => {
    state.startsAt = datetime
  },
  setTemplate: (state, { i, template }) => {
    state.templates[i] = template
  },
  setTemplateLayout: (state, { i, currentLayout }) => {
    Object.assign(state.templates[i], { layout: currentLayout })
  },
  setUploadedPhotos: (state, photoArray) => {
    state.uploadedPhotos = photoArray
  },
  setWatermark: (state, apply) => {
    state.watermark = apply
  }
}

export const actions = {
  loadAlbum: ({ commit, dispatch }, albumId) => {
    commit('setIsLoading', true)

    return new Promise((resolve, reject) => {
      api.albums
        .get(albumId)
        .then(response => {
          dispatch('setAlbum', response).then(() => {
            dispatch('setTemplateLayouts').then(() => {
              commit('setIsLoading', false)
              resolve()
            })
          })
        })
        .catch(error => {
          dispatch('resetAlbum').then(() => {
            commit('setIsLoading', false)
            reject(error)
          })
        })
    })
  },
  resetAlbum: context => {
    context.commit('setDescription', null)
    context.commit('setId', null)
    context.commit('setName', null)
    context.commit('setPrice', null)
    context.commit('setPublicRead', null)
    context.commit('setStartsAt', null)
    context.commit('clearUploadedPhotos')
    context.commit('clearTemplates')
  },
  setAlbum: (context, album) => {
    // album attributes should match backend syntax
    context.commit('setDescription', album.description)
    context.commit('setFaceRec', album.recognition)
    context.commit('setId', album.uid)
    context.commit('setName', album.name)
    context.commit('setPermissions', album.permissions)
    context.commit('setPrice', album.price)
    context.commit('setPublicRead', album.public_read)
    context.commit('setStartsAt', album.starts_at)
    context.commit('setWatermark', album.watermark)

    for (let i = 0; i < album.templates.length; i++) {
      let template = {
        backgroundURL: album.templates[i].background_url,
        overlayURL: album.templates[i].overlay_url,
        thumbnailURL: album.templates[i].thumbnail_url,
        layoutURL: album.templates[i].layout_url
      }

      context.commit('setTemplate', { i, template })
    }
  },
  setId: (context, id) => {
    context.commit('setId', id)
  },
  setName: (context, name) => {
    context.commit('setName', name)
  },
  setDescription: (context, description) => {
    context.commit('setDescription', description)
  },
  setFaceRec: (context, enable) => {
    context.commit('setFaceRec', enable)
  },
  setPrice: (context, price) => {
    context.commit('setPrice', price)
  },
  setPublicRead: (context, publicRead) => {
    context.commit('setPrice', publicRead)
  },
  setTemplateFiles: (context, allTemplates) => {
    context.commit('setTemplateFiles', allTemplates)
  },
  setTemplateLayouts: context => {
    return new Promise(resolve => {
      if (context.state.templates.length > 0) {
        for (let i = 0; i < context.state.templates.length; i++) {
          api.other.get(context.state.templates[i].layoutURL).then(currentLayout => {
            context.commit('setTemplateLayout', { i, currentLayout })
          })
        }
      }
      resolve()
    })
  },
  setStartsAt: (context, datetime) => {
    context.commit('setStartsAt', datetime)
  },
  setUploadedPhotos: (context, photoArray) => {
    context.commit('setUploadedPhotos', photoArray)
  },
  addUploadedPhoto: (context, photoUrl) => {
    context.commit('addUploadedPhoto', photoUrl)
  },
  clearUploadedPhotos: context => {
    context.commit('clearUploadedPhotos')
  },
  setWatermark: (context, apply) => {
    context.commit('setWatermark', apply)
  }
}

export default {
  namespaced: true,
  state: {
    description: null,
    faceRec: null,
    id: null,
    isLoading: false,
    name: null,
    permissions: [],
    price: null,
    publicRead: null,
    startsAt: null,
    templates: [],
    uploadedPhotos: [],
    watermark: null
  },
  getters,
  mutations,
  actions
}
