import api from '../../api'
import store from '../../store'

export const selections = {
  methods: {
    addMediaById(selectionId, mediaArray) {
      mediaArray.forEach(mediumId => {
        this.createSelectionMedium(selectionId, mediumId)
      })
    },

    async createSelectionMedium(selectionId, mediumId) {
      let data = { uid: mediumId }
      return await api.selections.createMedium(selectionId, data)
    },

    async createSelectionByName(name) {
      let params = {
        selection: {
          name: name
        }
      }

      return await api.selections.create(params)
    },

    async createSelectionWithMedia(name, mediaArray) {
      let selectionId = await this.createSelectionByName(name)

      mediaArray.forEach(medium => {
        this.createSelectionMedium(selectionId, medium.uid)
      })

      return selectionId
    },
    // Retrieve selection from the backend without setting it on the store
    async getSelections(params = {}) {
      if (!params.per_page) {
        params.per_page = store.state.selection.settings.perPage
      }

      return await api.selections.getSelections(params)
    },
    // selections: array of objects { text, value }
    // text = selection's name; value = selection's uid
    getNameFromId(selections, selectionId) {
      var index = selections.findIndex(o => o['value'] === selectionId)

      return index < 0 ? '' : selections[index].text ? selections[index].text : ''
    },

    async getSelection(selectionId) {
      return await api.selections.getSelection(selectionId)
    },

    // Translate from frontend object to backend object
    getSelectionParams(selection) {
      let params = {
        name: selection.name
      }

      if (selection.plan != undefined) {
        params.plan_uid = selection.plan.id
      }

      return params
    },

    async removeMediaFromSelection(selectionId, mediaArray) {
      mediaArray.forEach(async medium => {
        await store.dispatch('gallery/removeMediumFromContent', {
          mediumId: medium.uid,
          page: medium.page
        }) // Remove item from Selection resource

        await this.removeMediumFromSelection(selectionId, medium.uid) // remove from the backend
      })
      await store.dispatch('gallery/resetSelection') // Remove from the UI
    },

    async removeMediumFromSelection(selectionId, mediumId) {
      return await api.selections.removeMedium(selectionId, mediumId)
    },

    async updateSelection(selectionId, selection) {
      let params = this.getSelectionParams(selection)

      return await api.selections.update(selectionId, params)
    }
  }
}
