<template lang="pug">
  Step(
    :back="back"
    :back-click="backClick"
    :back-to="backTo"
    :foot-note="footNote"
    :id="id"
    :ok-disabled="isSubmitting"
    ok-only
    :paragraph="paragraph"
    :title="title"
    @back="$emit('back')"
    @ok="handleOk")
    MyProfileForm(
      action="new"
      :fields="fields"
      :myProfile="myProfileAdapter.data"
      no-button
      :personal="personal"
      ref="myProfileForm"
      @change="handleChange"
      @error="$emit('show-message', $t('error'))"
      @submit="handleSubmit")
    template(#footer)
      small
        Link(
          :label="$t('privacy-policy')"
          link="https://drive.google.com/file/d/15UmtuTyueczYaDHSqCiEl_4a1ovaUnEk/view")
</template>

<script>
import Link from '@/components/general/Link'
import MyProfileForm from '@/components/flows/forms/MyProfileForm'
import PersonAdapter from '@/adapt/person-adapter'
import Step from '@/layouts/Step'

export default {
  components: {
    Link,
    MyProfileForm,
    PersonAdapter,
    Step
  },

  props: {
    back: Boolean,
    backTo: String,
    backClick: Boolean,
    fields: Array,
    id: String,
    myProfileAdapter: Object, // MyProfileAdapter
    personal: Boolean
  },

  data() {
    return {
      isSubmitting: false
    }
  },

  computed: {
    footNote() {
      return this.personal ? this.$t('foot-note-personal') : this.$t('foot-note-community')
    },

    paragraph() {
      return this.personal ? this.$t('paragraph-personal') : this.$t('paragraph-community')
    },

    title() {
      return this.personal ? this.$t('profile-title-personal') : this.$t('profile-title-community')
    }
  },

  methods: {
    // Add the profile owner as a person in its own profile
    async addMeAsPerson() {
      let personAdapter = new PersonAdapter()

      personAdapter.data.email = this.myProfileAdapter.data.email
      personAdapter.data.is_me = true
      personAdapter.data.name = this.myProfileAdapter.data.name
      personAdapter.data.phone = this.myProfileAdapter.data.phone
      personAdapter.data.selfie = this.myProfileAdapter.data.avatar

      await personAdapter.save()
    },

    handleChange(isSubmitting) {
      this.isSubmitting = isSubmitting
    },

    handleOk() {
      // Activate the submit action of the form
      this.$refs.myProfileForm.$refs.formWrapper.onSubmit()
    },

    async handleSubmit() {
      // If the flow gets here, it means that there's no frontend error
      if (await this.myProfileAdapter.save()) {
        if (this.personal && this.myProfileAdapter.data.avatar.url) {
          await this.addMeAsPerson()
        }
        this.$refs.myProfileForm.$refs.formWrapper.isSubmitting = false
        this.$emit('ok')
      } else {
        // There's a backend error
        this.$emit('show-message', this.$t('error'))
        this.$refs.myProfileForm.$refs.formWrapper.showErrors()
      }
    }
  }
}
</script>

<i18n>
  pt-BR:
    error: "Não foi possível salvar o perfil. Verifique os erros."
    foot-note-community: "Ao inserir informações de uma comunidade nesta etapa, você confirma que tem autorização para fazê-lo."
    foot-note-personal: "Ao inserir informações pessoais nesta etapa, você confirma que elas são suas e não de outra pessoa."
    paragraph-community: "Insira aqui o logotipo e outras informações relativas à sua comunidade."
    paragraph-personal: "Insira aqui informações relativas a você. Caso esteja criando sua conta para receber fotos de um(a) filho(a), os dados deste dependente serão solicitados a seguir."
    profile-saved: "Perfil salvo com sucesso"
    profile-title-community: "Sua comunidade"
    profile-title-personal: "Seu perfil"
    privacy-policy: "Política de Privacidade"
</i18n>
