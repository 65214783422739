<template lang="pug">
  .d-flex.flex-column.align-items-center.justify-content-center.vph-100
    Icon.icon-sq-xl.txt-secondary.my-3(
      icon='search')
    h1.my-3 {{ $t('title') }}
    Button.my-3(
      :label="$t('next')"
      classes='primary'
      @onClick='redirect')
    Footer
</template>

<script>
import Icon from '../components/Icon.vue'
import Button from '../components/Button.vue'
import Footer from '../components/Footer.vue'

export default {
  components: {
    Icon,
    Button,
    Footer
  },

  methods: {
    redirect() {
      this.$router.push('/')
    }
  }
}
</script>

<i18n>
  pt-BR:
    title: "Página não encontrada"
    next: "Voltar ao início"
</i18n>

<style lang="sass" scoped></style>
