export default Object.freeze({
  A: 65,
  C: 67,
  ESC: 27,
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  SHIFT: 16,
  SPACE: 32
})
