<template lang="pug">
  //- TODO: Separate archive input so it can be used in OrderDetails component
  b-modal(
    centered
    :id="modalId"
    ok-only
    :title="$t('title')"
    @hidden="close")
    #loading-archive.text-center.my-3(
      v-if="isLoading")
        span
          Icon(
            class="icon-sq-xl txt-secondary"
            icon="loading")
    #order-archive(
      v-else)
      .d-block.py-3(
      v-if="archive && archive.parts")
        div(
          v-if="archive.parts.length > 0")
          b-input-group(
            v-for="(part, index) in archive.parts"
            :key="index")
            b-form-input(
              readonly
              :id="URL_PREFIX + index"
              :value="getPlaceholder(part.url)")
            b-input-group-append
              b-button(
                :disabled="getDisabled(part.url)"
                @click="copy(index)") {{ $t('copy') }}
        div(
          v-else)
          b-input-group(
            key="generating")
            b-form-input(
              readonly
              :id="URL_PREFIX + 'generating'"
              :value="$t('generating-placeholder')")
            b-input-group-append
              b-button(
                :disabled="true"
                @click="copy(index)") {{ $t('copy') }}
      .d-block.py-3(
        v-else)
        b-input-group(
          key="generate")
          b-form-input(
            readonly
            :id="URL_PREFIX + 'generate'"
            :value="$t('generate-placeholder')")
          b-input-group-append
            b-button(
              @click="generate") {{ $t('generate-label') }}
    //- TODO: Separete archive details into OrderArchive component and also
    //- put conditions in a way to have only two input groups (now there are 3)
    //- OrderArchive(
      v-if="order"
      :order="order"
      @update-archive="$emit('update-archive', $event)")
    template(#modal-footer)
      Link.mr-auto(
        v-if="archive"
        :label="$t('update')"
        @click="loadArchive")
      b-button(
        variant="primary"
        @click="$bvModal.hide(modalId)") {{ $t('close') }}
</template>

<script>
import { mapActions } from 'vuex'
import { orders } from '../../../mixins/resources/orders.js'
import Icon from '../../Icon'
import Link from '../../general/Link'

export default {
  mixins: [orders], // generateArchive, getArchive

  components: {
    Icon,
    Link
  },

  props: {
    modalId: {
      type: String,
      default: 'order-archive-modal'
    },
    order: Object
  },

  data() {
    return {
      archive: null,
      isLoading: false
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'ux_controls/showMessage'
    }),

    copy(index) {
      let url = document.querySelector('#' + this.URL_PREFIX + index).value

      navigator.clipboard.writeText(url)
      this.showMessage(this.$t('copied'))
    },

    async generate() {
      this.isLoading = true
      this.$gtm.trackEvent({ action: 'order-download', target: this.order.id })
      let response = await this.generateArchive(this.order.id)

      if (response.uid) {
        this.$emit('update-archive', response)
        this.archive = await this.getArchive(response.uid)
      }

      this.isLoading = false
    },

    getLabel(url) {
      return url != null ? this.$t('copy') : this.$t('generate-label')
    },

    getDisabled(url) {
      return url != null ? false : true
    },

    getPlaceholder(url) {
      return url != null ? url : this.$t('generating-placeholder')
    },

    close() {
      this.archive = null
      this.isLoading = false
      this.$emit('ok')
    },

    async loadArchive() {
      this.isLoading = true

      if (this.order.archive) {
        try {
          this.archive = await this.getArchive(this.order.archive.uid)
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
        }
      }
    }
  },
  // This watched was used because of OrderList, where the user could select
  // one order and then switch to another.
  watch: {
    order: {
      handler() {
        if (this.order && this.order.archive) {
          this.loadArchive()
        }
      },
      deep: true // Watch changes inside the order object
    }
  },

  created() {
    this.URL_PREFIX = 'archive-url-'
  },

  mounted() {
    // Listen to modal opening
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId == this.modalId) {
        if (this.order && this.order.archive && !this.archive) {
          this.loadArchive()
        }
      }
    })
  }
}
</script>

<i18n>
  pt-BR:
    close: 'Fechar'
    copied: 'O link de download foi copiado para a área de transferência.'
    copy: 'Copiar'
    generate-label: 'Gerar'
    generate-placeholder: "Clique em 'Gerar' para obter o(s) arquivo(s)"
    generating-placeholder: 'Arquivo(s) para download sendo gerado(s)...'
    title: 'Arquivos para download'
    update: 'Atualizar'
</i18n>

<style lang="sass" scoped></style>
