<template lang="pug">
  b-avatar(
    :class="avatarClass"
    :badge="badgeToString"
    :badge-variant="badgeVariant"
    :size="`${size}em`"
    :src="src"
    :variant="variant")
    template(
      v-if="badgeIcon"
      #badge)
      Icon(
        :icon="badgeIcon"
        @click="teste")
    slot
</template>

<script>
import Vue from 'vue'
import { BIcon, BIconPersonFill } from 'bootstrap-vue'
import Component from 'vue-class-component'

import Icon from '@/components/Icon'

@Component({
  components: {
    BIcon,
    BIconPersonFill,
    Icon
  },

  props: {
    avatarClass: String,
    badge: Number,
    badgeIcon: String,
    badgeVariant: {
      type: String,
      default: 'primary'
    },
    size: String,
    src: String,
    variant: {
      type: String,
      default: 'light'
    }
  }
})
class Avatar extends Vue {
  get badgeToString() {
    return this.badge ? this.badge.toString() : false
  }

  teste() {
    console.log('testeee')
  }
}

export default Avatar
</script>

<style lang="sass" scoped></style>
