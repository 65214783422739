<template lang="pug">
  Form(
    ref="formWrapper"
    :no-button="noButton"
    @submit="$emit('submit')")
    PersonCheckboxInput(
      name="people-select"
      :people-adapter="peopleAdapter"
      :rules="rules"
      v-model="localValue")
</template>

<script>
import Form from '@/components/Shared/Form'
import PersonCheckboxInput from '@/components/flows/inputs/PersonCheckboxInput'

export default {
  components: {
    Form,
    PersonCheckboxInput
  },

  props: {
    noButton: {
      type: Boolean,
      default: true
    },
    peopleAdapter: Object,
    rules: String, // 'required'
    value: Array
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>
