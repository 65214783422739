import api from '../../api'
import uuid from 'uuid'
import { albums } from '../../mixins/resources/albums.js'
import { arrays } from '../../mixins/arrays.js'

export const getters = {
  // Find the first occurrence of a 'digital_media' type item from 'resourceId'
  // otherwise -1 is returned
  findResourceMedia: state => resourceId => {
    return arrays.methods.indexOfByProps(
      state.items,
      'resourceId',
      resourceId,
      'type',
      'digital_media'
    )
  },
  findCollectable: state => collectableId => {
    for (let index = 0; index < state.items.length; index++) {
      if (state.items[index].data && state.items[index].data.collectableId == collectableId)
        return index
    }

    return -1
  },
  getCollectables: state => {
    let response = []

    state.items.forEach(item => {
      if (item.type == 'collectable') {
        response.push(item.data.collectableId)
      }
    })

    return response
  },
  // Return medium id of all digital media in the cart
  // getDigitalMediaIds: state => {
  //   let response = []

  //   state.items.forEach(item => {
  //     item.media.forEach(mediumId => {
  //       response.push(mediumId)
  //     })
  //   })

  //   return response
  // },
  // Convert items to backend notation
  getItems: state => {
    let response = []

    state.items.forEach(item => {
      // For now it's creating an order only with 'digital_media' items
      if (item.type == 'digital_media') {
        response.push({
          media: item.media,
          selection_uid: item.resourceId, // For now resource must be a selection
          type: item.type
        })
      } else {
        console.log('Not including ' + item.type + ':')
        console.log(item)
      }
    })

    return response
  },
  // Merge item media with parameter and remove duplicates
  getItemMergedMedia: state => ({ index, media }) => {
    // Concat media already in the cart with the new ones
    let concat = [...state.items[index].media, ...media]
    // Remove duplicates
    return arrays.methods.unique(concat)
  },
  getTotalPrice: state => {
    let total = 0

    state.items.forEach(item => {
      total += item.price
    })

    return total
  }
}

export const mutations = {
  addItem: (state, item) => {
    state.items.push(item)
  },
  clearItems: state => {
    state.items.splice(0, state.items.length)
  },
  setDownloaded: (state, downloaded) => {
    state.downloaded = downloaded
  },
  setIsFinished: (state, isFinished) => {
    state.isFinished = isFinished
  },
  setItems: (state, items) => {
    state.items.splice(0, state.items.length, ...items)
  },
  setItemMedia: (state, { index, media }) => {
    // eslint-disable-next-line
    state.items[index].media.splice(0, state.items[index].media.length, ...media)
  },
  setItemPrice: (state, { index, price }) => {
    state.items[index].price = price
  },
  setOrderId: (state, orderId) => {
    state.orderId = orderId
  },
  setProfile: (state, profile) => {
    state.profile.email = profile.email
    state.profile.name = profile.name
  },
  setPayment: (state, payment) => {
    state.payment = payment
  },
  setStorageURL: (state, url) => {
    state.storageURL = url
  },
  setTemplateID: (state, id) => {
    state.templateID = id
  },
  setToken: (state, token) => {
    state.token = token
  },
  setTransactionID: (state, id) => {
    state.transactionID = id
  },
  storeImage: async (state, { albumId, blobImage, filename, callback }) => {
    state.storageURL = await albums.methods.storeAlbumMedium(albumId, blobImage, filename, callback)
  }
}

export const actions = {
  // item = { data, media, price, resourceId, type }
  addItem: ({ commit }, item) => {
    commit('addItem', item)
  },
  // media = array of medium uid
  addDigitalMedia: ({ commit }, { media, resourceId, price }) => {
    commit('addItem', {
      data: {
        description: 'Mídia digital'
      }, // Data is not a backend information so far
      media: media,
      price: price,
      resourceId: resourceId, // For now resource must be a selection
      type: 'digital_media'
    })
  },
  // Add new media, removing existing ones (duplicates)
  addItemMedia: ({ state, commit }, { index, media }) => {
    // Concat media arrays and remove duplicates
    let concat = [...state.items[index].media, ...media]
    let unique = arrays.methods.unique(concat)

    commit('setItemMedia', {
      index: index,
      media: unique
    })
  },
  createOrder: ({ state, commit, getters }) => {
    return new Promise((resolve, reject) => {
      let params = {
        name: state.profile.name,
        email: state.profile.email,
        items: getters.getItems
      }

      api.orders
        .create(params)
        .then(response => {
          commit('setOrderId', response.uid)
          resolve(response.uid)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createPayment: ({ state, commit }, orderId = state.orderId) => {
    return new Promise((resolve, reject) => {
      if (orderId) {
        let params = { order_uid: orderId }

        api.payments
          .create(params)
          .then(response => {
            commit('setPayment', response)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      } else {
        reject()
      }
    })
  },
  emptyCart: context => {
    context.commit('clearItems')
    context.commit('setProfile', { email: null, name: null })
    context.commit('setDownloaded', false)
    context.commit('setIsFinished', false)
    context.commit('setPayment', null)
    context.commit('setStorageURL', null)
    context.commit('setTemplateID', null)
    context.commit('setToken', null)
    context.commit('setTransactionID', null)
  },
  setDownloaded: (context, downloaded) => {
    context.commit('setDownloaded', downloaded)
  },
  setIsFinished: (context, isFinished) => {
    context.commit('setIsFinished', isFinished)
  },
  setItemMedia: ({ commit }, { index, media, price }) => {
    commit('setItemMedia', { index, media })

    if (price) {
      commit('setItemPrice', { index, price })
    }
  },
  setItemPrice: ({ commit }, { index, price }) => {
    commit('setItemPrice', { index, price })
  },
  // profile = { email, name }
  setProfile: (context, profile) => {
    context.commit('setProfile', profile)
  },
  setPayment: (context, payment) => {
    context.commit('setPayment', payment)
  },
  setStorageURL: (context, url) => {
    context.commit('setStorageURL', url)
  },
  setTemplateID: (context, id) => {
    context.commit('setTemplateID', id)
  },
  setToken: (context, token) => {
    context.commit('setToken', token)
  },
  setTransactionID: (context, id) => {
    context.commit('setTransactionID', id)
  },
  removeCollectables: ({ state, commit }) => {
    let itemsWithoutCollectables = state.items.filter(function(item) {
      return item.type !== 'collectable'
    })

    commit('setItems', itemsWithoutCollectables)
  },
  storeImage: (context, callback) => {
    let extention =
      context.rootState.settings.imageFormat == 'jpeg'
        ? 'jpg'
        : context.rootState.settings.imageFormat

    context.commit('storeImage', {
      albumId: context.rootState.album.id,
      blobImage: context.rootState.fabric.blobImage,
      filename: uuid.v4() + '.' + extention,
      callback: callback
    })
  }
}

export default {
  namespaced: true,
  state: {
    items: [],
    downloaded: false,
    isFinished: false,
    profile: {
      name: null,
      email: null
    },
    storageURL: null,
    templateID: null,
    token: null,
    transactionID: null,
    orderId: null,
    payment: null
  },
  getters,
  mutations,
  actions
}
