<template lang="pug">
  Pagination(
    v-show="!isLoading"
    v-model="pageNumber",
    :perPage="perPage",
    :totalRows="totalRows")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Pagination from '../../company/Pagination'

export default {
  components: {
    Pagination
  },

  computed: {
    ...mapState({
      isLoading: state => state.order.isLoading,
      perPage: state => state.order.settings.perPage,
      totalRows: state => state.order.total
    }),

    pageNumber: {
      get() {
        return this.$store.state.order.currentPage
      },
      set(page) {
        this.setCurrentPage(page)
        this.loadOrders()
      }
    }
  },

  methods: {
    ...mapActions({
      loadOrders: 'order/loadOrders',
      setCurrentPage: 'order/setCurrentPage'
    })
  }
}
</script>

<style lang="sass" scoped></style>
