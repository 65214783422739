import BaseModel from '@/models/base_model'
import Medium from '@/models/medium'

export default class Face extends BaseModel {
  static name = 'face'
  static entity = 'faces'
  static primaryKey = 'uid'

  static fields() {
    return {
      uid: this.attr(null),
      persisted: this.attr(false),
      coords: this.attr({ x: null, y: null, w: null, h: null }),
      // belongs to medium
      medium_uid: this.attr(null),
      medium: this.belongsTo(Medium, 'medium_uid')
    }
  }
}
