import BaseModel from '@/models/base_model'

export default class Album extends BaseModel {
  static name = 'album'
  static entity = 'albums'
  static primaryKey = 'uid'

  static fields() {
    return {
      uid: this.attr(null),
      name: this.attr(''),
      description: this.attr(''),
      starts_at: this.attr(null),
      cover_url: this.attr(''),
      watermark: this.attr(false),
      media_visibility: this.attr('all'),
      recognition: this.attr(false),
      visibility: this.attr(''),
      permissions: this.attr(['all']),
      // belongs to profile
      profile_user_name: this.attr(null),
      profile_order: this.attr(null),
      // has many media
      medium_count: this.attr(null),
      // has many people
      person_count: this.attr(null)
    }
  }
}
