<template lang="pug">
  ValidationProvider(
    :name="name"
    :rules="rules"
    v-slot="{ errors }")
    b-form-group(
      :description="description"
      :label="label"
      label-for="name")
      b-input-group(
        prepend="@")
        b-form-input(
          :id="name"
          :placeholder="placeholder"
          v-model="inputValue")
      span.field-error(
        v-show="errors.length > 0"
        class="is-invalid") {{ errors[0] }}.
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },

  props: {
    description: String,
    label: {
      type: String,
      default: function() {
        return this.$t('fields.' + this.name)
      }
    },
    name: String,
    placeholder: String,
    rules: String,
    value: String
  },

  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }

  // methods: {
  //   // If you want to display either the description or the error, not both
  //   getDescription(errors) {
  //     return errors.length > 0 ? null : this.description
  //   }
  // }
}
</script>

<style lang="sass" scoped>
@import "../../assets/stylesheets/_variables.sass"

.field-error
  font-size: 13px
  color: $primary-color
</style>
