import BasePaginatedCollectionAdapter from '@/adapters/base_paginated_collection_adapter'
import MyProfileAdapter from '@/adapters/my_profile_adapter'

import Album from '@/models/album'
import MyProfile from '@/models/my_profile'

export default class AlbumsAdapter extends BasePaginatedCollectionAdapter {
  static model = Album
  static targetModel = Album
  static property = 'albums'

  constructor(params = {}) {
    params.parent = new MyProfileAdapter({ data: MyProfile.query().first() })
    super(params)
  }

  get url() {
    return '/api/v1/albums'
  }

  get canCreate() {
    return this.data // && this.data.permissions.includes('all')
  }
}
