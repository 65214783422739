import Base from './base.js'
import store from '../../store'

const API_URL = process.env.VUE_APP_ALBUMS_API_URL
export default class Archives {
  // GET   /api/v1/archives/:uid
  // response:
  //
  async get(archiveId) {
    let url = API_URL + '/api/v1/archives/' + archiveId
    let config = { headers: store.getters['auth/getAxiosHeader'].headers }

    return await Base.get(url, config)
  }
}
