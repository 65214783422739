<template lang="pug">
  fieldset
    TextInput#name-field(
      name="order.name"
      :label="$t('model.order.fields.name')"
      rules="required"
      type="text"
      v-model="value.name")
    EmailInput(
      name="order.email"
      :label="$t('model.order.fields.email')"
      v-model="value.email")
    EmailInput(
      v-if="retype"
      name="order.email-confirmation"
      :label="$t('model.order.fields.email-confirmation')"
      confirm="order.email"
      v-model="emailConfirmation")
</template>

<script>
import EmailInput from '../inputs/EmailInput.vue'
import TextInput from '../inputs/TextInput'

export default {
  components: {
    EmailInput,
    TextInput
  },

  props: {
    value: {
      name: String,
      email: String
      // password: String,
      // username: String
    },
    retype: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      emailConfirmation: ''
    }
  }
}
</script>

<i18n>
  pt-BR:
    name: 'Nome'
</i18n>
