import { Model } from '@vuex-orm/core'

import { apiClassMethods, apiInstanceMethods } from '@/models/mixins/api'
import { errorsClassMethods, errorsInstanceMethods } from '@/models/mixins/errors'
import { viewInstanceMethods } from '@/models/mixins/view'

class BaseModel extends Model {
  constructor(record) {
    super(record)
    this.resetErrors()
  }

  static get foreignKey() {
    return Array.isArray(this.primaryKey) ? this.primaryKey : `${this.name}_${this.primaryKey}`
  }

  static get authHeaders() {
    try {
      // TODO: improve auth store
      return this.store().getters['auth/getAxiosHeader'].headers
    } catch {
      return null
    }
  }
}

Object.assign(BaseModel, apiClassMethods)
Object.assign(BaseModel, errorsClassMethods)
Object.assign(BaseModel.prototype, apiInstanceMethods)
Object.assign(BaseModel.prototype, errorsInstanceMethods)
Object.assign(BaseModel.prototype, viewInstanceMethods)

export default BaseModel
