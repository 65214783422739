<template lang="pug">
  b-avatar(
    class="p-1"
    :size="size"
    variant="light"
    :src="url"
    @click="$emit('click')")
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import MyProfileAdapter from '@/adapters/my_profile_adapter'

@Component({
  props: {
    size: {
      type: String,
      default: '46px'
    }
  }
})
class Icon extends Vue {
  profile = new MyProfileAdapter()

  beforeMount() {
    this.profile.loadFromStore()
  }

  get url() {
    return this.profile.data.picture_url
  }
}
export default Icon
</script>
