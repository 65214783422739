<template lang="pug">
  ValidationProvider(
      :rules="{ required: isRequired, regex: /^[(][0-9]{2}[)] [0-9]{4,5}\-[0-9]{4}$/ }"
      :name="name"
      v-slot="{ errors }")
    b-form-group#phone-input-group(
      :label="label"
      label-for="name")
      b-form-input(
        :disabled="disabled"
        :id="name"
        v-model='inputValue'
        v-mask="['(##) ####-####', '(##) #####-####']"
        :placeholder="placeholder"
        type="tel")
      span.field-error(v-show="errors.length>0" class="is-invalid") {{ errors[0] }}.
</template>

<script>
import { mask } from 'vue-the-mask'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },
  directives: {
    mask
  },
  props: {
    disabled: Boolean,
    label: {
      type: String,
      default() {
        return this.$t('fields.phone')
      }
    },
    name: String,
    placeholder: {
      type: String,
      default: ''
    },
    rules: String, // 'required' only
    value: String
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },

    isRequired() {
      return this.rules != undefined && this.rules.includes('required')
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/stylesheets/_variables.sass"

.field-error
  font-size: 13px
  color: $primary-color
</style>
