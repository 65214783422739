import BaseAdapter from '@/adapt/base-adapter'
import ProfileAlbumsAdapter from '@/adapt/profile-albums-adapter'
import ProfilePeopleAdapter from '@/adapt/profile-people-adapter'

import Profile from '@/models/profile'

export default class ProfileAdapter extends BaseAdapter {
  static model = Profile
  static relationships = ['albums']

  constructor(username, params = {}) {
    super(params)
    this.albums = new ProfileAlbumsAdapter(username)
    this.people = new ProfilePeopleAdapter(username) // People from current user following this profile
  }

  _beforeLoad(id) {
    // Update the username if it has changed as a parameter to load()
    this.albums.username = id
    this.people.username = id
  }

  // Update the people from visitor user's profile that are following this profile
  async updateFollowers(peopleArray) {
    // 22 02 11 (rodrigo): The following commented code is the beginning of add and remove person as
    // a member. For now those endpoints are not available (add requires invitation and remove
    // doesn't exist)
    // if (peopleArray.length > 0 && this.people.data.length == 0) {
    //   // The user wants to follow the profile, but she wasn't
    //   console.log('profile-adapter updateFollowers add user as member')
    //   // let profileMemberAdapter = new ProfileMemberAdapter(this.username)
    //   // ProfileMemberAdapter.create()
    // } else {
    //   if (peopleArray.length == 0 && this.people.data.length > 0) {
    //     // The user was following the profile, but she doesn't want it anymore
    //     console.log('profile-people-adapter updateFollowers remove user as member')
    //   }
    // }

    await this.people.updatePeople(peopleArray)
  }

  get url() {
    return `/api/v1/profiles/${this.data.user_name}`
  }
}
