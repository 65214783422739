import { configure, extend } from 'vee-validate'
import {
  alpha_num,
  alpha_spaces,
  dimensions,
  email,
  length,
  numeric,
  regex,
  required
} from 'vee-validate/dist/rules'
import i18n from '../i18n/index.js'

extend('alpha_num', alpha_num)
extend('alpha_spaces', alpha_spaces)
extend('dimensions', dimensions)
extend('email', email)
extend('length', length)
extend('numeric', numeric)
extend('regex', regex)
extend('required', required)

extend('verification_code', {
  ...regex,
  message: (_, values) => {
    values._field_ = i18n.t('fields.verification-code')
    return i18n.t('validation.verification_code', values)
  }
})

extend('email_confirmation', {
  params: ['email'],
  validate: (value, { email }) => {
    return value === email
  },
  // the values param is the placeholders values
  message: (_, values) => {
    values._field_ = i18n.t('fields.email-confirmation')
    return i18n.t('validation.email_confirmation', values)
  }
})

extend('expiration_date', {
  validate: value => {
    // If tue user didn't finish typing
    if (value.length < 7) return false

    let inputDate = value.split('/')
    let currentDate = new Date()

    // If month is not between 1 and 12
    if (inputDate[0] < 1 || inputDate[0] > 12) {
      return false
    }

    // If the year is not the current or a future one
    if (inputDate[1] < currentDate.getFullYear()) {
      return false
    }

    // If it's a previous month in the current year
    if (inputDate[1] == currentDate.getFullYear() && inputDate[0] < currentDate.getMonth() + 1) {
      return false
    }

    return true
  },
  // the values param is the placeholders values
  message: (_, values) => {
    values._field_ = i18n.t('fields.expiration-date')
    return i18n.t('validation.expiration_date', values)
  }
})

// Link vue-i18n with vee-validate
configure({
  defaultMessage: (fieldName, values) => {
    let [model, field] = fieldName.split('.')

    values._field_ = field
      ? i18n.t(`model.${model}.fields.${field}`) // model-related field
      : i18n.t(`fields.${model}`) // not model-related (base.pt-BR.json)

    return i18n.t(`validation.${values._rule_}`, values)
  }
})

// Class created to identify ValidationError in a try-catch and
// show a proper alert to the user
export default class ValidationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ValidationError'
  }
}
