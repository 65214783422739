<template lang="pug">
  .container-fluid
    FilterOptionsFace(
      :face="filters.face"
      :selfie="filters.selfie"
      @clear="$emit('clear')"
      @filter-by-selfie="$emit('filter-by-selfie', $event)")
</template>

<script>
import FilterOptionsFace from './FilterOptionsFace'

export default {
  components: {
    FilterOptionsFace
  },

  props: {
    filters: Object
  }
}
</script>

<i18n>
  pt-BR:
    title: 'Filtros disponíveis'
</i18n>

<style lang="sass" scoped></style>
