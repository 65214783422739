<template lang="pug">
  #gallery-navbar(
    v-if="hasMedia || isFiltering")
    Navbar.top-menu.mb-4
      template(#left)
        span.media-count {{ mediaCountLabel }}
      template(#right)
        div(v-if="canSelect || canFilter")
          b-dropdown.d-block.d-sm-none.mr-3.mr-3.mb-3(
            variant="primary"
            right)
            template(v-slot:button-content)
              span.text-uppercase {{ $t('actions') }}
            b-dropdown-item(
              v-if="canSelect"
              @click="toggleSelection")
              label.text-uppercase(v-if="!isSelecting") {{ $t('select') }}
              label.text-uppercase(v-else) {{ $t('cancel') }}
            b-dropdown-item(
              v-if="canFilter"
              @click="showFilterOptions")
              label.text-uppercase {{ $t('filter') }}
        .d-none.d-sm-block
          Button(
            v-if="canSelect"
            @click="toggleSelection")
            label(
              v-if="!isSelecting") {{ $t('select') }}
            label(
              v-else) {{ $t('cancel') }}
          Button(
            v-if="canFilter"
            @click="showFilterOptions")
            label {{ $t('filter') }}
    GalleryFilterModal(
      :modalId="FILTER_MODAL_ID")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Button from '../../general/Button/ButtonNew'
import GalleryFilterModal from './GalleryFilterModal'
import Navbar from '../Navbar'

export default {
  components: {
    Button,
    GalleryFilterModal,
    Navbar
  },

  computed: {
    ...mapGetters({
      isFiltering: 'gallery/isFiltering'
    }),

    ...mapState({
      hasMedia: state => state.gallery.content.total,
      isSelecting: state => state.gallery.selection.active,
      resource: state => state.gallery.resource.kind
    }),

    canFilter() {
      // Filter are not implemented for selections yet.
      return this.resource == 'album'
    },

    canSelect() {
      return this.resource != 'order'
    },

    mediaCountLabel() {
      if (this.hasMedia) {
        let label = this.hasMedia.toString()

        if (this.hasMedia > 1) {
          return label + ' ' + this.$t('media-count-plural')
        }

        return label + ' ' + this.$t('media-count-singular')
      }

      return ''
    }
  },

  methods: {
    ...mapActions({
      toggleSelection: 'gallery/toggleSelection'
    }),

    showFilterOptions() {
      this.$bvModal.show(this.FILTER_MODAL_ID)
    }
  },

  created() {
    this.FILTER_MODAL_ID = 'gallery-filter-modal'
  }
}
</script>

<i18n>
  pt-BR:
    cancel: "Cancelar"
    filter: "Filtrar"
    media-count-plural: "fotos"
    media-count-singular: "foto"
    select: "Selecionar"
    actions: "Ações"
</i18n>

<style lang="sass" scoped>
.media-count
  padding-left: 15px
  color: grey
  text-transform: uppercase

.top-menu
  border-bottom: 1px solid #f8f8f8

button.dropdown-toggle
  background-color: green
  text-transform: uppercase
</style>
