<template lang="pug">
  #review-step
    Navbar(
      bordered
      :title="$t('cart')")
      template(#left)
        Button(
          icon="left"
          @onClick="$emit('back')")
    .container-fluid
      .row
        .col-10.offset-1.col-sm-8.offset-sm-2.col-md-6.offset-md-3.col-lg-4.offset-lg-4
          Icon.icon-sq-xl.txt-secondary.mb-3(
            icon="photo-video")
          h1.instruction-title.mb-3 {{ $t('title') }}
          p.instruction-description.mb-4 {{ $t('description') }}
          OrderItems(
            v-if="items.length > 0")
          span
            strong
              | E-mail:
          span {{ ' ' + email }}
          .text-center.mt-4
            Button(
              :label="$t('keep-purchasing')"
              @onClick="$emit('keep')")
            .mt-3.mb-5
              Link(
                :label="$t('cancel')"
                @click="$emit('cancel')")
          br
          br
    Navbar(
      bordered
      color="white"
      fixed="bottom")
      template(#right)
        Button(
          :label="$t('next')"
          classes="primary"
          @onClick="$emit('ok')")
</template>

<script>
import { mapState } from 'vuex'
import Button from '../../Button'
import Icon from '../../Icon'
import Link from '../../general/Link'
import Navbar from '../../company/Navbar'
import OrderItems from '../../application/Order/OrderItems'

export default {
  components: {
    Button,
    Icon,
    Link,
    Navbar,
    OrderItems
  },

  computed: {
    ...mapState({
      email: state => state.cart.profile.email,
      items: state => state.cart.items
    })
  }
}
</script>

<i18n>
  pt-BR:
    cart: "Carrinho"
    title: "Sua compra"
    cancel: "Esvaziar carrinho"
    description: "Veja como está seu carrinho até o momento."
    email: "E-mail"
    keep-purchasing: "Continuar comprando"
    next: "Finalizar"
    total: "Quantidade de fotos"
</i18n>

<style lang="sass" scoped></style>
