import Vue from 'vue'
import Vuex from 'vuex'

import album from './modules/album.js'
import auth from './modules/auth.js'
import cart from './modules/cart.js'
import collectables from './modules/collectables.js'
import fabric from './modules/fabric.js'
import gallery from './modules/gallery.js'
import library from './modules/library.js'
import payment from './modules/payment.js'
import order from './modules/order.js'
import selection from './modules/selection.js'
import settings from './modules/settings.js'
import ux_controls from './modules/ux_controls.js'

// --- VuexORM and VuexORMAxios

import axios from 'axios'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import Album from '@/models/album'
import AlbumMedium from '@/models/album_medium'
import AlbumPerson from '@/models/album_person'
import AlbumPersonMedium from '@/models/album_person_medium'
import Face from '@/models/face'
import Medium from '@/models/medium'
import MyProfile from '@/models/my_profile'
import MyProfileStorageCredentials from '@/models/my_profile_storage_credentials'
import Person from '@/models/person'
import PersonStorageCredentials from '@/models/person_storage_credentials'
import Profile from '@/models/profile'

VuexORM.use(VuexORMAxios, {
  axios,
  baseURL: process.env.VUE_APP_ALBUMS_API_URL
})

// Configure models
const database = new VuexORM.Database()
database.register(Album)
database.register(AlbumMedium)
database.register(AlbumPerson)
database.register(AlbumPersonMedium)
database.register(Face)
database.register(Medium)
database.register(MyProfile)
database.register(MyProfileStorageCredentials)
database.register(Person)
database.register(PersonStorageCredentials)
database.register(Profile)

// --- * --- * --- * --- * ---

Vue.use(Vuex)

// This getter uses data from album and cart. That's why it's here instead of
// being in one of those stores.
export const getters = {
  getCharge: state => {
    if (state.cart.token && state.cart.email && state.album.id) {
      return {
        token: state.cart.token,
        email: state.cart.email,
        items: [{ type: 'Album', uid: state.album.id }]
      }
    }

    return null
  }
}

export const actions = {
  addSelectedMediaToCart: ({ state, dispatch, getters }) => {
    let selected = getters['gallery/getSelectionIds']
    let resourceId = state.gallery.resource.id
    // Find the 'digital_media' from that resource that's already in the cart
    let index = getters['cart/findResourceMedia'](resourceId)
    // Add only new media (not duplicates)
    if (index >= 0) {
      // Add selected media to the item and remove duplicates
      let media = getters['cart/getItemMergedMedia']({ index, media: selected })
      // Calculate new price based on new media set
      let price = getters['selection/getPrice'](media.length)
      dispatch('cart/setItemMedia', { index, media, price })
    } else {
      // Add all the selected media
      let price = getters['selection/getPrice'](selected.length)
      dispatch('cart/addDigitalMedia', { media: selected, resourceId, price })
    }
  }
}

export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  modules: {
    album,
    auth,
    cart,
    collectables,
    fabric,
    gallery,
    library,
    payment,
    order,
    selection,
    settings,
    ux_controls
  },
  getters,
  actions
})
