<template lang="pug">
  Form(
    ref="formWrapper"
    :no-button="noButton"
    @submit="$emit('submit')")
    RadioInput(
      id="profile-type-radio-group"
      name="profile-type-radio-name"
      :options="options"
      rules="required"
      size="lg"
      v-model="selected"
      v-slot="{ ariaDescribedby }"
      stacked)
      //- :label="label"
</template>

<script>
import Form from '@/components/Shared/Form'
import RadioInput from '@/components/inputs/RadioInput'

export default {
  components: {
    Form,
    RadioInput
  },

  props: {
    noButton: {
      type: Boolean,
      default: true
    },
    value: Boolean
  },

  data() {
    return {
      selected: '',
      options: [
        { text: 'Acervo pessoal e familiar', value: 'personal' },
        { text: 'Acervo de uma comunidade', value: 'community' }
      ]
    }
  },

  watch: {
    selected() {
      this.$emit('input', this.selected == 'personal' ? true : false)
    }
  }
}
</script>
