<template lang="pug">
  ToggleButton(
    :description="description"
    :disabled="disabled"
    :id="name"
    :label="label"
    :name="name"
    :size="size"
    switch
    v-model="checked")
</template>

<script>
import ToggleButton from '@/components/inputs/ToggleButton'

export default {
  components: {
    ToggleButton
  },

  props: {
    value: String,
    disabled: Boolean,
    label: String,
    name: String,
    size: String
  },

  data() {
    return {
      checked: false
    }
  },

  computed: {
    description() {
      return this.checked ? this.$t('description-own') : this.$t('description-all')
    }
  },

  watch: {
    checked() {
      if (this.checked) {
        this.$emit('input', 'own')
      } else {
        this.$emit('input', 'all')
      }
    }
  },

  beforeMount() {
    this.checked = this.value == 'own'
  }
}
</script>

<i18n>
  pt-BR:
    description-all: "Pela configuração atual, todos com acesso ao álbum poderão ver todas as fotos."
    description-own: "Pela configuração atual, aqueles com acesso ao álbum só verão fotos em que eles e seus dependentes apareçam."
</i18n>

<style lang="sass" scoped></style>
