<i18n>
  pt-BR:
    processing: 'Processando...'
</i18n>

<template lang="pug">
  div
    .thumbnail-box(:class="selectedClass")
      .image(
        :class="selectedClass"
        :style="background"
        @click="clicked")
      .processing.text-muted(
        v-if="isProcessing"
        @click="clicked") {{ $t('processing') }}
      Button#btn-zoom(
        v-if="isSelecting"
        @click="$emit('preview', mediumAdapter)")
        Icon.text-white.black-shadow(icon="zoom-in")
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

import { images } from '@/mixins/images.js'
import Button from '@/components/general/Button/ButtonNew'
import Icon from '@/components/Icon'

@Component({
  components: { Button, Icon },
  props: { mediumAdapter: Object, isSelecting: Boolean },
  mixins: [images]
})
class Thumbnail extends Vue {
  isProcessing = false

  get background() {
    let url = `url(${this.mediumAdapter.data.thumb_url})`
    return url && !this.isProcessing ? `background-image: ${url};` : 'background-color: #eeeeee;'
  }

  get selectedClass() {
    return this.mediumAdapter.selected ? 'selected' : ''
  }

  async beforeMount() {
    try {
      await this.loadImageFromURL(this.mediumAdapter.data.thumb_url)
    } catch (err) {
      this.isProcessing = true // still processing thumbnail
    }
  }

  clicked() {
    let event = this.isSelecting ? 'select' : 'preview'
    this.$emit(event, this.mediumAdapter)
  }
}
export default Thumbnail
</script>

<style lang="sass" scoped>
@import '@/assets/stylesheets/_variables.sass'
#btn-zoom
  position: absolute
  top: 0
  left: 0
.processing
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  &:hover
    cursor: default
.thumbnail-box
  position: relative  // to position btn zoom
  &.selected
    padding: 10px
    background-color: $primary-color
.image
  width: 100%
  padding-bottom: 100%
  background-size: cover
  background-position: center
  &.selected
    border: 10px
</style>
