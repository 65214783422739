import api from '../../api'
// import { images } from '../images.js'
// import { files } from '../files.js'
// import store from '../../store'

export const plans = {
  methods: {
    async createPlan(params) {
      return await api.plans.create(this.getPlanParams(params))
    },

    async getPlans() {
      return await api.plans.index()
    },

    // Translate from frontend object to backend object
    getPlanParams(params) {
      return params
        ? {
            name: params.name.length > 0 ? params.name : null,
            single_price_cents: parseInt(params.singlePrice) * 100,
            cap_price_cents: parseInt(params.capPrice) * 100
          }
        : {}
    }

    // Translate frontend notation to backend notation
    // getProfileParams(profile) {
    //   return profile
    //     ? {
    //         // The verifications below are necessary for optional fields
    //         // because if they are '' it will be erased during an update
    //         // eslint-disable-next-line
    //         description: profile.description.length > 0 ? profile.description : null,
    //         name: profile.name,
    //         picture_url: profile.pictureURL.length > 0 ? profile.pictureURL : null,
    //         user_name: profile.username.length > 0 ? profile.username : null
    //       }
    //     : {}
    // },

    // async storeAvatar(file, callback) {
    //   let base64 = await files.methods.loadFileReader(file)
    //   let filename = files.methods.getUuidName(file)
    //   let overwrite = true // Overwrite previous profile picture

    //   try {
    //     return await api.s3.create(
    //       filename,
    //       images.methods.imageBlob(base64),
    //       store.state.profile.storageCredential,
    //       overwrite,
    //       callback
    //     )
    //   } catch (error) {
    //     // In case of a S3 error, ask for a new storage credential
    //     await store.dispatch('profile/getStorageCredential')

    //     return await api.s3.create(
    //       filename,
    //       images.methods.imageBlob(base64),
    //       store.state.profile.storageCredential,
    //       overwrite,
    //       callback
    //     )
    //   }
    // },

    // async updateProfile(profile) {
    //   return await api.profiles.update(this.getProfileParams(profile))
    // }
  }
}
