<template lang="pug">
  b-alert#alert(
    :show="countdown"
    class='fixed-top'
    dismissible
    fade
    @dismissed="setAlertCountdown(0)"
    @dismiss-count-down="alertCountdownChanged") {{ message }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Alert',

  data() {
    return {
      dismissCountDown: 0
    }
  },

  computed: {
    ...mapState({
      message: state => state.ux_controls.alertMessage,
      countdown: state => state.ux_controls.alertCountdown
    })
  },

  methods: {
    ...mapActions({
      setAlertCountdown: 'ux_controls/setAlertCountdown',
      alertCountdownChanged: 'ux_controls/alertCountdownChanged'
    })
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/stylesheets/_variables.sass'

#alert
  // background-color: black
  max-width: 300px
  left: auto
  top: $navbar-height + 10px
  right: 10px
  // color: white
  // background: rgba(17, 16, 30, .7)
  margin: 10px
  border: none
  z-index: 1100 // to work on modals, that on bootstrap-vue have 1050 as z-index
</style>
