/* eslint-disable */
import ValidationError from '../plugins/vee-validate.js'

export const forms = {
  methods: {
    // Return an abbreviation of the name adding '...' at the end if needed
    getFirstLastNames(fullName, maxLength) {
      if (maxLength > 0) {
        let response = (`${this.getFirstName(fullName)} ${this.getLastName(fullName, true)}`).trim()

        return response.length <= maxLength
          ? response
          : `${response.substring(0, maxLength - 3)}...`
      }

      return fullName
    },

    // Return the first string of the full name
    getFirstName(fullName) {
      return fullName.split(' ')[0]
    },

    // Return last string or all strings except the first one (if lastOnly == true)
    getLastName(fullName, lastOnly) {
      var splittedName = fullName.split(' ')
      // If there's at least one family name
      if (splittedName.length > 1) {
        // Rodrigo de Castro Vianna Barbosa = Barbosa
        if (lastOnly) {
          return splittedName[splittedName.length - 1]
        }
        // Rodrigo de Castro Vianna Barbosa = de Castro Vianna Barbosa
        return splittedName.slice(1, splittedName.length).join(' ')
      }
      // No family name. Only one name, which is the first
      return ''
    },

    // Depending on 'full' prop passed to NameInput component, returns a name
    // object with firstName, lastName and fullName
    getNameInput(name, full) {
      // NameInput has 1 input for full name
      if (full) {
        return {
          firstName: this.getFirstName(name.fullName),
          lastName: this.getLastName(name.fullName),
          fullName: name.fullName
        }
      }
      // NameInput has 2 inputs: first and last name
      return {
        firstName: name.firstName,
        lastName: name.lastName,
        fullName: name.lastName == '' ? name.firstName : `${name.firstName} ${name.lastName}`
      }
    },

    // expirationDate should be in the format 'MM/YYYY'
    splitExpirationDate(expirationDate) {
      let response = {}
      let split = expirationDate.split('/')

      response.month = split[0]
      response.year = split[1]

      return response
    },

    // This is a wrapper function to isolate validate() function in order to
    // get to test this component without taking care of VeeValidate
    isFormValid(form) {
      return new Promise((resolve, reject) => {
        form.validate().then(response => {
          if (response) {
            resolve(true)
          }

          reject(new ValidationError('Invalid form'))
        })
      })
    }
  }
}
