<template lang="pug">
  BaseView
    Navbar(
      brand
      :title="$t('title')")
      template(#right)
        CreateAlbumDropdown.mr-2
    div(v-if="albumsAdapter.loading")
      Loading
    div(v-else)
      .row.no-gutters.mx-2
        .col-6.col-sm-4.col-md-3.col-lg-2(
          v-for="album in albumsAdapter.data"
          :key="album.uid")
          Thumbnail(:album='album')
      div(v-if="albumsAdapter.data.length == 0")
        Instructions(
          icon="library"
          :title="$t('empty-album-title')"
          :description="$t('empty-album-description')")
      div(v-else)
        Pagination(
          v-model="albumsAdapter.currentPage"
          :perPage="albumsAdapter.perPage"
          :totalRows="albumsAdapter.count")
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapActions } from 'vuex'

import AlbumsAdapter from '@/adapters/albums_adapter'
import BaseView from '@/layouts/Base'
import CreateAlbumDropdown from '@/components/Albums/CreateAlbumDropdown'
import Instructions from '@/components/application/Instructions'
import Loading from '@/components/Shared/Loading'
import Navbar from '@/components/Shared/Navbar'
import Pagination from '@/components/company/Pagination'
import Thumbnail from '@/components/Albums/Thumbnail'

@Component({
  components: {
    BaseView,
    CreateAlbumDropdown,
    Instructions,
    Loading,
    Navbar,
    Pagination,
    Thumbnail
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      logout: 'auth/logout'
    })
  }
})
class Index extends Vue {
  albumsAdapter = new AlbumsAdapter()

  async beforeMount() {
    await this.albumsAdapter.reset()
    await this.albumsAdapter.load()
  }
}
export default Index
</script>

<i18n>
  pt-BR:
    empty-album-description: 'Não há nenhum álbum em seu acervo. Comece criando um pelo menu acima.'
    empty-album-title: 'Acervo vazio'
    title: 'Meus álbuns'
</i18n>
