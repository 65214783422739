<template lang="pug">
  .form-group.text-center
    ValidationProvider(
      ref="validation"
      :name="name"
      :rules="rules"
      v-slot="{ errors }")
      label(
        class="d-block"
        :for="name") {{ label }}
      AvatarUpload(
        :loading="loading"
        :no-reset="action == 'edit'"
        size="7"
        :src="value.url"
        @remove="remove")
      input(
        v-show="false"
        accept="image/jpeg, image/png"
        :id="name"
        ref="inputRef"
        type="file"
        @change="onFileSelected")
      //- If you want to send a custom template to launch the input you have to
      //- give a ref to this (e.g parent) and at @click event you add
      //- $refs.parent.$refs.fileInput.click() to trigger the input click
      slot(v-if="hasSlot")
      .mt-1(v-else)
        Button(
          :disabled="loading"
          :uppercase="false"
          variant="light"
          @click="$refs.inputRef.click()") {{ ctaLabel }}...
      span.field-error(
        v-show="errors.length > 0"
        class="is-invalid") {{ errors[0] }}.
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Pica from 'pica'

import AvatarUpload from '@/components/AvatarUpload'
import Button from '@/components/company/Button'

export default {
  components: {
    AvatarUpload,
    Button,
    ValidationProvider
  },

  props: {
    // TODO: Remove 'action' prop and handle 'rules' properly. This component is not able to handle
    // the 'rules' prop properly. I had to send an 'action' prop in order to change a 'required'
    // rule to false if the user is on editing mode. When editing and the avatar is not changed
    // (e.g. the user wanted to change only her name), it shows the required error but there is a
    // previous avatar being shown.
    action: String, // 'edit', 'new'
    noReset: Boolean,
    label: String,
    name: String,
    personal: {
      type: Boolean,
      default: true
    },
    rules: String,
    value: Object // { url, blob }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    ctaLabel() {
      return this.personal ? this.$t('upload-personal') : this.$t('upload-organization')
    },

    hasSlot() {
      return this.$slots.default
    }
  },

  methods: {
    async onFileSelected(event) {
      this.$refs.validation.reset()
      this.loading = true
      // Validation for files using vee-validate
      const { valid } = await this.$refs.validation.validate(event)
      if (valid) {
        let file = event.target.files[0]

        if (file) {
          let url = URL.createObjectURL(file)
          let img = new Image()

          img.onload = async () => {
            await this._resizeImage(img)
            this.loading = false
          }

          img.src = url
        }
      }
    },

    async _resizeImage(img) {
      // create canvas with targeted dims
      let offScreenCanvas = document.createElement('canvas')
      let dim = this._newDimensions(img.width, img.height, 300)
      offScreenCanvas.width = dim.width
      offScreenCanvas.height = dim.height
      // resize image
      let pica = new Pica({ features: ['js', 'wasm'] })
      let result = await pica.resize(img, offScreenCanvas)
      let blob = await pica.toBlob(result, 'image/jpeg')
      // update avatar
      this.$emit('input', { url: URL.createObjectURL(blob), blob: blob })
    },

    _newDimensions(width, height, max) {
      let dim = { width: 0, height: 0 }
      if (width > height) {
        dim.width = max
        dim.height = Math.round(height / (width / max))
      } else {
        dim.width = Math.round(width / (height / max))
        dim.height = max
      }
      return dim
    },

    remove() {
      this.$refs.validation.reset()
      this.$emit('input', { url: '', blob: null })
    }
  } // methods
}
</script>

<i18n>
  pt-BR:
    remove: "Deletar"
    upload-personal: "Escolher selfie"
    upload-organization: "Escolher imagem"

</i18n>
