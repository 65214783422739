import BaseModel from '@/models/base_model'

export default class MediumUrlAdapter {
  constructor(resource, mediumId) {
    this.mediumId = mediumId
    this.resource = resource
  }

  async loadFromApi() {
    this.loading = true
    let response = await BaseModel.apiFetch({ url: this.url, save: false })
    this.loading = false
    return response
  }

  get url() {
    return `/api/v1/${this.resource.kind}s/${this.resource.id}/media/${this.mediumId}/url`
  }
}
