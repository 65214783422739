<template lang="pug">
  #setup-profile-flow
    ProfileTypeStep(
      v-model="isPersonal"
      v-show="currentStep == PROFILE_TYPE"
      @ok="next")
    MyProfileStep(
      :fields="profileFields"
      id="my-profile-step"
      :back-click="invitation ? false : true"
      :paragraph="$t('paragraph')"
      :personal="isPersonal"
      :my-profile-adapter="myProfileAdapter"
      v-show="currentStep == MY_PROFILE"
      @back="back"
      @ok="next"
      @show-message="$emit('show-message', $event)")
    AddDependentFlow(
      :my-profile-adapter="myProfileAdapter"
      v-show="currentStep == DEPENDENTS"
      @back="back"
      @ok="next")
</template>

<script>
import AddDependentFlow from '@/components/flows/AddDependentFlow'
import MyProfileStep from '@/components/flows/steps/MyProfileStep'
import ProfileTypeStep from '@/components/flows/steps/ProfileTypeStep'

export default {
  components: {
    AddDependentFlow,
    MyProfileStep,
    ProfileTypeStep
  },

  props: {
    invitation: Object, // { host, id } If profile creation follows an invitation
    myProfileAdapter: Object
  },

  data() {
    return {
      currentStep: 1,
      isPersonal: true,
      profileFields: ['avatar', 'name', 'user_name', 'phone'] // Personal by default, so that if it's comming from the inviation flow, PROFILE_TYPE step is skipped
    }
  },

  methods: {
    back() {
      this.currentStep--
    },

    next() {
      switch (this.currentStep) {
        case this.PROFILE_TYPE:
          // Clear up the fields because the user could be comming back from MY_PROFILE step to change her choice
          this.profileFields.splice(0, this.profileFields.length)
          this.profileFields.push('avatar', 'name', 'user_name') // Common fields
          if (this.isPersonal) {
            this.profileFields.push('phone')
          } else {
            this.profileFields.push('description')
          }
          this.currentStep++
          break
        case this.MY_PROFILE:
          if (!this.isPersonal) {
            this.$router.push('/home') // If it's a community, there's no dependent
          }
          this.currentStep++
          break
        case this.DEPENDENTS:
          if (this.invitation) {
            this.$router.push(`/${this.invitation.host}/invites/${this.invitation.id}/join`)
          } else {
            this.$router.push('/home')
          }
          break
        default:
          this.currentStep++
      }
    }
  }, // methods

  beforeMount() {
    // If the user is coming from an invitation flow, it's a personal profile
    this.currentStep = this.invitation ? this.MY_PROFILE : this.PROFILE_TYPE
  },

  created() {
    // Step numbers
    this.PROFILE_TYPE = 1
    this.MY_PROFILE = 2
    this.DEPENDENTS = 3
  }
}
</script>

<i18n>
  pt-BR:
    cta-label: "Eu quero"
    order-creation-error: "Houve um erro no processamento do seu pedido. Tente novamente ou fale com o suporte."
    media-number-error: "Selecione ao menos uma mídia para adicioná-la ao seu acervo."
</i18n>

<style lang="sass" scoped></style>
