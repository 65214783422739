<template lang="pug">
  BaseView
    Navbar(
      brand
      no-profile)
    section.d-flex.flex-column.align-items-center.justify-content-center.vph-80.text-center
      FormWrapper.text-center(
        no-card)
        h1.display-4.mb-4 {{ title }}
        p.lead.mb-4.txt-dark-grey  {{ $t('subtitle') }}
        Button(
          :label="$t('cta')"
          variant="primary"
          @click="handleAction")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseView from '@/layouts/Base'
import Button from '@/components/company/Button'
import FormWrapper from '@/layouts/FormWrapper'
import Navbar from '@/components/Shared/Navbar'
import MyProfileAdapter from '@/adapt/my-profile-adapter'
import ProfileAdapter from '@/adapt/profile-adapter'

export default {
  components: {
    BaseView,
    Button,
    FormWrapper,
    Navbar,
    MyProfileAdapter
  },

  props: {
    uid: String,
    username: String
  },

  data() {
    return {
      myProfileAdapter: new MyProfileAdapter(),
      profileAdapter: new ProfileAdapter(this.username)
    }
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    }),

    joinUrl() {
      return `/${this.username}/invites/${this.uid}/join`
    },

    title() {
      return this.$t('title').replace('%c', `@${this.username}`)
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    handleAction() {
      if (!this.isLoggedIn) {
        this.login(this.joinUrl)
      } else {
        this.$router.push(this.joinUrl)
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(async vm => {
      try {
        await vm.myProfileAdapter.load()
        if (vm.myProfileAdapter.data.user_name == vm.username) {
          // Redirect the user to its profile page if he's accessing its own invitation
          next({ name: 'profile', params: { userName: vm.username } })
        } else {
          await vm.profileAdapter.load({ id: vm.username })
          console.log('Invites Show beforeRouteEnter profileAdapter', vm.profileAdapter)
          next()
        }
      } catch (error) {
        // If the user has no profile, it just shows the invitation
        await vm.profileAdapter.load({ id: vm.username })
        console.log('Invites Show beforeRouteEnter profileAdapter', vm.profileAdapter)
        next()
      }
    })
  },

  // vue-meta settings
  metaInfo() {
    return {
      title: `Convite para o acervo ${this.profileAdapter.data.name} - Fottorama`, // max 65 char
      meta: [
        {
          name: 'description',
          content: 'Receba as fotos que ' + this.profileAdapter.data.name + ' tirar de você.' // max 155 char
        },
        {
          property: 'og:title',
          content: 'Faça parte do acervo ' + this.profileAdapter.data.name + ' no Fottorama' // max 35 char
        },
        // {
        //   property: 'og:url',
        //   content: // Full link to the current webpage address
        // },
        {
          property: 'og:description',
          // eslint-disable-next-line
          content:'Receba fotos importantes pra você que estiverem no acervo ' + this.profileAdapter.data.name // max 65 char
        },
        {
          property: 'og:image',
          content: this.profileAdapter.data.picture_url
        }
      ]
    }
  }
}
</script>

<i18n>
  pt-BR:
    cta: "Aceitar convite"
    error-cta: "Página inicial"
    error-title: "Convite inválido"
    error-subtitle: "Peça um novo link a quem lhe convidou."
    title: "Você foi convidado a participar do acervo %c!"
    subtitle: "Entre com sua conta Fottorama ou cadastre-se."
    next: "Aceitar convite"
</i18n>

<style lang="sass" scoped>
@import '@/assets/stylesheets/_variables.sass'
h1
  font-weight: 700

h1
  font-size: 2rem

@media (min-width: $screen-xs-sm)
  h1
    font-size: 2.5rem

@media (min-width: $screen-sm-md)
  h1
    font-size: 3rem

@media (min-width: $screen-md-lg)
  h1
    font-size: 3.5rem
</style>
