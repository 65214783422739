import BasePaginatedCollectionAdapter from '@/adapt/base-paginated-collection-adapter'
import Album from '@/models/album'
import Profile from '@/models/profile'

export default class ProfileAlbumsAdapter extends BasePaginatedCollectionAdapter {
  static model = Album
  static targetModel = Album
  static parentModel = Profile // ProfileAlbums retrieves albums from a Profile (parent)
  static property = 'albums'

  constructor(username, params = {}) {
    super(params)
    this.username = username
  }

  get url() {
    return `/api/v1/profiles/${this.username}/albums`
  }

  get _pId() {
    return this.username
  }
}
